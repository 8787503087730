import { Form, TreeSelect } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface ICreateSales {
  data: any;
  value: string | undefined;
  setValue: (data: string) => void;
  setIsSet?: (data: boolean) => void;
}

export const CommonTreeSelectSubCategories: React.FC<ICreateSales> = ({
  data,
  value,
  setValue,
  setIsSet,
}) => {
  const [selectedSubCategoryName, setSelectedSubCategoryName] = useState<
    string | undefined
  >(undefined);

  // Function to format tree data
  const formatTreeData = (data: any, isTopLevel = true) => {
    return {
      title: data?.name?.name, // Display name
      value: data?.id, // Unique value
      key: data?.id, // Key for TreeSelect
      disabled: isTopLevel, // Disable only the first parent (top level)
      children: data?.children?.map(
        (child: any) => formatTreeData(child, false) // Pass 'false' to children to enable them
      ),
    };
  };
  const [form] = Form.useForm();
  const location = useLocation();
  const onChange = (newValue: string, label: any) => {
    setValue(newValue); // Update selected value
    setSelectedSubCategoryName(label[0]);
    form.setFieldValue("category", newValue);
    setIsSet && setIsSet(true);
  };

  useEffect(() => {
    if (value) {
      setValue(value?.toString());
    }
  }, [value]);

  return (
    <Form.Item
      label="Category"
      name={"category"}
      rules={[
        {
          required: location?.pathname === "/add-job-card" ? false : true,
          message: "Category is required",
        },
      ]}
    >
      <TreeSelect
        style={{ width: "100%" }}
        value={value}
        dropdownStyle={{ height: 280, overflow: "auto" }}
        placeholder="Please select an option"
        treeData={[formatTreeData(data?.data)]} // Format tree data with dynamic logic
        onChange={onChange}
        treeDefaultExpandAll={true}
        showSearch // Enable search functionality
        filterTreeNode={(inputValue: any, treeNode: any) =>
          treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
        className="treeSelect customSelect"
      />
    </Form.Item>
  );
};

export default CommonTreeSelectSubCategories;
