import { Col, Form, Input, message, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { validatePositiveNumber } from "../../../helpers/helperFunction";
import {
  useCreateReservationMutation,
  useCreateSelectApiMutation,
  useGetAllReservationByIdQuery,
  useGetSelectApiQuery,
  useGetSelectByIdApiQuery,
  useUpdateReservationMutation,
} from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";

interface ICreateSales {
  setOpen: (data: boolean) => void;
  setDrawer: (data: any) => void;
}

export const ReservationForm: React.FC<ICreateSales> = ({
  setOpen,
  setDrawer,
}) => {
  const [form] = Form.useForm();
  const { data: selectById } = useGetSelectByIdApiQuery("finishedgoods");
  const [createReservation, { isLoading: reservationLoading }] =
    useCreateReservationMutation();
  const [updateReservation, { isLoading: updateLoading }] =
    useUpdateReservationMutation();
  const [name, setName] = useState("reserved_for");
  const [searchParams] = useSearchParams();
  const itemName = searchParams.get("item");
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const reservation_id = searchParams.get("reservation_id");
  const navigate = useNavigate();
  const categoryParams = {
    name: name,
    model: Number(selectById?.id),
  };
  const {
    data: getAllCategory,
    isFetching,
    isLoading,
  } = useGetSelectApiQuery(categoryParams, {
    skip: !name && !selectById?.id, // Skip the query when id is 0
  });
  interface getSelectValues {
    value: string; // Ensure `value` is typed as `number`
    label: string;
  }
  const optionsCategory: getSelectValues[] | undefined =
    getAllCategory?.data?.map(({ id, name }) => ({
      value: id?.toString() as string, // Convert `id` to string
      label: name as string, // Ensure `name` is a string
    }));
  const [
    createSelectWarehouse,
    { data: addCategoryData, isLoading: createSelectLoading },
  ] = useCreateSelectApiMutation();
  const { data: reservationDataById, refetch } = useGetAllReservationByIdQuery(
    Number(reservation_id),
    {
      skip: !reservation_id,
    }
  );
  const handleCreate = async (option: string) => {
    const newOption = {
      name: option || "",
      field: name,
      model: Number(selectById?.id),
    }; // Prepare the new option

    try {
      const response = await createSelectWarehouse({
        name: "finishedgoods", // Pass the field name
        // Pass the model
        body: newOption, // Pass the body object
      }).unwrap(); // Unwrap the response for easier handling

      form.setFieldValue(name, {
        value: response?.data?.id,
        label: response?.data?.name,
      });
      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message || "Failed to create warehouse");
    }
  };
  const handleFinish = async (values: any) => {
    if (reservation_id) {
      const finalData = {
        ...values,
        reserved_for: values.reserved_for.value,
        finished_good: id,
        reserved_by: Number(localStorage.getItem("user_id")),
      };
      try {
        const response = await updateReservation({
          id: Number(reservation_id),
          body: finalData,
        }).unwrap();
        message.success(response?.message);
        navigate(
          `/finished-goods?id=${id}&type=${type}&item=${itemName}&reservation_id=${reservation_id}&reservation=true`
        );
        setDrawer("");
      } catch (err: any) {
        message.error(err?.data?.message);
      }
    } else
      try {
        const response = await createReservation({
          ...values,
          reserved_for: values.reserved_for.value,
          finished_good: id,
          reserved_by: Number(localStorage.getItem("user_id")),
        }).unwrap();
        message.success(response?.message);
        setOpen(false);
      } catch (err: any) {
        message.error(err?.data?.message);
      }
  };
  useEffect(() => {
    if (itemName) {
      form.setFieldValue("product_name", itemName);
    }
  }, [itemName, form]);
  useEffect(() => {
    if (reservationDataById) {
      form.setFieldValue("reserved_for", {
        value: reservationDataById?.data?.reserved_for?.id,
        label: reservationDataById?.data?.reserved_for?.name,
      });
      form.setFieldValue("quantity", reservationDataById?.data?.quantity);
      form.setFieldValue("remarks", reservationDataById?.data?.remarks);
      refetch();
    }
  }, [reservationDataById, form]);

  return (
    <div className="h-full">
      <Form
        form={form}
        layout="vertical"
        className="h-full"
        onFinish={(values) => handleFinish(values)}
      >
        <div className="flex flex-col justify-between h-full">
          <div>
            {/* Product Name */}
            <Form.Item
              label="Product Name"
              name="product_name" // Attach to form field
              className="w-full"
              rules={[
                { required: true, message: "Please enter the product name" },
              ]}
            >
              <Input placeholder="Enter Product Name" disabled />
            </Form.Item>

            {/* Reserved For */}
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Reserved For"
                  name="reserved_for" // Attach to form field
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Please select a reserved for",
                    },
                  ]}
                >
                  <CreatableSelect
                    isClearable
                    name="name"
                    options={!isFetching ? optionsCategory : []}
                    onCreateOption={(option) => handleCreate(option)}
                    placeholder="Enter Reserved For"
                    onMenuOpen={() => setName("reserved_for")}
                    isLoading={isFetching}
                    loadingMessage={() => "Loading..."}
                  />
                </Form.Item>
              </Col>

              {/* Quantity */}
              <Col span={12}>
                <Form.Item
                  label="Quantity"
                  name="quantity" // Attach to form field
                  className="w-full"
                  rules={[
                    { required: true, message: "Please enter the quantity" },
                    {
                      validator: validatePositiveNumber,
                    },
                  ]}
                >
                  <Input placeholder="Enter Quantity" className="customInput" />
                </Form.Item>
              </Col>
            </Row>

            {/* Remarks */}
            <Form.Item
              label="Remarks"
              name="remarks" // Attach to form field
              className="w-full"
            >
              <TextArea rows={4} placeholder="Enter Remarks" />
            </Form.Item>
          </div>

          {/* Submit Button */}
          <div>
            <FormItem>
              <CommonButton
                variant="wealay-primary-btn"
                value={
                  reservationDataById
                    ? "Update Reservation"
                    : "Reserve this Product"
                }
                className="w-full h-52"
                htmlType="submit"
                isLoading={reservationLoading || updateLoading}
                disabled={reservationLoading || updateLoading}
              />
            </FormItem>
            {reservation_id && (
              <FormItem>
                <CommonButton
                  variant="wealay-secondary-btn"
                  value="Cancel"
                  className="w-full h-52"
                  onClick={() => {
                    // setOpen(false);
                    setDrawer("");
                  }}
                />
              </FormItem>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ReservationForm;
