import moment from "moment";
import { useEffect, useState } from "react";
import { getContactsParams } from "../../helpers/quarery";
import { useGetAllDashboardWorkDelayQuery } from "../../services/api";
import { CommonTable } from "../commonTable";

function WorkDelayDashboard() {
  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 10,
  });
  const params = getContactsParams(pageData.page, pageData.pageSize);

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };

  const getAllExecutiveParams = {
    page_size: params?.page_size,
    page: params?.page,
  };

  const { data, isLoading, isFetching, refetch } =
    useGetAllDashboardWorkDelayQuery({});
  useEffect(() => {
    refetch();
  }, []);
  const columns = [
    {
      title: "Committed Date",
      dataIndex: "completion_on",
      render: (text: any, record: any) => (
        <div>{text ? moment(text).format("DD-MM-YYYY") : "-"}</div>
      ),
      ellipsis: true,
    },
    {
      title: "Job Card Number",
      dataIndex: "job_card_number",
      ellipsis: true,
    },
    {
      title: "Job Type",
      dataIndex: "job_type",

      ellipsis: true,
      render: (text: any, record: any) => (
        <div>
          {text === "job_work"
            ? "Job Work"
            : text === "mould"
            ? "Mould"
            : "For Lining"}
        </div>
      ),
    },
  ];
  return (
    <div className="flex flex-col justify-between h-full ">
      <CommonTable
        datasource={data?.data}
        coloumn={columns}
        noRowSelection
        isLoading={isFetching}
      />
    </div>
  );
}

export default WorkDelayDashboard;
