import { Button, Form, Input, message } from "antd";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { validatePositiveNumber } from "../../../helpers/helperFunction";
import { useApproveForLiningMutation } from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import { Text } from "../../CommonFont/text";
interface ICreateSales {
  setOpen: (data: boolean) => void;

  // Pass the record ID as a prop
}
export const QuatedPriceModal: React.FC<ICreateSales> = ({ setOpen }) => {
  const [approveQutation, { isLoading }] = useApproveForLiningMutation();
  const [updateQuotation, { isLoading: quotationLoading }] =
    useApproveForLiningMutation();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  const handleApprove = async (value: any) => {
    try {
      const response = await approveQutation({
        id: Number(id),
        body: { ...value, quote_status: "approved" },
      }).unwrap(); // Send transformed data
      form.resetFields();
      //   navigate("/for-lining");
      setOpen(false);

      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message);
    }
  };

  return (
    <div>
      <Form layout="vertical" onFinish={handleApprove} form={form}>
        <div className="flex gap-2">
          <img src="/assets/iButton.svg" />
          <Text
            variant="wearlay-subtitle"
            value="Enter quoted price against this job "
          />
        </div>
        <Form.Item
          required
          rules={[
            { required: true },
            {
              validator: validatePositiveNumber,
            },
          ]}
          label="Quoted Price"
          className="mt-24"
          name={"quote_price"}
        >
          <Input placeholder="Enter" />
        </Form.Item>
        <div className="flex gap-2 justify-end mt-24p">
          <Button
            className="wealay-secondary-btn"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>

          <CommonButton
            variant="wealay-primary-btn"
            value="Save"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
      </Form>
    </div>
  );
};
