export const PathConstants = {
  LOGIN: "/login",
  OVERVIEW: "/overview",
  OVERVIEW_INVENTORY: "/inventory-overview",

  HOME: "/",
  ORDER: "/order",
  SALES: "/sales",
  QUOTATION: "/quotation",
  DETAIL_QUOTATION: "/quotation/details",
  SALES_DETAILS: "/sales/details",

  DETAIL_ORDER: "/order/details",
  ERROR_403: "/403",

  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  TICKET_OPEN_URL: "/ticket",
  CREATE_QUOTATION: "/create-quotation",
  PROFILE: "/profile",
  INQUIRIES: "/inquiries",
  ALLOCATED: "/allocated",
  EMPLOYEE: "/employee",
  INVENTORY_EMPLOYEE: "/inventory-employees",

  NOTIFICATION: "/notification",
  CONTACTS: "/contacts",

  ADD_LEAD: "/add-lead",
  ADD_ASSETS: "/add-asset",

  DETAIL_PAGE: "/employee-detail-page",
  INVENTORY_DETAIL_PAGE: "/inventory-employee-detail-page",

  CATEGORY_MANAGEMENT: "/category-management",
  VENDOR_MANAGEMENT: "/vendor-management",
  WARE_HOUSE_MANAGEMENT: "/warehouse-management",
  FINISHED_GOODS: "/finished-goods",
  UNCLASSIFIED: "/unclassified",
  SAMPLE: "/samples-specimens",
  ADD_SAMPLE: "/add-sample-specimen",

  CONSUMABLE: "/consumables",
  ADD_CONSUMABLE: "/add-consumable",

  ASSETS: "/assets",

  REJECTED_PRODUCTS: "/rejected-products",
  RAW_MATERIALs: "/raw-materials",

  ADD_GOODS: "/add-goods",
  ADD_REJECTED_PRODUCT: "/add-rejected-product",
  ADD_RAW_MATERIAL: "/add-raw-material",
  FOR_LINING: "/for-lining",
  MOULDS: "/moulds",

  ADD_UNCLASSIFIED: "/add-product",
  ADD_CATEGORY: "/add-category",
  ADD_LINING: "/add-lining",
  ADD_JOB_CARD: "/add-job-card",
  ADD_MOULDS: "/add-mould",

  JOB_CARD: "/job-card",
};
