import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  Dropdown,
  message,
  Pagination,
  PaginationProps,
  Popover,
  Radio,
  Select,
  Space,
  Tag,
} from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { saveAs } from "file-saver";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CommonButton } from "../../../components/CommonButton/button";
import WarehouseFilterComponent from "../../../components/CommonFilter/warehouse";
import { Text } from "../../../components/CommonFont/text";
import { SearchBox } from "../../../components/CommonSearchBox";
import { CommonTable } from "../../../components/commonTable";

import ReservationForm from "../../../components/Drawer/AddReservation";
import FinishedGoodsPreview from "../../../components/Drawer/FinishedGoodsPreview";
import { getContactsParams } from "../../../helpers/quarery";
import {
  useActivateFinishedGoodsMutation,
  useDeleteFinishedGoodsMutation,
  useGetAllFinishedGoodsQuery,
  useGetExcelFinishedGoodsByIdQuery,
  useGetWarehouseQuery,
} from "../../../services/api";
import "./index.css";

function FinishedGoods() {
  const [open, setOpen] = useState(false);
  const [warehouse_id, setWarehouseId] = useState("");
  const [isActive, setActive] = useState(false);
  const [isReserved, setReserved] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const location = useLocation();

  const search = searchParams.get("search");
  const status = searchParams.get("status");
  const type = searchParams.get("type");
  const warehouseId = searchParams.get("warehouse_id");

  const [selectedValue, setSelectedValue] = useState(type || "new_goods");

  const [drawerContent, setDrawerContent] = useState<
    "add" | "preview" | "update"
  >("add"); // To toggle between AddWarehouse and WarehousePreview
  const [selectedWarehouse, setSelectedWarehouse] = useState(null); // Store selected warehouse data
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const [downloadIds, setDownloadIds] = useState<any>();

  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 10,
  });
  const params = getContactsParams(pageData.page, pageData.pageSize);

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPageData({
      page: current,
      pageSize: pageSize,
    });
  };

  const getAllExecutiveParams = {
    page_size: params?.page_size,
    ...(search || status ? { page: params?.page } : { page: params?.page }),
    query: search || "", // Simplified ternary for `query`
    ...(status ? { status: status === "true" ? "True" : "False" } : {}),
    type: type || selectedValue,
    ...(warehouseId ? { warehouse: warehouseId } : {}),
  };

  // Pass the params explicitly inside an object
  const { data, isLoading, isFetching, refetch } = useGetAllFinishedGoodsQuery(
    getAllExecutiveParams
  );
  useEffect(() => {
    if (search || status) {
      setPageData({
        page: 0,
        pageSize: 10,
      });
    }
  }, [status, search]);

  const [deactivateWarehouse, { isLoading: deactivatingLoading }] =
    useDeleteFinishedGoodsMutation();
  const [activateWarehouse, { isLoading: activateLoading }] =
    useActivateFinishedGoodsMutation();

  const showDrawer = () => {
    setOpen(true);
  };
  const [selectedUnit, setSelectedUnit] = useState<string | null>(null);

  // Handle unit selection change

  // Handle unit selection change
  const [selectedDimensions, setSelectedDimensions] = useState<
    Record<number, string>
  >({});
  const [selectedUnits, setSelectedUnits] = useState<Record<number, string>>(
    {}
  );
  const getAllExecutiveParam = {
    page_size: 100,
    status: "True",
  };
  const {
    data: warehouseData,
    isLoading: selectApiLoading,
    isFetching: selectApiFetching,
  } = useGetWarehouseQuery(getAllExecutiveParam);
  // Handle unit selection change
  const handleUnitChange = (
    value: string,
    specKey: string,
    specName: string,
    specifications: any[],
    isInitial = false
  ) => {
    const selectedSpec = specifications.find(
      (spec: any) => spec.specification_name === specName
    );

    if (selectedSpec) {
      const correspondingValueField = selectedSpec.valueFields.find(
        (field: any) => field.unit === value
      );

      if (
        !correspondingValueField ||
        !correspondingValueField.dimension_value
      ) {
        setSelectedDimensions((prev) => ({
          ...prev,
          [specKey]: "No dimension available.",
        }));
      } else {
        setSelectedDimensions((prev) => ({
          ...prev,
          [specKey]: Array.isArray(correspondingValueField.dimension_value)
            ? correspondingValueField.dimension_value.join(", ") // Join array values with a separator
            : correspondingValueField.dimension_value,
        }));
      }

      if (!isInitial) {
        setSelectedUnits((prev) => ({
          ...prev,
          [specKey]: value,
        }));
      }
    }
  };

  // useEffect(() => {
  //   data?.results?.data.forEach((record: any, recordIndex: number) => {
  //     if (record.specification && record.specification.length > 0) {
  //       record.specification.forEach((spec: any, specIndex: number) => {
  //         if (spec.unitOptions && spec.unitOptions.length > 0) {
  //           const firstUnit = spec.unitOptions[0].value;
  //           handleUnitChange(
  //             firstUnit,
  //             `${recordIndex}-${specIndex}`,
  //             spec.specification_name,
  //             record.specification,
  //             true
  //           );
  //         }
  //       });
  //     }
  //   });
  // }, [data]);

  const onClose = () => {
    setOpen(false);
    setSelectedWarehouse(null); // Reset selected warehouse when closing
    navigate(`/finished-goods `);
  };
  // const [selectedDimension, setSelectedDimension] = useState<string>("");
  const [showAll, setShowAll] = useState(false); // State to track whether to show all items

  const columns = [
    {
      title: "Product Id",
      dataIndex: "finished_goods_id",
      // sorter: (a: any, b: any) => a.company_name.localeCompare(b.company_name),
      ellipsis: true,
      render: (text: string, record: any) => (
        <div className="flex gap-2 flex-col">
          <span
            className="cursor-pointer text-blue-500"
            onClick={() => {
              handleRowClick(record);
              navigate(
                `/finished-goods?id=${record.id}&type=${selectedValue}&item=${record.name.name}`
              ); // Open the drawer in preview mode
            }}
          >
            {text}
          </span>
          <span>
            {record?.reserved === true ? (
              <Tag color="green">Reserved</Tag>
            ) : null}
          </span>
        </div>
      ),
      fixed: "left",
      width: 100,
    },
    {
      title: "Item Name",
      dataIndex: "name",
      width: 200,
      fixed: "left",

      ellipsis: true,
      render: (text: string, record: any) => (
        <span
          className="cursor-pointer text-blue-500"
          onClick={() => {
            handleRowClick(record);

            navigate(
              `/finished-goods?id=${record.id}&type=${selectedValue}&item=${record.name.name}`
            ); // Open the drawer in preview mode
          }}
        >
          {record?.name?.name}
        </span>
      ),
    },

    {
      title: "Category",
      dataIndex: "phone",
      render: (text: any, record: any) => (
        <div>
          {record?.category?.name?.name ? record?.category?.name?.name : "-"}
        </div>
      ),
      ellipsis: true,
      width: 100,
    },
    {
      title: "Brand",
      dataIndex: "email",
      ellipsis: true,
      render: (text: any, record: any) => (
        <div>{record?.brand?.name ? record?.brand?.name : "-"}</div>
      ),
      width: 100,
    },
    {
      title: "Model",
      dataIndex: "email",
      ellipsis: true,
      render: (text: any, record: any) => (
        <div>{record?.model?.name ? record?.model?.name : "-"}</div>
      ),
    },
    {
      title: "Specification",
      dataIndex: "email", // You can change this to another dataIndex if needed
      ellipsis: true,
      width: 200,

      render: (text: any, record: any, recordIndex: number) => {
        // State to track whether to show all items

        const handleViewAllClick = () => {
          setShowAll(true); // Show all items when the button is clicked
        };

        // Ensure record.specification is an array before calling .slice
        const specifications = Array.isArray(record.specification)
          ? record.specification
          : [];

        // Limit the displayed specifications based on `showAll`
        const displayedSpecifications = showAll
          ? specifications
          : specifications.slice(0, 2);

        return (
          <div className="flex flex-col">
            {specifications.length > 0 ? (
              <>
                {displayedSpecifications?.map(
                  (spec: any, specIndex: number) => {
                    const specKey: any = `${recordIndex}-${specIndex}`;
                    return (
                      <div key={specKey} className="flex flex-col mb-2">
                        {/* Display selected dimension */}
                        <div className="flex gap-2">
                          {selectedDimensions[specKey] ? (
                            <div style={{ marginBottom: "5px" }}>
                              <span>Dimension:</span>{" "}
                              <strong>{selectedDimensions[specKey]}</strong>
                            </div>
                          ) : (
                            <div style={{ marginBottom: "5px" }}>
                              <span>Dimension:</span>{" "}
                              {spec?.valueFields
                                ?.flatMap((item: any) => item?.dimension_value) // Flatten all dimension_value arrays
                                ?.map(
                                  (
                                    dimension: any,
                                    index: number,
                                    array: string[]
                                  ) => (
                                    <strong key={index}>
                                      {dimension}
                                      {index !== array.length - 1 && ", "}
                                    </strong>
                                  )
                                )}
                            </div>
                          )}

                          {/* Dimensions */}
                          {/* {spec.unitOptions?.length > 0 &&
                            spec.valueFields
                              ?.slice(0, 1)
                              .map((field: any, fieldIndex: number) => (
                                <div key={fieldIndex} className="flex mb-2">
                                  <Select
                                    style={{ width: 70 }}
                                    placeholder={`Select ${spec.specification_name} unit`}
                                    value={
                                      selectedUnits[specKey] ||
                                      spec.unitOptions[0]?.value
                                    }
                                    onChange={(value) =>
                                      handleUnitChange(
                                        value,
                                        specKey,
                                        spec.specification_name,
                                        record.specification
                                      )
                                    }
                                    options={spec.unitOptions.map(
                                      (unit: any) => ({
                                        label: unit.label,
                                        value: unit.value,
                                      })
                                    )}
                                  />
                                </div>
                              ))} */}
                          {spec.unitOptions?.length > 0 && (
                            <strong>
                              {}
                              {
                                spec.unitOptions.find(
                                  (option: any) =>
                                    option.value === spec.valueFields[0]?.unit
                                )?.label
                              }
                            </strong>
                          )}
                        </div>
                        {/* Specification Name */}
                        <div className="flex">
                          <div>Specification Name:</div>
                          <strong className="pl-5">
                            {" "}
                            {spec.specification_name}
                          </strong>
                        </div>
                      </div>
                    );
                  }
                )}

                {/* Show View All button if there are more than 2 items */}
                {!showAll && specifications.length > 2 && (
                  <div
                    onClick={() => {
                      navigate(
                        `/finished-goods?id=${record?.id}&type=${selectedValue}`
                      );
                      handleRowClick(record);
                    }}
                    className="mt-2 text-blue-500 hover:underline view-all cursor-pointer"
                  >
                    View All
                  </div>
                )}
              </>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },

    {
      title: "Grade",
      dataIndex: "email",
      ellipsis: true,
      render: (text: any, record: any) => (
        <div>{record?.grade?.name ? record?.grade?.name : "-"}</div>
      ),
    },
    {
      title: "Color",
      dataIndex: "email",
      ellipsis: true,
      render: (text: any, record: any) => (
        <div>{record?.color?.name ? record?.color?.name : "-"}</div>
      ),
    },
    {
      title: "Stock Qty",
      dataIndex: "stock_quantity",
      ellipsis: true,
      width: 100,
      render: (text: any, record: any) => <div>{text ? text : "-"}</div>,
    },
    {
      title: "Safety Stock",
      dataIndex: "safety_stock",
      ellipsis: true,
      width: 100,
      render: (text: any, record: any) => <div>{text ? text : "-"}</div>,
    },
    {
      title: "Demand Forecast",
      dataIndex: "demand_forcast",
      ellipsis: true,
      width: 100,
      render: (text: any, record: any) => <div>{text ? text : "-"}</div>,
    },
    {
      title: "Lead Time",
      dataIndex: "lead_time",
      ellipsis: true,
      width: 100,
      render: (text: any, record: any) => <div>{text ? text : "-"}</div>,
    },
    {
      title: "EOQ",
      dataIndex: "new_order_demand",
      ellipsis: true,
      width: 100,
      render: (text: any, record: any) => <div>{text ? text : "-"}</div>,
    },
    {
      title: "Stock Required",
      dataIndex: "stock_required",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Stock Updated On",
      dataIndex: "modified_date",
      ellipsis: true,
      width: 100,

      render: (text: any, record: any) => (
        <div>{text ? moment(text).format("DD/MM/YYYY") : "-"}</div>
      ),
      sorter: (a: any, b: any) =>
        moment(a.modified_date).valueOf() - moment(b.modified_date).valueOf(),
    },
    {
      title: "Dead Stock",
      dataIndex: "dead_stock",
      ellipsis: true,
      width: 100,
      render: (text: any, record: any) => <div>{text ? text : "-"}</div>,
    },
    {
      title: "Reserved ",
      dataIndex: "reservations",
      ellipsis: true,
      render: (text: any, record: any) => <div>{text ? text : "-"}</div>,
    },

    {
      title: "Status",
      dataIndex: "is_active",
      ellipsis: true,
      render: (text: boolean) => (
        <div>
          {text === false ? (
            <Tag color="red" icon={<CloseCircleOutlined />}>
              Inactive
            </Tag>
          ) : (
            <Tag color="green" icon={<CheckOutlined />}>
              Active
            </Tag>
          )}
        </div>
      ),
    },
    {
      fixed: "right",
      title: "Actions",
      dataIndex: "industry_name",
      ellipsis: true,
      width: 100,
      render: (_: unknown, record: any) => {
        // Dynamically define dropdown items based on status
        const items: ItemType[] = [
          record.is_active &&
          localStorage.getItem("user_role") !== "2" &&
          localStorage.getItem("user_role") !== "1"
            ? {
                key: "1",
                label: (
                  <Button
                    type="ghost"
                    onClick={() => {
                      setOpen(true);
                      setDrawerContent("update");
                    }}
                  >
                    Update
                  </Button>
                ),
              }
            : null,
          localStorage.getItem("user_role") !== "2" &&
          localStorage.getItem("user_role") !== "1"
            ? {
                key: "2",
                label: (
                  <div>
                    {" "}
                    {record.is_active ? (
                      <Button
                        type="ghost"
                        disabled={deactivatingLoading}
                        loading={deactivatingLoading}
                      >
                        Deactivate
                      </Button>
                    ) : (
                      <Button
                        type="ghost"
                        disabled={activateLoading}
                        loading={activateLoading}
                      >
                        Activate
                      </Button>
                    )}
                  </div>
                ),
              }
            : null,
          localStorage.getItem("user_role") !== "3" &&
          localStorage.getItem("user_role") !== "4" &&
          record?.is_active
            ? {
                key: "3",
                label: (
                  <div>
                    {" "}
                    {localStorage.getItem("user_role") !== "3" &&
                      localStorage.getItem("user_role") !== "4" && (
                        <Button
                          type="ghost"
                          onClick={() => {
                            setOpen(true);
                            setDrawerContent("add");
                            // navigate(
                            //   `/finished-goods?id=${record.id}&type=${selectedValue}&item=${record.name.name}`
                            // ); // Open the drawer in preview mode
                          }}
                        >
                          Reserve this product
                        </Button>
                      )}
                  </div>
                ),
              }
            : null,
          (localStorage.getItem("user_role") === "3" ||
            localStorage.getItem("user_role") === "4") &&
          record?.is_active
            ? {
                key: "4",
                label: (
                  <div>
                    {" "}
                    <Button
                      type="ghost"
                      onClick={() => {
                        // Open the drawer in preview mode
                        handleRowClick(record);
                      }}
                    >
                      Add Log Entry
                    </Button>
                  </div>
                ),
              }
            : null,
        ].filter(Boolean); // Removes null or undefined values safely

        const handleDeleteWarehouse = async (id: number | null) => {
          if (!id) {
            console.error("Warehouse ID is null");
            return; // Or show an error message
          }

          const numericId = Number(id);
          if (isNaN(numericId)) {
            console.error("Invalid Warehouse ID");
            return; // Handle invalid ID case
          }

          await deactivateWarehouse(numericId)
            .unwrap()
            .then((response) => {
              message.success(response.message);
              navigate("/finished-goods");
            })
            .catch((error) => {
              message.error(error.message);
            });
        };

        const handleActivateWarehouse = async (id: number | null) => {
          if (!id) {
            console.error("Warehouse ID is null");
            return; // Or show an error message
          }

          const numericId = Number(id);
          if (isNaN(numericId)) {
            console.error("Invalid Warehouse ID");
            return; // Handle invalid ID case
          }

          await activateWarehouse({
            id: numericId,
            body: { is_active: true, is_deleted: false },
          })
            .unwrap()
            .then((response) => {
              message.success(response.message);
              navigate("/finished-goods");
            })
            .catch((error) => {
              message.error(error.message);
            });
        };

        return (
          <Dropdown
            menu={{
              items,
              onClick: (info) => {
                if (info.key === "1") {
                  setOpen(true);
                  setDrawerContent("update");
                  navigate(`/add-goods?id=${record.id}&type=${selectedValue}`); // Open the drawer in preview mode
                } else if (info.key === "2") {
                  // Handle Activate/Deactivate logic
                  if (!record.is_active) {
                    handleActivateWarehouse(Number(record.id));
                    navigate(`/finished-goods?id=${record.id}`); // Open the drawer in preview mode
                  } else {
                    handleDeleteWarehouse(Number(record.id));
                    navigate(`/finished-goods?id=${record.id}`); // Open the drawer in preview mode
                  }
                } else if (info.key === "4") {
                  navigate(
                    `/finished-goods?id=${record.id}&type=${selectedValue}&item=${record.name.name}&log=true`
                  );
                } else {
                  setOpen(true);
                  navigate(
                    `/finished-goods?id=${record.id}&type=${selectedValue}&item=${record.name.name}`
                  ); // Open the drawer in preview mode
                }
              },
            }}
            placement="bottomLeft"
            trigger={["click"]}
          >
            <img
              className="cursor-pointer"
              src="/assets/dots.svg"
              onClick={(e) => e.stopPropagation()} // Prevent row click on dots
            />
          </Dropdown>
        );
      },
    },
  ];

  const handleRowClick = (record: any) => {
    setSelectedWarehouse(record); // Set selected warehouse details
    setDrawerContent("preview"); // Open the drawer in preview mode
    setOpen(true);
    setWarehouseId(record?.vendor_id);
  };
  const findIndexForObject = (array: [], object: any) => {
    return _.findIndex(array, object);
  };

  const targetObject = { id: Number(id) };
  const index = findIndexForObject(data?.results?.data as [], targetObject);

  const [currentIndex, setCurrentIndex] = useState(index);

  const getObjectAtIndex = (array: [], index: number) => {
    const object = _.get(array, index);
    return object;
  };

  // useEffect(() => {
  //   setCurrentIndex(index);
  // }, [index]);
  // useEffect(() => {
  //   if (currentIndex > -1) {
  //     const updatedObject: any = getObjectAtIndex(
  //       data?.results?.data as [],
  //       currentIndex
  //     );

  //     navigate(`/finished-goods?id=${updatedObject?.id}`);
  //   }
  // }, [currentIndex]);

  const handleNext = () => {
    if (currentIndex < (data?.results?.data?.length as number) - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];
  const idArray = _.map(data?.results?.data, "id");

  const { data: downloadedData, isLoading: downloadLoading } =
    useGetExcelFinishedGoodsByIdQuery(
      downloadIds?.length > 0 ? downloadIds : idArray
    );
  type DownloadedData = Blob | string; // Update this type to match your data structure

  const handleDownload = () => {
    if (downloadedData) {
      if (
        typeof downloadedData === "string" ||
        downloadedData instanceof Blob
      ) {
        saveAs(downloadedData, "finished-goods-export.xlsx");
      } else {
        message.error("Error: Invalid data format for download.");
      }
    } else {
      message.error("Error: Downloaded data is undefined.");
    }
  };

  const handleChange = (e: any) => {
    setSelectedValue(e.target.value);
    setPageData({
      page: 0,
      pageSize: 10,
    });
    navigate(`/finished-goods`);
  };

  const content = (
    <div>
      <p
        className="rawMaterialItem cursor-pointer"
        onClick={() => {
          navigate(`/add-goods?type=new_goods`);
        }}
      >
        Add New Goods
      </p>
      <p
        className="rawMaterialItem cursor-pointer"
        onClick={() => {
          navigate(`/add-goods?type=refurbished_goods`);
        }}
      >
        Add Refurbished Goods
      </p>
    </div>
  );
  const handleChange2 = (value: string | undefined) => {
    // Use URLSearchParams to manipulate the query string
    const params = new URLSearchParams(location.search);

    if (value) {
      // Add or update the 'warehouse_id' parameter
      params.set("warehouse_id", value);
    } else {
      // Remove the 'warehouse_id' parameter
      params.delete("warehouse_id");
    }

    // Update the URL with the new query parameters
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };
  useEffect(() => {
    refetch();
  }, []);
  return (
    <div className="mt-24">
      <div className="flex justify-between items-center">
        <div className="sales-title">
          <Text value="Finished Goods" variant="wearlay-display" />
        </div>
        {localStorage.getItem("user_role") !== "2" &&
          localStorage.getItem("user_role") !== "1" && (
            <Popover content={content} trigger="click" placement="bottom">
              <Button className="primary-btn wealay-primary-btn cursor-pointer">
                + Add New Goods
              </Button>
            </Popover>
          )}
      </div>
      <div className="p-10 mt-24p">
        <div className="flex justify-between">
          <div className="flex gap-2 items-center">
            <Text
              value="Finished Goods List"
              variant="wearlay-headline-2"
              className="p-1"
            />
            <Radio.Group
              onChange={handleChange}
              value={type || selectedValue}
              defaultValue={["new_goods"]}
            >
              <Space>
                <Radio value="new_goods">New Goods</Radio>
                <Radio value="refurbished_goods">Refurbished Goods</Radio>
              </Space>
            </Radio.Group>
          </div>
          <div className="flex items-center h-full gap-2">
            <div className="search-resp">
              <SearchBox placeholder="Search" />
            </div>
            {(localStorage?.getItem("user_role") === "2" ||
              localStorage?.getItem("user_role") === "1") && (
              <Select
                allowClear
                placeholder="Warehouse"
                loading={isLoading}
                onChange={handleChange2}
                showSearch
                optionFilterProp="children" // Filter based on the text displayed in the dropdown
                filterOption={
                  (input, option) =>
                    (option?.children as any)
                      .toLowerCase()
                      .includes(input.toLowerCase()) // Custom search logic
                }
                className="finishedGoodsWidth"
              >
                {warehouseData?.results?.data?.map((item: any) => (
                  <Select.Option key={item?.id} value={item?.id}>
                    {item?.name?.name}
                  </Select.Option>
                ))}
              </Select>
            )}

            <div className="relative">
              <CommonButton
                variant="wearlay-with-icon"
                icon={filter ? "/assets/close.svg" : "/assets/filter.svg"}
                onClick={() => {
                  setFilter(!filter);
                }}
                className="download-btn-color"
              />
              {filter && (
                <WarehouseFilterComponent
                  title="Status"
                  options={statusOptions}
                  queryParam="status" // This will append "status=true" or "status=false" to the URL
                />
              )}
            </div>

            <CommonButton
              variant="wearlay-with-icon"
              icon={
                data?.results?.data.length === 0
                  ? "/assets/disabledDownload.svg"
                  : "/assets/download.svg"
              }
              onClick={() => {
                handleDownload();
              }}
              disabled={data?.results?.data.length === 0}
            />
          </div>
        </div>
        <div className="resp-search">
          <SearchBox placeholder="Search" />
        </div>
        <Divider className="commonDidider" />

        <div className="resp-table">
          <CommonTable
            datasource={data?.results?.data}
            coloumn={columns}
            isLoading={isLoading || isFetching}
            setDownloadIds={setDownloadIds}
          />
        </div>
        <div className="flex h-full items-end justify-end">
          {(data?.count as number) > 0 && (
            <Pagination
              current={pageData.page === 0 ? 1 : pageData.page}
              onChange={onPaginationChange}
              total={data?.count}
              pageSize={pageData?.pageSize}
              defaultCurrent={1}
              responsive={true}
              simple

              // onShowSizeChange={onShowSizeChange}
              // showSizeChanger={(data?.count as number) > 10 ? true : false}
            />
          )}
        </div>
      </div>

      <Drawer
        title={
          <div className="flex justify-between items-center">
            <div className="flex gap-2">
              <Text value="Preview" variant="wearlay-subtitle" />

              {drawerContent === "preview" && (
                <Tag color="var(--primary)" className="warehouse-tag">
                  {warehouse_id}
                </Tag>
              )}
            </div>
            {drawerContent === "preview" && (
              <div className="flex gap-2">
                {isActive &&
                  localStorage.getItem("user_role") !== "2" &&
                  localStorage.getItem("user_role") !== "1" && (
                    <img
                      src="/assets/warehouseEdit.svg"
                      className="cursor-pointer"
                      onClick={() => {
                        navigate(`/add-goods?id=${id}&type=${selectedValue}`);
                        setDrawerContent("update");
                      }}
                    />
                  )}
                {(localStorage.getItem("user_role") === "2" ||
                  localStorage.getItem("user_role") === "1") && (
                  <CommonButton
                    value="Reserve Product"
                    variant="wealay-primary-btn"
                    onClick={() => {
                      setOpen(true);
                      setDrawerContent("add");
                    }}
                  />
                )}
              </div>
            )}
          </div>
        }
        placement="right"
        onClose={onClose}
        open={open}
        width="500"
        destroyOnClose
      >
        {drawerContent === "add" || drawerContent === "update" ? (
          <ReservationForm setOpen={setOpen} setDrawer={setDrawerContent} />
        ) : (
          <FinishedGoodsPreview
            setId={setWarehouseId}
            setActive={setActive}
            setReserved={setReserved}
            setEditOpen={setOpen}
            setDrawer={setDrawerContent}
          />
        )}
      </Drawer>
    </div>
  );
}

export default FinishedGoods;
