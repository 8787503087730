import {
  ArrowLeftOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { CommonButton } from "../../components/CommonButton/button";
import { Text } from "../../components/CommonFont/text";
import CommonTreeSelectSubCategories from "../../components/CommonTreeSelectSubCategories";
import {
  disableFutureDates,
  validatePositiveNumber,
} from "../../helpers/helperFunction";
import {
  useCreateAssetsMutation,
  useCreateSelectApiMutation,
  useGetAllAssetsByIdQuery,
  useGetAllInventriesByIdQuery,
  useGetAllInventriesQuery,
  useGetAllVendorQuery,
  useGetCategoriesAndSubCategoriesQuery,
  useGetSelectApiQuery,
  useGetSelectByIdApiQuery,
  useGetUserDetailByIdQuery,
  useUpdateAssetsMutation,
} from "../../services/api";

// import "./index.css";

function AddAssets() {
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const [defaultCategoryId, setDefaultCategory] = useState<number | undefined>(
    undefined
  );
  const [value, setValue] = useState<string | undefined>(undefined); // Value selected from TreeSelect
  const [isSet, setIsSet] = useState(false);

  const { data, isLoading } = useGetCategoriesAndSubCategoriesQuery(
    Number(defaultCategoryId),
    {
      skip: !defaultCategoryId,
    }
  );
  // const { data, isLoading } = useGetCategoriesAndSubCategoriesQuery(
  //   { body: Number(defaultCategoryId), clean: true },
  //   {
  //     skip: !defaultCategoryId,
  //   }
  // );
  const navigate = useNavigate();
  const { data: allInventory, isFetching: allInventoryFetching } =
    useGetAllInventriesQuery({});
  useEffect(() => {
    const finishedGoods = allInventory?.data.find(
      (item: any) => item?.name?.name === "Assets"
    );
    if (
      finishedGoods &&
      typeof finishedGoods?.name === "object" &&
      "id" in finishedGoods.name
    ) {
      setDefaultCategory(Number(finishedGoods?.id));
    }
  }, [allInventory]);
  const {
    data: inventoryById,
    isLoading: inventoryLoading,
    isFetching,
  } = useGetAllInventriesByIdQuery(
    {
      id: Number(value),
    },
    {
      skip: !Number(value),
    }
  );

  useEffect(() => {
    if (value && isSet) {
      form.setFieldsValue({
        specification:
          inventoryById?.data &&
          inventoryById?.data?.specification?.length > 0 &&
          inventoryById?.data?.specification?.map((spec) => ({
            specification_name: spec?.specification_name?.name || "",
            valueFields:
              spec?.dimension?.map((dim, index) => ({
                // dimension_name: dim?.dimension_name?.name || "",
                unit: spec?.unit?.map((unit) => unit.name) || [],
              })) || [],
          })),
      });
    } else {
      form.setFieldsValue({
        specification: [],
      });
    }
  }, [inventoryById, isSet]);
  const [
    createSelectWarehouse,
    { data: addCategoryData, isLoading: createSelectLoading },
  ] = useCreateSelectApiMutation();
  const { data: selectById } = useGetSelectByIdApiQuery("asset");
  const [name, setName] = useState("name");
  const userId = localStorage.getItem("user_id");
  const [selectedValue, setSelectedValue] = useState<string>(
    type ? type : "internal"
  );

  const { data: userDetail } = useGetUserDetailByIdQuery(userId);
  useEffect(() => {
    if (userDetail) {
      form.setFieldsValue({
        warehouse: userDetail?.data[0]?.warehouse?.name?.name || "",
        address: userDetail?.data[0]?.warehouse?.address || "",
        contact: userDetail?.data[0]?.warehouse?.contact_person || "",
        contact_no: userDetail?.data[0]?.warehouse?.phone || "",
        email: userDetail?.data[0]?.warehouse?.email || "",
      });
    }
  }, [userDetail]);

  const handleCreate = async (option: string) => {
    const newOption = {
      name: option || "",
      field: name,
      model: Number(selectById?.id),
    }; // Prepare the new option

    try {
      const response = await createSelectWarehouse({
        name: "asset", // Pass the field name
        // Pass the model
        body: newOption, // Pass the body object
      }).unwrap(); // Unwrap the response for easier handling

      form.setFieldValue(name, {
        value: response?.data?.id,
        label: response?.data?.name,
      });
      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message || "Failed to create warehouse");
    }
  };
  const categoryParams = {
    name: name,
    model: Number(selectById?.id),
  };
  const {
    data: getAllCategory,
    isFetching: isFetchingCategory,
    isLoading: isLoadingCategory,
  } = useGetSelectApiQuery(categoryParams, {
    skip: !name && !selectById?.id, // Skip the query when id is 0
  });
  interface getSelectValues {
    value: string; // Ensure `value` is typed as `number`
    label: string;
  }
  const optionsCategory: getSelectValues[] | undefined =
    getAllCategory?.data?.map(({ id, name }) => ({
      value: id?.toString() as string, // Convert `id` to string
      label: name as string, // Ensure `name` is a string
    }));
  interface SpecificationOption {
    value: string;
    label: string;
  }

  const [specificationOptions, setSpecificationOptions] = useState<
    SpecificationOption[]
  >([]);
  const [unitOptions, setUnitOptions] = useState<SpecificationOption[]>([]);
  const [selectedSpecification, setSelectedSpecification] = useState("");
  const [dimensionList, setDimensionList] = useState<any>([]);
  const [isDelete, setIsDelete] = useState(false);

  const { data: finishedGoodsById, isLoading: finishedGoodsLoading } =
    useGetAllAssetsByIdQuery(Number(id), {
      skip: !id,
    });
  useEffect(() => {
    if (
      inventoryById?.data?.specification &&
      inventoryById?.data?.specification?.length > 0
    ) {
      // Get all specification names from the specification array
      const specificationOptions = inventoryById.data.specification.map(
        (spec) => ({
          value: spec.specification_name?.id?.toString() || "", // Use ID as the value
          label: spec.specification_name?.name || "Unknown Specification", // Fallback for missing names
        })
      );
      setSpecificationOptions(specificationOptions);
    }
  }, [inventoryById]);

  useEffect(() => {
    if (selectedSpecification !== "") {
      const selectedSpec =
        inventoryById?.data?.specification[parseInt(selectedSpecification)];
      // Access the specification by index

      const options =
        selectedSpec?.unit?.map((unit) => ({
          value: unit.name,
          label: unit.name,
        })) || [];

      setUnitOptions(options);
    } else {
      setUnitOptions([]); // Reset unit options if no specification is selected
    }
  }, [selectedSpecification, inventoryById]);
  const handleSpecificationChange = (value: string, index: number) => {
    const selectedSpec = inventoryById?.data?.specification.find(
      (spec) => spec.specification_name?.name?.toString() === value
    );

    if (selectedSpec) {
      // Extract dimension placeholders for the selected specification
      const dimensionPlaceholders = selectedSpec.dimension.map((dim) => ({
        placeholder: dim?.dimension_name?.name || "",
      }));

      const units = selectedSpec.unit.map((unit) => ({
        value: unit.id.toString(),
        label: unit.name || "",
      }));

      // Retrieve the current specifications from the form
      const currentSpecifications = form.getFieldValue("specification");

      // Update only the selected specification's dimensions and units
      const updatedSpecifications = currentSpecifications.map(
        (item: any, idx: number) => {
          if (idx === index) {
            return {
              ...item,
              valueFields: [
                {
                  dimension_name: "", // Set dimension_name initially
                  placeholder: dimensionPlaceholders, // Store dimension placeholders
                },
              ],
              unitOptions: units, // Update unit options dynamically
              value_type: selectedSpec.value_type,
            };
          }
          return item;
        }
      );

      // Update the form with the new specifications
      // form.setFieldsValue({ specification: updatedSpecifications });
      form.setFieldValue("specification", updatedSpecifications);
    }
  };

  useEffect(() => {
    const initialSpecifications = form.getFieldValue("specification") || [];

    // Update unit options for each pre-filled specification
    const updatedSpecifications = initialSpecifications.map((item: any) => {
      const selectedSpec = inventoryById?.data?.specification.find(
        (spec) =>
          spec.specification_name?.name?.toString() === item.specification_name
      );

      if (selectedSpec) {
        return {
          ...item,
          unitOptions: selectedSpec.unit.map((unit) => ({
            value: unit.id.toString(),
            label: unit.name || "", // Unit name for display
          })),
          valueFields: [
            {
              dimension_name: "",
              placeholder:
                selectedSpec.dimension?.map(
                  (dim) => dim?.dimension_name?.name
                ) || "",
            },
          ], // Display only one dimension initially
          value_type: selectedSpec.value_type,
        };
      }

      return {
        ...item,
        unitOptions: [], // Default empty options if no match found
        valueFields: [{ dimension_name: "", placeholder: "Enter dimension" }],
        value_type: "",
      };
    });

    // Set updated specifications in the form
    form.setFieldsValue({ specification: updatedSpecifications });
  }, [inventoryById, form]);

  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [
    createUnclassified,
    { data: addSelectData, isLoading: createFinishedGoodsLoading },
  ] = useCreateAssetsMutation();
  const [
    updateFinishedGoods,
    { data: updateSelectData, isLoading: updateFinishedGoodsLoading },
  ] = useUpdateAssetsMutation();
  // Handle Unit Dropdown Change (Optional, can be customized as needed)

  const onFinish = async (values: any) => {
    // Transform the specification field
    // const transformedSpecification = values.specification.map((spec: any) => ({
    //   specification_name: spec.specification_name,
    //   valueFields: spec.valueFields.map((valueField: any) => ({
    //     dimension_name: valueField.dimension_name, // Use dimension_name directly
    //     dimension_value: valueField.dimension_value, // Use dimension_value directly
    //     unit: valueField.unit, // Keep unit as is
    //   })),
    //   unitOptions: spec.unitOptions, // Include unitOptions as is
    // }));
    const transformedData = values?.specification?.map((spec: any) => {
      return {
        specification_name: spec.specification_name,
        valueFields: spec.valueFields.map((valueField: any) => {
          // Extract all dimension values
          const dimensionValues = Object.keys(valueField)
            .filter((key) => key.startsWith("dimension_name_"))
            .map((key) => valueField[key]); // Collect values

          return {
            dimension_name: valueField.placeholder, // Use placeholder as dimension_name
            dimension_value: dimensionValues, // Use extracted values
            unit: valueField.unit, // Keep unit as is
          };
        }),
        unitOptions: spec.unitOptions, // Include unitOptions as is
      };
    });

    // Extract only the value property for fields that need it
    const formData = new FormData();

    // Ensure values.document.fileList exists and has at least one file
    if (values?.document?.fileList?.length > 0 && !isDelete) {
      formData.append("document", values.document.fileList[0].originFileObj); // Append actual file object
    } else {
      formData.append("document", "");
    }

    // Add other form fields to FormData
    formData.append("name", values.name?.value);
    values.brand?.value
      ? formData.append("brand", values.brand?.value)
      : formData.append("brand", "");
    values.main?.value
      ? formData.append("main", values.main?.value)
      : formData.append("main", "");
    values.metrix?.value
      ? formData.append("metrix", values.metrix?.value)
      : formData.append("metrix", "");
    values.rack?.value
      ? formData.append("rack", values.rack?.value)
      : formData.append("rack", "");
    values.row?.value
      ? formData.append("row", values.row?.value)
      : formData.append("row", "");
    values.bin?.value
      ? formData.append("bin", values.bin?.value)
      : formData.append("bin", "");
    values?.compartment?.value
      ? formData.append("compartment", values.compartment?.value)
      : formData.append("compartment", "");
    values?.status && formData.append("status", values.status);
    values?.purchase_date
      ? formData.append("purchase_date", dayjs(values.purchase_date).format())
      : formData.append("purchase_date", "");
    values?.quantity && formData.append("quantity", values.quantity);
    values?.warranty
      ? formData.append("warranty", values.warranty)
      : formData.append("warranty", "");
    formData.append("category", value && (value as any));
    formData.append("warehouse", userDetail?.data[0]?.warehouse?.id as any);

    // selectedValue !== "internal" && formData.append("quote_status", "approved");
    values.remarks
      ? formData.append("remarks", values.remarks)
      : formData.append("remarks", "");
    // If specifications exist, append as JSON string
    formData.append(
      "specification",
      JSON.stringify(values.specification?.length > 0 ? transformedData : [])
    );

    const finalData = formData;
    if (id) {
      try {
        const response = await updateFinishedGoods({
          id: Number(id),
          body: finalData,
        }).unwrap(); // Send transformed data
        form.resetFields();
        navigate("/assets");

        message.success(response?.message);
      } catch (err: any) {
        message.error(err?.data?.message);
      }
    } else
      try {
        const response = await createUnclassified(finalData).unwrap(); // Send transformed data
        form.resetFields();
        navigate("/assets");

        message.success(response?.message);
      } catch (err: any) {
        message.error(err?.data?.message);
      }
  };
  useEffect(() => {
    if (id && finishedGoodsById) {
      const {
        name,
        quantity,
        category,

        remarks,
        main,
        metrix,
        rack,
        row,
        bin,
        brand,
        compartment,
        warranty,
        status,
        purchase_date,
        document,
        document_name,
      } = finishedGoodsById?.data;

      form.setFieldValue("warranty", warranty);
      status && form.setFieldValue("status", status);

      purchase_date &&
        form.setFieldValue("purchase_date", dayjs(purchase_date));
      form.setFieldValue("name", {
        value: name?.id,
        label: name?.name,
      });

      brand &&
        form.setFieldValue("brand", {
          value: brand?.id,
          label: brand?.name,
        });
      main &&
        form.setFieldValue("main", {
          value: main?.id,
          label: main?.name,
        });

      metrix &&
        form.setFieldValue("metrix", {
          value: metrix?.id,
          label: metrix?.name,
        });
      rack &&
        form.setFieldValue("rack", {
          value: rack?.id,
          label: rack?.name,
        });
      row &&
        form.setFieldValue("row", {
          value: row?.id,
          label: row?.name,
        });
      bin &&
        form.setFieldValue("bin", {
          value: bin?.id,
          label: bin?.name,
        });
      compartment &&
        form.setFieldValue("compartment", {
          value: compartment?.id,
          label: compartment?.name,
        });
      form.setFieldValue("remarks", remarks);
      form.setFieldValue("quantity", quantity);

      form.setFieldValue("category", category?.id);
      form.setFieldValue("document", {
        uid: "1", // Unique ID
        name: document_name || "Uploaded File", // File name
        status: "done", // Marks file as uploaded
        url: document, // File URL for preview
      });
    }
  }, [id, finishedGoodsById, form]);
  useEffect(() => {
    if (
      finishedGoodsById?.data &&
      id &&
      finishedGoodsById?.data?.specification?.length > 0 &&
      !isSet
    ) {
      // Transform the specifications to match the required structure
      const specifications =
        finishedGoodsById?.data?.specification.length > 0 &&
        finishedGoodsById?.data?.specification?.map((spec: any) => ({
          specification_name: spec.specification_name,
          unitOptions: spec.unitOptions?.map((unit: any) => ({
            label: unit.label,
            value: unit.value,
          })),
          valueFields: spec.valueFields?.map((valueField: any) => {
            const dimensionNames: any = {};
            valueField.dimension_value?.forEach((value: any, index: number) => {
              dimensionNames[`dimension_name_${index}`] = value; // Assign dimension_name_0, dimension_name_1, etc.
            });
            return {
              unit: valueField.unit,
              ...dimensionNames, // Spread the dimension names into the object
            };
          }),
        }));

      // Set the transformed data to the form
      form.setFieldValue("specification", specifications);

      // Log the transformed specifications to check the result
    }
  }, [
    finishedGoodsById?.data,
    id,
    form,
    finishedGoodsLoading,
    inventoryById,
    value,
  ]);
  const options = [
    { label: "Internal", value: "internal" },
    { label: "Outsourcing Vendor", value: "outsourced" },
  ];

  const beforeUpload = (file: any) => {
    setIsDelete(false);
    const isLt1M = file.size / 1024 / 1024 < 1; // Convert bytes to MB
    if (!isLt1M) {
      message.error("File size must be smaller than 1MB!");
      return Upload.LIST_IGNORE; // Prevents file from being added to the list
    }
    setIsFileUploaded(true);
    return false; // Allows upload
  };
  const getAllExecutiveParams = {
    page_size: 100,
    status: "True",
  };
  const {
    data: vendorData,
    isLoading: selectApiLoading,
    isFetching: selectApiFetching,
  } = useGetAllVendorQuery(getAllExecutiveParams);

  return (
    <div>
      {finishedGoodsLoading ? (
        <Skeleton active />
      ) : (
        <div className="mx-24">
          <div className="flex create-quotation-title gap-2 mt-24p">
            <ArrowLeftOutlined
              onClick={() => {
                navigate(`/assets`);
              }}
            />
            <Text
              value={id ? "Update Asset" : "Create Asset"}
              variant="wearlay-headline-1"
            />
          </div>
          <Form
            layout="vertical"
            className="mt-24p"
            form={form}
            onFinish={onFinish}
          >
            <Row className="w-full mt-24">
              <Form.Item
                label="Item Name"
                className="w-full"
                name={"name"}
                rules={[
                  {
                    required: true,
                    message: "Item name is required",
                  },
                ]}
              >
                <CreatableSelect
                  className="w-full"
                  name="name"
                  options={!isFetchingCategory ? optionsCategory : []} // Set options to empty array when isFetching is true
                  onCreateOption={(option) => {
                    handleCreate(option);
                  }}
                  placeholder="Enter Item Name"
                  onMenuOpen={() => setName("name")}
                  isLoading={isFetchingCategory}
                  loadingMessage={() => "Loading..."}
                  isClearable
                />
              </Form.Item>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <CommonTreeSelectSubCategories
                  data={data}
                  value={
                    value || finishedGoodsById?.data?.category?.id.toString()
                  }
                  setValue={setValue}
                  setIsSet={setIsSet}
                />
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Quantity"
                  name={"quantity"}
                  rules={[
                    { required: true },
                    {
                      validator: validatePositiveNumber,
                    },
                  ]}
                >
                  <Input className="customInput" placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Brand" name={"brand"}>
                  <CreatableSelect
                    className="w-full"
                    name="brand"
                    options={!isFetchingCategory ? optionsCategory : []} // Set options to empty array when isFetching is true
                    onCreateOption={(option) => {
                      handleCreate(option);
                    }}
                    placeholder="Enter Brand"
                    onMenuOpen={() => setName("brand")}
                    isLoading={isFetchingCategory}
                    loadingMessage={() => "Loading..."}
                    isClearable
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item
                  label="Status"
                  name={"status"}
                  rules={[
                    {
                      required: true,
                      message: "Status is required",
                    },
                  ]}
                >
                  <Select
                    className="customSelect"
                    options={[
                      { value: "in_use", label: "In Use" },
                      { value: "disposable", label: "Disposable" },
                      {
                        value: "dead_but_repairable",
                        label: "Dead But Repairable",
                      },
                      {
                        value: "dead_and_unrepairable",
                        label: "Dead And Unrepairable",
                      },
                    ]}
                    placeholder="Select"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Warranty" name={"warranty"}>
                  <Input className="customInput" placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Date of Purchase" name={"purchase_date"}>
                  <DatePicker
                    className="customPicker w-full"
                    format={"DD-MM-YYYY"}
                    disabledDate={disableFutureDates}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Form.Item label="Upload" name={"document"}>
                <Upload
                  accept=".pdf,.jpg,.jpeg,.png"
                  beforeUpload={beforeUpload}
                  // beforeUpload={()=>false}
                  onChange={(info) => {
                    if (info.file.status === "error") {
                      message.error(`${info.file.name} upload failed.`);
                    }
                  }}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Row>

            {!isFileUploaded &&
              finishedGoodsById?.data?.document_name &&
              !isDelete && (
                <div className="flex gap-2 items-center">
                  <div>{finishedGoodsById.data.document_name}</div>
                  <DeleteOutlined
                    className="cursor-pointer"
                    onClick={() => {
                      setIsDelete(true);
                    }}
                  />
                </div>
              )}
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item label="Remarks" name={"remarks"} className="w-full">
                  <TextArea
                    className="w-full"
                    rows={4}
                    placeholder="Enter Remarks"
                  />
                </Form.Item>
              </Col>
            </Row>

            {form.getFieldValue("specification")?.length > 0 && (
              <Row>
                <Text variant="wearlay-headline-2" value="Specifications" />
              </Row>
            )}

            {isFetching ? (
              <Skeleton active />
            ) : (
              form.getFieldValue("specification")?.length > 0 && (
                <Form.List name="specification" initialValue={[{}]}>
                  {(fields, { add, remove }) => (
                    <div className="item-table-width mt-24p">
                      <div className="child-item-table-width">
                        <Row>
                          <Col
                            span={10}
                            className="create-quotation-border-main"
                          >
                            <span className="categoryMAnandatory">*</span>{" "}
                            <span className="categoryLabel">
                              {" "}
                              Specification Name
                            </span>
                          </Col>
                          <Col
                            span={9}
                            className="create-quotation-border-main"
                          >
                            <span className="categoryMAnandatory">*</span>{" "}
                            <span className="categoryLabel">
                              {" "}
                              Dimension Name
                            </span>
                          </Col>
                          <Col
                            span={3}
                            className="create-quotation-border-main"
                          >
                            {/* <span className="categoryMAnandatory">*</span>{" "} */}
                            <span className="categoryLabel"> Unit</span>
                          </Col>

                          <Col
                            span={2}
                            className="create-quotation-border-main categoryLabel"
                          >
                            Action
                          </Col>
                        </Row>
                        {fields?.map(({ key, name, ...restField }) => (
                          <Row key={key}>
                            <Col
                              span={10}
                              className="create-quotation-border-main"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "specification_name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter name",
                                  },
                                ]}
                              >
                                <Select
                                  id="specification"
                                  value={selectedSpecification}
                                  onChange={(value) =>
                                    handleSpecificationChange(value, name)
                                  }
                                  placeholder="Select Specification"
                                  style={{ width: "100%" }}
                                  // disabled={
                                  //   inventoryById &&
                                  //   inventoryById?.data?.specification?.length >
                                  //     0 &&
                                  //   inventoryById?.data?.specification?.some(
                                  //     (spec, idx) => idx === name
                                  //   ) // Disable if it corresponds to prefilled data
                                  // }
                                  className="customSelect"
                                >
                                  {specificationOptions.map((option) => (
                                    <Select.Option
                                      key={option.value}
                                      value={option.label}
                                    >
                                      {option.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col
                              span={12}
                              className="create-quotation-border-main"
                            >
                              <Form.List
                                name={[name, "valueFields"]}
                                initialValue={[{ unit: "", key: 0, name: 0 }]} // Initialize with a single subfield
                              >
                                {(
                                  subFields,
                                  { add: addSubField, remove: removeSubField }
                                ) => {
                                  return (
                                    <>
                                      {subFields.map(
                                        (
                                          {
                                            key: subKey,
                                            name: subName,
                                            ...restSubField
                                          },
                                          index
                                        ) => {
                                          // Retrieve the selected specification
                                          const selectedSpec =
                                            form.getFieldValue([
                                              "specification",
                                              name,
                                              "specification_name",
                                            ]);

                                          // Find the corresponding specification in the data
                                          const matchingSpec =
                                            inventoryById?.data?.specification.find(
                                              (spec) =>
                                                spec.specification_name?.name?.toString() ===
                                                selectedSpec
                                            ) ||
                                            inventoryById?.data?.specification.find(
                                              (spec) =>
                                                spec.specification_name?.id?.toString() ===
                                                selectedSpec
                                            );

                                          // Get dimensions from the matched specification or fallback to the dimensionList
                                          const setOfDimensions =
                                            matchingSpec?.dimension.map(
                                              (dim) => ({
                                                dimension_name:
                                                  dim?.dimension_name?.name ||
                                                  "Enter dimension",
                                                placeholder:
                                                  dim?.dimension_name?.name ||
                                                  "Enter dimension",
                                              })
                                            ) ||
                                            dimensionList ||
                                            [];

                                          return (
                                            <div key={subKey} className="">
                                              <div className="flex  gap-2 justify-between  items-start mb-4 w-full">
                                                {/* Render all dimensions only once */}
                                                <div className="flex gap-2 flex-wrap w-70">
                                                  {setOfDimensions.map(
                                                    (
                                                      dimension: any,
                                                      idx: number
                                                    ) => (
                                                      <Form.Item
                                                        {...restSubField}
                                                        name={[
                                                          subName,
                                                          `dimension_name_${idx}`,
                                                        ]} // Unique name for each dimension
                                                        label={
                                                          dimension.placeholder
                                                        }
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: `Enter dimension`,
                                                          },
                                                          ({
                                                            getFieldValue,
                                                          }) => ({
                                                            validator(
                                                              _,
                                                              value
                                                            ) {
                                                              const currentSpecs =
                                                                getFieldValue([
                                                                  "specification",
                                                                  name,
                                                                ]);
                                                              const valueType =
                                                                currentSpecs?.value_type; // Get value_type for this specification

                                                              if (
                                                                valueType ===
                                                                  "integer" &&
                                                                !/^\d+$/.test(
                                                                  value
                                                                )
                                                              ) {
                                                                return Promise.reject(
                                                                  new Error(
                                                                    "Please enter a valid integer"
                                                                  )
                                                                );
                                                              }

                                                              if (
                                                                valueType ===
                                                                  "float" &&
                                                                !/^\d+(\.\d+)?$/.test(
                                                                  value
                                                                )
                                                              ) {
                                                                return Promise.reject(
                                                                  new Error(
                                                                    "Please enter a valid float value"
                                                                  )
                                                                );
                                                              }

                                                              if (
                                                                valueType ===
                                                                  "string" &&
                                                                typeof value !==
                                                                  "string"
                                                              ) {
                                                                return Promise.reject(
                                                                  new Error(
                                                                    "Please enter a valid string"
                                                                  )
                                                                );
                                                              }

                                                              return Promise.resolve();
                                                            },
                                                          }),
                                                        ]}
                                                      >
                                                        <Input
                                                          placeholder={"Enter"}
                                                          // disabled={
                                                          //   !form.getFieldValue(
                                                          //     [
                                                          //       "specification",
                                                          //       name,
                                                          //       "specification_name",
                                                          //     ]
                                                          //   )
                                                          // }
                                                          className="customInput"
                                                        />
                                                      </Form.Item>
                                                    )
                                                  )}
                                                </div>
                                                <div>
                                                  {/* Unit Dropdown */}
                                                  {form.getFieldValue(
                                                    "specification"
                                                  )?.[name]?.unitOptions
                                                    ?.length > 0 && (
                                                    <Form.Item
                                                      name={[subName, "unit"]}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            "Please select a unit",
                                                        },
                                                      ]}
                                                      label="unit"
                                                    >
                                                      <Select
                                                        placeholder="Select Unit"
                                                        options={
                                                          form.getFieldValue(
                                                            "specification"
                                                          )?.[name]
                                                            ?.unitOptions || []
                                                        }
                                                        // disabled={
                                                        //   !form.getFieldValue([
                                                        //     "specification",
                                                        //     name,
                                                        //     "specification_name",
                                                        //   ])
                                                        // }
                                                        className="customSelect unitSelectWidth"
                                                      />
                                                    </Form.Item>
                                                  )}
                                                </div>
                                                {/* Remove Icon */}
                                                <div className="flex ">
                                                  {subFields.length > 1 && (
                                                    <img
                                                      className="icon unitDelete"
                                                      src="/assets/trash.svg"
                                                      alt="Remove"
                                                      onClick={() =>
                                                        removeSubField(subName)
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}

                                      {/* Add Dimension Button */}
                                      {/* <Row>
                                        <Col span={24}>
                                          <Form.Item>
                                            <CommonButton
                                              onClick={() => {
                                                // Retrieve the selected specification name
                                                const selectedSpec =
                                                  form.getFieldValue([
                                                    "specification",
                                                    name,
                                                    "specification_name",
                                                  ]);

                                                // Find the matching specification from `inventoryById`
                                                const matchingSpec =
                                                  inventoryById?.data?.specification.find(
                                                    (spec) =>
                                                      spec.specification_name?.name?.toString() ===
                                                      selectedSpec
                                                  );

                                                if (matchingSpec) {
                                                  const groupedDimensions =
                                                    matchingSpec.dimension ||
                                                    []; // Dimensions for the selected spec
                                                  const currentCount =
                                                    form.getFieldValue([
                                                      name,
                                                      "valueFields",
                                                    ])?.length || 0; // Current count of dimensions

                                                  // Add a new set of dimensions only if the count is within bounds
                                                  if (
                                                    currentCount <
                                                    groupedDimensions.length
                                                  ) {
                                                    addSubField({
                                                      dimension_name: "", // Set the dimension name empty initially
                                                      placeholder:
                                                        groupedDimensions.map(
                                                          (dim) =>
                                                            dim?.dimension_name
                                                              ?.name ||
                                                            "Enter dimension"
                                                        ), // Add all placeholders from the matching spec
                                                      unit: "", // Add an empty unit
                                                      key: currentCount + 1,
                                                      name: currentCount + 1,
                                                    });
                                                  } else {
                                                    console.log(
                                                      "All dimensions have already been added."
                                                    );
                                                  }
                                                } else {
                                                  // If no specification is selected, add a generic placeholder
                                                  addSubField({
                                                    dimension_name: "",
                                                    placeholder: [
                                                      "Enter dimension",
                                                    ], // Default placeholder
                                                    key: 0,
                                                    name: 0,
                                                    unit: "",
                                                  });
                                                }
                                              }}
                                              value="Add Dimension"
                                              className="mt-2 transparent z-index-Button"
                                              variant="wealay-primary-btn"
                                              disabled={
                                                !form.getFieldValue([
                                                  "specification",
                                                  name,
                                                  "specification_name",
                                                ])
                                              }
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row> */}
                                    </>
                                  );
                                }}
                              </Form.List>
                            </Col>

                            <Col
                              span={2}
                              className="create-quotation-border-main"
                            >
                              <Button
                                type="ghost"
                                // disabled={
                                //   inventoryById &&
                                //   inventoryById?.data?.specification?.length >
                                //     0 &&
                                //   inventoryById?.data?.specification?.some(
                                //     (spec, idx) => idx === name
                                //   )
                                // }
                                className={
                                  inventoryById &&
                                  inventoryById?.data?.specification?.length >
                                    0 &&
                                  inventoryById?.data?.specification?.some(
                                    (spec, idx) => idx === name
                                  )
                                    ? "cursor-pointer"
                                    : "cursor-pointer"
                                }
                              >
                                {fields.length > 1 && (
                                  <img
                                    src="/assets/remove.svg"
                                    onClick={() => {
                                      remove(name);
                                    }}
                                    alt="Remove"
                                  />
                                )}
                              </Button>
                            </Col>
                          </Row>
                        ))}
                        <Row>
                          <Col
                            span={22}
                            className="create-quotation-border-main"
                          ></Col>
                          <Col
                            span={2}
                            className="create-quotation-border-main"
                          >
                            <img
                              src="/assets/add.svg"
                              onClick={() => add()}
                              className="close-button-item cursor-pointer"
                              alt="Add"
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </Form.List>
              )
            )}
            <Divider />
            <Text variant="wearlay-headline-2" value="Warehouse" />
            <Row gutter={[16, 16]}>
              <Col span={16}>
                <Form.Item label="Warehouse Name" name={"warehouse"}>
                  <Input
                    disabled
                    placeholder="Enter warehouse"
                    className="customInput"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Address" name={"address"}>
                  <Input
                    disabled
                    placeholder="Enter address"
                    className="customInput"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item label="Contact Person" name={"contact"}>
                  <Input
                    disabled
                    placeholder="Enter contact"
                    className="customInput"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Contact Number" name={"contact_no"}>
                  <Input
                    disabled
                    placeholder="Enter contact"
                    className="customInput"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Email Address" name={"email"}>
                  <Input
                    disabled
                    placeholder="Enter email"
                    className="customInput"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Text variant="wearlay-subtitle" value="Stock Keeping Unit" />
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item label="Main Store Area" name={"main"}>
                  <CreatableSelect
                    name="main"
                    options={!isFetchingCategory ? optionsCategory : []} // Set options to empty array when isFetching is true
                    onCreateOption={(option) => {
                      handleCreate(option);
                    }}
                    placeholder="Enter Main Name"
                    onMenuOpen={() => setName("main")}
                    isLoading={isFetchingCategory}
                    loadingMessage={() => "Loading..."}
                    isClearable
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Matrix " name={"metrix"}>
                  <CreatableSelect
                    name="matrix"
                    options={!isFetchingCategory ? optionsCategory : []} // Set options to empty array when isFetching is true
                    onCreateOption={(option) => {
                      handleCreate(option);
                    }}
                    placeholder="Enter Matrix Name"
                    onMenuOpen={() => setName("matrix")}
                    isLoading={isFetchingCategory}
                    loadingMessage={() => "Loading..."}
                    isClearable
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Rack/Shelf" name={"rack"}>
                  <CreatableSelect
                    name="rack"
                    options={!isFetchingCategory ? optionsCategory : []} // Set options to empty array when isFetching is true
                    onCreateOption={(option) => {
                      handleCreate(option);
                    }}
                    placeholder="Enter Rack Name"
                    onMenuOpen={() => setName("rack")}
                    isLoading={isFetchingCategory}
                    loadingMessage={() => "Loading..."}
                    isClearable
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item label="Row" name={"row"}>
                  <CreatableSelect
                    name="row"
                    options={!isFetchingCategory ? optionsCategory : []} // Set options to empty array when isFetching is true
                    onCreateOption={(option) => {
                      handleCreate(option);
                    }}
                    placeholder="Enter Row Name"
                    onMenuOpen={() => setName("row")}
                    isLoading={isFetchingCategory}
                    loadingMessage={() => "Loading..."}
                    isClearable
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Bin " name={"bin"}>
                  <CreatableSelect
                    name="bin"
                    options={!isFetchingCategory ? optionsCategory : []} // Set options to empty array when isFetching is true
                    onCreateOption={(option) => {
                      handleCreate(option);
                    }}
                    placeholder="Enter Bin Name"
                    onMenuOpen={() => setName("bin")}
                    isLoading={isFetchingCategory}
                    loadingMessage={() => "Loading..."}
                    isClearable
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Compartment" name={"compartment"}>
                  <CreatableSelect
                    name="compartment"
                    options={!isFetchingCategory ? optionsCategory : []} // Set options to empty array when isFetching is true
                    onCreateOption={(option) => {
                      handleCreate(option);
                    }}
                    placeholder="Enter Compartment Name"
                    onMenuOpen={() => setName("compartment")}
                    isLoading={isFetchingCategory}
                    loadingMessage={() => "Loading..."}
                    isClearable
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row justify={"end"} className="goods-margin-bottom">
              <CommonButton
                variant="wealay-primary-btn"
                value={id ? "Update Asset" : "Create Asset"}
                isLoading={
                  createFinishedGoodsLoading || updateFinishedGoodsLoading
                }
                disabled={
                  createFinishedGoodsLoading || updateFinishedGoodsLoading
                }
              ></CommonButton>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
}

export default AddAssets;
