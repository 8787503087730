import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { Avatar, Badge, Dropdown, Menu, Space } from "antd";
import _ from "lodash";

import { useLocation, useNavigate } from "react-router";
import { headerVariable } from "../../constants/headerConstants";
import { removeTokens } from "../../helpers/localStorage";
import { PathConstants } from "../../pathConstants/pathConstants";
import {
  useGetNotificationsQuery,
  useGetUserDetailByIdQuery,
} from "../../services/api";

import { useEffect } from "react";
import "./index.css";
interface IHeader {
  collapsed?: boolean;
  toggleCollapsed?: () => void;
}
export const HeaderComponents: React.FC<IHeader> = ({
  collapsed,
  toggleCollapsed,
}) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    removeTokens();
    localStorage.clear();
    navigate(PathConstants.LOGIN);
    sessionStorage.removeItem("isBackLog");
  };
  const userId = localStorage.getItem("user_id");
  const location = useLocation();
  const { data: notificationData, isLoading: notificationLoading } =
    useGetNotificationsQuery(userId);
  const filteredData = _.filter(notificationData?.data, (item) => !item.read);
  const { data } = useGetUserDetailByIdQuery(userId);
  useEffect(() => {
    if (data?.data[0]) {
      sessionStorage.setItem("isBackLog", data?.data[0]?.backdate_permission);
    }
  }, [data?.data[0]]);
  const widgetMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          navigate("/profile");
        }}
      >
        <div>Profile</div>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          handleLogout();
        }}
      >
        <div className="logout-div">
          <Space>
            {/* <LoginOutlined className="logout-icon " /> */}
            <span className="logout-text ">{headerVariable.Logout}</span>
          </Space>
        </div>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className="avatar">
        {/* <Space size={"middle"}> */}
        <img
          src="/assets/toggle.svg"
          className={collapsed ? "header-img" : "header-toggle "}
          onClick={() => {
            // setToggle && setToggle(true);
            toggleCollapsed && toggleCollapsed();
          }}
        ></img>
        {!collapsed && (
          <CloseOutlined
            onClick={() => {
              toggleCollapsed && toggleCollapsed();
            }}
            className={!collapsed ? "header-img" : "header-toggle "}
            style={{ fontSize: "20px" }}
          />
        )}
        <img
          src="/assets/new-logo.svg"
          className={collapsed ? "header-img" : "header-toggle "}
        ></img>
        <div className="flex gap-34 items-center">
          <Badge count={filteredData?.length} offset={[-6, 10]} size={"small"}>
            <Avatar
              shape="square"
              // size="m"
              icon={<img src="/assets/bell-icon.svg"></img>}
              style={{ background: "white" }}
              onClick={() => {
                navigate("/notification");
              }}
              className="badge-div"
            />
          </Badge>

          <Dropdown
            overlay={widgetMenu}
            trigger={["click"]}
            className="dropdown-click"
          >
            <Avatar
              className="avatar-icon"
              icon={
                <img
                  src={
                    data?.data[0]?.image_url
                      ? data?.data[0]?.image_url
                      : "/assets/profile.svg"
                  }
                ></img>
              }
            >
              {/* {email
              ?.match(/(\b\S)?/g)
              .join("")
              .match(/(^\S|\S$)?/g)
              .join("")
              .toUpperCase()} */}
            </Avatar>
          </Dropdown>
        </div>
        <Dropdown
          overlay={widgetMenu}
          trigger={["click"]}
          className="dropdown-click"
        >
          <span className="user-cursor-pointer">
            {/* {email} */}
            {data?.data[0]?.full_name}
            <DownOutlined className="p-1" />
          </span>
        </Dropdown>
        {/* </Space> */}
      </div>
    </>
  );
};
