import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Divider, Form, message, Row, Select, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { CommonButton } from "../../components/CommonButton/button";
import { Text } from "../../components/CommonFont/text";
import {
  useCreateCategoryMutation,
  useCreateSelectApiMutation,
  useGetAllInventriesByIdQuery,
  useGetSelectApiQuery,
  useGetSelectByIdApiQuery,
  useGetWarehouseQuery,
  useUpdateCategoryMutation,
} from "../../services/api";
import "./index.css";

function AddCategory() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [name, setName] = useState("name");
  const [searchParams] = useSearchParams();
  const category_id = searchParams.get("category_id");
  const parent_id = searchParams.get("inventory_id");
  const sub_category = searchParams.get("sub_category");
  const sub_category_id = searchParams.get("sub_category_id");

  const sub_category_name = searchParams.get("sub_category_name");
  const method = searchParams.get("method");

  const { data: inventoryById, isLoading: inventoryByIdLoading } =
    useGetAllInventriesByIdQuery(
      {
        id: Number(sub_category_id),
      },
      {
        skip: Number(sub_category_id) === 0,
      }
    );

  // Handler to process form submission
  const [
    createCategory,
    { data: addSelectData, isLoading: createCategoryLoading },
  ] = useCreateCategoryMutation();
  const [
    updateCategory,
    { data: updateSelectData, isLoading: updateCategoryLoading },
  ] = useUpdateCategoryMutation();
  const { data: selectById } = useGetSelectByIdApiQuery("category");
  const getAllExecutiveParams = {
    page_size: 100,
    status: "True",
  };
  const {
    data,
    isLoading: selectApiLoading,
    isFetching: selectApiFetching,
  } = useGetWarehouseQuery(getAllExecutiveParams);

  const onFinish = async (values: any) => {
    const formattedData = {
      name: Number(values.name?.value),
      brand: Number(values.brand?.value),
      warehouse: Number(values.warehouse)
        ? Number(values.warehouse)
        : Number(inventoryById?.data?.warehouse?.id),
      parent:
        method === "add_sub_category"
          ? Number(sub_category_id)
          : method === "update" && !category_id
          ? Number(parent_id)
          : method === "update" && category_id
          ? Number(category_id)
          : category_id && !sub_category_id
          ? Number(category_id)
          : sub_category && category_id
          ? Number(sub_category_id)
          : Number(parent_id),
      specification: values?.specification?.map((item: any) => ({
        specification_name: {
          model: selectById?.id,
          name: item.specification_name?.label,
          feild: "specification_name",
          id: item?.specification_name?.value || item?.specification_name?.id,
        },
        unit: item.unit?.length
          ? item.unit.map((unit: any) => ({
              model: selectById?.id,
              name: unit?.label,
              feild: "unit",
              id: unit?.value || unit?.id,
            }))
          : [],
        value_type: item.value_type,
        dimension: item.valueFields.map((field: any) => ({
          dimension_name: {
            model: selectById?.id,
            name: field.dimension_name?.label,
            feild: "dimension",
            id: field.dimension_name?.value || field.dimension_name?.id,
          }, // Extract dimension names
        })),
      })),
    };

    const formattedDataWithoutSpecification = {
      name: Number(values.name?.value),
      brand: Number(values.brand?.value),
      warehouse: Number(values.warehouse)
        ? Number(values.warehouse)
        : Number(inventoryById?.data?.warehouse?.id),
      parent:
        method === "add_sub_category"
          ? Number(sub_category_id)
          : method === "update" && !category_id
          ? Number(parent_id)
          : method === "update" && category_id
          ? Number(category_id)
          : category_id && !sub_category_id
          ? Number(category_id)
          : sub_category && category_id
          ? Number(sub_category_id)
          : Number(parent_id),
      specification: [],
    };
    if (method === "update") {
      try {
        const response = await updateCategory(
          {
            id: Number(sub_category_id),
            body: open ? formattedData : formattedDataWithoutSpecification,
          } // Pass the body object
        ).unwrap(); // Unwrap the response for easier handling
        form.resetFields();
        if (category_id) {
          navigate(
            `/category-management?inventory_id=${parent_id}&category_id=${category_id}&sub_category_id=${sub_category_id}&sub_category=true&sub_category_name=${sub_category_name}`
          );
        } else {
          navigate(`/category-management?inventory_id=${parent_id}`);
        }
        message.success(response?.message);
      } catch (err: any) {
        message.error(
          err?.data?.message ||
            "A category with the same name cannot be created under the same parent and warehouse."
        );
      }
    } else {
      try {
        const response = await createCategory(
          open ? formattedData : formattedDataWithoutSpecification // Pass the body object
        ).unwrap(); // Unwrap the response for easier handling
        form.resetFields();
        if (method === "add_sub_category") {
          navigate(
            `/category-management?inventory_id=${parent_id}&category_id=${sub_category_id}&sub_category=true&sub_category_name=${sub_category_name}`
          );
          // inventory_id=5&category_id=18&sub_category=true&sub_category_name=Xuc
        } else if (category_id && !sub_category_id) {
          navigate(
            `/category-management?inventory_id=${parent_id}&category_id=${category_id}&sub_category=true&sub_category_name=${sub_category_name}`
          );
        } else {
          navigate(`/category-management?inventory_id=${parent_id}`);
        }

        message.success(response?.message);
      } catch (err: any) {
        message.error(
          err?.data?.message ||
            "A category with the same name cannot be created under the same parent and warehouse."
        );
      }
    }

    // Submit the `formattedData` here
  };
  const handleFieldsChange = async () => {
    try {
      await form.validateFields(); // Try to validate all fields
      setIsButtonDisabled(false); // If no error, enable the button
    } catch (error) {
      setIsButtonDisabled(true); // If validation fails, disable the button
    }
  };
  useEffect(() => {
    if (method === "update") {
      setOpen(
        inventoryById?.data?.specification &&
          inventoryById?.data?.specification?.length > 0
          ? true
          : false
      );
    }
  }, [method, inventoryById]);
  const params = {
    name: name,
    model: Number(selectById?.id),
  };
  const { data: getAllWareHouse } = useGetSelectApiQuery(params, {
    skip: !name, // Skip the query when id is 0
  });
  const categoryParams = {
    name: name,
    model: Number(selectById?.id),
  };
  const {
    data: getAllCategory,
    isFetching,
    isLoading,
  } = useGetSelectApiQuery(categoryParams, {
    skip: !name && !selectById?.id, // Skip the query when id is 0
  });

  const [
    createSelectWarehouse,
    { data: addCategoryData, isLoading: createSelectLoading },
  ] = useCreateSelectApiMutation();
  const handleCreate = async (
    option: string,
    index?: number,
    subIndex?: number
  ) => {
    const newOption = {
      name: option || "",
      field: name,
      model: Number(selectById?.id),
    }; // Prepare the new option

    try {
      const response = await createSelectWarehouse({
        name: "category", // Pass the field name
        // Pass the model
        body: newOption, // Pass the body object
      }).unwrap(); // Unwrap the response for easier handling

      // form.setFieldValue(name, {
      //   value: response?.data?.id,
      //   label: response?.data?.name,
      // });

      if (name === "specification_name") {
        const updatedValues = [...form.getFieldValue("specification")]; // Get current list values
        updatedValues[index as number][name] = {
          value: response?.data?.id,
          label: response?.data?.name,
        }; // Update the specific field with the created option
        form.setFieldsValue({ locations: updatedValues });
        message.success(response?.message);
      } else if (name === "dimension_name") {
        const updatedValues = [...form.getFieldValue("specification")];

        updatedValues[index as number].valueFields[subIndex as number][
          "dimension_name"
        ] = {
          value: response?.data?.id,
          label: response?.data?.name,
        };

        form.setFieldsValue({ specification: updatedValues });
        message.success(response?.message);
      } else if (name === "unit") {
        const updatedValues = [...form.getFieldValue("specification")]; // Get current list values

        if (!updatedValues[index as number].unit) {
          updatedValues[index as number].unit = []; // Ensure `unit` is initialized as an array
        }

        updatedValues[index as number].unit = [
          ...updatedValues[index as number].unit,
          {
            value: response?.data?.id,
            label: response?.data?.name,
          },
        ]; // Append new unit

        form.setFieldsValue({ specification: updatedValues });
        message.success(response?.message);
      } else {
        form.setFieldValue(name, {
          value: response?.data?.id,
          label: response?.data?.name,
        });
        message.success(response?.message);
      }
    } catch (err: any) {
      message.error(err?.data?.message || "Failed to create category");
    }
  };
  interface getSelectValues {
    value: string; // Ensure `value` is typed as `number`
    label: string;
  }

  const optionsCategory: getSelectValues[] | undefined =
    getAllCategory?.data?.map(({ id, name }) => ({
      value: id?.toString() as string, // Convert `id` to string
      label: name as string, // Ensure `name` is a string
    }));
  useEffect(() => {
    if (inventoryById && method === "update") {
      const { warehouse, brand, name } = inventoryById.data;

      // Prefill warehouse field
      form.setFieldValue("warehouse", {
        value: warehouse?.id,
        label: warehouse?.name?.name,
      });

      // Prefill brand field
      brand &&
        form.setFieldValue("brand", {
          value: brand?.id,
          label: brand?.name,
        });

      // Prefill name field
      form.setFieldValue("name", {
        value: name?.id,
        label: name?.name,
      });

      // Prefill specifications
      if (
        inventoryById?.data?.specification &&
        inventoryById?.data?.specification?.length > 0
      ) {
        const specifications = inventoryById?.data?.specification?.map(
          (spec) => ({
            specification_name: {
              id: spec.specification_name?.id,
              label: spec.specification_name?.name,
            },
            value_type: spec.value_type,
            unit: spec.unit.map((unit) => ({
              id: unit?.id,
              label: unit.name,
            })),
            valueFields: spec.dimension.map((dim) => ({
              dimension_name: {
                id: dim.dimension_name?.id,
                label: dim.dimension_name?.name,
              },
            })),
          })
        );
        form.setFieldValue("specification", specifications);
      }

      // Debugging line
    }
  }, [inventoryById, form]);
  useEffect(() => {
    if (inventoryById) {
      form.setFieldValue("warehouse", inventoryById?.data?.warehouse?.id);
    }
  }, [inventoryById]);
  return (
    <div className="mx-24">
      <div className="flex create-quotation-title gap-2 mt-24p">
        <ArrowLeftOutlined
          onClick={() => {
            if (category_id) {
              navigate(-1);
            }
            navigate(`/category-management?inventory_id=${parent_id}`);
          }}
        />
        <Text
          value={
            method === "update"
              ? "Edit Category"
              : category_id
              ? "Add Sub Category"
              : sub_category_id
              ? "Add Sub Category"
              : "Add New Category"
          }
          variant="wearlay-headline-1"
        />
      </div>
      {inventoryByIdLoading ? (
        <Skeleton active />
      ) : (
        <div className="mainWrap">
          <Form
            layout="vertical"
            onFinish={onFinish}
            // onFieldsChange={handleFieldsChange}ss
            form={form}
          >
            <Form.Item
              label="Warehouse"
              required
              name="warehouse"
              rules={[{ required: true, message: "Please select a warehouse" }]}
            >
              <Select
                placeholder="Enter Warehouse"
                onDropdownVisibleChange={() => setName("warehouse")}
                loading={isLoading}
                className="customSelect"
                disabled={inventoryById?.data?.warehouse?.id ? true : false}
              >
                {data?.results?.data?.map((item: any) => (
                  <Select.Option value={item?.id}>
                    {item?.name?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Category Name"
              required
              name="name"
              rules={[
                { required: true, message: "Please enter a category name" },
              ]}
            >
              <CreatableSelect
                isClearable
                name="name"
                options={!isFetching ? optionsCategory : []} // Set options to empty array when isFetching is true
                onCreateOption={(option) => {
                  handleCreate(option);
                }}
                placeholder="Enter Category Name"
                onMenuOpen={() => setName("name")}
                isLoading={isFetching}
                loadingMessage={() => "Loading..."}
                styles={{
                  menu: (provided) => ({
                    ...provided,

                    zIndex: 100000000000, // Enable vertical scrolling if options exceed max height
                  }),
                }}
              />
            </Form.Item>
            <Form.Item
              label="Brand"
              // required
              name="brand"
              // rules={[{ required: true, message: "Please enter a brand name" }]}
            >
              <CreatableSelect
                placeholder="Enter Brand"
                isClearable
                options={!isFetching ? optionsCategory : []}
                onCreateOption={(option) => {
                  handleCreate(option);
                }}
                onMenuOpen={() => setName("brand")}
                isLoading={isFetching || isLoading}
                loadingMessage={() =>
                  (isFetching || isLoading) && <div>Loading...</div>
                }
                styles={{
                  menu: (provided) => ({
                    ...provided,

                    zIndex: 100000000000, // Enable vertical scrolling if options exceed max height
                  }),
                }}
                // onChange={(option) => console.log("option", option)}
              />
            </Form.Item>

            {!open && (
              <div
                className="flex justify-center add-specification specification-wrapper"
                onClick={() => setOpen(true)}
              >
                <span>Add Specification +</span>
              </div>
            )}
            {open && <Divider />}
            {open && (
              <Form.List name="specification" initialValue={[{}]}>
                {(fields, { add, remove }) => (
                  <div className="item-table-width mt-24p">
                    <div className="child-item-table-width">
                      <Row>
                        <Col span={7} className="create-quotation-border-main">
                          <span className="categoryMAnandatory">*</span>{" "}
                          <span className="categoryLabel">
                            {" "}
                            Specification Name
                          </span>
                        </Col>
                        <Col span={7} className="create-quotation-border-main">
                          {/* <span className="categoryMAnandatory">*</span>{" "} */}
                          <span className="categoryLabel"> Unit</span>
                        </Col>
                        <Col span={5} className="create-quotation-border-main">
                          <span className="categoryMAnandatory">*</span>{" "}
                          <span className="categoryLabel"> Dimension Name</span>
                        </Col>
                        <Col span={3} className="create-quotation-border-main">
                          <span className="categoryMAnandatory">*</span>{" "}
                          <span className="categoryLabel"> Value Type</span>
                        </Col>
                        <Col
                          span={2}
                          className="create-quotation-border-main"
                        />
                      </Row>
                      {fields?.map(({ key, name, ...restField }) => (
                        <Row key={key}>
                          <Col
                            span={7}
                            className="create-quotation-border-main"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "specification_name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter name",
                                },
                              ]}
                            >
                              <CreatableSelect
                                placeholder="Enter Name"
                                options={!isFetching ? optionsCategory : []} // Set options to empty array when isFetching is true
                                onCreateOption={(option) => {
                                  handleCreate(option, name);
                                }}
                                onMenuOpen={() => setName("specification_name")}
                                isLoading={isFetching}
                                loadingMessage={() => "Loading..."}
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,

                                    zIndex: 100000000000, // Enable vertical scrolling if options exceed max height
                                  }),
                                }}
                                isClearable
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={7}
                            className="create-quotation-border-main"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "unit"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter unit",
                              //   },
                              // ]}
                            >
                              <CreatableSelect
                                isMulti
                                placeholder="Enter Units"
                                options={!isFetching ? optionsCategory : []} // Set options to empty array when isFetching is true
                                onCreateOption={(option) => {
                                  handleCreate(option, name);
                                }}
                                onMenuOpen={() => setName("unit")}
                                isLoading={isFetching}
                                loadingMessage={() => "Loading..."}
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,

                                    zIndex: 100000000000, // Enable vertical scrolling if options exceed max height
                                  }),
                                }}
                                isClearable
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={5}
                            className="create-quotation-border-main"
                          >
                            <Form.List
                              name={[name, "valueFields"]}
                              initialValue={[{}]} // Ensure an initial value for the nested Form.List
                            >
                              {(
                                subFields,
                                { add: addSubField, remove: removeSubField }
                              ) => (
                                <>
                                  {subFields?.map(
                                    ({
                                      key: subKey,
                                      name: subName,

                                      ...restSubField
                                    }) => (
                                      <Row key={subKey} className="mb-4">
                                        <Col
                                          span={
                                            subFields?.length === 1 ? 24 : 18
                                          }
                                        >
                                          <Form.Item
                                            {...restSubField}
                                            name={[subName, "dimension_name"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Enter dimension",
                                              },
                                            ]}
                                          >
                                            <CreatableSelect
                                              placeholder="Enter "
                                              className="z-index "
                                              options={
                                                !isFetching
                                                  ? optionsCategory
                                                  : []
                                              } // Set options to empty array when isFetching is true
                                              onCreateOption={(option) => {
                                                handleCreate(
                                                  option,
                                                  name as number,
                                                  subName as number
                                                );
                                              }}
                                              onMenuOpen={() =>
                                                setName("dimension_name")
                                              }
                                              isLoading={isFetching}
                                              loadingMessage={() =>
                                                "Loading..."
                                              }
                                              styles={{
                                                menu: (provided) => ({
                                                  ...provided,

                                                  zIndex: 100000000000, // Enable vertical scrolling if options exceed max height
                                                }),
                                              }}
                                              isClearable
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col
                                          span={6}
                                          className="flex items-end h-full"
                                        >
                                          {subFields.length > 1 && (
                                            <img
                                              className="icon icon-trash"
                                              src="/assets/trash.svg"
                                              alt="Remove"
                                              onClick={() =>
                                                removeSubField(subName)
                                              }
                                              style={{ cursor: "pointer" }}
                                            />
                                          )}
                                        </Col>
                                      </Row>
                                    )
                                  )}
                                  <Form.Item>
                                    <CommonButton
                                      onClick={() =>
                                        addSubField({
                                          dimension_name: undefined,
                                        })
                                      }
                                      value="Add "
                                      className="mt-2 transparent z-index-Button"
                                      variant="wealay-primary-btn"
                                    />
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </Col>
                          <Col
                            span={3}
                            className="create-quotation-border-main"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "value_type"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter value type",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Enter Value Type"
                                options={[
                                  { value: "string", label: "String" },
                                  { value: "integer", label: "Integer" },
                                  { value: "float", label: "Float" },
                                ]}
                                className="customSelect"
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={2}
                            className="create-quotation-border-main"
                          >
                            {fields.length > 1 && (
                              <img
                                src="/assets/remove.svg"
                                onClick={() => {
                                  remove(name);
                                }}
                                alt="Remove"
                              />
                            )}
                          </Col>
                        </Row>
                      ))}
                      <Row>
                        <Col
                          span={22}
                          className="create-quotation-border-main"
                        ></Col>
                        <Col span={2} className="create-quotation-border-main">
                          <img
                            src="/assets/add.svg"
                            onClick={() =>
                              add({
                                specification_name: undefined,
                                unit: undefined,
                                valueFields: [{ dimension_name: undefined }], // Ensure nested list has an initial value
                                value_type: undefined,
                              })
                            }
                            className="close-button-item"
                            alt="Add"
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </Form.List>
            )}
            {open && (
              <div className="flex justify-end">
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <img src="/assets/trashGreen.svg" alt="Delete"></img>
                  <span className="ml-2 delete-specification">
                    Delete Specifications
                  </span>
                </div>
              </div>
            )}
            <Divider />
            <div className="flex justify-end">
              <CommonButton
                htmlType="submit"
                variant="wealay-primary-btn"
                value={
                  method === "update" ? "Update Category" : "Save Category"
                }
                // disabled={isButtonDisabled}
                isLoading={createCategoryLoading || updateCategoryLoading}
                disabled={createCategoryLoading || updateCategoryLoading}
              />
            </div>
          </Form>
        </div>
      )}
    </div>
  );
}

export default AddCategory;
