import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Rate,
  Row,
  Skeleton,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { Vendor } from "../../../interface/vendor";
import {
  useCreateSelectApiMutation,
  useCreateVendorMutation,
  useGetSelectApiQuery,
  useGetSelectByIdApiQuery,
  useGetVendorByIdQuery,
  useUpdateVendorMutation,
} from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import "./index.css";

interface ICreateSales {
  setOpen: (data: boolean) => void;
}

export const AddVendor: React.FC<ICreateSales> = ({ setOpen }) => {
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const id = searchParams.get("id");
  const [createVendor, { isLoading: createVendorLoading }] =
    useCreateVendorMutation();
  const [updateVendor, { isLoading: updateVendorLoading }] =
    useUpdateVendorMutation();
  const { data, isLoading: vendorLoading } = useGetVendorByIdQuery(Number(id));
  const ratingData = [
    "Cost",
    "Quality",
    "Credit terms",
    "Reliability",
    "Logistic chain",
    "Communication",
  ];
  // On form submission
  useEffect(() => {
    if (data) {
      // Update Formik fields for vendor details
      if (data?.data?.name) {
        form.setFieldValue("vendor_name", {
          id:
            typeof data?.data?.name === "string" ||
            typeof data?.data?.name === "number"
              ? data?.data?.name
              : (data?.data?.name?.id as string),
          label:
            typeof data?.data?.name === "number"
              ? String(data?.data?.name)
              : data?.data?.name?.name,
        });
      }

      form.setFieldValue("vendorAddress", data?.data?.address);
      form.setFieldValue("contactNumber", data?.data?.phone);
      form.setFieldValue("contact_person", data?.data?.phone);
      form.setFieldValue("email", data?.data?.email);
      form.setFieldValue("vendorType", data?.data?.type);

      form.setFieldValue("remarks", data?.data?.remarks);

      // Mapping locations
      const locations = data?.data?.locations?.map((spec) => ({
        city_name: spec.city
          ? { id: spec.city?.id, label: spec.city?.name }
          : undefined,
        state_name: spec.state
          ? { id: spec.state?.id, label: spec.state?.name }
          : undefined,
      }));

      form.setFieldValue("locations", locations);

      // Mapping ratings
      const rating = ratingData.reduce((acc, spec) => {
        const specKey = spec.toLowerCase().replace(" ", "_") as keyof Vendor; // Safe key lookup
        if (data?.data[specKey] !== undefined) {
          acc[spec] = data?.data[specKey];
        }
        return acc;
      }, {} as Record<string, any>); // Accumulating ratings dynamically

      form.setFieldValue("rating", rating);
    }
  }, [id, data]);

  const onFinish = async (values: any) => {
    // Transform the form values to match the expected output
    const transformedData = {
      name: values.vendor_name?.value,
      address: values.vendorAddress,
      phone: values.contactNumber,
      email: values.email,
      type: values.vendorType,
      remarks: values.remarks,
      locations: values.locations.map((loc: any) => ({
        state: {
          model: selectById?.id,
          name: loc.state_name?.label,
          feild: "state_name",
          id: loc.state_name.value,
        },
        city: {
          model: selectById?.id,
          name: loc.city_name?.label,
          feild: "city_name",
          id: loc.city_name?.value,
        },
      })),
      cost: values.rating?.Cost || 0,
      quality: values.rating?.Quality || 0,
      credit_terms: values.rating?.["Credit terms"] || 0,
      reliability: values.rating?.Reliability || 0,
      logistic_chain: values.rating?.["Logistic chain"] || 0,
      communication: values.rating?.Communication || 0,
    };

    if (id) {
      const finalValues = {
        body: transformedData,
        id: Number(id),
      };
      await updateVendor(finalValues)
        .unwrap()
        .then((data: any) => {
          message.success(data?.message);
          setOpen && setOpen(false);
          form.resetFields();
          navigate("/vendor-management");
        })
        .catch((err: any) => {
          message.error(err?.data?.message);
        });
    } else {
      await createVendor(transformedData)
        .unwrap()
        .then((data: any) => {
          message.success(data?.message);
          setOpen && setOpen(false);
          form.resetFields();
          navigate("/vendor-management");
        })
        .catch((err: any) => {
          message.error(err?.data?.message);
        });
    }
  };
  const [
    createSelectWarehouse,
    { data: addCategoryData, isLoading: createSelectLoading },
  ] = useCreateSelectApiMutation();
  const { data: selectById } = useGetSelectByIdApiQuery("vendor");
  const [name, setName] = useState("vendor");
  const categoryParams = {
    name: name,
    model: Number(selectById?.id),
  };
  const {
    data: getAllCategory,
    isFetching,
    isLoading,
  } = useGetSelectApiQuery(categoryParams, {
    skip: !name && !selectById?.id, // Skip the query when id is 0
  });
  interface getSelectValues {
    value: string; // Ensure `value` is typed as `number`
    label: string;
  }
  const optionsCategory: getSelectValues[] | undefined =
    getAllCategory?.data?.map(({ id, name }) => ({
      value: id?.toString() as string, // Convert `id` to string
      label: name as string, // Ensure `name` is a string
    }));
  const handleCreate = async (option: string, index?: number) => {
    const newOption = {
      name: option || "",
      field: name,
      model: Number(selectById?.id),
    }; // Prepare the new option

    try {
      const response = await createSelectWarehouse({
        name: "vendor", // Pass the field name
        // Pass the model
        body: newOption, // Pass the body object
      }).unwrap(); // Unwrap the response for easier handling

      if (name === "state_name" || name === "city_name") {
        const updatedValues = [...form.getFieldValue("locations")]; // Get current list values
        updatedValues[index as number][name] = {
          value: response?.data?.id,
          label: response?.data?.name,
        }; // Update the specific field with the created option
        form.setFieldsValue({ locations: updatedValues });
        message.success(response?.message);
      } else {
        form.setFieldValue(name, {
          value: response?.data?.id,
          label: response?.data?.name,
        });
        message.success(response?.message);
      }
    } catch (err: any) {
      message.error(err?.data?.message || "Failed to create warehouse");
    }
  };
  return (
    <>
      {vendorLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{ rating: {} }}
          >
            {/* Vendor Name */}
            <Form.Item
              label="Vendor Name"
              name="vendor_name"
              rules={[{ required: true, message: "Vendor Name is required" }]}
            >
              <CreatableSelect
                isClearable
                name="name"
                options={!isFetching ? optionsCategory : []} // Set options to empty array when isFetching is true
                onCreateOption={(option) => {
                  handleCreate(option);
                }}
                placeholder="Enter Vendor Name"
                onMenuOpen={() => setName("vendor_name")}
                isLoading={isFetching}
                loadingMessage={() => "Loading..."}
              />
            </Form.Item>

            {/* Contact Number and Email */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Contact Number"
                  name="contactNumber"
                  rules={[
                    { required: true, message: "Contact Number is required" },
                  ]}
                >
                  <Input
                    placeholder="Enter Contact Number"
                    className="customInput"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email Id"
                  name="email"
                  rules={[
                    { required: true, message: "Email Id is required" },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                    {
                      validator: (_, value) => {
                        if (
                          value &&
                          !/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i.test(value)
                        ) {
                          return Promise.reject(
                            "Email must include a valid domain like .com, .in, etc."
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder="Enter Email Id" className="customInput" />
                </Form.Item>
              </Col>
            </Row>

            {/* Vendor Address */}
            <Form.Item
              label="Vendor Address"
              name="vendorAddress"
              rules={[
                { required: true, message: "Vendor Address is required" },
              ]}
            >
              <Input.TextArea placeholder="Enter Address" rows={4} />
            </Form.Item>

            {/* Vendor Type */}
            <Form.Item
              label="Vendor Type"
              name="vendorType"
              rules={[{ required: true, message: "Vendor Type is required" }]}
              className="flex"
            >
              <Checkbox.Group className="customCheckbox">
                <Checkbox value="Manufacturers">Manufacturers</Checkbox>
                <Checkbox value="Suppliers">Suppliers</Checkbox>
              </Checkbox.Group>
            </Form.Item>

            {/* Remarks */}
            <Form.Item label="Remarks" name="remarks">
              <Input.TextArea placeholder="Enter Remarks" rows={4} />
            </Form.Item>

            <Divider />

            {/* Locations */}
            <h3>Locations</h3>
            <Form.List name="locations" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row gutter={[16, 16]} key={key}>
                      <Col span={11}>
                        <Form.Item
                          {...restField}
                          name={[name, "state_name"]}
                          rules={[
                            { required: true, message: "State is required" },
                          ]}
                          label="State"
                        >
                          <CreatableSelect
                            isClearable
                            name="state_name"
                            options={!isFetching ? optionsCategory : []} // Set options to empty array when isFetching is true
                            onCreateOption={(option) => {
                              handleCreate(option, name);
                            }}
                            placeholder="Select State"
                            onMenuOpen={() => setName("state_name")}
                            isLoading={isFetching}
                            loadingMessage={() => "Loading..."}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          {...restField}
                          name={[name, "city_name"]}
                          rules={[
                            { required: true, message: "City is required" },
                          ]}
                          label="City"
                        >
                          <CreatableSelect
                            isClearable
                            name="city_name"
                            options={!isFetching ? optionsCategory : []} // Set options to empty array when isFetching is true
                            onCreateOption={(option) => {
                              handleCreate(option, name);
                            }}
                            placeholder="Select City"
                            onMenuOpen={() => setName("city_name")}
                            isLoading={isFetching}
                            loadingMessage={() => "Loading..."}
                          />
                        </Form.Item>
                      </Col>
                      <Form.Item className="delete-icon-margin">
                        <Col span={2}>
                          {fields?.length > 1 && (
                            <img
                              src="/assets/listDeleteIcon.svg"
                              onClick={() => remove(name)}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </Col>
                      </Form.Item>
                    </Row>
                  ))}
                  <Row>
                    <Col span={22}></Col>
                    <Col span={2}>
                      <img
                        src="/assets/add.svg"
                        onClick={() => add({ state_name: "", city_name: "" })}
                        style={{ cursor: "pointer" }}
                        className="vendorAdd"
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>

            <Divider />

            {/* Ratings */}
            <h3>Rating</h3>
            <Row gutter={[16, 8]}>
              {ratingData.map((item) => (
                <Col span={24} key={item}>
                  <Row align="middle">
                    {/* Label Column */}
                    <Col span={12}>
                      <div style={{ fontWeight: 500 }}>{item}</div>
                    </Col>
                    {/* Rating Column with flex-end */}
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Form.Item
                        name={["rating", item]}
                        style={{ marginBottom: 0 }}
                      >
                        <Rate allowHalf />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>

            {/* Save Button */}
            <Form.Item>
              <CommonButton
                variant="wealay-primary-btn"
                value={id ? "Update Vendor Details" : "Save Vendor Details"}
                htmlType="submit"
                className="w-full mt-24p h-52"
                isLoading={createVendorLoading || updateVendorLoading}
                disabled={createVendorLoading || updateVendorLoading}
              ></CommonButton>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
};

export default AddVendor;
