import { Col, message, Modal, Row, Skeleton, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { concatTabTextFunction } from "../../../helpers/helperFunction";
import { useUpdateMouldsMutation } from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import { AssignMouldModal } from "../../Modals/assignMould";
// import "./index.css";
interface ICreateSales {
  setId: (data: string) => void;
  setActive: (data: boolean) => void;
  data: any;
  isLoading: boolean;
  setOpenDrawer: (data: boolean) => void;
}
export const MouldPreviewDetail: React.FC<ICreateSales> = ({
  setId,
  setActive,
  data,
  isLoading,
  setOpenDrawer,
}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const [open, isOpen] = useState(false);
  const [assignOpen, isAssignOpen] = useState(false);

  const ratingData = [
    "Cost",
    "Quality",
    "Credit terms",
    "Reliability",
    "Logistic chain",
    "Communication",
  ];

  useEffect(() => {
    if (id) {
      setId(data?.job_id as string);
      setActive(data?.is_active as boolean);
    }
  }, [id, data?.job_id]);
  const [approveQutation, { isLoading: approveLoading }] =
    useUpdateMouldsMutation();
  const formData = new FormData();

  const handleRejected = async () => {
    formData.append("quote_status", "rejected");
    try {
      const response = await approveQutation({
        id: Number(id),
        body: formData,
      }).unwrap(); // Send transformed data
      //   form.resetFields();
      //   navigate("/for-lining");
      setOpenDrawer(false);

      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message);
    }
  };
  const handleClosed = async () => {
    formData.append("job_status", "closed");

    try {
      const response = await approveQutation({
        id: Number(id),
        body: formData,
      }).unwrap(); // Send transformed data
      //   form.resetFields();
      //   navigate("/for-lining");
      setOpenDrawer(false);

      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message);
    }
  };
  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <div className="sales-detail-border-main ">
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Mould Name</div>
                <div className="sales-detail-sub-title common-work-break">
                  {data?.name?.name}
                </div>
              </div>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Category</div>
                <div className="sales-detail-sub-title common-work-break">
                  <div className="flex gap-2">
                    {data?.category?.breadcrumb &&
                      data?.category?.breadcrumb?.map(
                        (item: any, index: number) => (
                          <div className="flex gap-2">
                            <span>{item}</span>
                            {data?.category?.breadcrumb &&
                              index !== data.category.breadcrumb.length - 1 && (
                                <img
                                  src="/assets/rightArrow.svg"
                                  alt="Right Arrow"
                                />
                              )}
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder"> Client Name</div>
                <div className="sales-detail-sub-title common-work-break">
                  {data?.client?.name}
                </div>
              </div>
            </Row>
            <div>
              <Row>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">Brand</div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.brand ? data?.brand.name : "-"}
                    </div>
                  </div>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">
                      Mold Condition Last Checked On
                    </div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.last_checked
                        ? moment(data.last_checked).format("DD-MM-YYYY")
                        : "-"}
                    </div>
                  </div>
                </Col>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">Last Used On</div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.last_used
                        ? moment(data.last_used).format("DD-MM-YYYY")
                        : "-"}
                    </div>
                  </div>
                  <div className=" flex flex-col gap-2 overallrating sales-detail-border">
                    <div className="sales-detail-placeholder">Mould Health</div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.health?.name ? data?.health?.name : "-"}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">Mould Age</div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.mould_age
                        ? `${
                            data?.mould_age > 0
                              ? data?.mould_age + " days"
                              : "day"
                          }`
                        : "-"}
                    </div>
                  </div>
                </Col>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">
                      Cost Incurred
                    </div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.cost ? data?.cost : "-"}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Row className="w-full">
            <div className="sales-detail-border flex flex-col gap-2 w-full">
              <div className="sales-detail-placeholder">
                {" "}
                Design Availability
              </div>
              <div className="sales-detail-sub-title common-work-break">
                {data?.availability ? (
                  data?.availability === "available" ? (
                    <span className="textGreen">Available</span>
                  ) : (
                    <span className="textRed">Unavailable</span>
                  )
                ) : (
                  "-"
                )}
              </div>
            </div>
          </Row>

          <Row className="w-full mt-24p">
            <div className="sales-detail-border flex flex-col gap-2 w-full">
              <div className="sales-detail-placeholder">Remarks</div>
              <div className="sales-detail-sub-title  common-work-break">
                {data?.remarks ? data?.remarks : "-"}
              </div>
            </div>
          </Row>
          {data?.document && (
            <div>
              <div className="flex justify-between cancel-pdf items-center mt-24">
                <div className="flex gap-2">
                  <img src="/assets/file.svg"></img>
                  <div className="flex flex-col">
                    <div>
                      <Tooltip title={data?.document_name}>
                        {concatTabTextFunction(data?.document_name, 10)}
                      </Tooltip>
                    </div>
                    <div className="textHash">
                      {data?.document_size} .{" "}
                      {moment(data?.modified_date).format("DD-MMM-YYYY")}{" "}
                    </div>
                  </div>
                </div>

                <a href={data?.document}>
                  <img
                    src="/assets/download.svg"
                    className="file-delete "
                  ></img>
                </a>
              </div>
            </div>
          )}
          {data?.specification?.length > 0 && (
            <div>
              <Row className="mt-24p">
                <Col span={8} className="sales-detail-border">
                  <b>Specification</b>
                </Col>
                <Col span={8} className="sales-detail-border">
                  <b>Dimension</b>
                </Col>
                <Col span={8} className="sales-detail-border">
                  <b>Unit</b>
                </Col>
              </Row>

              {data?.specification?.map(
                (specification: any, specIndex: number) => (
                  <Row key={specIndex}>
                    {/* Material (Specification Name) */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.specification_name}
                    </Col>

                    {/* Dimension */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.valueFields?.map(
                        (field: any, fieldIndex: number) => (
                          <div key={fieldIndex}>
                            {field?.dimension_value?.join(", ") || "N/A"}
                          </div>
                        )
                      )}
                    </Col>

                    {/* Unit */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.valueFields?.map(
                        (field: any, fieldIndex: number) => {
                          const unitName = specification?.unitOptions?.find(
                            (option: any) => option.value === field.unit
                          )?.label;

                          return <div key={fieldIndex}>{unitName || "-"}</div>;
                        }
                      )}
                    </Col>
                  </Row>
                )
              )}
            </div>
          )}
          {data?.specification?.length > 0 && (
            <Row className="w-full mt-24p">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">
                  Additional Specification
                </div>
                <div className="sales-detail-sub-title  common-work-break">
                  {data?.remarks ? data?.remarks : "-"}
                </div>
              </div>
            </Row>
          )}
          {data?.job_card &&
            data?.job_card?.type !== "internal" &&
            data?.job_status !== "opened" &&
            data?.job_status !== "completed" &&
            data?.job_status !== "partially_completed" &&
            data?.job_status !== "closed" && (
              <div className="flex gap-2 justify-end mt-24p">
                {data?.job_card?.job_status === "closed" ||
                  data?.job_card?.quote_status === "rejected" ||
                  (data?.job_card?.quote_status === "pending" && (
                    <CommonButton
                      variant="wealay-secondary-btn"
                      value="Reject Quote"
                      onClick={handleRejected}
                      isLoading={approveLoading}
                      disabled={approveLoading}
                    />
                  ))}

                <CommonButton
                  variant="wealay-primary-btn"
                  value={
                    data?.job_status === "closed" ||
                    data?.quote_status === "rejected"
                      ? "Re-Assign Work"
                      : "Assign Work"
                  }
                  onClick={() => {
                    isAssignOpen(true);
                  }}
                />
              </div>
            )}
        </div>
      )}

      <Modal open={assignOpen} footer={false} closable={false} destroyOnClose>
        <AssignMouldModal
          setOpen={isAssignOpen}
          isReassign={
            ["rejected", "closed"].includes(data?.quote_status) ? true : false
          }
          setDrawer={setOpenDrawer}
          job_status={
            data?.job_status === "closed" || data?.quote_status === "rejected"
          }
        />
      </Modal>
    </>
  );
};

export default MouldPreviewDetail;
