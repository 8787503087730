import { Col, Row, Skeleton } from "antd";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetAllInventriesByIdQuery } from "../../../services/api";
import "./index.css";

interface ICreateSales {
  setActive: (data: boolean) => void;
}
export const CategoryPreview: React.FC<ICreateSales> = ({ setActive }) => {
  const [searchParams] = useSearchParams();
  const category_id = searchParams.get("sub_category_id");

  const {
    data: inventoryById,
    isLoading,
    isFetching,
  } = useGetAllInventriesByIdQuery(
    {
      id: Number(category_id),
    },
    {
      skip: Number(category_id) === 0,
    }
  );

  useEffect(() => {
    if (inventoryById) {
      setActive(inventoryById?.data?.is_active as boolean);
    }
  }, [inventoryById]);

  return (
    <>
      {isFetching ? (
        <Skeleton active />
      ) : (
        <div>
          <Row className="w-full">
            <div className="sales-detail-border flex flex-col gap-2 w-full">
              <div className="sales-detail-placeholder">Category Name</div>
              <div className="sales-detail-sub-title common-work-break">
                {typeof inventoryById?.data?.name === "object" &&
                "name" in inventoryById?.data?.name
                  ? (inventoryById?.data?.name.name?.toString() as string)
                  : "-"}
              </div>
            </div>
          </Row>
          <div className="w-full flex flex-row">
            <div className="sales-detail-border flex flex-col gap-2 w-half">
              <div className="sales-detail-placeholder">Warehouse</div>
              <div className="sales-detail-sub-title common-work-break">
                {inventoryById?.data?.warehouse?.name?.name}
              </div>
            </div>
            <div className="sales-detail-border flex flex-col gap-2 w-half">
              <div className="sales-detail-placeholder">brand</div>
              <div className="sales-detail-sub-title common-work-break">
                {inventoryById?.data?.brand?.name
                  ? inventoryById?.data?.brand?.name
                  : "-"}
              </div>
            </div>
          </div>
          <Row className="w-half">
            <div className="sales-detail-border flex flex-col gap-2 w-full">
              <div className="sales-detail-placeholder ">Status</div>
              <div
                className={
                  inventoryById?.data?.is_active
                    ? "sales-detail-sub-title categoryActive"
                    : "sales-detail-sub-title categoryInActive"
                }
              >
                {inventoryById?.data?.is_active ? "Active" : "Inactive"}
              </div>
            </div>
          </Row>
          {inventoryById?.data?.specification?.map((item: any) => (
            <div>
              <Row className="w-full mt-24p">
                <div className="sales-detail-border flex flex-col gap-2 w-full">
                  <div className="sales-detail-placeholder">Specification</div>

                  <span className="sales-detail-sub-title common-work-break ">
                    {item?.specification_name?.name}
                  </span>
                </div>
              </Row>

              <Row className="w-full">
                <div className="sales-detail-border flex flex-col gap-2 w-full">
                  <div className="sales-detail-placeholder">Units</div>
                  <div className="flex gap-2">
                    {item?.unit?.length > 0 ? (
                      item?.unit?.map((item: any) => (
                        <div className="sales-detail-sub-title  categoryUnits common-work-break">
                          {item?.name}
                        </div>
                      ))
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </div>
              </Row>
              <Row>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">
                      Value Fields Name
                    </div>
                    <div className="flex gap-2 flex-wrap">
                      {item?.dimension?.map((dim: any, index: number) => (
                        <div
                          key={dim?.id || index} // Ensure a unique key
                          className="sales-detail-sub-title common-work-break"
                        >
                          {dim?.dimension_name?.name || "N/A"}
                          {index !== item?.dimension?.length - 1 && (
                            <span>,</span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>

                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">Value Type</div>
                    <div className="sales-detail-sub-title common-work-break">
                      {item?.value_type}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CategoryPreview;
