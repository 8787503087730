import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CommonButton } from "../../../CommonButton/button";
import "./index.css";
interface ICreateSales {
  setOpen: (data: any) => void;
}

export const ConfirmationMessages: React.FC<ICreateSales> = ({ setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const category_id = searchParams.get("category_id");
  const parent_id = searchParams.get("inventory_id");
  const sub_category_name = searchParams.get("sub_category_name");
  const sub_category_id = searchParams.get("sub_category_id");
  const confirmation = searchParams.get("confirmation");
  const method = searchParams.get("method");

  const handleCancelModal = () => {
    // Initialize navigate function

    // Create a new URLSearchParams instance with the current query parameters

    const updatedSearchParams = new URLSearchParams(location.search);

    // Remove the 'confirmation' query parameter
    updatedSearchParams.delete("confirmation");

    // Update the URL without the 'confirmation' query parameter
    navigate({
      pathname: location.pathname, // Keep the same pathname
      search: updatedSearchParams.toString(), // Updated query string
    });

    // Close the modal (assuming you have setIsModalOpen function)
  };

  const handleCancelModal2 = () => {
    // Initialize navigate function

    // Create a new URLSearchParams instance with the current query parameters

    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.delete("confirmation");

    // Remove the 'confirmation' query parameter
    updatedSearchParams.delete("method");

    // Update the URL without the 'confirmation' query parameter
    navigate({
      pathname: location.pathname, // Keep the same pathname
      search: updatedSearchParams.toString(), // Updated query string
    });

    // Close the modal (assuming you have setIsModalOpen function)
  };
  const handleSubmit = () => {
    if (method !== "add_sub_category") {
      if (category_id) {
        navigate(
          `/add-category?inventory_id=${parent_id}&category_id=${category_id}&sub_category=true&sub_category_name=${sub_category_name}`
        );
      } else {
        navigate(`/add-category?inventory_id=${parent_id}`);
      }
      setOpen("");
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 align-start">
        <img src="/assets/notify.svg"></img>
        <div className="confirmationtext">
          The newly added category will be categorized under {sub_category_name}
          .
        </div>
      </div>
      <div className="confirmationtext2">
        You cant move to another category later
      </div>
      <div className="flex justify-end gap-2">
        <CommonButton
          variant="wealay-secondary-btn"
          value="Cancel"
          onClick={() => {
            handleCancelModal();
            setOpen("");

            handleCancelModal2();
          }}
        />
        <CommonButton
          variant="wealay-primary-btn"
          value="Continue"
          onClick={() => {
            handleCancelModal();
            if (method !== "add_sub_category") {
              handleSubmit();
            } else {
              navigate(
                `/add-category?inventory_id=${parent_id}&sub_category=true&sub_category_id=${sub_category_id}&sub_category_name=${sub_category_name}&method=add_sub_category`
              );
            }
          }}
        />
      </div>
    </div>
  );
};
