import { Checkbox, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Assuming you're using React Router
import { CommonButton } from "../CommonButton/button";
import "./index.css";

interface FilterOption {
  label: string;
  value: string | number;
}

interface FilterComponentProps {
  title: string;
  options: FilterOption[]; // Array of filter options
  queryParam: string; // Query parameter name for the filter
}

const WarehouseFilterComponent: React.FC<FilterComponentProps> = ({
  title,
  options,
  queryParam,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedOption, setSelectedOption] = useState<string | number | null>(
    null
  );
  const [selectedRating, setSelectedRating] = useState<string | null>(null);

  // Update the selected warehouse and rating options whenever the URL changes
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const currentWarehouseValue = queryParams.get(queryParam);
    const currentRatingValue = queryParams.get("rating");

    setSelectedOption(currentWarehouseValue ? currentWarehouseValue : null);
    setSelectedRating(currentRatingValue ? currentRatingValue : null);
  }, [location.search, queryParam]);

  const handleOptionChange = (value: string | number) => {
    setSelectedOption((prevSelected) =>
      prevSelected === value ? null : value
    );
  };

  const handleRatingChange = (value: string) => {
    setSelectedRating((prevSelected) =>
      prevSelected === value ? null : value
    );
  };

  const handleSubmit = () => {
    const queryParams = new URLSearchParams(location.search);

    // Update warehouse filter
    if (selectedOption) {
      queryParams.set(queryParam, String(selectedOption));
    } else {
      queryParams.delete(queryParam);
    }

    // Update rating filter
    if (selectedRating) {
      queryParams.set("rating", selectedRating);
    } else {
      queryParams.delete("rating");
    }

    navigate({ search: queryParams.toString() }, { replace: true });
  };

  const handleReset = () => {
    const queryParams = new URLSearchParams(location.search);

    // Clear warehouse and rating filters
    setSelectedOption(null);
    setSelectedRating(null);

    queryParams.delete(queryParam);
    queryParams.delete("rating");

    navigate({ search: queryParams.toString() }, { replace: true });
  };

  const ratingOptions = [
    { label: "Ascending", value: "asc" },
    { label: "Descending", value: "desc" },
  ];

  return (
    <Menu
      mode="inline"
      style={{ width: 215 }}
      className="filter-drop-down"
      defaultOpenKeys={[queryParam, "vendor"]}
    >
      {/* Warehouse Filter */}
      <Menu.SubMenu key={queryParam} title={title}>
        {options.map((option) => (
          <Menu.Item key={option.value}>
            <Checkbox
              checked={String(selectedOption) === String(option.value)}
              onChange={() => handleOptionChange(option.value)}
            >
              {option.label}
            </Checkbox>
          </Menu.Item>
        ))}
      </Menu.SubMenu>

      {/* Rating Filter */}
      {location?.pathname === "/vendor-management" && (
        <Menu.SubMenu key={"vendor"} title={"Rating"}>
          {ratingOptions.map((option) => (
            <Menu.Item key={option.value}>
              <Checkbox
                checked={String(selectedRating) === String(option.value)}
                onChange={() => handleRatingChange(option.value)}
              >
                {option.label}
              </Checkbox>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      )}

      {/* Action Buttons */}
      <Menu.Item key="actions" className="filter-drop-down">
        <Row justify="space-between">
          <CommonButton
            variant="wearlay-without-bg"
            onClick={handleReset}
            value="Reset"
            className="reset"
          />
          <CommonButton
            variant="wealay-primary-btn"
            onClick={handleSubmit}
            value="Ok"
          />
        </Row>
      </Menu.Item>
    </Menu>
  );
};

export default WarehouseFilterComponent;
