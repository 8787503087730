import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { validatePositiveNumber } from "../../../helpers/helperFunction";
import { Item } from "../../../interface/finishedGoods";
import {
  useCreateLoginEntriesMutation,
  useGetAllLoginEntriesQuery,
  useUpdateLoginHistoryMutation,
} from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import { Text } from "../../CommonFont/text";

interface ICreateSales {
  data: Item;
  inventory_type: string;
  defaultCategoryId: number;
  setOpen: (data: boolean) => void;
  path: string;
  object_id?: number;
}
export const AddNewLog: React.FC<ICreateSales> = ({
  data,
  inventory_type,
  defaultCategoryId,
  setOpen,
  path,
  object_id,
}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const date = searchParams.get("date");
  const log_id = searchParams.get("log_id");

  const Loginparams = {
    model_id: defaultCategoryId,
    object_id: id,
    date: date,
  };
  const { data: loginData, isLoading: dateLoading } =
    useGetAllLoginEntriesQuery(Loginparams, {
      skip: !date,
    });
  const navigate = useNavigate();
  const [
    createLogEntries,
    { data: addLogEntries, isLoading: logEntriesLoading },
  ] = useCreateLoginEntriesMutation();
  const [
    updateLogEntries,
    { data: updateLogEntriesData, isLoading: updateEntriesLoading },
  ] = useUpdateLoginHistoryMutation();
  // Handle form submission
  const handleFinish = async (values: any) => {
    const output = {
      ...values,
      content_type: Number(defaultCategoryId), // Static value
      object_id: Number(id), // Static value
      date: values.date ? dayjs(values.date).format("YYYY-MM-DD") : null, // Format date
      opening_balance: Number(values.opening_balance),
      closing_balance: Number(values.closing_balance),
      timely_logs: values.timely_logs.map((log: any, index: number) => {
        // Find the corresponding timely_log in loginData

        return {
          ...log,
          ...(log_id &&
            loginData?.data &&
            loginData?.data[0]?.timely_logs?.length > 0 && {
              id: loginData?.data[0]?.timely_logs[index]?.id || null,
            }), // Add the id from matchingLog
          warehouse: data?.warehouse?.id, // Use the warehouse ID
        };
      }),
    };
    if (
      log_id &&
      loginData?.data &&
      loginData?.data[0]?.timely_logs?.length > 0
    ) {
      try {
        const response = await updateLogEntries({
          id: Number(log_id),
          body: output,
        }).unwrap(); // Send transformed data
        form.resetFields();
        navigate(`${path}?id=${id}${type ? `&type=${type}` : ""}`);
        setOpen(false);
        message.success(response?.message);
      } catch (err: any) {
        message.error(err?.data?.message);
      }
    } else {
      try {
        const response = await createLogEntries(output).unwrap(); // Send transformed data
        form.resetFields();
        navigate(`${path}?id=${id}${type ? `&type=${type}` : ""}`);

        setOpen(false);
        message.success(response?.message);
      } catch (err: any) {
        message.error(err?.data?.message);
      }
    }
  };
  const [form] = Form.useForm();

  const today = dayjs(); // Get today's date

  const [selectedDate, setSelectedDate] = useState<any>(dayjs(date) || today);

  const disabledDate = (current: dayjs.Dayjs | null): boolean => {
    // Disable all dates except today
    return !!current && !current.isSame(today, "day");
  };
  useEffect(() => {
    // Set warehouse value for each log entry in timely_logs
    const timelyLogs = form.getFieldValue("timely_logs");
    const updatedTimelyLogs = timelyLogs?.map((log: any) => ({
      ...log,
      warehouse: data?.warehouse?.name?.name,
    }));

    form.setFieldValue("timely_logs", updatedTimelyLogs);
    form.setFieldValue("date", selectedDate);
  }, [data, selectedDate, form, loginData]);

  useEffect(() => {
    if (loginData && date && log_id) {
      // Prefill opening_balance and closing_balance
      form.setFieldValue(
        "opening_balance",
        loginData?.data?.map((item) => item?.opening_balance)
      );
      form.setFieldValue(
        "closing_balance",
        loginData?.data?.map((item) => item?.closing_balance)
      );

      // Prefill timely logs fields
      if (loginData?.data[0]?.timely_logs?.length > 0) {
        const timelyLogs = loginData?.data?.flatMap(
          (item) => item?.timely_logs
        );
        const formattedTimelyLogs = timelyLogs?.map((log: any) => ({
          type: log?.type,
          quantity: log?.quantity,
          remarks: log?.remarks,
          warehouse: log?.warehouse?.name?.name,
        }));

        // Set timely logs data in the form
        form.setFieldValue("timely_logs", formattedTimelyLogs);
      }
    }
  }, [loginData, form]);
  useEffect(() => {
    if (loginData && date) {
      form.setFieldValue(
        "opening_balance",
        loginData?.data?.map((item) => item?.opening_balance)
      );
      form.setFieldValue(
        "closing_balance",
        loginData?.data?.map((item) => item?.closing_balance)
      );
    }
  }, [loginData, date, form]);

  return (
    <div>
      {dateLoading ? (
        <Skeleton active />
      ) : (
        <Form onFinish={handleFinish} layout="vertical" form={form}>
          <Text variant="wearlay-headline-2" value="New Log Entry" />

          <Divider />
          <Form.Item label="Date" required name={"date"}>
            <DatePicker
              className="w-full"
              format={"DD-MM-YYYY"}
              defaultValue={selectedDate} // Prefill today's date
              disabledDate={disabledDate as any} // Disable all dates except today
              onChange={(date) => setSelectedDate(date || today)} // Handle date change
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Opening Balance"
                name="opening_balance"
                rules={[
                  { required: true, message: "Please enter opening balance" },
                  { validator: validatePositiveNumber },
                ]}
              >
                <Input placeholder="Enter" type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Closing Balance"
                name="closing_balance"
                dependencies={["opening_balance"]}
                rules={[
                  { validator: validatePositiveNumber },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const openingBalance1 =
                        form.getFieldValue("opening_balance");
                      const openingBalance = openingBalance1[0];
                      if (
                        Number(value) >
                        (log_id && log_id
                          ? openingBalance
                          : typeof form.getFieldValue("opening_balance") ===
                            "object"
                          ? openingBalance
                          : openingBalance1)
                      ) {
                        return Promise.reject(
                          "Closing balance must be less than or equal to Opening balance"
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input placeholder="Enter" />
              </Form.Item>
            </Col>
          </Row>

          <Form.List
            name="timely_logs"
            initialValue={form.getFieldValue("timely_logs") || [{}]}
            rules={[
              {
                validator: async (_, logs) => {
                  if (!logs || logs.length < 1) {
                    return Promise.reject(
                      new Error("At least one log entry is required")
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div key={key}>
                    {/* Status Field */}

                    <Form.Item
                      {...restField}
                      name={[name, "type"]}
                      label="Type (Inward/Outward)"
                      rules={[
                        { required: true, message: "Please select status" },
                      ]}
                      className="w-full"
                    >
                      <Select placeholder="Enter">
                        <Select.Option value="in">Inward</Select.Option>
                        <Select.Option value="out">Outward</Select.Option>
                      </Select>
                    </Form.Item>

                    {/* Quantity Field */}

                    <Form.Item
                      {...restField}
                      name={[name, "quantity"]}
                      label="Quantity"
                      rules={[
                        { required: true, message: "Please enter quantity" },
                        {
                          validator: validatePositiveNumber,
                        },
                      ]}
                      className="w-full"
                    >
                      <Input placeholder="Enter" />
                    </Form.Item>

                    {/* Warehouse Field */}

                    <Form.Item
                      {...restField}
                      name={[name, "warehouse"]}
                      label="Warehouse"
                    >
                      <Input
                        placeholder="Enter"
                        disabled
                        // value={loginData?.data[0]?.warehouse?.name?.name}
                      />
                    </Form.Item>

                    {/* Remarks Field */}

                    <Form.Item
                      {...restField}
                      name={[name, "remarks"]}
                      label="Remarks"
                    >
                      <TextArea placeholder="Enter" rows={4} />
                    </Form.Item>

                    {/* Remove Button */}
                    {/* <Button type="danger" onClick={() => remove(name)}>
            Remove Log
          </Button> */}
                  </div>
                ))}
                {/* <Button
        type="dashed"
        onClick={() => add()}
        icon={<PlusOutlined />}
      >
        Add Log Entry
      </Button> */}
              </>
            )}
          </Form.List>
          <div className="flex gap-2 justify-end">
            <CommonButton
              variant="wealay-secondary-btn"
              value="Cancel"
              onClick={() => {
                setOpen(false);
                navigate(`${path}?id=${id}&type=new_goods`);
              }}
            />

            <CommonButton
              variant="wealay-primary-btn"
              value={log_id ? "Update" : "Save"}
              isLoading={logEntriesLoading || updateEntriesLoading}
              disabled={logEntriesLoading || updateEntriesLoading}
            />
          </div>
        </Form>
      )}
    </div>
  );
};

export default AddNewLog;
