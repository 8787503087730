import { Pagination, Radio, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getContactsParams } from "../../helpers/quarery";
import { useGetAllDashboardFinishedGoodsQuery } from "../../services/api";
import { Text } from "../CommonFont/text";
import { CommonTable } from "../commonTable";

function FinishedGoodsDashboard() {
  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 10,
  });
  const params = getContactsParams(pageData.page, pageData.pageSize);
  const [alertType, setAlertType] = useState<string>("critical");
  const navigate = useNavigate();

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };

  const handleChange = (e: any) => {
    setAlertType(e.target.value === 1 ? "critical" : "moderate");
  };

  const getAllExecutiveParams = {
    page_size: params?.page_size,
    page: params?.page,
    type: alertType,
  };

  const { data, isLoading, isFetching, refetch } =
    useGetAllDashboardFinishedGoodsQuery(getAllExecutiveParams);
  const columns = [
    {
      title: "Product Name",
      dataIndex: "company_name",

      ellipsis: true,
      render: (text: any, record: any) => (
        <div>{record?.name?.name ? record?.name?.name : "-"}</div>
      ),
    },
    {
      title: "Cut off Stock",
      dataIndex: "cutoff_stock",
      ellipsis: true,
    },
    {
      title: "Demand ForCast",
      dataIndex: "demand_forcast",

      ellipsis: true,
    },
  ];
  useEffect(() => {
    refetch();
  }, []);
  return (
    <div>
      <div className="pieWrapper">
        <div className="flex w-full justify-between">
          <Text variant="wearlay-headline-2" value="Finished Goods"></Text>
          <Radio.Group
            name="radiogroup"
            defaultValue={1}
            onChange={handleChange}
          >
            <Radio value={1} className="radioText">
              Critical Alert
            </Radio>
            <Radio value={2} className="radioText">
              Moderate Alert
            </Radio>
          </Radio.Group>

          <span
            className="viewall cursor-pointer"
            onClick={() => navigate("/finished-goods")}
          >
            View All
          </span>
        </div>
        <div className="flex flex-col justify-between h-full ">
          <CommonTable
            datasource={data?.results?.data}
            coloumn={columns}
            noRowSelection
            isLoading={isFetching}
          />
          <Row justify={"center"} className="mt-24">
            {(data?.count as number) > 0 && (
              <Pagination
                current={pageData.page === 0 ? 1 : pageData.page}
                onChange={onPaginationChange}
                total={data?.count}
                pageSize={pageData?.pageSize}
                defaultCurrent={1}
                responsive={true}
                simple
                className="dashboardPaddingBottom"

                // onShowSizeChange={onShowSizeChange}
                // showSizeChanger={(data?.count as number) > 10 ? true : false}
              />
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default FinishedGoodsDashboard;
