import {
  CheckOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  Dropdown,
  message,
  Pagination,
  PaginationProps,
  Tag,
} from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { saveAs } from "file-saver";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CommonButton } from "../../../components/CommonButton/button";
import WarehouseFilterComponent from "../../../components/CommonFilter/warehouse";
import { Text } from "../../../components/CommonFont/text";
import { SearchBox } from "../../../components/CommonSearchBox";
import { CommonTable } from "../../../components/commonTable";

import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import MouldPreview from "../../../components/Drawer/MouldPreview";
import { JobCardPdf } from "../../../components/Export/jobCard";
import { getContactsParams } from "../../../helpers/quarery";
import {
  useActivateMouldsMutation,
  useDeleteMouldsMutation,
  useGetAllMouldsByIdQuery,
  useGetAllMouldsQuery,
  useGetExcelMouldsByIdQuery,
} from "../../../services/api";
// import "./index.css";

function Molds() {
  const [open, setOpen] = useState(false);
  const [warehouse_id, setWarehouseId] = useState("");
  const [isActive, setActive] = useState(false);
  // const [selectedValue, setSelectedValue] = useState("new_goods");

  const [drawerContent, setDrawerContent] = useState<
    "add" | "preview" | "update"
  >("add"); // To toggle between AddWarehouse and WarehousePreview
  const [selectedWarehouse, setSelectedWarehouse] = useState(null); // Store selected warehouse data
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState(false);
  const [isEnable, setEnable] = useState(false);

  const [downloadIds, setDownloadIds] = useState<any>();

  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const search = searchParams.get("search");
  const status = searchParams.get("status");

  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 10,
  });
  const params = getContactsParams(pageData.page, pageData.pageSize);

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPageData({
      page: current,
      pageSize: pageSize,
    });
  };

  const getAllExecutiveParams = {
    page_size: params?.page_size,
    ...(search || status ? { page: params?.page } : { page: params?.page }),
    query: search || "", // Simplified ternary for `query`
    ...(status ? { active_status: status === "true" ? "True" : "False" } : {}),
  };

  // Pass the params explicitly inside an object
  const { data, isLoading, isFetching, refetch } = useGetAllMouldsQuery(
    getAllExecutiveParams
  );
  useEffect(() => {
    if (search || status) {
      setPageData({
        page: 0,
        pageSize: 10,
      });
    }
  }, [status, search]);
  useEffect(() => {
    refetch();
  }, []);

  const [deactivateWarehouse, { isLoading: deactivatingLoading }] =
    useDeleteMouldsMutation();
  const [activateWarehouse, { isLoading: activateLoading }] =
    useActivateMouldsMutation();

  const showDrawer = () => {
    setOpen(true);
  };
  const [selectedUnit, setSelectedUnit] = useState<string | null>(null);

  // Handle unit selection change

  // Handle unit selection change
  const [selectedDimensions, setSelectedDimensions] = useState<
    Record<number, string>
  >({});
  const [selectedUnits, setSelectedUnits] = useState<Record<number, string>>(
    {}
  );
  // Handle unit selection change
  const handleUnitChange = (
    value: string,
    specKey: string,
    specName: string,
    specifications: any[],
    isInitial = false
  ) => {
    const selectedSpec = specifications.find(
      (spec: any) => spec.specification_name === specName
    );

    if (selectedSpec) {
      const correspondingValueField = selectedSpec.valueFields.find(
        (field: any) => field.unit === value
      );

      if (
        !correspondingValueField ||
        !correspondingValueField.dimension_value
      ) {
        setSelectedDimensions((prev) => ({
          ...prev,
          [specKey]: "No dimension available.",
        }));
      } else {
        setSelectedDimensions((prev) => ({
          ...prev,
          [specKey]: Array.isArray(correspondingValueField.dimension_value)
            ? correspondingValueField.dimension_value.join(", ") // Join array values with a separator
            : correspondingValueField.dimension_value,
        }));
      }

      if (!isInitial) {
        setSelectedUnits((prev) => ({
          ...prev,
          [specKey]: value,
        }));
      }
    }
  };

  // useEffect(() => {
  //   data?.results?.data.forEach((record: any, recordIndex: number) => {
  //     if (record.specification && record.specification.length > 0) {
  //       record.specification.forEach((spec: any, specIndex: number) => {
  //         if (spec.unitOptions && spec.unitOptions.length > 0) {
  //           const firstUnit = spec.unitOptions[0].value;
  //           handleUnitChange(
  //             firstUnit,
  //             `${recordIndex}-${specIndex}`,
  //             spec.specification_name,
  //             record.specification,
  //             true
  //           );
  //         }
  //       });
  //     }
  //   });
  // }, [data]);

  const onClose = () => {
    setOpen(false);
    setSelectedWarehouse(null); // Reset selected warehouse when closing
    navigate(`/moulds `);
  };
  // const [selectedDimension, setSelectedDimension] = useState<string>("");
  const [showAll, setShowAll] = useState(false); // State to track whether to show all items

  const columns = [
    {
      title: "Mould Id",
      dataIndex: "mould_id",
      // sorter: (a: any, b: any) => a.company_name.localeCompare(b.company_name),
      ellipsis: true,
      render: (text: string, record: any) => (
        <span
          className="cursor-pointer text-blue-500"
          onClick={() => {
            setEnable(
              record?.job_card?.job_status === "closed" ||
                record?.job_card?.job_status === "partially_completed" ||
                record?.job_card?.job_status === "completed"
                ? true
                : false
            );
            handleRowClick(record);
            navigate(`/moulds?id=${record.id}${
              record?.job_card ? `&type=${record.job_card.type}` : ""
            }
              `); // Open the drawer in preview mode
          }}
        >
          {text}
        </span>
      ),
      fixed: "left",
      width: 100,
    },
    {
      title: "Mould Name",
      dataIndex: "name",
      width: 200,
      fixed: "left",

      ellipsis: true,
      render: (text: string, record: any) => (
        <div className="flex gap-2 flex-col">
          <span
            className="cursor-pointer text-blue-500"
            onClick={() => {
              setEnable(
                record?.job_card?.job_status === "closed" ||
                  record?.job_card?.job_status === "partially_completed" ||
                  record?.job_card?.job_status === "completed"
                  ? true
                  : false
              );
              handleRowClick(record);
              navigate(`/moulds?id=${record.id}${
                record?.job_card ? `&type=${record.job_card.type}` : ""
              }
                `); // Open the drawer in preview mode// Open the drawer in preview mode
            }}
          >
            {record?.name?.name}
          </span>

          {record?.job_card?.type === "internal" ? (
            <Tag color="green">Internal</Tag>
          ) : record?.job_card?.type === "outsourced" ? (
            <Tag color="purple">Outsource Vendor</Tag>
          ) : null}
        </div>
      ),
    },
    {
      title: "Job Status",
      ellipsis: true,
      width: 200,
      fixed: "left",
      dataIndex: "job_status",
      render: (text: string, record: any) => {
        return (
          <div>
            {record?.job_card?.job_status === "completed" ? (
              <Tag color="green" icon={<CheckOutlined />}>
                Completed
              </Tag>
            ) : record?.job_card?.job_status === "closed" ? (
              <Tag color="red" icon={<CloseCircleOutlined />}>
                Declined
              </Tag>
            ) : record?.job_card?.job_status === "partially_completed" ? (
              <Tag color="orange" icon={<CloseCircleOutlined />}>
                Incompleted
              </Tag>
            ) : record?.job_card?.job_status === "opened" ? (
              <Tag color="blue" icon={<CheckOutlined />}>
                Assigned
              </Tag>
            ) : (
              <div>-</div> // Default case for unexpected values
            )}
          </div>
        );
      },
    },

    {
      title: "Active Status",
      fixed: "left",

      dataIndex: "is_active",
      ellipsis: true,
      render: (text: boolean) => (
        <div>
          {text === false ? (
            <Tag color="red" icon={<CloseCircleOutlined />}>
              Inactive
            </Tag>
          ) : (
            <Tag color="green" icon={<CheckOutlined />}>
              Active
            </Tag>
          )}
        </div>
      ),
      width: 150,
    },
    {
      title: "Brand Specified",
      dataIndex: "purpose",
      ellipsis: true,
      render: (text: any, record: any) => (
        <div>{record?.brand?.name ? record?.brand?.name : "-"}</div>
      ),
      width: 200,
    },
    {
      title: "Category",
      dataIndex: "phone",
      render: (text: any, record: any) => (
        <div>
          {record?.category?.name?.name ? record?.category?.name?.name : "-"}
        </div>
      ),
      ellipsis: true,
      width: 200,
    },

    {
      title: "Specification",
      dataIndex: "email", // You can change this to another dataIndex if needed
      ellipsis: true,
      width: 150,
      render: (text: any, record: any, recordIndex: number) => {
        // State to track whether to show all items

        const handleViewAllClick = () => {
          setShowAll(true); // Show all items when the button is clicked
        };

        // Ensure record.specification is an array before calling .slice
        const specifications = Array.isArray(record.specification)
          ? record.specification
          : [];

        // Limit the displayed specifications based on `showAll`
        const displayedSpecifications = showAll
          ? specifications
          : specifications.slice(0, 2);

        return (
          <div className="flex flex-col">
            {specifications.length > 0 ? (
              <>
                {displayedSpecifications.map((spec: any, specIndex: number) => {
                  const specKey: any = `${recordIndex}-${specIndex}`;
                  return (
                    <div key={specKey} className="flex flex-col mb-2">
                      {/* Display selected dimension */}
                      <div className="flex gap-2">
                        {selectedDimensions[specKey] ? (
                          <div style={{ marginBottom: "5px" }}>
                            <span>Dimension:</span>{" "}
                            <strong>{selectedDimensions[specKey]}</strong>
                          </div>
                        ) : (
                          <div style={{ marginBottom: "5px" }}>
                            <span>Dimension:</span>{" "}
                            {spec?.valueFields
                              ?.flatMap((item: any) => item?.dimension_value) // Flatten all dimension_value arrays
                              ?.map(
                                (
                                  dimension: any,
                                  index: number,
                                  array: string[]
                                ) => (
                                  <strong key={index}>
                                    {dimension}
                                    {index !== array.length - 1 && ", "}
                                  </strong>
                                )
                              )}
                          </div>
                        )}

                        {/* Dimensions */}
                        {/* {spec.unitOptions?.length > 0 &&
                            spec.valueFields
                              ?.slice(0, 1)
                              .map((field: any, fieldIndex: number) => (
                                <div key={fieldIndex} className="flex mb-2">
                                  <Select
                                    style={{ width: 70 }}
                                    placeholder={`Select ${spec.specification_name} unit`}
                                    value={
                                      selectedUnits[specKey] ||
                                      spec.unitOptions[0]?.value
                                    }
                                    onChange={(value) =>
                                      handleUnitChange(
                                        value,
                                        specKey,
                                        spec.specification_name,
                                        record.specification
                                      )
                                    }
                                    options={spec.unitOptions.map(
                                      (unit: any) => ({
                                        label: unit.label,
                                        value: unit.value,
                                      })
                                    )}
                                  />
                                </div>
                              ))} */}
                        {spec.unitOptions?.length > 0 && (
                          <strong>
                            {}
                            {
                              spec.unitOptions.find(
                                (option: any) =>
                                  option.value === spec.valueFields[0]?.unit
                              )?.label
                            }
                          </strong>
                        )}
                      </div>
                      {/* Specification Name */}
                      <div className="flex">
                        <div>Specification Name: </div>
                        <strong className="pl-5">
                          {spec.specification_name}
                        </strong>
                      </div>
                    </div>
                  );
                })}

                {/* Show View All button if there are more than 2 items */}
                {!showAll && specifications.length > 2 && (
                  <div
                    onClick={() => {
                      navigate(`/moulds?id=${record?.id}`);
                      handleRowClick(record);
                    }}
                    className="mt-2 text-blue-500 hover:underline view-all cursor-pointer"
                  >
                    View All
                  </div>
                )}
              </>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Vendor Name",
      dataIndex: "quantity",
      ellipsis: true,
      render: (text: any, record: any) => (
        <div>
          {record?.job_card?.vendor?.name?.name
            ? record?.job_card?.vendor?.name?.name
            : "-"}
        </div>
      ),
      width: 200,
    },
    {
      title: "Job Card No",
      dataIndex: "job_card_number",

      render: (text: any, record: any) => (
        <div>
          {record?.job_card_number && record?.job_card?.job_status
            ? text
            : record?.job_card_number &&
              record &&
              record?.job_card?.type !== "outsourced" &&
              record?.job_card?.type !== "outsourced"
            ? text
            : "-"}
          {/* {record?.job_card?.job_status
            ? text
            : record?.job_card_number !== "undefined"
            ? record?.job_card_number
            : "-"} */}
        </div>
      ),
      ellipsis: true,
      width: 200,
    },
    {
      title: "Job Issue Date",
      dataIndex: "received_on",
      render: (text: any, record: any) => (
        <div>
          {record?.job_card?.created_date
            ? moment(record?.job_card.created_date).format("DD-MM-YYYY")
            : "-"}
        </div>
      ),
      ellipsis: true,
      width: 200,
      sorter: (a: any, b: any) =>
        moment(a.record?.job_card?.created_date).valueOf() -
        moment(b.record?.job_card?.created_date).valueOf(),
    },

    {
      title: "Job Received Date",
      dataIndex: "received_on",
      width: 200,
      render: (text: any, record: any) => (
        <div>
          {record?.job_card?.received_on
            ? moment(record?.job_card?.received_on).format("DD-MM-YYYY")
            : "-"}
        </div>
      ),
      ellipsis: true,
      sorter: (a: any, b: any) =>
        moment(a.record?.job_card?.received_on).valueOf() -
        moment(b.record?.job_card?.received_on).valueOf(),
    },
    {
      title: "Cost Incurred",
      dataIndex: "cost",
      width: 200,
      ellipsis: true,
    },
    {
      title: "Last Used On",
      dataIndex: "last_used",
      render: (text: any, record: any) => (
        <div>{text ? moment(text).format("DD-MM-YYYY") : "-"}</div>
      ),
      width: 200,
      ellipsis: true,
      sorter: (a: any, b: any) =>
        moment(a.last_used).valueOf() - moment(b.last_used).valueOf(),
    },
    {
      title: "Mould Health",
      dataIndex: "health",
      ellipsis: true,

      render: (text: string, record: any) => (
        <div>{record?.health?.name ? record?.health?.name : "-"}</div>
      ),
      width: 200,
    },
    {
      title: "Mould Age",
      dataIndex: "mould_age",
      width: 200,
      render: (text: any, record: any) => (
        <div>{text ? `${text} ${text > 1 ? "days" : "day"}` : "-"}</div>
      ),
      ellipsis: true,
    },

    {
      title: "Condition Last Checked On",
      dataIndex: "last_checked",
      render: (text: any, record: any) => (
        <div>{text ? moment(text).format("DD-MM-YYYY") : "-"}</div>
      ),
      width: 200,
      ellipsis: true,
      sorter: (a: any, b: any) =>
        moment(a.last_checked).valueOf() - moment(b.last_checked).valueOf(),
    },
    {
      title: "Design Availability",
      dataIndex: "availability",
      render: (text: string, record: any) => (
        <div>
          {record?.availability ? (
            record?.availability === "available" ? (
              <span className="textGreen">Available</span>
            ) : (
              <span className="textRed">Unavailable</span>
            )
          ) : (
            "-"
          )}
        </div>
      ),
      ellipsis: true,

      width: 200,
    },

    {
      fixed: "right",
      width: 100,
      title: "Actions",
      dataIndex: "industry_name",
      ellipsis: true,
      render: (_: unknown, record: any) => {
        // Dynamically define dropdown items based on status
        const items: ItemType[] = [
          record.is_active &&
          record?.job_card?.job_status !== "closed" &&
          record?.job_card?.job_status !== "partially_completed" &&
          record?.job_card?.job_status !== "completed"
            ? {
                key: "1",
                label: (
                  <Button
                    type="ghost"
                    onClick={() => {
                      setOpen(true);
                      setDrawerContent("update");
                    }}
                  >
                    Update
                  </Button>
                ),
              }
            : null,
          {
            key: "2",
            label: (
              <span>
                {" "}
                {record.is_active ? (
                  <Button
                    type="ghost"
                    disabled={deactivatingLoading}
                    loading={deactivatingLoading}
                  >
                    Deactivate
                  </Button>
                ) : (
                  <Button
                    type="ghost"
                    disabled={activateLoading}
                    loading={activateLoading}
                  >
                    Activate
                  </Button>
                )}
              </span>
            ),
          },
          (localStorage.getItem("user_role") === "3" ||
            localStorage.getItem("user_role") === "4") &&
          record?.is_active
            ? {
                key: "3",
                label: (
                  <div>
                    {" "}
                    <Button
                      type="ghost"
                      onClick={() => {
                        // Open the drawer in preview mode
                        handleRowClick(record);
                      }}
                    >
                      Add Log Entry
                    </Button>
                  </div>
                ),
              }
            : null,
        ].filter(Boolean); // Removes null or undefined values safely

        const handleDeleteWarehouse = async (id: number | null) => {
          if (!id) {
            console.error("Warehouse ID is null");
            return; // Or show an error message
          }

          const numericId = Number(id);
          if (isNaN(numericId)) {
            console.error("Invalid Warehouse ID");
            return; // Handle invalid ID case
          }

          await deactivateWarehouse(numericId)
            .unwrap()
            .then((response) => {
              message.success(response.message);
              navigate("/moulds");
            })
            .catch((error) => {
              message.error(error.message);
            });
        };

        const handleActivateWarehouse = async (id: number | null) => {
          if (!id) {
            console.error("Warehouse ID is null");
            return; // Or show an error message
          }

          const numericId = Number(id);
          if (isNaN(numericId)) {
            console.error("Invalid Warehouse ID");
            return; // Handle invalid ID case
          }

          await activateWarehouse({
            id: numericId,
            body: { is_active: true, is_deleted: false },
          })
            .unwrap()
            .then((response) => {
              message.success(response.message);
              navigate("/moulds");
            })
            .catch((error) => {
              message.error(error.message);
            });
        };

        return (
          <Dropdown
            menu={{
              items,
              onClick: (info) => {
                if (info.key === "1") {
                  //   setOpen(true);
                  // setDrawerContent("update");
                  navigate(
                    `/add-mould?id=${record.id}${
                      record?.job_card ? `&type=${record.job_card.type}` : ""
                    }`
                  );
                  // Open the drawer in preview mode
                } else if (info.key === "2") {
                  // Handle Activate/Deactivate logic
                  if (!record.is_active) {
                    handleActivateWarehouse(Number(record.id));
                    navigate(`/moulds?id=${record.id}`); // Open the drawer in preview mode
                  } else {
                    handleDeleteWarehouse(Number(record.id));
                    navigate(`/moulds?id=${record.id}`); // Open the drawer in preview mode
                  }
                } else if (info.key === "3") {
                  navigate(`/moulds?id=${record.id}&log=true`);
                }
              },
            }}
            placement="bottomLeft"
            trigger={["click"]}
          >
            <img
              className="cursor-pointer"
              src="/assets/dots.svg"
              onClick={(e) => e.stopPropagation()} // Prevent row click on dots
            />
          </Dropdown>
        );
      },
    },
  ];

  const handleRowClick = (record: any) => {
    setSelectedWarehouse(record); // Set selected warehouse details
    setDrawerContent("preview"); // Open the drawer in preview mode
    setOpen(true);
    setWarehouseId(record?.vendor_id);
  };
  const findIndexForObject = (array: [], object: any) => {
    return _.findIndex(array, object);
  };

  const targetObject = { id: Number(id) };
  const index = findIndexForObject(data?.results?.data as [], targetObject);

  const [currentIndex, setCurrentIndex] = useState(index);

  const getObjectAtIndex = (array: [], index: number) => {
    const object = _.get(array, index);
    return object;
  };

  // useEffect(() => {
  //   setCurrentIndex(index);
  // }, [index]);
  // useEffect(() => {
  //   if (currentIndex > -1) {
  //     const updatedObject: any = getObjectAtIndex(
  //       data?.results?.data as [],
  //       currentIndex
  //     );

  //     navigate(`/unclassified?id=${updatedObject?.id}`);
  //   }
  // }, [currentIndex]);

  const handleNext = () => {
    if (currentIndex < (data?.results?.data?.length as number) - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];
  const idArray = _.map(data?.results?.data, "id");

  const { data: downloadedData, isLoading: downloadLoading } =
    useGetExcelMouldsByIdQuery(downloadIds?.length > 0 ? downloadIds : idArray);
  type DownloadedData = Blob | string; // Update this type to match your data structure

  const handleDownload = () => {
    if (downloadedData) {
      if (
        typeof downloadedData === "string" ||
        downloadedData instanceof Blob
      ) {
        saveAs(downloadedData, "moulds-export.xlsx");
      } else {
        message.error("Error: Invalid data format for download.");
      }
    } else {
      message.error("Error: Downloaded data is undefined.");
    }
  };

  const handleChange = (e: any) => {
    setPageData({
      page: 0,
      pageSize: 10,
    });
  };
  const { data: dataById, isLoading: pdfLoading } = useGetAllMouldsByIdQuery(
    Number(id),
    {
      skip: Number(id) === 0,
    }
  );

  return (
    <div className="mt-24">
      <div className="flex justify-between items-center">
        <div className="sales-title">
          <Text value="Moulds" variant="wearlay-display" />
        </div>
        <CommonButton
          variant="wealay-primary-btn"
          value="+ Add New Mould"
          className="primary-btn "
          onClick={() => {
            navigate(`/add-mould`);
          }}
        />
      </div>
      <div className="p-10 mt-24p">
        <div className="flex justify-between">
          <div className="flex gap-2 items-center">
            <Text
              value="Mould List"
              variant="wearlay-headline-2"
              className="p-1"
            />
          </div>
          <div className="flex items-center h-full gap-2">
            <div className="search-resp">
              <SearchBox placeholder="Search" />
            </div>

            <div className="relative">
              <CommonButton
                variant="wearlay-with-icon"
                icon={filter ? "/assets/close.svg" : "/assets/filter.svg"}
                onClick={() => {
                  setFilter(!filter);
                }}
                className="download-btn-color"
              />
              {filter && (
                <WarehouseFilterComponent
                  title="Status"
                  options={statusOptions}
                  queryParam="status" // This will append "status=true" or "status=false" to the URL
                />
              )}
            </div>
            <CommonButton
              variant="wearlay-with-icon"
              icon={
                data?.results?.data.length === 0
                  ? "/assets/disabledDownload.svg"
                  : "/assets/download.svg"
              }
              onClick={() => {
                handleDownload();
              }}
              disabled={data?.results?.data.length === 0}
            />
          </div>
        </div>
        <div className="resp-search">
          <SearchBox placeholder="Search" />
        </div>
        <Divider className="commonDidider" />

        <div className="resp-table">
          <CommonTable
            datasource={data?.results?.data}
            coloumn={columns}
            isLoading={isLoading || isFetching}
            setDownloadIds={setDownloadIds}
          />
        </div>
        <div className="flex h-full items-end justify-end">
          {(data?.count as number) > 0 && (
            <Pagination
              current={pageData.page === 0 ? 1 : pageData.page}
              onChange={onPaginationChange}
              total={data?.count}
              pageSize={pageData?.pageSize}
              defaultCurrent={1}
              responsive={true}
              simple
              // onShowSizeChange={onShowSizeChange}
              // showSizeChanger={(data?.count as number) > 10 ? true : false}
            />
          )}
        </div>
      </div>

      <Drawer
        title={
          <div className="flex justify-between items-center">
            <div className="flex gap-2">
              <Text value="Mould Preview" variant="wearlay-subtitle" />

              {drawerContent === "preview" && (
                <Tag color="var(--primary)" className="warehouse-tag">
                  {warehouse_id}
                </Tag>
              )}
            </div>
            {dataById?.data?.job_status && (
              <PDFDownloadLink
                document={<JobCardPdf data={dataById?.data as any} />}
                // fileName="order.pdf"
                fileName={`Job_Card_${warehouse_id}.pdf`}
              >
                <Button icon={<DownloadOutlined />} className="downloadCard">
                  Download Job card
                </Button>
              </PDFDownloadLink>
            )}
            {drawerContent === "preview" && (
              <div className="flex gap-2">
                {isActive && !isEnable && (
                  <img
                    src="/assets/warehouseEdit.svg"
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(`/add-mould?id=${id}`);
                      navigate(
                        `/add-mould?id=${id}${type ? `&type=${type}` : ""}`
                      ); // Open the drawer in preview mode
                      setDrawerContent("update");
                    }}
                  />
                )}
              </div>
            )}
          </div>
        }
        placement="right"
        onClose={onClose}
        open={open}
        width="500"
        destroyOnClose
      >
        <MouldPreview
          setId={setWarehouseId}
          setActive={setActive}
          setOpenDrawer={setOpen}
        />
      </Drawer>
    </div>
  );
}

export default Molds;
