import { Checkbox, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useGetWarehouseQuery } from "../../services/api";
import { CommonButton } from "../CommonButton/button";
import "./index.css";

const CategoryFilterComponent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams(); // useSearchParams hook to get query params

  const [selectedStatus, setSelectedStatus] = useState<string | number | null>(
    null
  );
  const [selectedWarehouse, setSelectedWarehouse] = useState<
    (string | number)[]
  >([]);

  const getAllExecutiveParam = {
    page_size: 100,
    status: "True",
  };

  const {
    data: warehouseData,
    isLoading: selectApiLoading,
    isFetching: selectApiFetching,
  } = useGetWarehouseQuery(getAllExecutiveParam);

  // Update state from URL parameters on load
  const warehouseValues = searchParams.get("warehouse");

  useEffect(() => {
    // Get 'status' and 'warehouse' from the query string
    const statusValue = searchParams.get("status");
    setSelectedStatus(statusValue ? statusValue : null);

    const warehouseValues = searchParams.get("warehouse");

    // Convert warehouseValues to a number array
    const warehouseIds = warehouseValues
      ? warehouseValues.split(",").map((id) => Number(id))
      : [];

    setSelectedWarehouse(warehouseIds);
  }, [searchParams]);
  // Re-run when URL query params change

  // Handle single-select for status with checkboxes
  const handleStatusChange = (value: string | number) => {
    setSelectedStatus((prevSelected) =>
      prevSelected === value ? null : value
    );
  };

  // Handle multi-select for warehouse
  const handleWarehouseChange = (value: string | number) => {
    setSelectedWarehouse((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((option) => option !== value)
        : [...prevSelected, value]
    );
  };

  // Submit the selected filters
  const handleSubmit = () => {
    const queryParams = new URLSearchParams(location.search);

    // Set status filter
    if (selectedStatus) {
      queryParams.set("status", String(selectedStatus));
    } else {
      queryParams.delete("status");
    }

    // Set warehouse filter
    if (selectedWarehouse.length > 0) {
      queryParams.set("warehouse", selectedWarehouse.join(","));
    } else {
      queryParams.delete("warehouse");
    }

    navigate({ search: queryParams.toString() }, { replace: true });
  };

  // Reset all filters
  const handleReset = () => {
    const queryParams = new URLSearchParams(location.search);

    // Clear both filters
    setSelectedStatus(null);
    setSelectedWarehouse([]);
    queryParams.delete("status");
    queryParams.delete("warehouse");

    navigate({ search: queryParams.toString() }, { replace: true });
  };

  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];

  return (
    <Menu
      mode="inline"
      style={{ width: 215 }}
      className="filter-drop-down"
      defaultOpenKeys={["status", "warehouse"]}
    >
      {/* Status Single Select as Checkboxes */}
      {/* <div className="filter-max-height"> */}
      <Menu.SubMenu key="status" title="Status">
        {statusOptions.map((option) => (
          <Menu.Item key={option.value}>
            <Checkbox
              checked={selectedStatus === option.value} // Ensure only one is checked
              onChange={
                () =>
                  setSelectedStatus(
                    selectedStatus === option.value ? null : option.value
                  ) // Toggle logic for single select
              }
            >
              {option.label}
            </Checkbox>
          </Menu.Item>
        ))}
      </Menu.SubMenu>

      {/* Warehouse Multi-Select */}
      <Menu.SubMenu key="warehouse" title="Warehouse">
        <div className="filter-max-height">
          {warehouseData?.results?.data.map((option: any) => (
            <Menu.Item key={option.id}>
              <Checkbox
                checked={selectedWarehouse.includes(option.id)} // Prefill checkbox if selected
                onChange={() => handleWarehouseChange(option.id)}
              >
                {option.name?.name}
              </Checkbox>
            </Menu.Item>
          ))}
        </div>
      </Menu.SubMenu>
      {/* </div> */}
      {/* Action Buttons */}
      <Menu.Item key="actions" className="filter-drop-down">
        <Row justify="space-between">
          <CommonButton
            variant="wearlay-without-bg"
            onClick={handleReset}
            value="Reset"
            className="reset"
          />
          <CommonButton
            variant="wealay-primary-btn"
            onClick={handleSubmit}
            value="Ok"
          />
        </Row>
      </Menu.Item>
    </Menu>
  );
};

export default CategoryFilterComponent;
