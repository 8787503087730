import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Item } from "../../../interface/finishedGoods";
import {
  useGetAllFinishedGoodsByIdQuery,
  useGetSelectByIdApiQuery,
  useGetUserDetailByIdQuery,
} from "../../../services/api";
import AddNewLog from "./addNewLog";
import "./index.css";
import LoginHistory from "./loginHistory";
import FinishedGoodsPreview from "./productDetails";
import ReservedBy from "./reservedBy";
import WarehouseTab from "./warehouse";
interface ICreateSales {
  setId: (data: string) => void;
  setDrawer: (data: any) => void;
  setActive: (data: boolean) => void;
  setEditOpen: (data: boolean) => void;

  setReserved?: (data: boolean) => void;
}
export const FinishedGoodsTabPreview: React.FC<ICreateSales> = ({
  setId,
  setActive,
  setReserved,
  setEditOpen,
  setDrawer,
}) => {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const log = searchParams.get("log");
  const reservation = searchParams.get("reservation");

  const { data, isLoading } = useGetAllFinishedGoodsByIdQuery(Number(id));
  const ratingData = [
    "Cost",
    "Quality",
    "Credit terms",
    "Reliability",
    "Logistic chain",
    "Communication",
  ];

  useEffect(() => {
    if (id) {
      setId(data?.data?.finished_goods_id as string);
      setActive(data?.data?.is_active as boolean);
    }
  }, [id, data?.data?.finished_goods_id]);
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };
  const { data: selectById } = useGetSelectByIdApiQuery("finishedgoods");

  useEffect(() => {
    setReserved && setReserved(data?.data?.reserved as boolean);
  }, [selectById?.id, data?.data?.reserved]);
  const userId = localStorage.getItem("user_id");

  const { data: userDetail } = useGetUserDetailByIdQuery(userId);
  useEffect(() => {
    if (log) {
      setActiveTab("4");
    }
    if (
      reservation &&
      (localStorage?.getItem("user_role") === "2" ||
        localStorage?.getItem("user_role") === "1")
    ) {
      setActiveTab("2");
    }
  }, [log, reservation]);
  return (
    <>
      <Tabs
        className="finishedGoodsTab"
        activeKey={activeTab}
        onChange={handleTabChange}
        type="card"
        items={
          localStorage?.getItem("user_role") === "2" ||
          localStorage?.getItem("user_role") === "1"
            ? [
                {
                  label: "Product Details", // Unique label for the first tab
                  key: "1", // Unique key for the first tab
                  children: (
                    <FinishedGoodsPreview
                      setActive={setActive}
                      setId={setId}
                      data={data?.data as Item}
                      isLoading={isLoading as boolean}
                    />
                  ),
                },

                {
                  label: "Reserved By", // Unique label for the second tab
                  key: "2", // Unique key for the second tab
                  children: (
                    <ReservedBy
                      setEditOpen={setEditOpen}
                      setDrawer={setDrawer}
                    />
                  ),
                },
              ]
            : [
                {
                  label: "Product Details", // Unique label for the first tab
                  key: "1", // Unique key for the first tab
                  children: (
                    <FinishedGoodsPreview
                      setActive={setActive}
                      setId={setId}
                      data={data?.data as Item}
                      isLoading={isLoading as boolean}
                    />
                  ),
                },
                {
                  label: "Warehouse Details", // Unique label for the second tab
                  key: "2", // Unique key for the second tab
                  children: (
                    <WarehouseTab
                      data={userDetail?.data[0] as Item}
                      sku={data?.data?.storage_unit as string}
                    />
                  ),
                },
                {
                  label: "Reserved By", // Unique label for the second tab
                  key: "3", // Unique key for the second tab
                  children: (
                    <ReservedBy
                      setEditOpen={setEditOpen}
                      setDrawer={setDrawer}
                    />
                  ),
                },
                ...(data?.data?.is_active
                  ? [
                      {
                        label: "Log Inwards/Outwards",
                        key: "4",
                        children: open ? (
                          <AddNewLog
                            data={data?.data as any}
                            inventory_type="finshedgoods"
                            defaultCategoryId={selectById?.id as number}
                            setOpen={setOpen}
                            path="/finished-goods"
                          />
                        ) : (
                          <LoginHistory
                            modelId={Number(selectById?.id as number)}
                            objectId={Number(id)}
                            setOpen={setOpen}
                          />
                        ),
                      },
                    ]
                  : []),
              ]
        }
      />
    </>
  );
};

export default FinishedGoodsTabPreview;
