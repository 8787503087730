import { Col, Row, Skeleton, Tag } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ConsumableItem2 } from "../../../interface/consumable";

interface ICreateSales {
  setId: (data: string) => void;
  setActive: (data: boolean) => void;
  data: ConsumableItem2;
  isLoading: boolean;
}
export const ConsumablePreview: React.FC<ICreateSales> = ({
  setId,
  setActive,
  data,
  isLoading,
}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  useEffect(() => {
    if (id) {
      setId(data?.consumable_id as string);
      setActive(data?.is_active as boolean);
    }
  }, [id, data?.consumable_id]);

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <div className="sales-detail-border-main ">
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Item Name</div>
                <div className="sales-detail-sub-title common-work-break">
                  {data?.name?.name}
                </div>
              </div>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Category</div>
                <div className="sales-detail-sub-title common-work-break">
                  <div className="flex gap-2">
                    {data?.category?.breadcrumb &&
                      data?.category?.breadcrumb?.map((item: any, index) => (
                        <div className="flex gap-2">
                          <span>{item}</span>
                          {data?.category?.breadcrumb &&
                            index !== data.category.breadcrumb.length - 1 && (
                              <img
                                src="/assets/rightArrow.svg"
                                alt="Right Arrow"
                              />
                            )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Purpose</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.purpose?.name ? data?.purpose?.name : "-"}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Safety Stock</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.safety_stock ? data?.safety_stock : "-"}
                  </div>
                </div>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">
                    Quantity In Hand
                  </div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.quantity_inhand}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">
                    Daily Average Demand
                  </div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.daily_average_demand}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Lead Time</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.lead_time ? data?.lead_time : "-"}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">
                    Item Order Quantity
                  </div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.item_order_quantity
                      ? data?.item_order_quantity
                      : "-"}
                  </div>
                </div>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Reorder Point</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.reorder_point ? data?.reorder_point : "-"}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Previous DOP</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.previous_dop
                      ? moment(data?.previous_dop).format("DD-MM-YYYY")
                      : "-"}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <div className=" flex flex-col gap-2 p-1">
                <div className="sales-detail-placeholder">
                  Inventory Suggestion
                </div>
                <div className="sales-detail-sub-title common-work-break">
                  {data?.safety_stock > data?.reorder_point ? (
                    <Tag color="green">Sufficient</Tag>
                  ) : data?.safety_stock === data?.reorder_point ? (
                    <Tag color="orange">Just Sufficient</Tag>
                  ) : (
                    <Tag color="red">Insufficient</Tag>
                  )}
                </div>
              </div>
            </Row>
          </div>

          <Row className="w-full mt-24">
            <div className="sales-detail-border flex flex-col gap-2 w-full">
              <div className="sales-detail-placeholder">Remarks</div>
              <div className="sales-detail-sub-title  common-work-break">
                {data?.remarks ? data?.remarks : "-"}
              </div>
            </div>
          </Row>
          {data?.specification?.length > 0 && (
            <div>
              <Row className="mt-24p">
                <Col span={8} className="sales-detail-border">
                  <b>Specification</b>
                </Col>
                <Col span={8} className="sales-detail-border">
                  <b>Dimension</b>
                </Col>
                <Col span={8} className="sales-detail-border">
                  <b>Unit</b>
                </Col>
              </Row>

              {data?.specification?.map(
                (specification: any, specIndex: number) => (
                  <Row key={specIndex}>
                    {/* Material (Specification Name) */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.specification_name}
                    </Col>

                    {/* Dimension */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.valueFields?.map(
                        (field: any, fieldIndex: number) => (
                          <div key={fieldIndex}>
                            {field?.dimension_value?.join(", ") || "N/A"}
                          </div>
                        )
                      )}
                    </Col>

                    {/* Unit */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.valueFields?.map(
                        (field: any, fieldIndex: number) => {
                          const unitName = specification?.unitOptions?.find(
                            (option: any) => option.value === field.unit
                          )?.label;

                          return <div key={fieldIndex}>{unitName || "-"}</div>;
                        }
                      )}
                    </Col>
                  </Row>
                )
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ConsumablePreview;
