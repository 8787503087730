import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Col, message, Modal, Row, Skeleton, Tag, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { concatTabTextFunction } from "../../../helpers/helperFunction";
import { useUpdateJobInHandMutation } from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import { AssignQuoteForJobCardModal } from "../../Modals/assignQuoteForJobCard";
import CompletedJob from "../../Modals/completedJob";
// import "./index.css";
interface ICreateSales {
  setId: (data: string) => void;
  setActive: (data: boolean) => void;
  data: any;
  isLoading: boolean;
  setOpenDrawer: (data: boolean) => void;
}
export const JobHandDetailPreview: React.FC<ICreateSales> = ({
  setId,
  setActive,
  data,
  isLoading,
  setOpenDrawer,
}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const [open, isOpen] = useState(false);
  const [assignOpen, isAssignOpen] = useState(false);

  const ratingData = [
    "Cost",
    "Quality",
    "Credit terms",
    "Reliability",
    "Logistic chain",
    "Communication",
  ];

  useEffect(() => {
    if (id) {
      setId(data?.job_id as string);
      setActive(data?.is_active as boolean);
    }
  }, [id, data?.job_id]);
  const [approveQutation, { isLoading: approveLoading }] =
    useUpdateJobInHandMutation();
  const formData = new FormData();

  const handleRejected = async () => {
    formData.append("quote_status", "rejected");
    try {
      const response = await approveQutation({
        id: Number(id),
        body: formData,
      }).unwrap(); // Send transformed data
      //   form.resetFields();
      //   navigate("/for-lining");
      setOpenDrawer(false);

      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message);
    }
  };
  const handleClosed = async () => {
    formData.append("job_status", "closed");

    try {
      const response = await approveQutation({
        id: Number(id),
        body: formData,
      }).unwrap(); // Send transformed data
      //   form.resetFields();
      //   navigate("/for-lining");
      setOpenDrawer(false);

      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message);
    }
  };
  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <div className="sales-detail-border-main ">
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Job Name</div>
                <div className="sales-detail-sub-title common-work-break">
                  {data?.name?.name}
                </div>
              </div>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Category</div>
                <div className="sales-detail-sub-title common-work-break">
                  <div className="flex gap-2">
                    {data?.category?.breadcrumb &&
                      data?.category?.breadcrumb?.map(
                        (item: any, index: number) => (
                          <div className="flex gap-2">
                            <span>{item}</span>
                            {data?.category?.breadcrumb &&
                              index !== data.category.breadcrumb.length - 1 && (
                                <img
                                  src="/assets/rightArrow.svg"
                                  alt="Right Arrow"
                                />
                              )}
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </Row>

            <div>
              <Row>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">Job Type</div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.type
                        ? data?.type === "internal"
                          ? "Internal"
                          : "Outsource Vendor"
                        : "-"}
                    </div>
                  </div>
                  {data?.type === "internal" && (
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Brand Specified
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.received_on
                          ? moment(data.received_on).format("DD-MM-YYYY")
                          : "-"}
                      </div>
                    </div>
                  )}
                </Col>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">Client Name</div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.client?.name ? data?.client?.name : "-"}
                    </div>
                  </div>

                  {data?.type === "internal" && (
                    <div className=" flex flex-col gap-2 overallrating sales-detail-border">
                      <div className="sales-detail-placeholder">
                        Proposed Time for Completion
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.completion_on
                          ? moment(data.completion_on).format("DD-MM-YYYY")
                          : "-"}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              {data?.job_status && data?.type === "internal" && (
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="flex flex-col gap-2 p-1">
                      <div className="sales-detail-placeholder">
                        Job Card Number
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.job_card_number ? data?.job_card_number : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Job Issue Date
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.created_date
                          ? moment(data.created_date).format("DD-MM-YYYY")
                          : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {data?.type === "internal" && (
                <Row className="sales-detail-border">
                  <Col span={24} md={{ span: 24 }}>
                    <div className="flex flex-col gap-2 ">
                      <div className="sales-detail-placeholder">Job Status</div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.job_status === "completed" ? (
                          <Tag color="green" icon={<CheckOutlined />}>
                            Completed
                          </Tag>
                        ) : data?.job_status === "closed" ? (
                          <Tag color="red" icon={<CloseCircleOutlined />}>
                            Declined
                          </Tag>
                        ) : data?.job_status === "partially_completed" ? (
                          <Tag color="orange" icon={<CloseCircleOutlined />}>
                            Incompleted
                          </Tag>
                        ) : data?.job_status === "opened" ? (
                          <Tag color="blue" icon={<CheckOutlined />}>
                            Assigned
                          </Tag>
                        ) : (
                          <div>-</div> // Default case for unexpected values
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {data?.type !== "internal" && (
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Quoted Issue Date
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.quote_issue_on
                          ? moment(data.quote_issue_on).format("DD-MM-YYYY")
                          : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Quoted Price for Fabrication
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.quote_price_fabrication
                          ? data?.quote_price_fabrication
                          : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {data?.type !== "internal" && (
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Quoted Price For Mould
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.quote_price_mould
                          ? data?.quote_price_mould
                          : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Proposed Time for Completion
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.completion_on
                          ? moment(data.completion_on).format("DD-MM-YYYY")
                          : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {data?.job_status && data?.type !== "internal" && (
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Quoted Price
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.quote_price ? data?.quote_price : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Job Card Number
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.job_card_number ? data?.job_card_number : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              {data?.job_status && data?.type !== "internal" && (
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Job Issue Date
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.created_date
                          ? moment(data.created_date).format("DD-MM-YYYY")
                          : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="flex flex-col gap-2 p-1">
                      <div className="sales-detail-placeholder">Job Status</div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.job_status === "completed" ? (
                          <Tag color="green" icon={<CheckOutlined />}>
                            Completed
                          </Tag>
                        ) : data?.job_status === "closed" ? (
                          <Tag color="red" icon={<CloseCircleOutlined />}>
                            Declined
                          </Tag>
                        ) : data?.job_status === "partially_completed" ? (
                          <Tag color="orange" icon={<CloseCircleOutlined />}>
                            Incompleted
                          </Tag>
                        ) : data?.job_status === "opened" ? (
                          <Tag color="blue" icon={<CheckOutlined />}>
                            Assigned
                          </Tag>
                        ) : (
                          <div>-</div> // Default case for unexpected values
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {data?.job_status && data?.type !== "internal" && (
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Job Received Date
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.received_on
                          ? moment(data.received_on).format("DD-MM-YYYY")
                          : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Amount Paid
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.amount ? data?.amount : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <Row className="w-full mt-24p">
            <div className="sales-detail-border flex flex-col gap-2 w-full">
              <div className="sales-detail-placeholder">Remarks</div>
              <div className="sales-detail-sub-title  common-work-break">
                {data?.remarks ? data?.remarks : "-"}
              </div>
            </div>
          </Row>
          {data?.document && (
            <div>
              <div className="flex justify-between cancel-pdf items-center mt-24">
                <div className="flex gap-2">
                  <img src="/assets/file.svg"></img>
                  <div className="flex flex-col">
                    <div>
                      <Tooltip title={data?.document_name}>
                        {concatTabTextFunction(data?.document_name, 10)}
                      </Tooltip>
                    </div>
                    <div className="textHash">
                      {data?.document_size} .{" "}
                      {moment(data?.modified_date).format("DD-MMM-YYYY")}{" "}
                    </div>
                  </div>
                </div>

                <a href={data?.document}>
                  <img
                    src="/assets/download.svg"
                    className="file-delete "
                  ></img>
                </a>
              </div>
            </div>
          )}
          {data?.specification?.length > 0 && (
            <div>
              <Row className="mt-24p">
                <Col span={8} className="sales-detail-border">
                  <b>Specification</b>
                </Col>
                <Col span={8} className="sales-detail-border">
                  <b>Dimension</b>
                </Col>
                <Col span={8} className="sales-detail-border">
                  <b>Unit</b>
                </Col>
              </Row>

              {data?.specification?.map(
                (specification: any, specIndex: number) => (
                  <Row key={specIndex}>
                    {/* Material (Specification Name) */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.specification_name}
                    </Col>

                    {/* Dimension */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.valueFields?.map(
                        (field: any, fieldIndex: number) => (
                          <div key={fieldIndex}>
                            {field?.dimension_value?.join(", ") || "N/A"}
                          </div>
                        )
                      )}
                    </Col>

                    {/* Unit */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.valueFields?.map(
                        (field: any, fieldIndex: number) => {
                          const unitName = specification?.unitOptions?.find(
                            (option: any) => option.value === field.unit
                          )?.label;

                          return <div key={fieldIndex}>{unitName || "-"}</div>;
                        }
                      )}
                    </Col>
                  </Row>
                )
              )}
            </div>
          )}
          {data?.specification?.length > 0 && (
            <Row className="w-full mt-24p">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">
                  Additional Specification
                </div>
                <div className="sales-detail-sub-title  common-work-break">
                  {data?.remarks ? data?.remarks : "-"}
                </div>
              </div>
            </Row>
          )}
          {data?.type !== "internal" &&
          data?.job_type === "job_work" &&
          data?.job_status !== "opened" &&
          data?.job_status !== "completed" &&
          data?.job_status !== "partially_completed" ? (
            <div className="flex gap-2 justify-end mt-24p">
              {data?.job_status === "closed" ||
                data?.quote_status === "rejected" ||
                (data?.quote_status === "pending" && (
                  <CommonButton
                    variant="wealay-secondary-btn"
                    value="Reject Quote"
                    onClick={handleRejected}
                    isLoading={approveLoading}
                    disabled={approveLoading}
                  />
                ))}

              <CommonButton
                variant="wealay-primary-btn"
                value={
                  data?.job_status === "closed" ||
                  data?.quote_status === "rejected"
                    ? "Re-Assign Work"
                    : "Assign Work"
                }
                onClick={() => {
                  isAssignOpen(true);
                }}
              />
            </div>
          ) : (
            data?.job_status === "opened" && (
              <div className="flex gap-2 justify-end mt-24p">
                <CommonButton
                  variant="wealay-secondary-btn"
                  value="Cancel this Job"
                  onClick={handleClosed}
                  isLoading={approveLoading}
                  disabled={approveLoading}
                />

                <CommonButton
                  variant="wealay-primary-btn"
                  value={"Mark as Completed"}
                  onClick={() => {
                    isOpen(true);
                  }}
                  isLoading={approveLoading}
                  disabled={approveLoading}
                />
              </div>
            )
          )}
        </div>
      )}

      <Modal open={open} footer={false} closable={false} destroyOnClose>
        <CompletedJob
          setOpen={isOpen}
          setDrawer={setOpenDrawer}
          commitedDate={
            data?.completion_on ? data?.completion_on : data?.committed_on
          }
          jobType={data?.type}
          quotedPrice={data?.quote_price}
        />
      </Modal>
      <Modal open={assignOpen} footer={false} closable={false} destroyOnClose>
        <AssignQuoteForJobCardModal
          setOpen={isAssignOpen}
          isReassign={
            ["rejected", "closed"].includes(data?.quote_status) ? true : false
          }
          setDrawer={setOpenDrawer}
          job_status={
            data?.job_status === "closed" || data?.quote_status === "rejected"
          }
        />
      </Modal>
    </>
  );
};

export default JobHandDetailPreview;
