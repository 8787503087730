import { Col, Rate, Row, Skeleton } from "antd";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetVendorByIdQuery } from "../../../services/api";
import "./index.css";

interface ICreateSales {
  setId: (data: string) => void;
  setActive: (data: boolean) => void;
}
export const VendorPreview: React.FC<ICreateSales> = ({ setId, setActive }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { data, isLoading } = useGetVendorByIdQuery(Number(id));
  const ratingData = [
    "Cost",
    "Quality",
    "Credit terms",
    "Reliability",
    "Logistic chain",
    "Communication",
  ];
  const ratingItems = [
    { name: "Cost", value: data?.data.cost },
    { name: "Quality", value: data?.data?.quality },
    { name: "Credit Terms", value: data?.data.credit_terms },
    { name: "Reliability", value: data?.data?.reliability },
    { name: "Logistic Chain", value: data?.data?.logistic_chain },
    { name: "Communication", value: data?.data?.communication },
  ];
  useEffect(() => {
    if (id) {
      setId(data?.data?.vendor_id as string);
      setActive(data?.data?.is_active as boolean);
    }
  }, [id, data?.data?.vendor_id]);
  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <div className="sales-detail-border-main ">
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Vendor Name</div>
                <div className="sales-detail-sub-title common-work-break">
                  {typeof data?.data?.name === "string" ||
                  typeof data?.data?.name === "number"
                    ? data?.data?.name
                    : (data?.data?.name?.name as string)}
                </div>
              </div>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Address</div>
                <div className="sales-detail-sub-title common-work-break">
                  {data?.data?.address}
                </div>
              </div>
            </Row>
            <Row>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Contact Number</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.data?.phone}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Vendor Type</div>
                  {data?.data?.type?.map((item) => (
                    <div className="sales-detail-sub-title common-work-break">
                      {item}
                    </div>
                  ))}
                </div>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Email Id</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.data?.email}
                  </div>
                </div>
                <div className=" flex flex-col gap-2 overallrating">
                  <div className="sales-detail-placeholder">Overall Rating</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.data?.ratings ? data?.data?.ratings : "-"}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Remarks</div>
                <div className="sales-detail-sub-title  common-work-break">
                  {data?.data?.remarks ? data?.data?.remarks : "-"}
                </div>
              </div>
            </Row>
          </div>

          <Row className="w-full margin-block">
            <div className="sales-detail-border flex flex-col gap-2 w-full">
              <div className="sales-detail-placeholder">Locations</div>
              {data?.data?.locations?.map((item) => (
                <div className="sales-detail-sub-title ">
                  {item?.city?.name}, {item?.state?.name}
                </div>
              ))}
            </div>
          </Row>
          <Row className="w-full">
            <div className="sales-detail-border flex flex-col gap-2 w-full">
              <div className="sales-detail-placeholder">Ratings</div>
              {ratingItems.map((item, index) => (
                <div key={index} className="flex justify-between">
                  <div>{`${item.name} (${item.value})`}</div>
                  <div>
                    <Rate allowHalf value={item.value} disabled />
                  </div>
                </div>
              ))}
            </div>
          </Row>
        </div>
      )}
    </>
  );
};

export default VendorPreview;
