import { Col, Divider, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { Text } from "../../components/CommonFont/text";
import InventryPieChart from "../../components/DashboardGraph/inventryPieChart";
import RawMaterialDashboard from "../../components/InventryDashBoard/RawMaterials";
import FinishedGoodsDashboard from "../../components/InventryDashBoard/finishedGoods";
import WorkDelayDashboard from "../../components/InventryDashBoard/workDelay";
import "./index.css";
function InventryDashboard() {
  const navigate = useNavigate();

  return (
    <div className="p24">
      <Text value="Overview" variant="wearlay-display" />

      <Row className="mt-24p" justify={"space-between"} gutter={[16, 16]}>
        <Col span={24} xl={{ span: 12 }}>
          <div className="pieWrapper">
            <div className="flex w-full justify-between">
              <Text variant="wearlay-headline-2" value="Works Delayed"></Text>
              <span
                className="viewall cursor-pointer"
                onClick={() => navigate("/job-card")}
              >
                View All
              </span>
            </div>
            <WorkDelayDashboard />
          </div>
        </Col>
        <Col span={24} xl={{ span: 12 }}>
          {" "}
          <div className="pieWrapper">
            <Text
              variant="wearlay-headline-2"
              value="Fast-Moving Goods (Demand Forecast)"
            ></Text>
            <Divider />
            <InventryPieChart />
          </div>
        </Col>
      </Row>
      <Row className="mt-24p" justify={"space-between"} gutter={[16, 16]}>
        <Col span={24} xl={{ span: 12 }}>
          <FinishedGoodsDashboard />
        </Col>
        <Col span={24} xl={{ span: 12 }}>
          <RawMaterialDashboard />
        </Col>
      </Row>
    </div>
  );
}

export default InventryDashboard;
