import { ResponsivePie } from "@nivo/pie";
import { Col, Empty, Row } from "antd";
import React, { useEffect } from "react";
import { useGetAllDashboardFastMovingGoodsQuery } from "../../services/api";
import { Text } from "../CommonFont/text";
import { Loader } from "../loader";
import "./index.css";
interface ICreateDashboard {
  datas?: any;
  loader?: boolean;
}
export const InventryPieChart: React.FC<ICreateDashboard> = ({
  datas,
  loader,
}) => {
  const getAllExecutiveParams = {
    page_size: 10,
    page: 0,
  };

  const { data, isLoading, isFetching, refetch } =
    useGetAllDashboardFastMovingGoodsQuery(getAllExecutiveParams);
  useEffect(() => {
    refetch();
  }, []);
  const colorArray = [
    "#56AEE2",
    "#5668E2",
    "#8A56E2",
    "#CF56E2",
    "#E256AE",
    "#E28956",
    "#E2CF56",
    "#AEE256",
    "#68E256",
    "#56E2CF",
  ];

  const transformedData =
    data?.data && data?.data?.length > 0
      ? data?.data?.map((item, index) => ({
          id: item?.name?.name ?? "Unknown",
          label: item?.name?.name ?? "Unknown",
          value: item?.demand_forcast ?? 0,
          color: colorArray[index % colorArray.length], // Assign color sequentially
        }))
      : [];

  const datass = [
    {
      id: "stylus",
      label: "stylus",
      value: 493,
      color: "hsl(218, 70%, 50%)",
    },
    {
      id: "make",
      label: "make",
      value: 402,
      color: "hsl(106, 70%, 50%)",
    },
    {
      id: "ruby",
      label: "ruby",
      value: 21,
      color: "hsl(112, 70%, 50%)",
    },
    {
      id: "php",
      label: "php",
      value: 346,
      color: "hsl(252, 70%, 50%)",
    },
  ];

  // console.log(data?.data, transformedData);

  return (
    <>
      <Row>
        <Col className="pieChartHeight pieRelative" span={18}>
          {isLoading ? (
            <div className="flex w-full justify-center items-center h-full">
              <Loader />
            </div>
          ) : transformedData?.length > 0 ? (
            <ResponsivePie
              data={transformedData}
              margin={{ top: 40, right: 80, bottom: 80, left: -120 }}
              startAngle={-3}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              colors={colorArray}
              enableArcLinkLabels={false}
              enableArcLabels={false}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              fill={[
                {
                  match: {
                    id: "ruby",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "c",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "go",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "python",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "scala",
                  },
                  id: "lines",
                },
                {
                  match: {
                    id: "lisp",
                  },
                  id: "lines",
                },
                {
                  match: {
                    id: "elixir",
                  },
                  id: "lines",
                },
                {
                  match: {
                    id: "javascript",
                  },
                  id: "lines",
                },
              ]}
              // legends={[
              //   {
              //     anchor: "top-right",
              //     direction: "column",
              //     justify: false,
              //     translateX: 0,
              //     translateY: 0,
              //     itemsSpacing: 30,
              //     itemWidth: 105,
              //     itemHeight: -10,
              //     itemTextColor: "",

              //     itemDirection: "left-to-right",
              //     itemOpacity: 1,
              //     symbolSize: 12,
              //     symbolShape: "circle",
              //     effects: [
              //       {
              //         on: "hover",
              //         style: {
              //           itemTextColor: "#000",
              //         },
              //       },
              //     ],
              //   },
              // ]}
              theme={{
                legends: {
                  text: {
                    fontSize: 14, // Set font size here
                    fill: "#",
                  },
                },
              }}
            />
          ) : (
            <Empty className="w-full h-full flex justify-center items-center flex-col" />
          )}
        </Col>
        {}

        <Col span={6} className="pieAbsolute1">
          <div className="pie-margin-bottom">Demand</div>

          {data?.data?.map((item: any) => (
            <div className="pie-legend-margin-bottom">
              <Text
                // className="pieAbsolute"
                value={item?.demand_forcast.toString()}
                variant="wearlay-label-bold"
              />
            </div>
          ))}
        </Col>
        <div className="flex flex-col pieAbsolute pieFont">
          <Text
            className="legendPie pie-margin-bottom"
            value="Product Name"
            variant="wearlay-label"
          />
          {transformedData?.map((item: any) => (
            <div className="flex gap-2 items-center ">
              <div
                style={{
                  backgroundColor: item?.color,
                  borderRadius: "50%",
                  width: "10px",
                  height: "10px",
                }}
              ></div>
              <Text
                // className="pieAbsolute"
                className="legendPie"
                value={item?.label}
                variant="wearlay-label-bold"
              />
            </div>
          ))}
        </div>
        <div></div>
      </Row>
    </>
  );
};

export default InventryPieChart;
