import { Menu, Popover } from "antd";
import "../../antd.override.css";

import { useLocation, useNavigate } from "react-router";

import "./index.css";

import { useEffect, useState } from "react";

import { ConstantVariable } from "../../constants/sideBarconstants";
import { ISideBar } from "../../interface/sideBar";
import { PathConstants } from "../../pathConstants/pathConstants";

export const Sidebar = () => {
  const isManager = localStorage.getItem("user_role");

  const path = useLocation().pathname;

  const [selectedSubmenuKey, setSelectedSubmenuKey] = useState<string[]>([]);

  const navigate = useNavigate();

  //or simply use const [current, setCurrent] = useState(location.pathname)

  function handleClick(pathName: string) {
    navigate(pathName);
  }
  function selectedKeys() {
    const activeIndex = (
      isManager === "1"
        ? sideBarListManager
        : isManager === "3"
        ? sideBarListInventoryManager
        : sideBarList
    )?.findIndex((item: any) => item.baseUrl.includes(path));

    if (activeIndex > -1) {
      return [`${activeIndex}`];
    } else {
      return [];
    }
  }

  const sideImage = (item: ISideBar) => {
    if (item?.baseUrl?.includes(path)) {
      return item.imgSrcActive;
    } else {
      return item.imgSrc;
    }
  };
  const sideBarList = [
    {
      name: ConstantVariable.OVERVIEW,
      baseUrl: [`${PathConstants.OVERVIEW}`, "/"],
      path: `${PathConstants.OVERVIEW}`,
      // path: `${PathConstants.DASHBOARD}?filter=day&from=${epoch(
      //   currentFromDate
      // )}&to=${epoch(currentToDate)}`,
      imgSrc: "/assets/overview.svg",
      imgSrcActive: "/assets/overview-active.svg",
    },

    {
      name: ConstantVariable.SALES,
      baseUrl: [`${PathConstants.SALES}`],
      path: `${PathConstants.SALES}`,
      // path: `${PathConstants.TICKETS}?filter=day&from=${epoch(
      //   currentFromDate
      // )}&to=${epoch(currentToDate)}`,
      imgSrc: "/assets/sales.svg",
      imgSrcActive: "/assets/sales-active.svg",
    },
    {
      name: ConstantVariable.QUOTATION,
      baseUrl: [`${PathConstants.QUOTATION}`],
      path: PathConstants.QUOTATION,
      imgSrc: "/assets/quotaion.svg",
      imgSrcActive: "/assets/quotation-active.svg",
    },
    {
      name: ConstantVariable.ORDERS,
      baseUrl: [`${PathConstants.ORDER}`],
      path: PathConstants.ORDER,
      imgSrc: "/assets/orders.svg",
      imgSrcActive: "/assets/order-active.svg",
    },
    {
      name: ConstantVariable.INQUIRIES,
      baseUrl: [`${PathConstants.INQUIRIES}`],
      path: PathConstants.INQUIRIES,
      imgSrc: "/assets/inquiry.svg",
      imgSrcActive: "/assets/inquiry-active.svg",
    },
    {
      name: ConstantVariable.ALLOCATED,
      baseUrl: [`${PathConstants.ALLOCATED}`],
      path: PathConstants.ALLOCATED,
      imgSrc: "/assets/allocated.svg",
      imgSrcActive: "/assets/allocated-active.svg",
    },
    {
      name: ConstantVariable.CONATCTS,
      baseUrl: [`${PathConstants.CONTACTS}`],
      path: PathConstants.CONTACTS,
      imgSrc: "/assets/contact.svg",
      imgSrcActive: "/assets/contact-active.svg",
    },
    {
      name: ConstantVariable.INVENTORY,
      baseUrl: [`${PathConstants.FINISHED_GOODS}`],
      path: PathConstants.FINISHED_GOODS, // Default path
      imgSrc: "/assets/inventry_cnrl.svg",
      imgSrcActive: "/assets/inventory_cntl_active.svg",
    },
  ];
  const sideBarListManager = [
    {
      name: ConstantVariable.OVERVIEW,
      baseUrl: [`${PathConstants.OVERVIEW}`, "/"],
      path: `${PathConstants.OVERVIEW}`,
      // path: `${PathConstants.DASHBOARD}?filter=day&from=${epoch(
      //   currentFromDate
      // )}&to=${epoch(currentToDate)}`,
      imgSrc: "/assets/overview.svg",
      imgSrcActive: "/assets/overview-active.svg",
    },
    {
      name: ConstantVariable.EMPLOYEE,
      baseUrl: [`${PathConstants.EMPLOYEE}`],
      path: PathConstants.EMPLOYEE,
      imgSrc: "/assets/employee.svg",
      imgSrcActive: "/assets/employee-active.svg",
    },
    {
      name: ConstantVariable.SALES,
      baseUrl: [
        `${PathConstants.SALES}`,
        `${PathConstants.SALES}/ticket-details`,
      ],
      path: `${PathConstants.SALES}`,
      // path: `${PathConstants.TICKETS}?filter=day&from=${epoch(
      //   currentFromDate
      // )}&to=${epoch(currentToDate)}`,
      imgSrc: "/assets/sales.svg",
      imgSrcActive: "/assets/sales-active.svg",
    },
    {
      name: ConstantVariable.QUOTATION,
      baseUrl: [`${PathConstants.QUOTATION}`],
      path: PathConstants.QUOTATION,
      imgSrc: "/assets/quotaion.svg",
      imgSrcActive: "/assets/quotation-active.svg",
    },
    {
      name: ConstantVariable.ORDERS,
      baseUrl: [`${PathConstants.ORDER}`],
      path: PathConstants.ORDER,
      imgSrc: "/assets/orders.svg",
      imgSrcActive: "/assets/order-active.svg",
    },
    {
      name: ConstantVariable.INQUIRIES,
      baseUrl: [`${PathConstants.INQUIRIES}`],
      path: PathConstants.INQUIRIES,
      imgSrc: "/assets/inquiry.svg",
      imgSrcActive: "/assets/inquiry-active.svg",
    },
    {
      name: ConstantVariable.ALLOCATED,
      baseUrl: [`${PathConstants.ALLOCATED}`],
      path: PathConstants.ALLOCATED,
      imgSrc: "/assets/allocated.svg",
      imgSrcActive: "/assets/allocated-active.svg",
    },
    {
      name: ConstantVariable.CONATCTS,
      baseUrl: [`${PathConstants.CONTACTS}`],
      path: PathConstants.CONTACTS,
      imgSrc: "/assets/contact.svg",
      imgSrcActive: "/assets/contact-active.svg",
    },
    {
      name: ConstantVariable.INVENTORY,
      baseUrl: [`${PathConstants.FINISHED_GOODS}`],
      path: PathConstants.FINISHED_GOODS, // Default path
      imgSrc: "/assets/inventry_cnrl.svg",
      imgSrcActive: "/assets/inventory_cntl_active.svg",
      // Assign a unique key
    },
  ];
  const sideBarListInventoryManager = [
    {
      name: ConstantVariable.OVERVIEW,
      baseUrl: [`${PathConstants.OVERVIEW_INVENTORY}`],
      path: `${PathConstants.OVERVIEW_INVENTORY}`,
      imgSrc: "/assets/overview.svg",
      imgSrcActive: "/assets/overview-active.svg",
    },
    {
      name: ConstantVariable.EMPLOYEE,
      baseUrl: [`${PathConstants.INVENTORY_EMPLOYEE}`],
      path: PathConstants.INVENTORY_EMPLOYEE,
      imgSrc: "/assets/employee.svg",
      imgSrcActive: "/assets/employee-active.svg",
    },
    {
      name: ConstantVariable.INVENTORY,
      baseUrl: [
        `${PathConstants.FINISHED_GOODS}`,
        `${PathConstants.UNCLASSIFIED}`,
        `${PathConstants.CONSUMABLE}`,
        `${PathConstants.SAMPLE}`,
        `${PathConstants.ASSETS}`,
        `${PathConstants.REJECTED_PRODUCTS}`,
        `${PathConstants.RAW_MATERIALs}`,
        `${PathConstants.MOULDS}`,
        `${PathConstants.FOR_LINING}`,
        `${PathConstants.JOB_CARD}`,
        `${PathConstants.ADD_GOODS}`,
        `${PathConstants.ADD_ASSETS}`,
        `${PathConstants.ADD_CONSUMABLE}`,
        `${PathConstants.ADD_LINING}`,
        `${PathConstants.ADD_JOB_CARD}`,
        `${PathConstants.ADD_MOULDS}`,
        `${PathConstants.ADD_RAW_MATERIAL}`,
        `${PathConstants.ADD_REJECTED_PRODUCT}`,
        `${PathConstants.ADD_UNCLASSIFIED}`,
        `${PathConstants.ADD_SAMPLE}`,
      ],
      path: PathConstants.FINISHED_GOODS, // Default path
      imgSrc: "/assets/inventry_cnrl.svg",
      imgSrcActive: "/assets/inventory_cntl_active.svg",
      key: "INVENTORY", // Assign a unique key
    },
    {
      name: ConstantVariable.INVENTORY_CTRL,
      baseUrl: [
        `${PathConstants.WARE_HOUSE_MANAGEMENT}`,
        `${PathConstants.CATEGORY_MANAGEMENT}`,
        `${PathConstants.VENDOR_MANAGEMENT}`,
        `${PathConstants.ADD_CATEGORY}`,
      ],
      path: PathConstants.WARE_HOUSE_MANAGEMENT, // Default path
      imgSrc: "/assets/inventry_cnrl.svg",
      imgSrcActive: "/assets/inventory_cntl_active.svg",
      key: "INVENTORY_CTRL", // Assign a unique key
    },
  ];
  const sideBarListInventoryExecutive = [
    // {
    //   name: ConstantVariable.OVERVIEW,
    //   baseUrl: [`${PathConstants.OVERVIEW_INVENTORY}`],
    //   path: `${PathConstants.OVERVIEW_INVENTORY}`,
    //   imgSrc: "/assets/overview.svg",
    //   imgSrcActive: "/assets/overview-active.svg",
    // },
    // {
    //   name: ConstantVariable.EMPLOYEE,
    //   baseUrl: [`${PathConstants.EMPLOYEE}`],
    //   path: PathConstants.EMPLOYEE,
    //   imgSrc: "/assets/employee.svg",
    //   imgSrcActive: "/assets/employee-active.svg",
    // },
    {
      name: ConstantVariable.INVENTORY,
      baseUrl: [
        `${PathConstants.FINISHED_GOODS}`,
        `${PathConstants.UNCLASSIFIED}`,
        `${PathConstants.CONSUMABLE}`,
        `${PathConstants.SAMPLE}`,
        `${PathConstants.ASSETS}`,
        `${PathConstants.REJECTED_PRODUCTS}`,
        `${PathConstants.RAW_MATERIALs}`,
        `${PathConstants.MOULDS}`,
        `${PathConstants.FOR_LINING}`,
        `${PathConstants.JOB_CARD}`,
        `${PathConstants.ADD_GOODS}`,
        `${PathConstants.ADD_ASSETS}`,
        `${PathConstants.ADD_CONSUMABLE}`,
        `${PathConstants.ADD_LINING}`,
        `${PathConstants.ADD_JOB_CARD}`,
        `${PathConstants.ADD_MOULDS}`,
        `${PathConstants.ADD_RAW_MATERIAL}`,
        `${PathConstants.ADD_REJECTED_PRODUCT}`,
        `${PathConstants.ADD_UNCLASSIFIED}`,
        `${PathConstants.ADD_SAMPLE}`,
      ],
      path: PathConstants.FINISHED_GOODS, // Default path
      imgSrc: "/assets/inventry_cnrl.svg",
      imgSrcActive: "/assets/inventry_cnrl.svg",
      key: "INVENTORY_CTRL", // Assign a unique key

      // Assign a unique key
    },

    // {
    //   name: ConstantVariable.INVENTORY,
    //   baseUrl: ConstantVariable.INVENTORY,
    //   path: PathConstants.FINISHED_GOODS, // Default path
    //   imgSrc: "/assets/inventry_cnrl.svg",
    //   imgSrcActive: "/assets/inventry_cnrl.svg",
    //   key: "INVENTORY_CTRL", // Assign a unique key
    // },
  ];
  const inventoryCntrlSubmenu = [
    { name: "Category", path: PathConstants.CATEGORY_MANAGEMENT },
    { name: "Vendor", path: PathConstants.VENDOR_MANAGEMENT },
    { name: "Warehouse", path: PathConstants.WARE_HOUSE_MANAGEMENT },
  ];
  const inventoryMenu = [
    { name: "Finished Goods", path: PathConstants.FINISHED_GOODS },
    { name: "Unclassified", path: PathConstants.UNCLASSIFIED },
    { name: "Consumable", path: PathConstants.CONSUMABLE },
    { name: "Specimens/Samples", path: PathConstants.SAMPLE },
    { name: "Assets", path: PathConstants.ASSETS },

    { name: "Rejected Products", path: PathConstants.REJECTED_PRODUCTS },
    { name: "Raw Materials", path: PathConstants.RAW_MATERIALs },

    { name: "For Lining", path: PathConstants.FOR_LINING },
    { name: "Job In Hand", path: PathConstants.JOB_CARD },
    { name: "Moulds", path: PathConstants.MOULDS },
  ];
  const inventoryMenuForInventoryExecutive = [
    { name: "Finished Goods", path: PathConstants.FINISHED_GOODS },
  ];
  useEffect(() => {
    selectedKeys();
  }, [path]);

  let location = useLocation();
  const [current, setCurrent] = useState(location.pathname);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  return (
    <>
      <div className="sidebar">
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={selectedKeys()}
          selectedKeys={selectedKeys()}
        >
          {(isManager === "1"
            ? sideBarListManager
            : isManager === "3"
            ? sideBarListInventoryManager
            : isManager === "4"
            ? sideBarListInventoryExecutive
            : sideBarList
          ).map((item: any, index) => {
            if (
              isManager === "3" &&
              item.name === ConstantVariable.INVENTORY_CTRL
            ) {
              return (
                <Menu.Item key={index} className="menu-item">
                  <Popover
                    key={index}
                    className="popover-bg"
                    destroyTooltipOnHide
                    content={
                      <Menu className="sub-menu">
                        {inventoryCntrlSubmenu.map((subItem, subIndex) => (
                          <Menu.Item
                            key={subIndex}
                            onClick={() => {
                              handleClick(subItem.path);
                              setSelectedSubmenuKey([`${subIndex}`]); // Set the selected key for submenu
                            }}
                            className="popover-text "
                          >
                            {subItem.name}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    placement="right"
                  >
                    <Menu.Item
                      key={index}
                      icon={<img src={sideImage(item)} alt="item" />}
                      className="menu-item"
                    >
                      <span
                        className={
                          selectedKeys().includes("3")
                            ? "active-sub"
                            : "inactive-sub"
                        }
                      >
                        {item.name}
                      </span>
                    </Menu.Item>
                  </Popover>
                </Menu.Item>
              );
            } else if (
              (isManager === "3" || isManager === "4") &&
              item.name === ConstantVariable.INVENTORY
            ) {
              return (
                <Menu.Item key={index} className="menu-item ">
                  <Popover
                    key={index}
                    className="popover-bg "
                    destroyTooltipOnHide
                    trigger={"hover"}
                    content={
                      <Menu className="sub-menu">
                        {inventoryMenu.map((subItem, subIndex) => (
                          <Menu.Item
                            key={subIndex}
                            onClick={() => {
                              handleClick(subItem.path);
                              setSelectedSubmenuKey([`${subIndex}`]); // Set the selected key for submenu
                            }}
                            className="popover-text"
                          >
                            {subItem.name}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    placement="right"
                  >
                    <Menu.Item
                      key={index}
                      icon={<img src={sideImage(item)} alt="item" />}
                      className="menu-item"
                    >
                      <span
                        className={
                          selectedKeys().includes("2")
                            ? "active-sub"
                            : "inactive-sub "
                        }
                      >
                        {item.name}
                      </span>
                    </Menu.Item>
                  </Popover>
                </Menu.Item>
              );
            } else if (
              (isManager === "2" || isManager === "1") &&
              item.name === ConstantVariable.INVENTORY
            ) {
              return (
                <Menu.Item key={index} className="menu-item">
                  <Popover
                    key={index}
                    className="popover-bg"
                    destroyTooltipOnHide
                    trigger={"hover"}
                    content={
                      <Menu className="sub-menu">
                        {inventoryMenuForInventoryExecutive.map(
                          (subItem, subIndex) => (
                            <Menu.Item
                              key={subIndex}
                              onClick={() => {
                                handleClick(subItem.path);
                                setSelectedSubmenuKey([`${subIndex}`]); // Set the selected key for submenu
                              }}
                              className="popover-text"
                            >
                              {subItem.name}
                            </Menu.Item>
                          )
                        )}
                      </Menu>
                    }
                    placement="right"
                  >
                    <Menu.Item
                      key={index}
                      icon={<img src={sideImage(item)} alt="item" />}
                      className="menu-item"
                    >
                      <span
                        className={
                          selectedKeys().some((key) => ["7", "8"].includes(key))
                            ? "active-sub"
                            : "inactive-sub"
                        }
                      >
                        {item.name}
                      </span>
                    </Menu.Item>
                  </Popover>
                </Menu.Item>
              );
            }

            return (
              <Menu.Item
                key={index}
                icon={<img src={sideImage(item)} alt="item" />}
                onClick={() => handleClick(item.path)}
                className="menu-item"
              >
                {item.name}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    </>
  );
};
export default Sidebar;
