import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useGetAllRawMaterialsByIdQuery,
  useGetSelectByIdApiQuery,
  useGetUserDetailByIdQuery,
} from "../../../services/api";

import AddNewLog from "../FinishedGoodsPreview/addNewLog";
import LoginHistory from "../FinishedGoodsPreview/loginHistory";
import WarehouseTab from "../FinishedGoodsPreview/warehouse";
import "./index.css";
import { RawMaterialPreview } from "./productDetails";
import VendorTab from "./vendor";

interface ICreateSales {
  setId: (data: string) => void;
  setActive: (data: boolean) => void;
}
export const RawMaterialTabPreview: React.FC<ICreateSales> = ({
  setId,
  setActive,
}) => {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const log = searchParams.get("log");

  const { data, isLoading } = useGetAllRawMaterialsByIdQuery(Number(id));
  const ratingData = [
    "Cost",
    "Quality",
    "Credit terms",
    "Reliability",
    "Logistic chain",
    "Communication",
  ];

  useEffect(() => {
    if (id) {
      setId(data?.data?.raw_material_id as string);
      setActive(data?.data?.is_active as boolean);
    }
  }, [id, data?.data?.raw_material_id]);
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };
  const { data: selectById } = useGetSelectByIdApiQuery("rawmaterial");

  const userId = localStorage.getItem("user_id");

  const { data: userDetail } = useGetUserDetailByIdQuery(userId);
  useEffect(() => {
    if (log) {
      setActiveTab("4");
    }
  }, [log]);
  return (
    <>
      <Tabs
        className="finishedGoodsTab"
        activeKey={activeTab}
        onChange={handleTabChange}
        type="card"
        items={
          localStorage?.getItem("user_role") === "2" ||
          localStorage?.getItem("user_role") === "1"
            ? [
                {
                  label: "Product Details",
                  key: "1",
                  children: (
                    <RawMaterialPreview
                      setActive={setActive}
                      setId={setId}
                      data={data?.data as any}
                      isLoading={isLoading as boolean}
                    />
                  ),
                },
                {
                  label: "Vendor Details",
                  key: "2",
                  children: <VendorTab data={data?.data as any} />,
                },
                {
                  label: "Warehouse Details",
                  key: "3",
                  children: (
                    <WarehouseTab
                      data={userDetail?.data[0] as any}
                      sku={data?.data?.storage_unit as string}
                    />
                  ),
                },
              ]
            : [
                {
                  label: "Product Details",
                  key: "1",
                  children: (
                    <RawMaterialPreview
                      setActive={setActive}
                      setId={setId}
                      data={data?.data as any}
                      isLoading={isLoading as boolean}
                    />
                  ),
                },
                {
                  label: "Vendor Details",
                  key: "2",
                  children: <VendorTab data={data?.data as any} />,
                },
                {
                  label: "Warehouse Details",
                  key: "3",
                  children: (
                    <WarehouseTab
                      data={userDetail?.data[0] as any}
                      sku={data?.data?.storage_unit as string}
                    />
                  ),
                },
                ...(data?.data?.is_active
                  ? [
                      {
                        label: "Log Inwards/Outwards",
                        key: "4",
                        children: open ? (
                          <AddNewLog
                            data={data?.data as any}
                            inventory_type="rawmaterial"
                            defaultCategoryId={selectById?.id as number}
                            setOpen={setOpen}
                            path="/raw-materials"
                          />
                        ) : (
                          <LoginHistory
                            modelId={Number(selectById?.id as number)}
                            objectId={Number(id)}
                            setOpen={setOpen}
                          />
                        ),
                      },
                    ]
                  : []), // Only include this tab if is_active is true
              ]
        }
      />
    </>
  );
};

export default RawMaterialTabPreview;
