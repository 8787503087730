import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  Divider,
  Drawer,
  Dropdown,
  message,
  Modal,
  Pagination,
  Select,
  Tabs,
  Tag,
} from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { saveAs } from "file-saver";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CommonButton } from "../../../components/CommonButton/button";
import { Text } from "../../../components/CommonFont/text";
import { SearchBox } from "../../../components/CommonSearchBox";
import { CommonTable } from "../../../components/commonTable";
import CategoryPreview from "../../../components/Drawer/CategoryPreview";
import { getContactsParams } from "../../../helpers/quarery";
import { CategoryData } from "../../../interface/warehouse";

import CategoryFilterComponent from "../../../components/CommonFilter/categoryFilter";
import { CommonSubCategoriesMenu } from "../../../components/commonSubCategories";
import { ConfirmationMessages } from "../../../components/Modals/backlogDates/confirmationMesseges";
import { ActivateDeactivateModal } from "../../../components/Modals/deactivatePopUp";
import { concatTabTextFunction } from "../../../helpers/helperFunction";
import {
  useActivateCategoryMutation,
  useDeleteCategoryMutation,
  useGetAllInventriesQuery,
  useGetCategoriesQuery,
  useGetExcelCategoryByIdQuery,
} from "../../../services/api";
import "./index.css";

function CategoryManagement() {
  const navigate = useNavigate();
  const [defaultCategoryId, setDefaultCategory] = useState<number | undefined>(
    undefined
  );
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const location = useLocation();
  const [isActive, setActive] = useState(false);
  const [dropDownOpen, isDropdownOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState<string>("");
  const [subId, setId] = useState<number>(0); // To toggle between AddWarehouse and WarehousePreview

  const [selectedWarehouse, setSelectedWarehouse] = useState(null); // Store selected warehouse data
  const [searchParams] = useSearchParams();
  const category_id = searchParams.get("category_id");
  const parent_id = searchParams.get("inventory_id");
  const sub_category = searchParams.get("sub_category");
  const sub_category_id = searchParams.get("sub_category_id");
  const confirmation = searchParams.get("confirmation");
  const warehouse = searchParams.get("warehouse");

  const [filter, setFilter] = useState(false);
  const [deactivateCategory] = useDeleteCategoryMutation();
  const [activateCategory] = useActivateCategoryMutation();
  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 10,
  });
  const search = searchParams.get("search");
  const status = searchParams.get("status");
  const category_name = searchParams.get("category_name");
  const method = searchParams.get("method");

  const sub_category_name = searchParams.get("sub_category_name");
  const [downloadIds, setDownloadIds] = useState<any>();

  const params = getContactsParams(pageData.page, pageData.pageSize);
  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };
  const getAllExecutiveParams = {
    page_size: params?.page_size,
    ...(search || status ? { page: params?.page } : { page: params?.page }),
    query: search ? search : "",
    ...(status ? { status: status === "true" ? "True" : "False" } : {}),
    ...(warehouse ? { warehouse: warehouse } : {}),
  };
  const { data, isFetching } = useGetCategoriesQuery(
    {
      id:
        category_id !== null
          ? Number(category_id)
          : Number(parent_id) || Number(defaultCategoryId),
      params: getAllExecutiveParams,
    },
    {
      skip: !defaultCategoryId,
    }
  );
  useEffect(() => {
    if (search || status) {
      setPageData({
        page: 0,
        pageSize: 10,
      });
    }
  }, [status, search]);
  const idArray = _.map(data?.results?.data, "id");

  const { data: downloadedData, isLoading: downloadLoading } =
    useGetExcelCategoryByIdQuery(
      downloadIds?.length > 0 ? downloadIds : idArray
    );
  const { data: allInventory, isFetching: allInventoryFetching } =
    useGetAllInventriesQuery({});

  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];

  // const [tabs, setTabs] = useState([
  //   {
  //     key: "finished_goods",
  //     label: "Finished Goods",
  //     content: <div></div>,
  //     closable: false, // Default tab cannot be closed
  //   },
  // ]);
  // const [activeKey, setActiveKey] = useState("finished_goods");

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedWarehouse(null);

    navigate(-1); // Reset selected warehouse when closing
  };

  const handleRowClick = (record: any) => {
    setSelectedWarehouse(record); // Set selected warehouse details
    setDrawerContent("preview"); // Open the drawer in preview mode
    setOpen(true);
  };

  const handleAddTab = (tabKey: string, tabLabel: string) => {
    if (!tabs.some((tab) => tab.key === tabKey)) {
      setTabs([
        ...tabs,
        {
          key: tabKey, // Ensure each tab has a unique key
          label: tabLabel,
          // content: <div>{`${tabLabel} Content`}</div>,
          closable: true, // Only new tabs can be closed
        },
      ]);
      setActiveKey(tabKey);
    } else {
      setActiveKey(tabKey); // If the tab already exists, make it active
    }
  };

  useEffect(() => {
    const finishedGoods = allInventory?.data.find(
      (item: any) => item?.name?.name === "Finished Goods"
    );
    if (
      finishedGoods &&
      typeof finishedGoods?.name === "object" &&
      "id" in finishedGoods.name
    ) {
      setDefaultCategory(Number(finishedGoods?.id));
    }
  }, [allInventory]);
  const columns = [
    {
      title: "Sub",
      dataIndex: "name",
      render: (text: string, record: any) =>
        record?.is_parent && (
          <img
            src="/assets/subBranch.svg"
            className="cursor-pointer"
            alt="SubBranch"
            onClick={(e) => {
              e.stopPropagation();
              handleAddTab(`${record?.id}`, record?.name?.name);
              navigate(
                `/category-management?inventory_id=${
                  parent_id ? parent_id : defaultCategoryId
                }&category_id=${
                  record?.id
                }&sub_category=true&sub_category_name=${record?.name?.name}`
              );
            }} // Unique key
          />
        ),
      width: 100,
    },

    {
      title: "Category",
      dataIndex: "name",
      ellipsis: true,
      render: (text: string, record: any) => (
        <span
          className="cursor-pointer text-blue-500"
          onClick={(e) => {
            e.stopPropagation();
            handleRowClick(record);
            if (category_id === null) {
              navigate(
                `/category-management?inventory_id=${
                  parent_id ? parent_id : defaultCategoryId
                }&sub_category_id=${
                  record?.id
                }&sub_category=${sub_category}&method=preview`
              );
            } else if (record?.id) {
              navigate(
                `/category-management?inventory_id=${
                  parent_id ? parent_id : defaultCategoryId
                }&category_id=${category_id}&sub_category_id=${
                  record?.id
                }&sub_category_name=${sub_category_name}&sub_category=${sub_category}&method=preview`
              );
            } else {
              navigate(
                `/category-management?inventory_id=${
                  parent_id ? parent_id : defaultCategoryId
                }&sub_category_id=${
                  record?.id
                }&sub_category_name=${sub_category_name}&sub_category=${sub_category}&method=preview`
              );
            }
          }}
        >
          {record?.name?.name}
        </span>
      ),
    },
    {
      title: "Warehouse",
      dataIndex: "brand",
      ellipsis: true,
      render: (text: string, record: any) => (
        <span
          className="text-blue-500"
          onClick={(e) => {
            e.stopPropagation();
            // handleRowClick(record);
            // navigate(
            //   `/category-management?inventory_id=${defaultCategoryId}&category_id=${record?.id}`
            // );
          }}
        >
          {record?.warehouse?.name?.name ? record?.warehouse?.name?.name : "-"}
        </span>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brand",
      ellipsis: true,
      render: (text: string, record: any) => (
        <span
          className="text-blue-500"
          onClick={(e) => {
            e.stopPropagation();
            // handleRowClick(record);
            // navigate(
            //   `/category-management?inventory_id=${defaultCategoryId}&category_id=${record?.id}`
            // );
          }}
        >
          {record?.brand?.name ? record?.brand?.name : "-"}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      ellipsis: true,
      width: 200,

      render: (text: boolean) => (
        <div>
          {text === false ? (
            <Tag color="red" icon={<CloseCircleOutlined />}>
              Inactive
            </Tag>
          ) : (
            <Tag color="green" icon={<CheckOutlined />}>
              Active
            </Tag>
          )}
        </div>
      ),
    },
    {
      fixed: "right",
      title: "Actions",
      dataIndex: "industry_name",
      ellipsis: true,
      width: 200,
      render: (_: unknown, record: CategoryData) => {
        // Dynamically define dropdown items based on status
        const items: ItemType[] = [
          record.is_active
            ? {
                key: "1",
                label: (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(true);
                      if (category_id) {
                        navigate(
                          `/add-category?inventory_id=${
                            parent_id ? parent_id : defaultCategoryId
                          }&category_id=${category_id}&sub_category_id=${
                            record?.id
                          }&sub_category_name=${sub_category_name}&method=update`
                        );
                      } else {
                        navigate(
                          `/add-category?inventory_id=${
                            parent_id ? parent_id : defaultCategoryId
                          }&sub_category_id=${
                            record?.id
                          }&sub_category_name=${sub_category_name}&method=update`
                        );
                      }
                    }}
                  >
                    Update
                  </span>
                ),
              }
            : null,
          {
            key: "2",
            label: <span>{record.is_active ? "Deactivate" : "Activate"}</span>,
          },
          record.is_active
            ? {
                key: "3",
                label: (
                  <span
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   navigate(
                  //     `/category-management?inventory_id=${
                  //       parent_id ? parent_id : defaultCategoryId
                  //     }&sub_category=true&sub_category_id=${
                  //       record?.id
                  //     }&category_name=${
                  //       tabs?.find((tab) => tab.key === activeKey)?.label
                  //     }&sub_category_name=${
                  //       record?.name?.name
                  //     }&method=add_sub_category`
                  //   );
                  // }}
                  >
                    Add New Sub Category
                  </span>
                ),
              }
            : null,
        ].filter(Boolean); // Removes null or undefined values safely

        return (
          <Dropdown
            className="custom-dropdown"
            menu={{
              items,
              onClick: (info) => {
                if (info.key === "1") {
                  setOpen(true);
                  if (category_id) {
                    navigate(
                      `/add-category?inventory_id=${
                        parent_id ? parent_id : defaultCategoryId
                      }&category_id=${category_id}&sub_category_id=${
                        record?.id
                      }&sub_category_name=${sub_category_name}&method=update`
                    );
                  } else {
                    navigate(
                      `/add-category?inventory_id=${
                        parent_id ? parent_id : defaultCategoryId
                      }&sub_category_id=${record?.id}&sub_category_name=${
                        record?.name?.name
                      }&method=update`
                    );
                  } // Open the drawer in preview mode
                } else if (info.key === "2") {
                  // Handle Activate/Deactivate logic
                  if (!record.is_active) {
                    // handleActivateWarehouse(Number(record.id));
                    setId(Number(record?.id));
                    setDrawerContent("activate");
                  } else {
                    // handleDeleteWarehouse(Number(record.id));
                    setId(Number(record?.id));

                    setDrawerContent("deactivate");
                  }
                } else {
                  isDropdownOpen(true);
                  if (category_id) {
                    navigate(
                      `/category-management?inventory_id=${
                        parent_id ? parent_id : defaultCategoryId
                      }&category_id=${category_id}&sub_category=true&sub_category_id=${
                        record?.id
                      }&category_name=${
                        tabs?.find((tab) => tab.key === activeKey)?.label
                      }&sub_category_name=${
                        record?.name?.name
                      }&method=add_sub_category`
                    );
                  } else {
                    navigate(
                      `/category-management?inventory_id=${
                        parent_id ? parent_id : defaultCategoryId
                      }&sub_category=true&sub_category_id=${
                        record?.id
                      }&category_name=${
                        tabs?.find((tab) => tab.key === activeKey)?.label
                      }&sub_category_name=${
                        record?.name?.name
                      }&method=add_sub_category`
                    );
                  }
                }
              },
            }}
            placement="bottomLeft"
            trigger={["click"]}
          >
            <img
              className="cursor-pointer"
              src="/assets/dots.svg"
              onClick={(e) => e.stopPropagation()} // Prevent row click on dots
            />
          </Dropdown>
        );
      },
    },
  ];
  useEffect(() => {
    if (category_id && sub_category_name) {
      handleAddTab(`${category_id}`, sub_category_name as string);
    }
  }, [category_id, sub_category_name]);
  const dataSource = [
    {
      key: "1",
      company_name: "Warehouse A",
      contacts_customer_unique_identification_number: "CUST001",
      region_name: "New York",
      phone: "John Doe",
      email: "john.doe@example.com",
      type_name: "john.doe@warehousea.com",
      industry_name: "Active",
    },
    {
      key: "2",
      company_name: "Warehouse B",
      contacts_customer_unique_identification_number: "CUST002",
      region_name: "California",
      phone: "Jane Smith",
      email: "jane.smith@example.com",
      type_name: "jane.smith@warehouseb.com",
      industry_name: "Inactive",
    },
  ];
  const [tabs, setTabs] = useState([
    {
      key: "finished_goods",
      label: "Finished Goods",
      closable: false, // Default tab cannot be closed
    },
  ]);

  const [activeKey, setActiveKey] = useState("finished_goods");
  // const [defaultCategoryId, setDefaultCategory] = useState("finished_goods");

  // Handle tab change
  const handleTabChange = (key: string) => {
    setActiveKey(key);

    // Check if the clicked tab is the first tab
    if (tabs[0]?.key === key) {
      navigate(
        `/category-management?inventory_id=${
          parent_id ? parent_id : defaultCategoryId
        }`
      );
    } else {
      // Navigate with additional parameters for non-first tabs
      navigate(
        `/category-management?category_id=${key}&inventory_id=${
          parent_id ? parent_id : defaultCategoryId
        }&sub_category=true&sub_category_name=${
          tabs.find((tab) => tab.key === key)?.label
        }`
      );
    }
  };

  // Handle tab removal
  const handleTabRemove = (targetKey: string) => {
    const newTabs = tabs.filter((tab) => tab.key !== targetKey);
    setTabs(newTabs);

    if (activeKey === targetKey) {
      // If the active tab is removed, find the previous tab to activate
      const currentIndex = tabs.findIndex((tab) => tab.key === targetKey);
      const previousTab =
        currentIndex > 0 ? tabs[currentIndex - 1] : newTabs[0];

      if (tabs[0]?.key !== previousTab.key) {
        setActiveKey(previousTab.key);
        navigate(
          `/category-management?inventory_id=${
            parent_id ? parent_id : defaultCategoryId
          }&category_id=${previousTab.key}&sub_category=true&inventory_name=${
            previousTab.label
          }`
        );
        // inventory_id=5&category_id=12&sub_category=true&sub_category_name=Cxx
      } else {
        // If no tabs are left, reset the URL
        navigate(
          `/category-management?inventory_id=${
            parent_id ? parent_id : defaultCategoryId
          }&category_name=${previousTab.label}`
        );
        setActiveKey("");
      }
    }
  };
  const handleDownload = () => {
    if (downloadedData) {
      if (
        typeof downloadedData === "string" ||
        downloadedData instanceof Blob
      ) {
        saveAs(downloadedData, "category-export.xlsx");
      } else {
        message.error("Error: Invalid data format for download.");
      }
    } else {
      message.error("Error: Downloaded data is undefined.");
    }
  };
  // Handle dropdown change
  const handleDropdownChange = (value: string, label: any) => {
    // Helper function to get the name corresponding to an ID
    const getInventoryNameById = (id: string | null) => {
      return allInventory?.data?.find(
        (item: any) => item?.name?.id === Number(id)
      )?.name?.name as string | undefined;
    };

    const selectedInventoryName = getInventoryNameById(value); // Get the selected inventory name

    const existingTab = tabs.find((tab) => tab.key === value);

    if (existingTab) {
      // If the tab already exists, activate it
      setActiveKey(existingTab.key);
      navigate(
        `/category-management?inventory_id=${value}&inventory_name=${label?.children.toString()}`
      );
    } else {
      if (tabs.length > 1) {
        // If multiple tabs are open, close all except the first one
        const firstTab = tabs[0];
        const updatedFirstTab = {
          ...firstTab,
          key: value,
          label: selectedInventoryName || firstTab.label, // Update label with the selected name
          closable: false, // Ensure the first tab remains non-closable
        };
        setTabs([updatedFirstTab]); // Keep only the updated first tab
        setActiveKey(updatedFirstTab.key); // Activate the updated first tab
        navigate(
          `/category-management?inventory_id=${value}&category_name=${label?.children.toString()}`
        );
      } else {
        // Dynamically update the first tab based on the dropdown selection
        const updatedTabs = [...tabs];
        updatedTabs[0] = {
          key: value,
          label: selectedInventoryName || updatedTabs[0].label, // Update label with the selected name
          closable: false, // First tab remains non-closable
        };
        setTabs(updatedTabs);
        setActiveKey(value);
        navigate(
          `/category-management?inventory_id=${value}&category_name=${label?.children.toString()}`
        );
      }
    }
  };
  const getInventoryNameById = (id: string | null) => {
    return allInventory?.data?.find((item: any) => item?.id === Number(id))
      ?.name?.name as string | undefined;
  };

  useEffect(() => {
    const currentUrlParams = new URLSearchParams(location.search);
    const inventoryIdFromUrl = currentUrlParams.get("inventory_id");

    if (inventoryIdFromUrl) {
      const inventoryNameFromUrl = getInventoryNameById(inventoryIdFromUrl);

      if (inventoryNameFromUrl) {
        const firstTab = tabs[0];

        // Update the first tab if its key or label doesn't match
        if (
          firstTab.key !== inventoryIdFromUrl ||
          firstTab.label !== inventoryNameFromUrl
        ) {
          const updatedFirstTab = {
            ...firstTab,
            key: inventoryIdFromUrl,
            label: inventoryNameFromUrl,
            closable: false,
          };

          // Update the tabs array, preserving other tabs
          setTabs((prevTabs) => {
            const [, ...otherTabs] = prevTabs;
            return [updatedFirstTab, ...otherTabs];
          });

          // Set active key only if no other tab is active
          if (!tabs.some((tab) => tab.key === activeKey)) {
            setActiveKey(inventoryIdFromUrl);
          }
        }
      }
    }
  }, [location.search, allInventory, tabs, activeKey]);

  useEffect(() => {
    setName(category_name as string);
  }, [category_name]);
  const findIndexForObject = (array: [], object: any) => {
    return _.findIndex(array, object);
  };

  const targetObject = { id: Number(sub_category_id) };
  const index = findIndexForObject(data?.results?.data as [], targetObject);

  const [currentIndex, setCurrentIndex] = useState(index);

  const getObjectAtIndex = (array: [], index: number) => {
    const object = _.get(array, index);
    return object;
  };

  // useEffect(() => {
  //   setCurrentIndex(index);
  // }, [index]);
  // useEffect(() => {
  //   if (currentIndex > -1 && method === "preview") {
  //     const updatedObject: any = getObjectAtIndex(
  //       data?.results?.data as [],
  //       currentIndex
  //     );

  //     if (category_id) {
  //       navigate(
  //         `/category-management?inventory_id=${
  //           parent_id ? parent_id : defaultCategoryId
  //         }&category_id=${category_id}&sub_category_id=${
  //           updatedObject?.id
  //         }&sub_category_name=${sub_category_name}&sub_category=${sub_category}`
  //       );
  //     } else {
  //       navigate(
  //         `/category-management?inventory_id=${
  //           parent_id ? parent_id : defaultCategoryId
  //         }&sub_category_id=${updatedObject?.id}&sub_category_name=${
  //           updatedObject?.name?.name
  //         }&sub_category=${sub_category}`
  //       );
  //     }
  //   }
  // }, [currentIndex]);

  const handleNext = () => {
    if (currentIndex < (data?.results?.data?.length as number) - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleCancelModal = () => {
    // Initialize navigate function

    // Create a new URLSearchParams instance with the current query parameters
    const updatedSearchParams = new URLSearchParams(location.search);

    // Remove the 'confirmation' query parameter
    updatedSearchParams.delete("confirmation");

    // Update the URL without the 'confirmation' query parameter
    navigate({
      pathname: location.pathname, // Keep the same pathname
      search: updatedSearchParams.toString(), // Updated query string
    });

    // Close the modal (assuming you have setIsModalOpen function)
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    if (!parent_id || parent_id === "undefined") {
      // Append `parent_id` to the existing URL
      urlParams.set("inventory_id", String(defaultCategoryId));

      navigate(`${location.pathname}?${urlParams.toString()}`, {
        replace: true,
      });
    }
  }, [parent_id, defaultCategoryId, allInventory, allInventoryFetching]);
  return (
    <div className="mt-24">
      <div className="flex justify-between items-center mt-4">
        <div className="sales-title">
          <Text value="Category Management" variant="wearlay-display" />
        </div>
        <CommonButton
          variant="wealay-primary-btn"
          value={category_id ? "+ Add Sub Category" : "+ Add New Category"}
          className="primary-btn"
          onClick={() => {
            if (category_id) {
              setDrawerContent("add"); // Open the drawer in add mode
              if (category_id) {
                navigate(
                  `/category-management?inventory_id=${parent_id}&category_id=${category_id}&sub_category=true&sub_category_name=${sub_category_name}`
                );
              } else {
                navigate(
                  `/category-management?inventory_id=${
                    parent_id ? parent_id : defaultCategoryId
                  }`
                );
              }
            } else {
              if (category_id) {
                navigate(
                  `/add-category?inventory_id=${parent_id}&category_id=${category_id}&sub_category=true&sub_category_name=${sub_category_name}`
                );
              } else {
                navigate(
                  `/add-category?inventory_id=${
                    parent_id ? parent_id : defaultCategoryId
                  }`
                );
              }
            }
          }}
        />
      </div>
      <Tabs
        hideAdd
        className="pl-24 custom-tab"
        type="editable-card"
        activeKey={activeKey}
        onChange={handleTabChange}
        onEdit={(targetKey, action) => {
          if (action === "remove") {
            handleTabRemove(targetKey as string);
          }
        }}
        items={tabs?.map((tab, index) => ({
          key: tab.key,
          label: index === 0 ? tab.label : concatTabTextFunction(tab.label, 5),
          closable: tab.closable,
        }))}
      />
      <div className="p-10 mt-2">
        <div className="flex justify-between">
          <Select
            className="categoryDropDown"
            style={{ width: 240 }}
            value={{
              value: Number(parent_id) ? Number(parent_id) : defaultCategoryId,
            }}
            onChange={(value: any, label: any) => {
              setDefaultCategory(value);

              setName(label?.children.toString());
              handleDropdownChange(value.toString(), label);
              setPageData({
                page: 0,
                pageSize: 10,
              });
            }}
          >
            {allInventory?.data?.map((item: any) => (
              <Select.Option value={item?.id}>{item?.name?.name}</Select.Option>
            ))}
          </Select>
          <div className="flex items-center h-full gap-2 categoryPadding">
            <div className="search-resp">
              <SearchBox placeholder="Search" />
            </div>
            <div className="relative">
              <CommonButton
                variant="wearlay-with-icon"
                icon={filter ? "/assets/close.svg" : "/assets/filter.svg"}
                onClick={() => {
                  setFilter(!filter);
                }}
                className="download-btn-color"
              />
              {filter && (
                <CategoryFilterComponent
                // title="Status"
                // options={statusOptions}
                // queryParam="status" // This will append "status=true" or "status=false" to the URL
                />
              )}
            </div>
            <CommonButton
              variant="wearlay-with-icon"
              icon={
                data?.results?.data.length === 0
                  ? "/assets/disabledDownload.svg"
                  : "/assets/download.svg"
              }
              onClick={() => {
                handleDownload();
              }}
              disabled={data?.results?.data.length === 0}
            />
          </div>
        </div>
        <Divider className="commonDidider" />

        <div className="resp-table">
          <CommonTable
            datasource={data?.results?.data}
            coloumn={columns}
            isLoading={isFetching}
            setDownloadIds={setDownloadIds}
          />
        </div>
        <div className="flex h-full items-end justify-end">
          {(data?.count as number) > 0 && (
            <Pagination
              current={pageData.page === 0 ? 1 : pageData.page}
              onChange={onPaginationChange}
              total={data?.count}
              pageSize={pageData?.pageSize}
              defaultCurrent={1}
              responsive={true}
              simple
              // onShowSizeChange={onShowSizeChange}
              // showSizeChanger={(data?.count as number) > 10 ? true : false}
            />
          )}
        </div>
      </div>

      <Drawer
        title={
          <div className="flex justify-between items-center">
            {drawerContent === "add" ? (
              <Text value="New Warehouse" variant="wearlay-subtitle" />
            ) : (
              <Text value="Category Preview" variant="wearlay-subtitle" />
            )}
            <div className="flex gap-2">
              {/* <Space>
                <Button
                  onClick={() => handlePrevious()}
                  disabled={currentIndex === 0}
                >
                  <LeftOutlined />
                </Button>
                <Button
                  onClick={() => handleNext()}
                  disabled={
                    currentIndex === (data?.results?.data?.length as number) - 1
                  }
                >
                  <RightOutlined />
                </Button>
              </Space> */}
              {isActive && (
                <img
                  src="/assets/warehouseEdit.svg"
                  className="cursor-pointer"
                  onClick={() => {
                    if (category_id) {
                      navigate(
                        `/add-category?inventory_id=${
                          parent_id ? parent_id : defaultCategoryId
                        }&category_id=${category_id}&sub_category_id=${sub_category_id}&sub_category_name=${sub_category_name}&method=update`
                      );
                    } else {
                      navigate(
                        `/add-category?inventory_id=${
                          parent_id ? parent_id : defaultCategoryId
                        }&sub_category_id=${sub_category_id}&sub_category_name=${sub_category_name}&method=update`
                      );
                    }
                  }}
                />
              )}
            </div>
          </div>
        }
        placement="right"
        onClose={onClose}
        open={open}
        width="500"
      >
        {drawerContent === "preview" && (
          <CategoryPreview setActive={setActive} />
        )}
      </Drawer>
      <Modal
        open={dropDownOpen}
        onCancel={() => {
          navigate(-1);
        }}
        footer={false}
        closable={false}
        className=""
        destroyOnClose
        centered
      >
        <CommonSubCategoriesMenu
          setOpen={isDropdownOpen}
          setDrawer={setDrawerContent}
        />
      </Modal>
      <Modal
        closable={false}
        open={confirmation ? true : false || drawerContent === "add"}
        onCancel={handleCancelModal} // Handle modal close
        footer={null} // Optional: Add custom buttons if needed
        destroyOnClose
        centered
      >
        <ConfirmationMessages setOpen={setDrawerContent} />
      </Modal>

      <Modal
        closable={false}
        open={drawerContent === "activate" || drawerContent === "deactivate"}
        onCancel={handleCancelModal} // Handle modal close
        footer={null} // Optional: Add custom buttons if needed
        destroyOnClose
        centered
      >
        <ActivateDeactivateModal
          setOpen={setDrawerContent}
          drawerContent={drawerContent}
          id={subId}
        />
      </Modal>
    </div>
  );
}

export default CategoryManagement;
