import {
  Modal,
  Pagination,
  PaginationProps,
  Skeleton,
  Switch,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Text } from "../../components/CommonFont/text";
import {
  useGetInventoryExecutivesQuery,
  useGetUserDetailByIdQuery,
} from "../../services/api";
import "./index.css";

import _ from "lodash";
import { SearchBox } from "../../components/CommonSearchBox";
import Backdated from "../../components/Modals/backlogDates";
import ConfirmationMessagesForBacklog from "../../components/Modals/confirmationBackLog";
import { getEmployeesParams } from "../../helpers/quarery";
export const InventoryEmployee = () => {
  const [open, setOpen] = useState(false);
  const [openLead, setOpenLead] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState<any>();
  const [executiveIdParams, setExecutiveIdParams] = useState<any>();
  const [downloadIds, setDownloadIds] = useState<any>();

  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const isExecutive = localStorage.getItem("executive-id");
  const [searchParams, setSearchParams] = useSearchParams();
  // const [executiveId, setExecutiveId] = useState(
  //   searchParams.get("executive-id")
  // );
  const executiveId = searchParams.get("executive-id");
  const executiveById = searchParams.get("id");
  const isManager = localStorage.getItem("user_role");

  useEffect(() => {
    if (executiveId) {
      localStorage.setItem("executive-id", executiveId);
      setExecutiveIdParams(executiveId);
    }
  }, [executiveId]);

  const { data: userData, isLoading: userLoading } =
    useGetUserDetailByIdQuery(executiveId);

  const userId = localStorage.getItem("user_id");
  const search = searchParams.get("search");

  const onClose = () => {
    setOpen(false);
    setOpenLead(false);
    if (executiveIdParams) {
      // setSearchParams({ "executive-id": executiveId });
      navigate(
        `/inventory-employee-detail-page?executive-id=${executiveIdParams}`
      );
    } else {
      navigate(`/inventory-employee-detail-page`);
    }
    navigate(
      `/inventory-employee-detail-page?executive-id=${executiveIdParams}`
    );
  };
  const showDrawer = () => {
    setOpenLead(true);
  };
  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 4,
  });

  const params = getEmployeesParams(pageData.page, pageData.pageSize);
  const [isApprove, setIsApprove] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isClose, setIsClose] = useState(false);

  const getAllExecutiveParams = {
    page_size: params?.page_size,
    page: params?.page,
    query: search || "", // Simplified ternary for `query`
  };

  const { data: executiveSalesData, isLoading: executiveSalesLoading } =
    useGetInventoryExecutivesQuery(getAllExecutiveParams);

  const navigate = useNavigate();

  const findIndexForObject = (array: [], object: any) => {
    return _.findIndex(array, object);
  };

  const targetObject = { id: Number(executiveId ? executiveId : isExecutive) };
  const targetObject2 = { id: Number(executiveById) };

  const getObjectAtIndex = (array: [], index: number) => {
    const object = _.get(array, index);
    return object;
  };

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Company",
      dataIndex: "company_name",
      key: "company_name",
      sorter: (a: any, b: any) => a.name - b.name,
      ellipsis: true,
    },
    // {
    //   title: "Region",
    //   dataIndex: "region_name",
    //   key: "region_name",
    //   sorter: (a: any, b: any) => a.age - b.age,
    //   ellipsis: true,
    // },
    {
      title: "Lead Name",
      dataIndex: "lead_name",
      key: "lead_name",
      sorter: (a: any, b: any) => a.lead_name.localeCompare(b.lead_name),

      ellipsis: true,
    },
    {
      title: "Contact",
      dataIndex: "phone",
      key: "phone",
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      render: (text: any) => {
        return (
          <>
            <div>{text ? text : "-"}</div>
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type_name",
      key: "type_name",
      ellipsis: true,
    },
    {
      title: "Industry",
      dataIndex: "industry_name",
      key: "industry_name",
      ellipsis: true,
    },
    {
      title: "Enquiry Category",
      dataIndex: "enquiry_category_name",
      key: "enquiry_category_name",
      ellipsis: true,
    },
    {
      title: "Follow up Status",
      dataIndex: "status",
      key: "follow_up_status",
      ellipsis: true,
      render: (text: any) => {
        return (
          <>
            {text?.title_name === "Follow Up 1" ? (
              <Tag color="cyan">Follow up 1</Tag>
            ) : text?.title_name === "Follow Up 2" ? (
              <Tag color="orange">Follow up 2</Tag>
            ) : text?.title_name === "Follow Up 3" ? (
              <Tag color="purple">Follow up 3</Tag>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
  ];
  const titleData = [
    "Code",
    "Lead Name",
    "Region",
    "Contact",
    "Email",
    "Type",
    "Industry",
    "Status",
  ];
  //   useEffect(() => {
  //     setCurrentIndex(index);
  //     setCurrentIndex2(index2);
  //   }, [index, index2]);

  //   useEffect(() => {
  //     if (currentIndex2 > -1) {
  //       const updatedObject: any = getObjectAtIndex(
  //         executiveSalesData?.results?.data,
  //         currentIndex2
  //       );

  //       navigate(`/inventory-employee-detail-page?id=${updatedObject?.id}`);
  //     }
  //   }, [currentIndex2]);

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };
  const idArray = _.map(executiveSalesData?.results, "id");

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPageData({
      page: current,
      pageSize: pageSize,
    });
  };

  const onChange = (checked: boolean) => {
    setIsApprove(true);
    navigate("/inventory-employee?");
  };

  return (
    <>
      <div className="mt-24">
        <div className="sales-profile-p-10 flex flex-col justify-between h-80vh">
          {userLoading ? (
            <Skeleton avatar paragraph={{ rows: 2 }} />
          ) : (
            <div>
              <div className="flex justify-start">
                <SearchBox placeholder="Search" />
              </div>
              <div className="employee-max-height">
                {executiveSalesData?.results?.data?.map((item) => (
                  <div>
                    <div className="flex items-center w-full justify-between employee-detail-page-main-div">
                      <div className="flex items-center gap-2  ">
                        <img
                          className="sale-profile round-avatar"
                          src={
                            item?.image_url
                              ? item?.image_url
                              : "/assets/profile.svg"
                          }
                        ></img>
                        <div className="flex flex-col gap-2">
                          <Text
                            value={item?.first_name ? item?.first_name : "-"}
                            variant="wearlay-headline-2"
                          />
                          <Text
                            value="Inventory Executive"
                            variant="wearlay-label"
                            className="gray-text"
                          />
                        </div>
                      </div>
                      <div className="employee-detail-page-main-div-2 flex">
                        <div className="flex items-center gap-2 sale-profile-gap-1">
                          <img src="/assets/group.svg"></img>
                          {/* <div className="sales-profile-title-3 ">WY12345</div> */}
                          <Text
                            value={item?.code ? item?.code : "-"}
                            variant="wearlay-paragraph-bold"
                          />
                        </div>
                        <div className="flex items-center gap-2 sale-profile-gap">
                          <img src="/assets/message.svg"></img>
                          {/* <div className="sales-profile-title-3 ">roshan@chema.com</div> */}
                          <Text
                            value={item?.email ? item?.email : "-"}
                            variant="wearlay-paragraph-bold"
                          />
                        </div>
                        <div className="flex items-center gap-2">
                          <img src="/assets/phone.svg"></img>
                          {/* <div className="sales-profile-title-3 ">098 25 314786</div> */}
                          <Text
                            value={item?.phone ? item?.phone : "-"}
                            variant="wearlay-paragraph-bold"
                          />
                        </div>
                      </div>
                      {isManager === "3" && (
                        <div className="flex items-center gap-2 sale-profile-gap">
                          <Switch
                            checked={item?.backdate_permission}
                            onChange={() => {
                              if (!item?.backdate_permission) {
                                navigate(`/inventory-employees?id=${item?.id}`);
                                setIsOpen(true);
                              } else {
                                navigate(`/inventory-employees?id=${item?.id}`);

                                setIsClose(true);
                              }
                            }}
                          />
                          <Text
                            value={"Allow Backdated Entry"}
                            variant="wearlay-paragraph-bold"
                          />
                        </div>
                      )}

                      {/* <div>
                <Space>
                  <Button
                    onClick={() => {
                      handlePrevious();
                      // navigate(`/contacts?id=${object?.id}`);
                    }}
                    disabled={currentIndex === 0}
                  >
                    <LeftOutlined />
                  </Button>
                  <Button
                    onClick={() => {
                      // navigate(`/contacts?id=${object?.id}`);
                      handleNext();
                    }}
                    disabled={currentIndex === executivesData?.data?.length - 1}
                  >
                    <RightOutlined />
                  </Button>
                </Space>
              </div> */}
                    </div>

                    <div className="employee-resp-contacts">
                      <div className="flex w-full gap-2 ">
                        <div className="flex items-center gap-2 ">
                          <img src="/assets/group.svg"></img>
                          {/* <div className="sales-profile-title-3 ">WY12345</div> */}
                          <Text
                            value={item?.code ? item?.code : "-"}
                            variant="wearlay-paragraph-bold"
                          />
                        </div>
                        <div className="flex items-center gap-2 ">
                          <img src="/assets/message.svg"></img>
                          {/* <div className="sales-profile-title-3 ">roshan@chema.com</div> */}
                          <Text
                            value={item?.email ? item?.email : "-"}
                            variant="wearlay-paragraph-bold"
                          />
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <img src="/assets/phone.svg"></img>
                        {/* <div className="sales-profile-title-3 ">098 25 314786</div> */}
                        <Text
                          value={item?.phone ? item?.phone : "-"}
                          variant="wearlay-paragraph-bold"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex h-full items-end justify-end">
            {executiveSalesData && executiveSalesData?.count > 0 && (
              <Pagination
                // current={pageData.page}
                onChange={onPaginationChange}
                total={executiveSalesData?.count}
                pageSize={pageData?.pageSize}
                defaultCurrent={1}
                responsive={true}
                simple
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        open={isOpen}
        footer={false}
        onCancel={() => {
          setIsOpen(false);
          navigate("/inventory-employees");
        }}
      >
        <Backdated setOpen={setIsOpen} setApprove={setIsApprove} />
      </Modal>

      <Modal
        open={isClose}
        footer={false}
        onCancel={() => {
          setIsClose(false);
          navigate("/inventory-employees");
        }}
      >
        <ConfirmationMessagesForBacklog
          setOpen={setIsClose}
          setApprove={setIsApprove}
        />
      </Modal>
    </>
  );
};
