import { Button, Form, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDeleteRawMaterialsMutation } from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";

interface ICreateSales {
  setOpen: (data: boolean) => void;
  // Pass the record ID as a prop
}

export const ConfirmationMessagesForDiscontinue: React.FC<ICreateSales> = ({
  setOpen,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [deactivateWarehouse, { isLoading: deactivatingLoading }] =
    useDeleteRawMaterialsMutation();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const handleDeleteWarehouse = async (values: { reason: string }) => {
    try {
      const response = await deactivateWarehouse({
        id: Number(id),
        reason: values.reason, // Get the reason from form values
      }).unwrap();

      message.success(response.message);
      navigate("/raw-materials");
      setOpen(false);
    } catch (error: any) {
      message.error(error.message || "An error occurred while deactivating.");
    }
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleDeleteWarehouse} // Call with form values
        layout="vertical"
      >
        <Form.Item name="reason">
          <TextArea rows={4} placeholder="Enter reason for discontinuation" />
        </Form.Item>
        <div
          style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
        >
          <Button
            className="wealay-secondary-btn"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <CommonButton
            value="Discontinue this product"
            variant="wealay-primary-btn"
            type="submit"
            isLoading={deactivatingLoading} // Show loading state
          />
        </div>
      </Form>
    </div>
  );
};

export default ConfirmationMessagesForDiscontinue;
