import { Col, Collapse, Empty, Row, Skeleton } from "antd";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertTo12Hour } from "../../../helpers/helperFunction";
import { useGetAllLoginEntriesQuery } from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import { Text } from "../../CommonFont/text";

const { Panel } = Collapse;

interface ICreateSales {
  modelId: number;
  objectId: number;
  setOpen: (data: boolean) => void;
}

export const LoginHistory: React.FC<ICreateSales> = ({
  modelId,
  objectId,
  setOpen,
}) => {
  const [date, setDate] = useState("");
  const [activeKey, setActiveKey] = useState<string | string[]>(); // Track active panel(s)
  const navigate = useNavigate();
  const params = {
    model_id: modelId,
    object_id: objectId,
  };
  const Loginparams = {
    model_id: modelId,
    object_id: objectId,
    date: moment(date).format("YYYY-MM-DD"),
  };

  const { data, isLoading } = useGetAllLoginEntriesQuery(params);
  const { data: loginData, isLoading: dateLoading } =
    useGetAllLoginEntriesQuery(Loginparams, {
      skip: !date,
    });

  const handleDateClick = (date: string) => {
    setDate(date);
  };

  const handlePanelChange = (keys: string | string[]) => {
    setActiveKey(keys); // Update activeKey state
    const clickedDate = Array.isArray(keys) ? keys[0] : keys;
    handleDateClick(clickedDate);
  };

  const handleIconClick = (item: any) => {
    const currentUrl = window.location.href; // Get current URL
    const url = new URL(currentUrl);
    url.searchParams.set("date", item?.date);
    url.searchParams.set("log_id", item?.id);
    // Append 'date' to URL
    navigate(url.pathname + url.search); // Navigate to the updated URL
    setOpen(true);
  };

  return (
    <div>
      <div className="flex justify-between finished-goods-bottom">
        <Text variant="wearlay-headline-2" value="Login History" />
        <CommonButton
          variant="wealay-secondary-btn"
          value="+ Add New Log"
          onClick={() => {
            setOpen(true);
            const currentUrl = window.location.href; // Get current URL
            const url = new URL(currentUrl);
            url.searchParams.set("date", moment().format("YYYY-MM-DD"));

            // Append 'date' to URL
            navigate(url.pathname + url.search);
          }}
        />
      </div>
      {isLoading ? (
        <Skeleton active />
      ) : data?.data && data?.data?.length > 0 ? (
        <Collapse
          bordered={false}
          onChange={handlePanelChange}
          expandIcon={() => null}
          className="finished-goods-collapse"
          activeKey={activeKey} // Controlled active key
        >
          {data?.data?.map((item) => {
            const isPanelOpen =
              Array.isArray(activeKey) && activeKey.includes(item.date);

            return (
              <Panel
                className="finished-goods-bottom sales-detail-border"
                key={item.date}
                header={
                  <div className="flex justify-between items-center">
                    <Text
                      value={moment(item.date).format("DD/MM/YYYY")}
                      variant="wearlay-subtitle"
                    />
                    {/* Show balances only when the panel is closed */}
                    {!isPanelOpen && (
                      <div className="flex gap-2">
                        <div>Opening Balance: {item?.opening_balance}</div>
                        <div>Closing Balance: {item?.closing_balance}</div>
                      </div>
                    )}
                    {(moment(item.date).format("DD/MM/YYYY") ===
                      moment().format("DD/MM/YYYY") ||
                      sessionStorage.getItem("isBackLog") === "true" ||
                      localStorage.getItem("user_role") === "3") && (
                      <img
                        src="/assets/warehouseEdit.svg"
                        className="cursor-pointer"
                        onClick={() => handleIconClick(item)}
                      />
                    )}
                  </div>
                }
              >
                {dateLoading ? (
                  <Skeleton active />
                ) : (
                  loginData?.data?.map((logItem) => (
                    <div key={logItem.date}>
                      <Row className="finished-goods-closing-bg finished-goods-bottom mt-24">
                        <Col span={12} className="sales-detail-border">
                          <div>Opening Balance</div>
                          <div>{logItem?.opening_balance}</div>
                        </Col>
                        <Col span={12} className="sales-detail-border">
                          <div>Closing Balance</div>
                          <div>{logItem?.closing_balance}</div>
                        </Col>
                      </Row>
                      {logItem?.timely_logs?.map((timelog: any) => (
                        <div key={timelog.id} className="finished-goods-bottom">
                          <Row>
                            <Col span={24} md={{ span: 12 }}>
                              <div className="sales-detail-border flex flex-col gap-2">
                                <div className="sales-detail-placeholder">
                                  {timelog?.type === "in" ? (
                                    <img src="/assets/inwards.svg" />
                                  ) : (
                                    <img src="/assets/outward.svg"></img>
                                  )}
                                </div>
                                <div className="sales-detail-sub-title common-work-break">
                                  {moment(logItem?.date).format("DD-MM-YYYY")}{" "}
                                  {convertTo12Hour(timelog?.time)}
                                </div>
                              </div>
                            </Col>
                            <Col span={24} md={{ span: 12 }}>
                              <div className="sales-detail-border flex flex-col gap-2">
                                <div className="sales-detail-placeholder">
                                  Quantity
                                </div>
                                <div className="sales-detail-sub-title common-work-break">
                                  {timelog?.quantity}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row className="w-full">
                            <div className="sales-detail-border flex flex-col gap-2 w-full">
                              <div className="sales-detail-placeholder">
                                Warehouse
                              </div>
                              <div className="sales-detail-sub-title common-work-break">
                                {timelog?.warehouse?.name?.name}
                              </div>
                            </div>
                          </Row>
                          <Row className="w-full">
                            <div className="sales-detail-border flex flex-col gap-2 w-full">
                              <div className="sales-detail-placeholder">
                                Note
                              </div>
                              <div className="sales-detail-sub-title common-work-break">
                                {timelog?.remarks ? timelog?.remarks : "-"}
                              </div>
                            </div>
                          </Row>
                        </div>
                      ))}
                    </div>
                  ))
                )}
              </Panel>
            );
          })}
        </Collapse>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default LoginHistory;
