import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import React, { useState } from "react";
import { RejectedProductsDataType2 } from "../../interface/rejectedProducts";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 0,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "center",
    // fontFamily: "Inter",
  },
  image: {
    // marginVertical: 15,
    // marginHorizontal: 100,
    width: "100",
    marginRight: 30,
    // height: "100",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  backgroundColor: {
    backgroundColor: "#edf9f9",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: {
    display: "flex",
    width: "100%",
    // borderStyle: "solid",
    // borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  table1: {
    display: "flex",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    // borderRightWidth: 0,
    // borderBottomWidth: 0,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#e6ebec",
  },
  tableRow: {
    // margin: "auto",
    flexDirection: "row",
    backgroundColor: "#edf9f9",
  },
  tableRow1: {
    // margin: "auto",
    flexDirection: "row",
    backgroundColor: "#edf9f9",
  },
  tableRow4: {
    // margin: "auto",
    flexDirection: "row",
    backgroundColor: "white",
  },
  tableCol: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "10",
    borderColor: "#e6ebec",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol2: {
    width: "45%",
    borderStyle: "solid",
    wordBreak: "break-all",
    // borderWidth: 1,
    padding: "10",

    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol3: {
    width: "90%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "10",
    borderColor: "#e6ebec",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol4: {
    width: "90%",
    // borderStyle: "solid",
    // borderWidth: 1,
    padding: "10",
    // borderColor: "#e6ebec",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCell: {
    textAlign: "left",
    fontSize: 13,
    color: "#043843",

    fontFamily: "Times-BoldItalic",
  },
  tableCell2: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: "extrabold",
    fontFamily: "Helvetica-Bold",
    wordBreak: "break-all",
    width: "90%",
  },
  tableCell4: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: "extrabold",
    fontFamily: "Helvetica-Bold",
    wordBreak: "break-all",
  },
  tableCell3: {
    textAlign: "left",
    fontSize: 12,
  },
  marginTop: {
    marginTop: 24,
  },
  imageDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "30",
    marginTop: "20",
  },
  title1: {
    fontWeight: "extrabold",
    fontSize: "24",
    fontFamily: "Helvetica-Bold",
  },
  titleBorder: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#e6ebec",
  },
  borderRight: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRight: 1,
    borderLeft: 0,
    borderBottom: 0,
    borderTop: 0,
    borderColor: "#e6ebec",
  },
  rectangle: {
    borderColor: "black",
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    height: 15,
    width: 15,
  },
  rectangleFlex: {
    display: "flex",
    gap: 10,
    flexDirection: "row",
  },
  rectangleFlex2: {
    display: "flex",
    gap: 10,
    flexDirection: "row",
    marginLeft: 30,
    marginTop: 20,
    width: "100%",
  },
});

// Create Document Component
interface IExport {
  data: RejectedProductsDataType2;
}
export const CustomerCopyPdf: React.FC<IExport> = ({ data }) => {
  const [daysDifference, setDaysDifference] = useState(0);

  //   const { data, isLoading } = useGetQuotationByIdQuery(14);
  return (
    <Document>
      <Page style={styles.body} size="A4">
        <View style={styles.imageDiv}>
          <Text style={styles.title1}>Customer Copy</Text>

          {/* <Image src="/assets/jaws.png" style={styles.image} /> */}
        </View>
        <View style={{ ...styles.table, ...styles.marginTop }}>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow1}>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>Item Name</Text>
              </View>
            </View>
            <View style={styles.tableRow1}>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell2}>{data?.name?.name}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ ...styles.table }}>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow1}>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>Customer</Text>
              </View>
            </View>
            <View style={styles.tableRow1}>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell2}>{data?.costumer?.name}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ ...styles.table }}>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell}>Category</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Color</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell2}>
                  {" "}
                  {data?.category?.name?.name}
                </Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell4}>{data?.color?.name}</Text>
              </View>
            </View>
          </View>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell}>Quantity</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Days Performed</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell2}>
                  {data?.quantity ? data?.quantity : "-"}
                </Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell4}>
                  {data?.days_performed ? data?.days_performed : "-"}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell}>Sold on</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Sales Person</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell2}>
                  {data?.sold_on
                    ? moment(data?.sold_on).format("DD-MM-YYYY")
                    : "-"}
                </Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell4}>
                  {data?.sales_person?.name ? data?.sales_person?.name : "-"}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell}>Issue Reported On</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Item Received On</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell2}>
                  {data?.issue_reported_on
                    ? moment(data?.issue_reported_on).format("DD-MM-YYYY")
                    : "-"}
                </Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell4}>
                  {data?.item_received_on
                    ? moment(data?.item_received_on).format("DD-MM-YYYY")
                    : "-"}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.rectangleFlex2}>
          <View style={styles.rectangleFlex}>
            <View style={styles.rectangle}></View>
            <Text style={styles.tableCell}>Replacement</Text>
          </View>
          <View style={styles.rectangleFlex}>
            <View style={styles.rectangle}></View>
            <Text style={styles.tableCell}>Repair</Text>
          </View>
        </View>
        <View style={{ ...styles.table, ...styles.marginTop }}>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow4}>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>Conclusion</Text>
              </View>
            </View>
            <View style={styles.tableRow4}>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell3}>
                  {data?.conclusion ? data?.conclusion : "-"}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
