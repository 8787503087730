import { Button, DatePicker, Form, message } from "antd";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { disableFutureDates } from "../../../helpers/helperFunction";
import {
  useApproveForLiningMutation,
  useUpdateForLiningMutation,
} from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import { Text } from "../../CommonFont/text";
interface ICreateSales {
  setOpen: (data: boolean) => void;
  setDrawer: (data: boolean) => void;

  // Pass the record ID as a prop
}
export const LiningCompletionModal: React.FC<ICreateSales> = ({
  setOpen,
  setDrawer,
}) => {
  const [approveQutation, { isLoading }] = useApproveForLiningMutation();
  const [updateQuotation, { isLoading: quotationLoading }] =
    useUpdateForLiningMutation();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  const handleApprove = async (value: any) => {
    try {
      const response = await approveQutation({
        id: Number(id),
        body: { ...value, job_status: "completed" },
      }).unwrap(); // Send transformed data
      form.resetFields();
      //   navigate("/for-lining");
      setOpen(false);
      setDrawer(false);

      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message);
    }
  };

  return (
    <div>
      <Form layout="vertical" onFinish={handleApprove} form={form}>
        <div className="flex gap-2">
          <img src="/assets/iButton.svg" />
          <Text variant="wearlay-subtitle" value="Update Lining Status " />
        </div>
        <Form.Item
          required
          rules={[
            { required: true, message: "Please enter Date of Completion" },
          ]}
          label="Date of Completion"
          className="mt-24"
          name={"lining_completed_on"}
        >
          <DatePicker
            placeholder="Select"
            format={"DD/MM/YYYY"}
            className="w-full customPicker"
            disabledDate={disableFutureDates}
          />
        </Form.Item>
        <div className="flex gap-2 justify-end mt-24p">
          <Button
            className="wealay-secondary-btn"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>

          <CommonButton
            variant="wealay-primary-btn"
            value="Lining Job Completed"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
      </Form>
    </div>
  );
};
