import { Tooltip } from "antd";
import { Dayjs } from "dayjs";
import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { PathConstants } from "../pathConstants/pathConstants";
export const errorHandling = (error: any) => {
  if (error.status === 403) {
    // removeTokens();
    return `${PathConstants.ERROR_403}`;
  }
};
export const concatTextFunction = (data: string) => {
  if (data?.length > 24) {
    return data?.slice(0, 24).concat("...");
  } else {
    return data;
  }
};
export const concatTabTextFunction = (data: string, number: number) => {
  if (data?.length > number) {
    return (
      <Tooltip title={data}>{data?.slice(0, number).concat("...")}</Tooltip>
    );
  } else {
    return data;
  }
};
export const getUrlEndpoints = (
  endPoint: string,
  params: any,
  method?: string,
  requestBody?: any
) => {
  let url: any;
  if (method === "GET") {
    return {
      url: url.join(""),
      method,
    };
  } else {
    return {
      url: url.join(""),
      method,
      body: requestBody,
    };
  }
};

export const useArrayNavigator = (
  array: any[] | undefined,
  targetObject: any
) => {
  const findIndexForObject = (array: any[], object: any): number => {
    return _.findIndex(array, object);
  };

  const [currentIndex, setCurrentIndex] = useState(() =>
    findIndexForObject(array || [], targetObject)
  );

  // Memoize array and targetObject to prevent unnecessary renders
  const memoizedArray = useMemo(() => array || [], [array]);
  const memoizedTargetObject = useMemo(() => targetObject, [targetObject]);

  useEffect(() => {
    const newIndex = findIndexForObject(memoizedArray, memoizedTargetObject);
    if (newIndex !== currentIndex) {
      setCurrentIndex(newIndex);
    }
  }, [memoizedArray, array]);

  const handleNext = () => {
    if (currentIndex < memoizedArray.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const getObjectAtIndex = () => {
    return _.get(memoizedArray, currentIndex);
  };

  return {
    currentIndex,
    handleNext,
    handlePrevious,
    getObjectAtIndex,
  };
};
export const convertTo12Hour = (time: string) => {
  if (!time) return ""; // Handle undefined or null time
  return moment(time, "HH:mm").format("hh:mm A"); // Convert 24-hour time to 12-hour format
};
export const disablePastDates = (current: Dayjs | null): boolean => {
  return !!current && current.isBefore(new Date(), "day");
};
export const disableFutureDates = (current: Dayjs | null): boolean => {
  return !!current && current.isAfter(new Date(), "day");
};
export const validatePositiveNumber = (_: any, value: string) => {
  if (!value || Number(value) >= 0) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Please enter a positive number"));
};
