import { CloseOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./index.css";

export interface IInputForm {
  placeholder: string;
  onSearch?: (e: string) => void;
  onClose?: () => void;
}

export const SearchBox: React.FC<IInputForm> = ({
  placeholder,
  onSearch,
  onClose,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string>(
    searchParams.get("search") || "" // Default to empty string if not set
  );
  const navigate = useNavigate();
  const location = useLocation();

  // Handle input change
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value); // Update the search state without updating the URL
  };

  // Handle form submission (when Enter is pressed)
  const onFormSubmit = () => {
    // Update the URL with the search query parameter
    const params = new URLSearchParams(location.search);
    params.set("search", search); // Set or update the 'search' query param
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <Form
      className="searchbox-style"
      name="basic"
      onFinish={onFormSubmit} // Trigger form submit on Enter
    >
      <img
        src="/assets/search.svg"
        alt=""
        onClick={onFormSubmit} // Trigger form submit when clicking the search icon
        className="searchbox-icon"
      />
      <Input
        onChange={onInputChange} // Update search state when input changes
        onPressEnter={onFormSubmit} // Trigger form submit when pressing Enter
        className={"input heghtsearchBar"}
        // size="middle"
        placeholder={placeholder}
        value={search}
      />
      {search && (
        <CloseOutlined
          className="searchbox-padding"
          onClick={() => {
            setSearch(""); // Clear the search state
            const newParams = new URLSearchParams(location.search);
            newParams.delete("search"); // Remove the 'search' query param from the URL
            setSearchParams(newParams); // Update the URL with the new parameters
            // Navigate back to the previous page (optional)
            // onClose(); // Uncomment if needed
          }}
        />
      )}
    </Form>
  );
};
