import { Skeleton, TreeSelect } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useGetCategoriesAndSubCategoriesQuery } from "../../services/api";
import { CommonButton } from "../CommonButton/button";

import "./index.css";

// New rowData as provided
const rowData = {
  id: 10,
  name: { id: 25, name: "subway" },
  is_parent: true,
  children: [
    {
      id: 16,
      name: { id: 40, name: "string" },
      is_parent: true,
      children: [
        {
          id: 17,
          name: { id: 27, name: "Wood" },
          is_parent: false,
          children: [],
        },
      ],
    },
    {
      id: 18,
      name: { id: 41, name: "books" },
      is_parent: false,
      children: [],
    },
    {
      id: 19,
      name: { id: 43, name: "paper" },
      is_parent: false,
      children: [],
    },
  ],
};

// Recursive function to format the new raw data
const formatTreeData = (data: any) => {
  return {
    title: data?.name?.name, // Name to display
    value: data.id, // Unique ID for value
    key: data.id, // Key for Ant Design TreeSelect
    children: data.children ? data.children.map(formatTreeData) : [],
  };
};
interface ICreateSales {
  setOpen: (data: boolean) => void;
  setDrawer: (data: any) => void;
}

export const CommonSubCategoriesMenu: React.FC<ICreateSales> = ({
  setOpen,
  setDrawer,
}) => {
  const [value, setValue] = useState<string | undefined>(undefined); // Value selected from TreeSelect
  const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal visibility state
  const [searchParams, setSearchParams] = useSearchParams(); // Search parameters
  const navigate = useNavigate(); // For navigation
  const [selectedSubCategoryName, setSelectedSubCategoryName] = useState<
    string | undefined
  >(undefined);
  const sub_category_id = searchParams?.get("sub_category_id");
  const confirmation = searchParams.get("confirmation");

  const [isTrue, setIsTrue] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTrue(true); // Set state to true after 1 second
    }, 1000);

    // Cleanup timeout when the component unmounts
    return () => clearTimeout(timer);
  }, []);
  const onChange = (newValue: string, label: any) => {
    setValue(newValue); // Update selected value
    setSelectedSubCategoryName(label[0]);
  };

  const handleContinue = () => {
    if (value && selectedSubCategoryName) {
      // Append sub_category_id to URL
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set("sub_category_id", value); // Add or update sub_category_id
      updatedSearchParams.set("sub_category_name", selectedSubCategoryName);
      updatedSearchParams.set("confirmation", "true");

      navigate(`?${updatedSearchParams.toString()}`); // Update the URL with new query params
      setOpen(false);
      // Open modal
      setIsTrue(false);

      setIsModalOpen(true);
    } else {
      console.error("Please select an item before continuing.");
    }
  };

  const handleCancelModal = () => {
    setOpen(false);
    setIsTrue(false);
    setDrawer("");
    const updatedSearchParams = new URLSearchParams(location.search);

    // Remove the 'confirmation' query parameter
    updatedSearchParams.delete("method");

    // Update the URL without the 'confirmation' query parameter
    navigate({
      pathname: location.pathname, // Keep the same pathname
      search: updatedSearchParams.toString(), // Updated query string
    });
  };

  const { data, isLoading } = useGetCategoriesAndSubCategoriesQuery(
    Number(sub_category_id),
    {
      skip: !sub_category_id,
    }
  );
  // const { data, isLoading } = useGetCategoriesAndSubCategoriesQuery(
  //   { body: Number(sub_category_id), clean: false },
  //   {
  //     skip: !sub_category_id,
  //   }
  // );

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <div className="categoryModalHeight flex flex-col justify-between">
          <TreeSelect
            style={{ width: "100%" }}
            value={value}
            dropdownStyle={{ height: 280, overflow: "auto" }}
            placeholder="Please select an option"
            allowClear
            treeData={[formatTreeData(data?.data)]} // Format new rowData
            onChange={onChange}
            treeDefaultExpandAll={true}
            showSearch // Enable search functionality
            filterTreeNode={(inputValue: any, treeNode: any) =>
              treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
            className="treeSelect"
            open={isTrue}
          />
          <div className="flex justify-end gap-2">
            <CommonButton
              variant="wealay-secondary-btn"
              value="Cancel"
              onClick={() => {
                handleCancelModal();
              }}
            />
            <CommonButton
              variant="wealay-primary-btn"
              value="Continue"
              onClick={handleContinue} // Handle Continue button click
            />
          </div>
        </div>
      )}

      {/* Modal Component */}
      {/* <Modal
        closable={false}
        open={confirmation ? true : false || isModalOpen}
        onCancel={handleCancelModal} // Handle modal close
        footer={null} // Optional: Add custom buttons if needed
        // destroyOnClose
      >
        <ConfirmationMessages setOpen={setIsModalOpen} />
      </Modal> */}
    </>
  );
};
