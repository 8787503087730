import { Button } from "antd";
import classNames from "classnames";
import "./button.css";
enum commonStyles {
  "wealay-primary-btn",
  "wealay-secondary-btn",
  "wearlay-without-bg",
  "wearlay-with-icon",
}
enum commonButtonIcons {
  "/assets/download.svg",
  "/assets/filter.svg",
  "/assets/close.svg",
  "/assets/disabledDownload.svg",
  "/assets/warehouseEdit.svg",
}

export type ButtonVariantType = keyof typeof commonStyles;
export type ButtonVariantIcons = keyof typeof commonButtonIcons;

interface IFont {
  value?: string;
  variant: ButtonVariantType;
  className?: string;
  icon?: ButtonVariantIcons;
  onClick?: () => void;
  htmlType?: string;
  type?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

export const CommonButton: React.FC<IFont> = ({
  value,
  variant,
  className,
  icon,
  htmlType,
  type,
  onClick,
  disabled,
  isLoading,
}) => {
  return (
    <Button
      className={`${className} ${classNames(variant, variant)} cursor-pointer`}
      htmlType="submit"
      type="primary"
      loading={isLoading}
      onClick={() => {
        onClick && onClick();
      }}
      disabled={disabled}
    >
      {value && value}
      {icon && <img src={icon}></img>}
    </Button>
  );
};
