import { Form, message } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUpdateReservedMutation } from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import { Text } from "../../CommonFont/text";

interface ICreateSales {
  setOpen: (data: boolean) => void;
  id: number;
  // Pass the record ID as a prop
}

export const ConfirmationMessagesForReservation: React.FC<ICreateSales> = ({
  setOpen,
  id,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const finished_id = searchParams.get("id");
  const item = searchParams.get("item");

  const [
    updateReservation,
    { data: updateRenewalData, isLoading: updateReservationLoading },
  ] = useUpdateReservedMutation();
  const handleUpdate = async () => {
    const params = {
      id: Number(id),
      body: {
        reserved_on: moment(),
      },
    };

    try {
      const response = await updateReservation(params).unwrap(); // Send transformed data

      navigate(`/finished-goods?id=${finished_id}&type=${type}&item=${item}`);
      setOpen(false);
      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message);
    }
  };

  return (
    <div>
      <Text
        variant="wearlay-paragraph-bold"
        value="Do you want to extend the reservation for next 20 Days?"
      />
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <CommonButton
          value="No"
          variant="wealay-secondary-btn"
          onClick={() => setOpen(false)}
        />
        <CommonButton
          value="Yes"
          variant="wealay-primary-btn"
          type="submit"
          isLoading={updateReservationLoading}
          onClick={handleUpdate}
          // Show loading state
        />
      </div>
    </div>
  );
};

export default ConfirmationMessagesForReservation;
