import { Navigate } from "react-router";

import { getAccessToken } from "../helpers/localStorage";
import { PathConstants } from "../pathConstants/pathConstants";

export const PublicRoute = ({ children, ...rest }: any) => {
  const user_role = localStorage.getItem("user_role");
  if (getAccessToken()) {
    return (
      <Navigate
        to={
          user_role === "3"
            ? PathConstants.WARE_HOUSE_MANAGEMENT
            : user_role === "4"
            ? PathConstants.FINISHED_GOODS
            : PathConstants.OVERVIEW
        }
      />
    );
  } else {
    return children;
  }
};
