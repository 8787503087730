import { CheckOutlined } from "@ant-design/icons";
import { Col, Row, Skeleton, Tag } from "antd";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { UnclassifiedDataType2 } from "../../../interface/unclassified";

interface ICreateSales {
  setId: (data: string) => void;
  setActive: (data: boolean) => void;
  data: UnclassifiedDataType2;
  isLoading: boolean;
}
export const UnclassifiedPreview: React.FC<ICreateSales> = ({
  setId,
  setActive,
  data,
  isLoading,
}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const ratingData = [
    "Cost",
    "Quality",
    "Credit terms",
    "Reliability",
    "Logistic chain",
    "Communication",
  ];

  useEffect(() => {
    if (id) {
      setId(data?.unclassified_id as string);
      setActive(data?.is_active as boolean);
    }
  }, [id, data?.unclassified_id]);

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <div className="sales-detail-border-main ">
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Item Name</div>
                <div className="sales-detail-sub-title common-work-break">
                  {data?.name?.name}
                </div>
              </div>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Category</div>
                <div className="sales-detail-sub-title common-work-break">
                  <div className="flex gap-2">
                    {data?.category?.breadcrumb &&
                      data?.category?.breadcrumb?.map((item: any, index) => (
                        <div className="flex gap-2">
                          <span>{item}</span>
                          {data?.category?.breadcrumb &&
                            index !== data.category.breadcrumb.length - 1 && (
                              <img
                                src="/assets/rightArrow.svg"
                                alt="Right Arrow"
                              />
                            )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Quantity</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.quantity}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Current Status</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.status === "disposable" ? (
                      <Tag color="red">Disposable</Tag>
                    ) : data?.status === "in_use" ? (
                      <Tag color="success" icon={<CheckOutlined />}>
                        In Use
                      </Tag>
                    ) : (
                      <Tag color="orange">Usable</Tag>
                    )}
                  </div>
                </div>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <div className=" flex flex-col gap-2 sales-detail-border  ">
                  <div className="sales-detail-placeholder">Purpose</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.purpose?.name}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Remarks</div>
                <div className="sales-detail-sub-title  common-work-break">
                  {data?.remarks ? data?.remarks : "-"}
                </div>
              </div>
            </Row>
          </div>
          {data?.specification?.length > 0 && (
            <div>
              <Row className="mt-24p">
                <Col span={8} className="sales-detail-border">
                  <b>Specification</b>
                </Col>
                <Col span={8} className="sales-detail-border">
                  <b>Dimension</b>
                </Col>
                <Col span={8} className="sales-detail-border">
                  <b>Unit</b>
                </Col>
              </Row>

              {data?.specification?.map(
                (specification: any, specIndex: number) => (
                  <Row key={specIndex}>
                    {/* Material (Specification Name) */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.specification_name}
                    </Col>

                    {/* Dimension */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.valueFields?.map(
                        (field: any, fieldIndex: number) => (
                          <div key={fieldIndex}>
                            {field?.dimension_value?.join(", ") || "N/A"}
                          </div>
                        )
                      )}
                    </Col>

                    {/* Unit */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.valueFields?.map(
                        (field: any, fieldIndex: number) => {
                          const unitName = specification?.unitOptions?.find(
                            (option: any) => option.value === field.unit
                          )?.label;

                          return <div key={fieldIndex}>{unitName || "-"}</div>;
                        }
                      )}
                    </Col>
                  </Row>
                )
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UnclassifiedPreview;
