import { Row, Col } from "antd";
import "./index.css";
import moment from "moment";
type Props = {
  children: JSX.Element;
};
export const LoginLayout = ({ children }: Props) => {
  return (
    <>
      <Row className="w-full">
        <Col span={8} className="col-1-bg-image">
          <div></div>
          <img src="/assets/wearlay.svg" className="logo-img"></img>
          <div className="footer-login">
            Copyright © wearlay {moment().year()}. All rights reserved
          </div>
        </Col>
        <Col span={24} md={{ span: 16 }} className="col-2 mob-col-2">
          <Row justify={"start"} className="mob-div-2">
            <img src="/assets/new-logo.svg" className="mob-div-1"></img>
          </Row>
          {children}
          <div className="mob-div-1">
            {" "}
            Copyright © wearlay {moment().year()}. All rights reserved
          </div>
        </Col>
      </Row>
    </>
  );
};
