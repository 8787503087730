import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router";

import { PathConstants } from "../pathConstants/pathConstants";
import { getAccessToken } from "./localStorage";

export const PrivateRoute = ({ children, ...rest }: any) => {
  const currentURL = window.location.href;
  const navigate = useNavigate();
  const isQuotation =
    currentURL.includes("/quotation/details") ||
    currentURL.includes("/order/details");
  const user_role = localStorage.getItem("user_role");
  useEffect(() => {
    if (getAccessToken() && window.location.pathname === "/") {
      user_role === "1"
        ? navigate(PathConstants.OVERVIEW)
        : user_role === "2"
        ? navigate(PathConstants.OVERVIEW)
        : user_role === "3"
        ? navigate(PathConstants.OVERVIEW_INVENTORY)
        : user_role === "4"
        ? navigate(PathConstants.FINISHED_GOODS)
        : navigate(PathConstants.OVERVIEW);
    }
  }, []);
  useEffect(() => {
    if (isQuotation) {
      sessionStorage.setItem("current_path", currentURL);
    }
  }, []);
  // useEffect(() => {
  //   if (!getAccessToken()) {
  //     removeTokens();
  //   }
  // }, []);
  if (getAccessToken()) {
    return children;
  } else {
    return <Navigate to={PathConstants.LOGIN} />;
  }
};
