import { Col, Row } from "antd";
import React from "react";
import { Item } from "../../../interface/finishedGoods";

interface ICreateSales {
  data: Item;
  sku: string;
}
export const WarehouseTab: React.FC<ICreateSales> = ({ data, sku }) => {
  return (
    <div className="sales-detail-border-main ">
      <Row className="w-full">
        <div className="sales-detail-border flex flex-col gap-2 w-full">
          <div className="sales-detail-placeholder">Warehouse Name</div>
          <div className="sales-detail-sub-title common-work-break">
            {data?.warehouse?.name?.name ? data?.warehouse?.name?.name : "-"}
          </div>
        </div>
      </Row>
      <Row className="w-full">
        <div className="sales-detail-border flex flex-col gap-2 w-full">
          <div className="sales-detail-placeholder">Address</div>
          <div className="sales-detail-sub-title common-work-break">
            {data?.warehouse?.address ? data?.warehouse?.address : "-"}
          </div>
        </div>
      </Row>
      <Row>
        <Col span={24} md={{ span: 12 }}>
          <div className="sales-detail-border flex flex-col gap-2">
            <div className="sales-detail-placeholder">Contact person</div>
            <div className="sales-detail-sub-title common-work-break">
              {data?.warehouse?.contact_person
                ? data?.warehouse?.contact_person
                : "-"}
            </div>
          </div>
          <div className="finishedGoodsTab-p flex flex-col gap-2">
            <div className="sales-detail-placeholder">Email Id</div>
            {data?.warehouse?.email ? data?.warehouse?.email : "-"}
          </div>
        </Col>
        <Col span={24} md={{ span: 12 }}>
          <div className="sales-detail-border flex flex-col gap-2">
            <div className="sales-detail-placeholder">Contact Number</div>
            <div className="sales-detail-sub-title common-work-break">
              {data?.warehouse?.phone ? data?.warehouse?.phone : "-"}
            </div>
          </div>
          <div className=" flex flex-col gap-2 overallrating">
            <div className="sales-detail-placeholder">SKU</div>
            <div className="sales-detail-sub-title common-work-break">
              {sku}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WarehouseTab;
