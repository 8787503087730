export const ConstantVariable = {
  OVERVIEW: "Dashboard",
  SALES: "Leads",
  QUOTATION: "Quotations",
  ORDERS: "Orders",
  INQUIRIES: "NPD",
  ALLOCATED: "Allocated",
  EMPLOYEE: "Employee",
  CONATCTS: "Contacts",
  INVENTORY_CTRL: "Inventory Ctrl",
  INVENTORY: "Inventory",
};
