import { Skeleton, Table } from "antd";

import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./index.css";
export interface ITableData {
  datasource: any;
  coloumn: any;
  isLoading?: boolean;
  setDetailsOpen?: (data: boolean) => void;
  setId?: (data: any) => void;
  isId?: any;
  isDetail?: boolean;
  isDeactivate?: boolean;
  page?: number;
  totalElements?: number;
  refetch?: () => void;
  setDownloadIds?: (data: any) => void;
  noRowSelection?: boolean;
}

export const CommonTable: React.FC<ITableData> = ({
  isLoading,
  datasource,
  coloumn,
  setDetailsOpen,
  setId,
  isDetail,
  isDeactivate,
  isId,
  totalElements,
  refetch,
  setDownloadIds,
  noRowSelection,
}) => {
  useEffect(() => {
    console.log();
  }, [isId]);
  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 10,
  });
  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  useEffect(() => {
    refetch && refetch();
  }, [pageData]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    // const selectedId = selectedRows.length > 0 ? selectedRows[0].id : null;
    // console.log("Selected ID: ", selectedId);
    setSelectedRowKeys(newSelectedRowKeys);
    setDownloadIds && setDownloadIds(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const userId = localStorage.getItem("user_id");
  const scrollConfig =
    location.pathname === "/category-management" ||
    location.pathname === "/finished-goods" ||
    location.pathname === "/rejected-products" ||
    location.pathname === "/raw-materials" ||
    location.pathname === "/unclassified" ||
    location.pathname === "/vendor-management" ||
    location.pathname === "/warehouse-management" ||
    location.pathname === "/for-lining" ||
    location.pathname === "/job-card" ||
    location.pathname === "/moulds" ||
    location.pathname === "/assets" ||
    location.pathname === "/consumables" ||
    location.pathname === "/samples-specimens"
      ? { y: 400, x: "max-content" }
      : location.pathname === "/inventory-overview"
      ? { y: 230, x: "max-content" } // Only vertical scrolling for this route
      : { x: "max-content" };
  return (
    <div className="main-div-2">
      <Table
        dataSource={datasource}
        columns={coloumn}
        loading={isLoading}
        pagination={false}
        onRow={(record, rowIndex) => {
          if (
            currentUrl === "/warehouse-management" ||
            currentUrl === "/category-management" ||
            currentUrl === "/vendor-management" ||
            currentUrl === "/finished-goods" ||
            currentUrl === "/unclassified" ||
            currentUrl === "/rejected-products" ||
            currentUrl === "/raw-materials" ||
            currentUrl === "/for-lining" ||
            currentUrl === "/job-card" ||
            currentUrl === "/moulds" ||
            currentUrl === "/assets" ||
            currentUrl === "/consumables" ||
            currentUrl === "/samples-specimens" ||
            currentUrl === "/inventory-overview"
          ) {
            return {}; // No clickable action
          }
          return {
            onClick: () => {
              setDetailsOpen && setDetailsOpen(true);
              setId && setId(record.id);

              currentUrl === "/quotation"
                ? navigate(
                    `${currentUrl}/details?id=${record.id}&created_by=${record.lead}`
                  )
                : currentUrl === "/order"
                ? navigate(
                    `${currentUrl}/details?id=${record.id}&created_by=${record.lead}`
                  )
                : setSearchParams({ ["id"]: record.id });
            },
          };
        }}
        locale={{
          emptyText: isLoading && (
            <Skeleton
              active={true}
              paragraph={{ rows: 7, width: "100%" }}
              title={{ width: "100%" }}
            />
          ),
        }}
        rowClassName="table-row"
        rowSelection={noRowSelection ? undefined : rowSelection}
        rowKey="id"
        scroll={scrollConfig}
        expandable={
          currentUrl === "/vendor-management"
            ? {
                expandedRowRender: (record) => (
                  <Table
                    columns={[
                      {
                        title: "Product Name",
                        dataIndex: "name",
                        key: "name",
                        render: (text: any, product: any) =>
                          product?.name?.name,
                      },
                      {
                        title: "Raw Material ID",
                        dataIndex: "raw_material_id",
                        key: "raw_material_id",
                      },
                    ]}
                    dataSource={record.products}
                    rowKey="id"
                    pagination={false}
                    loading={isLoading}
                  />
                ),
                rowExpandable: (record) =>
                  record.products && record.products.length > 0, // Expand rows only with products
              }
            : undefined
        }
      />

      {/* <Row justify={"end"} className="pagination-row">
          <Pagination
            current={pageData.page}
            onChange={onPaginationChange}
            total={totalElements}
            pageSize={5}
          />
        </Row> */}
    </div>
  );
};
