import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import React, { useState } from "react";
import { ForLiningInventoryItem2 } from "../../interface/forLining";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 0,
    paddingBottom: 10,
    paddingHorizontal: 0,
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "center",
    // fontFamily: "Inter",
  },
  image: {
    // marginVertical: 15,
    // marginHorizontal: 100,
    width: "100",
    marginRight: 30,
    // height: "100",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  backgroundColor: {
    backgroundColor: "#edf9f9",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: {
    display: "flex",
    width: "100%",
    // borderStyle: "solid",
    // borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  table1: {
    display: "flex",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    // borderRightWidth: 0,
    // borderBottomWidth: 0,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#e6ebec",
  },
  tableRow: {
    // margin: "auto",
    flexDirection: "row",
    backgroundColor: "#edf9f9",
  },
  tableRow1: {
    // margin: "auto",
    flexDirection: "row",
    backgroundColor: "#edf9f9",
  },
  tableRow4: {
    // margin: "auto",
    flexDirection: "row",
    backgroundColor: "white",
  },
  tableCol: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "10",
    borderColor: "#e6ebec",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol2: {
    width: "45%",
    borderStyle: "solid",
    wordBreak: "break-all",
    // borderWidth: 1,
    padding: "10",

    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol3: {
    width: "90%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "10",
    borderColor: "#e6ebec",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol4: {
    width: "90%",
    // borderStyle: "solid",
    // borderWidth: 1,
    padding: "10",
    // borderColor: "#e6ebec",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol5: {
    width: "45%",

    padding: "10",
  },
  tableCell: {
    textAlign: "left",
    fontSize: 13,
    color: "#043843",

    fontFamily: "Times-BoldItalic",
  },
  tableCell2: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: "extrabold",
    fontFamily: "Helvetica-Bold",
    wordBreak: "break-all",
    width: "90%",
  },
  tableCell4: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: "extrabold",
    fontFamily: "Helvetica-Bold",
    wordBreak: "break-all",
  },
  tableCell3: {
    textAlign: "left",
    fontSize: 12,
  },
  marginTop: {
    marginTop: 24,
  },
  imageDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "30",
    marginTop: "20",
  },
  title1: {
    fontWeight: "extrabold",
    fontSize: "24",
    fontFamily: "Helvetica-Bold",
  },
  titleBorder: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#e6ebec",
  },
  borderRight: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRight: 1,
    borderLeft: 0,
    borderBottom: 0,
    borderTop: 0,
    borderColor: "#e6ebec",
  },
  rectangle: {
    borderColor: "black",
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    height: 15,
    width: 15,
  },
  rectangleFlex: {
    display: "flex",
    gap: 10,
    flexDirection: "row",
  },
  rectangleFlex2: {
    display: "flex",
    gap: 10,
    flexDirection: "row",
    marginLeft: 30,
    marginTop: 20,
    width: "100%",
  },
});

// Create Document Component
interface IExport {
  data: ForLiningInventoryItem2 | any;
}
export const JobCardPdf: React.FC<IExport> = ({ data }) => {
  const [daysDifference, setDaysDifference] = useState(0);

  //   const { data, isLoading } = useGetQuotationByIdQuery(14);
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        <Text
          style={{
            bottom: 0,
            position: "absolute",
            textAlign: "right",
            right: 25,
            fontSize: 12,
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        <View style={styles.imageDiv}>
          <Text style={styles.title1}>Job Card</Text>

          {/* <Image src="/assets/jaws.png" style={styles.image} /> */}
        </View>
        <View style={{ ...styles.table, ...styles.marginTop }}>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell}>Job Card Number</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Job Name</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell2}>
                  {" "}
                  {data?.job_card_number ? data?.job_card_number : "-"}
                </Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell4}>
                  {data?.job_card?.job_type == "lining_job"
                    ? "Lining Job"
                    : data?.job_card?.job_type === "mould"
                    ? "Mould"
                    : "Job Work"}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              ...styles.titleBorder,
            }}
          >
            <View style={styles.tableRow1}>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>Brand</Text>
              </View>
            </View>
            <View style={styles.tableRow1}>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell2}>
                  {data?.brand?.name ? data?.brand?.name : "-"}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {(data?.vendor || data?.job_card?.vendor) && (
          <View>
            <View style={{ ...styles.table, ...styles.marginTop }}>
              <View style={styles.titleBorder}>
                <View style={styles.tableRow1}>
                  <View style={styles.tableCol4}>
                    <Text style={styles.tableCell}>Vendor</Text>
                  </View>
                </View>
                <View style={styles.tableRow1}>
                  <View style={styles.tableCol4}>
                    <Text style={styles.tableCell2}>
                      {data?.vendor?.name?.name
                        ? data?.vendor?.name?.name
                        : data?.job_card?.vendor?.name?.name
                        ? data?.job_card?.vendor?.name?.name
                        : "-"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ ...styles.table }}>
              <View style={styles.titleBorder}>
                <View style={styles.tableRow1}>
                  <View style={styles.tableCol4}>
                    <Text style={styles.tableCell}>Address</Text>
                  </View>
                </View>
                <View style={styles.tableRow1}>
                  <View style={styles.tableCol4}>
                    <Text style={styles.tableCell2}>
                      {data?.vendor?.address
                        ? data?.vendor?.address
                        : data?.job_card?.vendor?.address
                        ? data?.job_card?.vendor?.address
                        : "-"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ ...styles.table }}>
              <View style={styles.titleBorder}>
                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                    <Text style={styles.tableCell}>Email Address</Text>
                  </View>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>Contact Number</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                    <Text style={styles.tableCell2}>
                      {" "}
                      {data?.vendor?.email
                        ? data?.vendor?.email
                        : data?.job_card?.vendor?.email
                        ? data?.job_card?.vendor?.email
                        : "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell4}>
                      {data?.vendor?.phone
                        ? data?.vendor?.phone
                        : data?.job_card?.vendor?.phone
                        ? data?.job_card?.vendor?.phone
                        : "-"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        )}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              //   padding: "10",
              border: "1px solid #e6ebec",
              marginTop: "20",
              width: "90%",
            }}
          >
            <View
              style={{
                border: "1px solid #e6ebec",
                width: "30%",
                padding: "10",
              }}
            >
              <Text
                style={{
                  fontFamily: "Helvetica-Bold",
                }}
              >
                Quotation No
              </Text>
            </View>
            <View></View>
          </View>
        </View>
        <View style={styles.rectangleFlex2}>
          <View style={styles.rectangleFlex}>
            <View style={styles.rectangle}></View>
            <Text style={styles.tableCell}>Fabrication</Text>
          </View>
          <View style={styles.rectangleFlex}>
            <View style={styles.rectangle}></View>
            <Text style={styles.tableCell}>Mould</Text>
          </View>
        </View>
        <View style={{ ...styles.table, ...styles.marginTop }}>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell}>
                  Quoted Price for Fabrication
                </Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Quoted Price for Mould</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell2}>
                  {data?.job_card?.quote_price_fabrication
                    ? data?.job_card?.quote_price_fabrication
                    : data?.quote_price_fabrication
                    ? data?.quote_price_fabrication
                    : "-"}
                </Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell4}>
                  {data?.job_card?.quote_price_mould
                    ? data?.job_card?.quote_price_mould
                    : data?.quote_price_mould
                    ? data?.quote_price_mould
                    : "-"}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ ...styles.titleBorder }}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell}>Quote Issue Date</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Job Issue Date</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell2}>
                  {data?.job_card?.received_on
                    ? moment(data?.job_card?.received_on).format("DD-MM-YYYY")
                    : data?.received_on
                    ? moment(data?.received_on).format("DD-MM-YYYY")
                    : "-"}
                </Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell4}>
                  {data?.job_card?.created_date
                    ? moment(data?.job_card?.created_date).format("DD-MM-YYYY")
                    : data?.created_date
                    ? moment(data?.created_date).format("DD-MM-YYYY")
                    : "-"}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell}>Job Committed Date</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Job Received Date</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell2}>
                  {data?.committed_on
                    ? moment(data?.committed_on).format("DD-MM-YYYY")
                    : data?.completion_on
                    ? moment(data?.completion_on).format("DD-MM-YYYY")
                    : "-"}
                </Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell4}>
                  {data?.job_card?.received_on
                    ? moment(data?.job_card?.received_on).format("DD-MM-YYYY")
                    : data?.received_on
                    ? moment(data?.received_on).format("DD-MM-YYYY")
                    : "-"}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ ...styles.table, ...styles.marginTop }}>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow4}>
              <View style={styles.tableCol4} break>
                <Text style={styles.tableCell}>Remarks</Text>
              </View>
            </View>
            <View style={styles.tableRow4}>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell3}>
                  {data?.remarks ? data?.remarks : "-"}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
