import { Calendar, Skeleton } from "antd";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import minMax from "dayjs/plugin/minMax";
import React from "react";
import { useSearchParams } from "react-router-dom";
import {
  useGetAllLoginEntriesQuery,
  useGetSelectByIdApiQuery,
} from "../../services/api";

dayjs.extend(isSameOrBefore);
dayjs.extend(minMax);

interface IInputForm {
  setOpen?: (e: boolean) => void;
  open?: boolean;
}

export const DailyDemandCalendar: React.FC<IInputForm> = ({
  setOpen,
  open,
}) => {
  const [searchParams] = useSearchParams();
  const { data: selectById } = useGetSelectByIdApiQuery("rawmaterial");

  const objectId = searchParams.get("id");
  const Loginparams = {
    model_id: selectById?.id,
    object_id: objectId,
  };

  const { data: loginData, isFetching: dateLoading } =
    useGetAllLoginEntriesQuery(Loginparams, {
      skip: !objectId,
    });

  // Generate demand data including missing dates
  const demandData: Record<string, number> = React.useMemo(() => {
    const data: Record<string, number> = {};

    if (loginData?.data) {
      // Extract dates from the data and find the earliest date
      const dates = loginData.data.map((entry: any) => dayjs(entry.date));
      const earliestDate = dayjs.min(dates);
      const today = dayjs();

      // Loop through all dates from the earliest to today
      let currentDate = earliestDate;
      while (currentDate?.isSameOrBefore(today)) {
        const formattedDate = currentDate.format("YYYY-MM-DD");

        // Find the matching entry for the current date
        const entry = loginData.data.find(
          (entry: any) => entry.date === formattedDate
        );

        if (entry) {
          const { opening_balance, closing_balance } = entry;
          data[formattedDate] = opening_balance - closing_balance; // Calculate the difference
        } else {
          data[formattedDate] = 0; // No entry for this date, count is 0
        }

        currentDate = currentDate.add(1, "day"); // Move to the next day
      }
    }

    return data;
  }, [loginData]);

  // Render logic for each calendar cell
  const dateCellRender = (date: Dayjs) => {
    const formattedDate = date.format("YYYY-MM-DD");
    const today = dayjs();

    // If the date is in the future, return null (render nothing)
    if (date.isAfter(today, "day")) {
      return null;
    }

    // Otherwise, render demand value or 0 for dates till today
    const demand = demandData[formattedDate];
    return (
      <div
        style={{
          fontSize: "18px",
          textAlign: "center",
          fontWeight: demand ? "bold" : "bold",
        }}
      >
        {demand || 0}
      </div>
    );
  };

  return (
    <>
      {dateLoading ? (
        <Skeleton active />
      ) : (
        <Calendar cellRender={dateCellRender} mode="month" />
      )}
    </>
  );
};

export default DailyDemandCalendar;
