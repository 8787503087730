import { Col, Form, Input, message, Row, Skeleton } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";
import {
  useCreateSelectApiMutation,
  useCreateWarehouseMutation,
  useGetSelectApiQuery,
  useGetSelectByIdApiQuery,
  useGetWarehouseByIdQuery,
  useUpdateWarehouseMutation,
} from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import "./index.css";

interface ICreateSales {
  setOpen: (data: boolean) => void;
}
export const AddWarehouse: React.FC<ICreateSales> = ({ setOpen }) => {
  const [name, setName] = useState("name");

  const validationSchema = Yup.object({
    name: Yup.object().required("Warehouse Name is required"),
    address: Yup.string().required("Warehouse Address is required"),

    location: Yup.string().required("Warehouse Location is required"),
    phone: Yup.string().required("Contact Number is required"),

    contact_person: Yup.string()
      .required("Contact Person is required")
      .min(3, "Contact Person must be at least 3 characters"),
    email: Yup.string()
      .required("Email Address is required")
      .email("Invalid Email Address")
      .matches(
        /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/,
        "Invalid domain format. It must contain a '.' followed by a valid domain."
      ),

    remarks: Yup.string(),
  });
  const [createWarehouse, { data: addData, isLoading: createLoading }] =
    useCreateWarehouseMutation();
  const [updateWarehouse, { data: updateData, isLoading: updateLoading }] =
    useUpdateWarehouseMutation();
  const [
    createSelectWarehouse,
    { data: addSelectData, isLoading: createSelectLoading },
  ] = useCreateSelectApiMutation();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { data, isLoading } = useGetWarehouseByIdQuery(Number(id), {
    skip: Number(id) === 0, // Skip the query when id is 0
  });
  const { data: selectById } = useGetSelectByIdApiQuery("warehouse");
  const params = {
    name: name,
    model: Number(selectById?.id),
  };
  const { data: getAllWareHouse, isFetching } = useGetSelectApiQuery(params, {
    skip: !name && !selectById?.id, // Skip the query when id is 0
  });

  const onFinish = async (values: any) => {
    // Create the updateBody for PUT request, only including the body
    // This is just the form values

    if (id) {
      // If there is an id, pass it along with the body in the mutation
      const finalValues = {
        ...values,

        name: Number(values.name?.value),
      };
      updateWarehouse({
        id: Number(id), // Pass the ID as part of the payload
        body: finalValues, // Spread the rest of the values into the body
      })
        .unwrap()
        .then((data: any) => {
          message.success(data?.message);
          setOpen && setOpen(false);
          form.resetFields();
        })
        .catch((err: any) => {
          message.error(err?.data?.message);
        });
    } else {
      // If no id, create a new warehouse (call createWarehouse mutation)
      const finalValues = {
        ...values,

        name: Number(values.name?.value),
      };
      createWarehouse(finalValues)
        .unwrap()
        .then((data: any) => {
          message.success(data?.message);
          setOpen && setOpen(false);
          form.resetFields();
        })
        .catch((err: any) => {
          message.error(err?.data?.message);
        });
    }
  };

  useEffect(() => {
    if (data) {
      // Update Formik fields
      form.setFieldValue("name", {
        label: data?.data?.name?.name,
        value: data?.data?.name?.id,
      }); // Set the selected value as an object
      form.setFieldValue("address", data?.data?.address);
      form.setFieldValue("location", data?.data?.location);
      form.setFieldValue("phone", data?.data?.phone);
      form.setFieldValue("contact_person", data?.data?.contact_person);
      form.setFieldValue("email", data?.data?.email);
      form.setFieldValue("remarks", data?.data?.remarks);
    }
  }, [id, data]);

  const handleCreate = async (option: string) => {
    const newOption = {
      name: option || "",
      field: name,
      model: Number(selectById?.id),
    }; // Prepare the new option

    try {
      const response = await createSelectWarehouse({
        name: "warehouse", // Pass the field name
        // Pass the model
        body: newOption, // Pass the body object
      }).unwrap(); // Unwrap the response for easier handling

      form.setFieldValue(name, {
        value: response?.data?.id,
        label: response?.data?.name,
      });
      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message || "Failed to create warehouse");
    }
  };

  interface getSelectValues {
    value: string; // Ensure `value` is typed as `number`
    label: string;
  }
  const optionsCategory: getSelectValues[] | undefined =
    getAllWareHouse?.data?.map(({ id, name }) => ({
      value: id?.toString() as string, // Convert `id` to string
      label: name as string, // Ensure `name` is a string
    }));

  return (
    <div className="">
      {isLoading ? (
        <Skeleton
          active={true}
          paragraph={{ rows: 7, width: "100%" }}
          title={{ width: "100%" }}
        />
      ) : (
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}

          // Use Formik's submit handler
        >
          <div className="flex flex-col  justify-between align-center warehouseDrawerHeight">
            <div>
              <Form.Item
                label="Warehouse Name"
                required
                name={"name"}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                {/* <CreatableSelect
                  isClearable
                  name="name"
                  options={options}
                  onCreateOption={(option) => {
                    handleCreate(option);
                  }}
                  onChange={(option) => handleChange2(option)}
                  value={formik.values.name}
                  placeholder="Enter Warehouse name"
                /> */}
                <CreatableSelect
                  className="w-full"
                  name="name"
                  options={!isFetching ? optionsCategory : []} // Set options to empty array when isFetching is true
                  onCreateOption={(option) => {
                    handleCreate(option);
                  }}
                  placeholder="Enter Warehouse Name"
                  onMenuOpen={() => setName("name")}
                  isLoading={isFetching}
                  loadingMessage={() => "Loading..."}
                  isClearable
                />
              </Form.Item>
              <Form.Item
                label="Warehouse Address"
                name={"address"}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea placeholder="Enter Warehouse Address" rows={4} />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Warehouse Location"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    required
                    name={"location"}
                  >
                    <Input
                      placeholder="Enter Warehouse Location"
                      className="customInput"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Contact Number"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    required
                    name={"phone"}
                  >
                    <Input
                      placeholder="Enter Contact Number"
                      className="customInput"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Contact Person"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    required
                    name={"contact_person"}
                  >
                    <Input
                      placeholder="Enter Contact Person"
                      className="customInput"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Email Id"
                    name={"email"}
                    required
                    rules={[
                      { required: true, message: "Email Id is required" },
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            !/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i.test(value)
                          ) {
                            return Promise.reject(
                              "Email must include a valid domain like .com, .in, etc."
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Email Id"
                      className="customInput"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Remarks" name={"remarks"}>
                <TextArea name="remarks" placeholder="Enter Remarks" rows={4} />
              </Form.Item>
            </div>
            <div>
              <Form.Item className="w-full">
                <CommonButton
                  variant="wealay-primary-btn"
                  value={
                    id ? "Update Warehouse Details" : "Save Warehouse Details"
                  }
                  className="primary-btn warehouseButton w-full"
                  type="submit"
                  disabled={createLoading || updateLoading} // Disable button if form is invalid
                  isLoading={createLoading || updateLoading}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default AddWarehouse;
