import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Col, Row, Skeleton, Tag } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RawMaterialsProduct2 } from "../../../interface/rawMaterials";
import "./index.css";
interface ICreateSales {
  setId: (data: string) => void;
  setActive: (data: boolean) => void;
  data: RawMaterialsProduct2;
  isLoading: boolean;
}
export const RawMaterialPreview: React.FC<ICreateSales> = ({
  setId,
  setActive,
  data,
  isLoading,
}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const ratingData = [
    "Cost",
    "Quality",
    "Credit terms",
    "Reliability",
    "Logistic chain",
    "Communication",
  ];

  useEffect(() => {
    if (id) {
      setId(data?.raw_material_id as string);
      setActive(data?.is_active as boolean);
    }
  }, [id, data?.raw_material_id]);

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <div className="sales-detail-border-main ">
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Item Name</div>
                <div className="sales-detail-sub-title common-work-break">
                  {data?.name?.name}
                </div>
              </div>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Category</div>
                <div className="sales-detail-sub-title common-work-break">
                  <div className="flex gap-2">
                    {data?.category?.breadcrumb &&
                      data?.category?.breadcrumb?.map((item: any, index) => (
                        <div className="flex gap-2">
                          <span>{item}</span>
                          {data?.category?.breadcrumb &&
                            index !== data.category.breadcrumb.length - 1 && (
                              <img
                                src="/assets/rightArrow.svg"
                                alt="Right Arrow"
                              />
                            )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Row>
            {type === "prime_quality" ? (
              <div>
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Inventory In Hand
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.inventory_inhand ? data?.inventory_inhand : "-"}
                      </div>
                    </div>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Safety Stock
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.safety_stock ? data?.safety_stock : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Daily Average Demand
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.daily_average_demand
                          ? data?.daily_average_demand
                          : "-"}
                      </div>
                    </div>
                    <div className=" flex flex-col gap-2 overallrating">
                      <div className="sales-detail-placeholder">
                        Average Lead Time
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.average_lead_time
                          ? data?.average_lead_time
                          : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        New Order Demand
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.new_order_demand ? data?.new_order_demand : "-"}
                      </div>
                    </div>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Reorder Point
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.reorder_point ? data?.reorder_point : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Max Lead TIme
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.maximum_lead_time
                          ? data?.maximum_lead_time
                          : "-"}
                      </div>
                    </div>
                    <div className=" flex flex-col gap-2 overallrating">
                      <div className="sales-detail-placeholder">
                        Item Order Quantity
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.quantity ? data?.quantity : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">Dead Stock</div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.dead_stock ? data?.dead_stock : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Previous DOP
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.previous_dop
                          ? moment(data.previous_dop).format("DD-MM-YYYY")
                          : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="w-full">
                  <div className="sales-detail-border flex flex-col gap-2 w-full">
                    <div className="sales-detail-placeholder ">
                      Inventory Suggestion
                    </div>
                    <div className="sales-detail-sub-title  common-work-break ">
                      {data.reorder_point < data?.safety_stock ? (
                        <Tag color="error" icon={<CloseCircleOutlined />}>
                          InSufficient
                        </Tag>
                      ) : data.reorder_point > data?.safety_stock ? (
                        <Tag color="success" icon={<CheckCircleOutlined />}>
                          Sufficient
                        </Tag>
                      ) : (
                        <Tag
                          color="warning"
                          icon={<ExclamationCircleOutlined />}
                        >
                          Just Sufficient
                        </Tag>
                      )}
                    </div>
                  </div>
                </Row>
                {data?.is_active === false && (
                  <Row className="w-full">
                    <div className="sales-detail-border flex flex-col gap-2 w-full">
                      <div className="sales-detail-placeholder item-discontinued">
                        Item Discontinued
                      </div>
                      <div className="sales-detail-sub-title  common-work-break ">
                        {data?.reason ? data?.reason : "-"}
                      </div>
                    </div>
                  </Row>
                )}
              </div>
            ) : (
              <div>
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">Quality</div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.quantity ? data?.quantity : "-"}
                      </div>
                    </div>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">Shift</div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.shift?.name ? data?.shift?.name : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Date of Occurrence
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.date_occurrence
                          ? moment(data.date_occurrence).format("DD-MM-YYYY")
                          : "-"}
                      </div>
                    </div>
                    <div className=" flex flex-col gap-2 overallrating">
                      <div className="sales-detail-placeholder">
                        Responsible Person
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.responsible_person?.name
                          ? data?.responsible_person?.name
                          : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Remarks</div>
                <div className="sales-detail-sub-title  common-work-break">
                  {data?.remarks ? data?.remarks : "-"}
                </div>
              </div>
            </Row>
          </div>
          {data?.specification?.length > 0 && (
            <div>
              <Row className="mt-24p">
                <Col span={8} className="sales-detail-border">
                  <b>Specification</b>
                </Col>
                <Col span={8} className="sales-detail-border">
                  <b>Dimension</b>
                </Col>
                <Col span={8} className="sales-detail-border">
                  <b>Unit</b>
                </Col>
              </Row>

              {data?.specification?.map(
                (specification: any, specIndex: number) => (
                  <Row key={specIndex}>
                    {/* Material (Specification Name) */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.specification_name}
                    </Col>

                    {/* Dimension */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.valueFields?.map(
                        (field: any, fieldIndex: number) => (
                          <div key={fieldIndex}>
                            {field?.dimension_value?.join(", ") || "N/A"}
                          </div>
                        )
                      )}
                    </Col>

                    {/* Unit */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.valueFields?.map(
                        (field: any, fieldIndex: number) => {
                          const unitName = specification?.unitOptions?.find(
                            (option: any) => option.value === field.unit
                          )?.label;

                          return <div key={fieldIndex}>{unitName || "-"}</div>;
                        }
                      )}
                    </Col>
                  </Row>
                )
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RawMaterialPreview;
