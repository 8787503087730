import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Col, message, Row, Skeleton, Tag } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useUpdateJobInHandMutation } from "../../../services/api";
// import "./index.css";
interface ICreateSales {
  setId: (data: string) => void;
  setActive: (data: boolean) => void;
  data: any;
  isLoading: boolean;
  setOpenDrawer: (data: boolean) => void;
}
export const JobDetail: React.FC<ICreateSales> = ({
  setId,
  setActive,
  data,
  isLoading,
  setOpenDrawer,
}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const [open, isOpen] = useState(false);
  const [assignOpen, isAssignOpen] = useState(false);

  const ratingData = [
    "Cost",
    "Quality",
    "Credit terms",
    "Reliability",
    "Logistic chain",
    "Communication",
  ];

  useEffect(() => {
    if (id) {
      setId(data?.mould_id as string);
      setActive(data?.is_active as boolean);
    }
  }, [id, data?.mould_id]);
  const [approveQutation, { isLoading: approveLoading }] =
    useUpdateJobInHandMutation();
  const formData = new FormData();

  const handleRejected = async () => {
    formData.append("quote_status", "rejected");
    try {
      const response = await approveQutation({
        id: Number(id),
        body: formData,
      }).unwrap(); // Send transformed data
      //   form.resetFields();
      //   navigate("/for-lining");
      setOpenDrawer(false);

      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message);
    }
  };
  const handleClosed = async () => {
    formData.append("job_status", "closed");

    try {
      const response = await approveQutation({
        id: Number(id),
        body: formData,
      }).unwrap(); // Send transformed data
      //   form.resetFields();
      //   navigate("/for-lining");
      setOpenDrawer(false);

      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message);
    }
  };
  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <div className="sales-detail-border-main ">
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Job Name</div>
                <div className="sales-detail-sub-title common-work-break">
                  {data?.job_card?.name?.name}
                </div>
              </div>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Job Card Number</div>
                <div className="sales-detail-sub-title common-work-break">
                  {data?.job_card?.job_status
                    ? data?.job_card?.job_card_number
                    : "-"}
                </div>
              </div>
            </Row>

            <div>
              <Row>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">Job Type</div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.job_card?.job_type}
                    </div>
                  </div>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">
                      Quoted Price for Mould
                    </div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.job_card?.quote_price_mould
                        ? data?.job_card?.quote_price_mould
                        : "-"}
                    </div>
                  </div>
                </Col>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">
                      Quoted Issue Date
                    </div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.job_card?.quote_issue_on
                        ? moment(data.job_card.quote_issue_on).format(
                            "DD-MM-YYYY"
                          )
                        : "-"}
                    </div>
                  </div>
                  <div className=" flex flex-col gap-2 overallrating sales-detail-border">
                    <div className="sales-detail-placeholder">
                      Quoted Price for Fabrication
                    </div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.job_card?.quote_price_fabrication
                        ? data?.job_card?.quote_price_fabrication
                        : "-"}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">
                      Quoted Amount
                    </div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.job_card_number ? data?.job_card_number : "-"}
                    </div>
                  </div>
                </Col>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">
                      Proposed Time for Completion
                    </div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.job_card?.completion_on
                        ? moment(data.job_card.completion_on).format(
                            "DD-MM-YYYY"
                          )
                        : "-"}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">
                      Job Issue Date
                    </div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.job_card?.created_date
                        ? moment(data.job_card.created_date).format(
                            "DD-MM-YYYY"
                          )
                        : "-"}
                    </div>
                  </div>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">Amount Paid</div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.job_card?.amount ? data.job_card?.amount : "-"}
                    </div>
                  </div>
                </Col>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">
                      Job Received Date
                    </div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.job_card?.received_on
                        ? moment(data.job_card?.received_on).format(
                            "DD-MM-YYYY"
                          )
                        : "-"}
                    </div>
                  </div>
                  <div className=" flex flex-col gap-2 overallrating sales-detail-border">
                    <div className="sales-detail-placeholder">Job Status</div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.job_card?.job_status === "completed" ? (
                        <Tag color="green" icon={<CheckOutlined />}>
                          Completed
                        </Tag>
                      ) : data?.job_status === "closed" ? (
                        <Tag color="red" icon={<CloseCircleOutlined />}>
                          Declined
                        </Tag>
                      ) : data?.job_status === "partially_completed" ? (
                        <Tag color="orange" icon={<CloseCircleOutlined />}>
                          Incompleted
                        </Tag>
                      ) : data?.job_status === "opened" ? (
                        <Tag color="blue" icon={<CheckOutlined />}>
                          Assigned
                        </Tag>
                      ) : (
                        <div>-</div> // Default case for unexpected values
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobDetail;
