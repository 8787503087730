import { UploadOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, message, Upload } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  disablePastDates,
  validatePositiveNumber,
} from "../../../helpers/helperFunction";
import { useAssignForLiningMutation } from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import { Text } from "../../CommonFont/text";
interface ICreateSales {
  setOpen: (data: boolean) => void;
  isReassign: boolean;
  setDrawer: (data: boolean) => void;
  // Pass the record ID as a prop
}
export const AssignQuoteModal: React.FC<ICreateSales> = ({
  setOpen,
  isReassign,
  setDrawer,
}) => {
  const [approveQutation, { isLoading }] = useAssignForLiningMutation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  const handleApprove = async (value: any) => {
    const formData = new FormData();

    // Ensure values.document.fileList exists and has at least one file
    if (value?.document?.fileList?.length > 0) {
      formData.append("document", value.document.fileList[0].originFileObj); // Append actual file object
    }

    value?.vendor_committed_on &&
      formData.append(
        "vendor_committed_on",
        dayjs(value.vendor_committed_on).format()
      );
    value?.quote_price && formData.append("quote_price", value.quote_price);
    isReassign && formData.append("quoted_status", "approved");
    formData.append("quote_issue_on", dayjs().format());
    try {
      const response = await approveQutation({
        id: Number(id),
        body: formData,
      }).unwrap(); // Send transformed data
      form.resetFields();
      //   navigate("/for-lining");
      setOpen(false);
      setDrawer(false);

      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message);
    }
  };
  const beforeUpload = (file: any) => {
    const isLt1M = file.size / 1024 / 1024 < 1; // Convert bytes to MB
    if (!isLt1M) {
      message.error("File size must be smaller than 1MB!");
      return Upload.LIST_IGNORE; // Prevents file from being added to the list
    }

    return false; // Allows upload
  };
  return (
    <div>
      <Form layout="vertical" onFinish={handleApprove} form={form}>
        <div className="flex gap-2 mb-10">
          <img src="/assets/iButton.svg" />
          <Text
            variant="wearlay-subtitle"
            value="Enter Committed Date by Vendor "
          />
        </div>
        {isReassign && (
          <Form.Item
            required
            rules={[
              { required: true },
              {
                validator: validatePositiveNumber,
              },
            ]}
            label="Quoted Price"
            className="mt-24 w-full"
            name={"quote_price"}
          >
            <Input placeholder="Enter" />
          </Form.Item>
        )}
        <Form.Item
          label="Committed Date by Vendor"
          required
          name={"vendor_committed_on"}
          rules={[
            {
              required: true,
              message: "Please select a date",
            },
          ]}
        >
          <DatePicker
            format={"DD-MM-YYYY"}
            className="w-full"
            disabledDate={disablePastDates}
          />
        </Form.Item>
        <Form.Item label="Document" name={"document"} className="w-full">
          <Upload
            accept=".pdf"
            beforeUpload={beforeUpload}
            // beforeUpload={()=>false}
            onChange={(info) => {
              if (info.file.status === "error") {
                message.error(`${info.file.name} upload failed.`);
              }
            }}
            maxCount={1}
            className="w-full"
          >
            <Button icon={<UploadOutlined />} className="w-full">
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
        <div className="flex gap-2 justify-end mt-24p">
          <Button
            className="wealay-secondary-btn"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>

          <CommonButton
            variant="wealay-primary-btn"
            value="Save"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
      </Form>
    </div>
  );
};
