import { Form, message, Select } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEditBacklogMutation } from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import { Text } from "../../CommonFont/text";

interface ICreateSales {
  setOpen: (data: boolean) => void;
  setApprove: (data: boolean) => void;
}

const Backdated: React.FC<ICreateSales> = ({ setOpen, setApprove }) => {
  const [enableEdit, { isLoading }] = useEditBacklogMutation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const handleEdit = async () => {
    try {
      const values = await form.validateFields();
      const response = await enableEdit({
        id: Number(id),
        body: { time: Number(values.timeframe) },
      }).unwrap();

      message.success(response?.message);
      navigate("/inventory-employees");
      form.resetFields();
      setOpen(false);
      setApprove(true);
    } catch (error) {
      message.error("Failed to update backlog");
    }
  };

  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex gap-2 items-center">
        <img src="/assets/timer.svg" alt="Timer Icon" />
        <Text
          variant="wearlay-paragraph-bold"
          value="Set Timeframe for Backlog Entry"
        />
      </div>
      <div className="text-gray-600">
        Once approved, these settings cannot be modified.
      </div>

      <Form form={form} layout="vertical">
        <Form.Item
          name="timeframe"
          label="Select Timeframe"
          rules={[{ required: true, message: "Please select a timeframe!" }]}
        >
          <Select
            options={[
              { value: "1", label: "1 hour" },
              { value: "2", label: "2 hours" },
              { value: "3", label: "3 hours" },
            ]}
            placeholder="Select Timeframe"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>

      <div className="flex justify-end gap-2">
        <CommonButton
          variant="wealay-primary-btn"
          value="Approve"
          onClick={handleEdit}
          isLoading={isLoading}
          disabled={isLoading}
        />
        <CommonButton
          variant="wealay-secondary-btn"
          value="Cancel"
          onClick={() => {
            setOpen(false);
            navigate("/inventory-employees");
          }}
        />
      </div>
    </div>
  );
};

export default Backdated;
