import { EditOutlined } from "@ant-design/icons";
import { Col, Empty, message, Modal, Row, Skeleton } from "antd";
import moment from "moment";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useDeleteReservationMutation,
  useGetAllReservationQuery,
} from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import ConfirmationMessagesForReservation from "../../Modals/ConfirmationForReservation";

interface ICreateSales {
  setReserved?: (data: boolean) => void;
  setEditOpen: (data: boolean) => void;
  setDrawer: (data: any) => void;
}
export const ReservedBy: React.FC<ICreateSales> = ({
  setReserved,
  setEditOpen,
  setDrawer,
}) => {
  const [searchParams] = useSearchParams();
  const finished_id = searchParams.get("id");
  const type = searchParams.get("type");
  const itemName = searchParams.get("item");

  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const navigate = useNavigate();
  const { data, isLoading } = useGetAllReservationQuery(Number(finished_id));
  const [
    deleteReservation,
    { data: updateLogEntriesData, isLoading: deleteReservationLoading },
  ] = useDeleteReservationMutation();

  const isInventoryManager =
    localStorage.getItem("user_role") === "3" ? true : false;
  const handleDelete = async (id: number) => {
    try {
      const response = await deleteReservation(id).unwrap(); // Send transformed data

      navigate(
        `/finished-goods?id=${finished_id}&type=${type}&item=${itemName}`
      );

      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message);
    }
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : data?.data && data?.data.length > 0 ? (
        data.data.map((item: any, index: number) => (
          <div key={index} className="mb-10">
            {/* First Row */}
            <Row className="border-with-no-padding">
              <Col span={2}>
                <div className="border-with-no-border-bottom flex flex-col gap-2">
                  <div className="sales-detail-placeholder">No</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {index + 1}
                  </div>
                </div>
              </Col>
              <Col span={11}>
                <div className="border-with-no-border-bottom flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Reserved By</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {item?.reserved_by?.first_name}{" "}
                    {item?.reserved_by?.last_name} (
                    {item?.reserved_by?.user_role_name})
                  </div>
                </div>
              </Col>
              <Col span={11}>
                <div className="border-with-no-border-bottom flex flex-col gap-2">
                  <div className="flex justify-between">
                    <div className="sales-detail-placeholder">Reserved For</div>
                    {(item?.has_permission || isInventoryManager) && (
                      <strong
                        onClick={() => {
                          setEditOpen(true);
                          setDrawer("add");
                          navigate(
                            `/finished-goods?id=${finished_id}&type=${type}&item=${itemName}&reservation_id=${item?.id}`
                          );
                        }}
                        className="cursor-pointer"
                      >
                        <EditOutlined /> Edit
                      </strong>
                    )}
                  </div>
                  <div className="sales-detail-sub-title common-work-break">
                    {item?.reserved_for?.name}
                  </div>
                </div>
              </Col>
            </Row>

            {/* Second Row */}
            <Row>
              <Col span={24} md={{ span: 13 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Contact Number</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {item?.reserved_by?.phone}
                  </div>
                </div>
              </Col>
              <Col span={24} md={{ span: 11 }}>
                <Row>
                  <Col span={13}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Reserved Quantity
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {item?.quantity}
                      </div>
                    </div>
                  </Col>
                  <Col span={11}>
                    <div className="flex flex-col gap-2 overallrating">
                      <div className="sales-detail-placeholder">
                        Reserved On
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {moment(item?.reserved_on).format("DD-MM-YYYY")}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Third Row */}
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Remarks</div>
                <div className="sales-detail-sub-title common-work-break">
                  {item?.remarks || "-"}
                </div>
              </div>
            </Row>
            <Row
              justify={"end"}
              className={
                item?.has_permission ||
                isInventoryManager ||
                (item?.renewal && !isInventoryManager)
                  ? "sales-detail-border"
                  : ""
              }
            >
              <div className="flex gap-2 mt-24">
                {(item?.has_permission || isInventoryManager) && (
                  <CommonButton
                    value="Close Reservation"
                    variant="wealay-secondary-btn"
                    onClick={() => {
                      handleDelete(item?.id);
                    }}
                    disabled={deleteReservationLoading}
                    isLoading={deleteReservationLoading}
                  />
                )}
                {item?.renewal && !isInventoryManager && (
                  <CommonButton
                    value="Renew Reservation"
                    variant="wealay-primary-btn"
                    onClick={() => {
                      setOpen(true);
                      setId(item?.id);
                    }}
                  />
                )}
              </div>
            </Row>
          </div>
        ))
      ) : (
        <Empty />
      )}
      <Modal
        open={open}
        footer={false}
        onCancel={() => setOpen(false)}
        closable={false}
        centered
      >
        <ConfirmationMessagesForReservation
          setOpen={setOpen}
          id={Number(id) as number}
        />
      </Modal>
    </>
  );
};

export default ReservedBy;
