import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useGetAllSpecimenByIdQuery,
  useGetSelectByIdApiQuery,
  useGetUserDetailByIdQuery,
} from "../../../services/api";

import AddNewLog from "../FinishedGoodsPreview/addNewLog";
import LoginHistory from "../FinishedGoodsPreview/loginHistory";
import WarehouseTab from "../FinishedGoodsPreview/warehouse";
import SpecimenPreview from "./productDetais";

// import "./index.css";

interface ICreateSales {
  setId: (data: string) => void;
  setActive: (data: boolean) => void;
}
export const SpecimenTabPreview: React.FC<ICreateSales> = ({
  setId,
  setActive,
}) => {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const log = searchParams.get("log");

  const { data, isLoading } = useGetAllSpecimenByIdQuery(Number(id));

  const ratingData = [
    "Cost",
    "Quality",
    "Credit terms",
    "Reliability",
    "Logistic chain",
    "Communication",
  ];

  useEffect(() => {
    if (id) {
      setId(data?.data?.specimen_id as string);
      setActive(data?.data?.is_active as boolean);
    }
  }, [id, data?.data?.specimen_id]);
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };
  const { data: selectById } = useGetSelectByIdApiQuery("specimen");

  const userId = localStorage.getItem("user_id");

  const { data: userDetail } = useGetUserDetailByIdQuery(userId);
  useEffect(() => {
    if (log) {
      setActiveTab("3");
    }
  }, [log]);
  return (
    <>
      <Tabs
        className="finishedGoodsTab"
        activeKey={activeTab}
        onChange={handleTabChange}
        type="card"
        items={
          localStorage?.getItem("user_role") === "2" ||
          localStorage?.getItem("user_role") === "1"
            ? [
                {
                  label: "Product Details", // Unique label for the first tab
                  key: "1", // Unique key for the first tab
                  children: (
                    <SpecimenPreview
                      setActive={setActive}
                      setId={setId}
                      data={data?.data as any}
                      isLoading={isLoading as boolean}
                    />
                  ),
                },

                {
                  label: "Warehouse Details", // Unique label for the second tab
                  key: "2", // Unique key for the second tab
                  children: (
                    <WarehouseTab
                      data={data?.data as any}
                      sku={data?.data?.storage_unit as string}
                    />
                  ),
                },
              ]
            : [
                {
                  label: "Product Details", // Unique label for the first tab
                  key: "1", // Unique key for the first tab
                  children: (
                    <SpecimenPreview
                      setActive={setActive}
                      setId={setId}
                      data={data?.data as any}
                      isLoading={isLoading as boolean}
                    />
                  ),
                },
                {
                  label: "Warehouse Details", // Unique label for the second tab
                  key: "2", // Unique key for the second tab
                  children: (
                    <WarehouseTab
                      data={userDetail?.data[0] as any}
                      sku={data?.data?.storage_unit as string}
                    />
                  ),
                },

                ...(data?.data?.is_active
                  ? [
                      {
                        label: "Log Inwards/Outwards",
                        key: "3",
                        children: open ? (
                          <AddNewLog
                            data={data?.data as any}
                            inventory_type="specimen"
                            defaultCategoryId={selectById?.id as number}
                            setOpen={setOpen}
                            path="/samples-specimens"
                          />
                        ) : (
                          <LoginHistory
                            modelId={Number(selectById?.id as number)}
                            objectId={Number(id)}
                            setOpen={setOpen}
                          />
                        ),
                      },
                    ]
                  : []),
              ]
        }
      />
    </>
  );
};

export default SpecimenTabPreview;
