import { Form, message } from "antd";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useEditBacklogMutation,
  useUpdateReservedMutation,
} from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import { Text } from "../../CommonFont/text";

interface ICreateSales {
  setOpen: (data: boolean) => void;
  setApprove: (data: boolean) => void;

  // Pass the record ID as a prop
}

export const ConfirmationMessagesForBacklog: React.FC<ICreateSales> = ({
  setOpen,
  setApprove,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const [
    updateReservation,
    { data: updateRenewalData, isLoading: updateReservationLoading },
  ] = useUpdateReservedMutation();
  const [enableEdit, { isLoading }] = useEditBacklogMutation();

  const handleEdit = async () => {
    try {
      const response = await enableEdit({
        id: Number(id),
        body: { time: Number(0) },
      }).unwrap();

      message.success(response?.message);
      navigate("/inventory-employees");
      setApprove(false);

      setOpen(false);
    } catch (error) {
      message.error("Failed to update backlog");
    }
  };

  return (
    <div>
      <Text
        variant="wearlay-paragraph-bold"
        value="Are you sure to disable this backlog?"
      />
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <CommonButton
          value="No"
          variant="wealay-secondary-btn"
          onClick={() => {
            setOpen(false);

            navigate("/inventory-employees");
          }}
        />
        <CommonButton
          value="Yes"
          variant="wealay-primary-btn"
          type="submit"
          isLoading={isLoading}
          onClick={handleEdit}
          disabled={isLoading}
          // Show loading state
        />
      </div>
    </div>
  );
};

export default ConfirmationMessagesForBacklog;
