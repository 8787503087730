import { Col, Row } from "antd";
import React from "react";
import { RawMaterialsProduct2 } from "../../../interface/rawMaterials";

interface ICreateSales {
  data: RawMaterialsProduct2;
}
export const VendorTab: React.FC<ICreateSales> = ({ data }) => {
  return (
    <div className="sales-detail-border-main ">
      <Row className="w-full">
        <div className="sales-detail-border flex flex-col gap-2 w-full">
          <div className="sales-detail-placeholder">Vendor Name</div>
          <div className="sales-detail-sub-title common-work-break">
            {data?.vendor?.name?.name ? data?.vendor?.name?.name : "-"}
          </div>
        </div>
      </Row>

      <Row>
        <Col span={24} md={{ span: 12 }}>
          <div className="sales-detail-border flex flex-col gap-2">
            <div className="sales-detail-placeholder">Contact Number</div>
            <div className="sales-detail-sub-title common-work-break">
              {data?.vendor?.phone ? data?.vendor?.phone : "-"}
            </div>
          </div>
        </Col>
        <Col span={24} md={{ span: 12 }}>
          <div className=" flex flex-col gap-2 overallrating">
            <div className="sales-detail-placeholder">Email</div>
            <div className="sales-detail-sub-title common-work-break">
              {data?.vendor?.email ? data?.vendor?.email : "-"}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="w-full">
        <div className="sales-detail-border flex flex-col gap-2 w-full">
          <div className="sales-detail-placeholder">Address</div>
          <div className="sales-detail-sub-title common-work-break">
            {data?.vendor?.address ? data?.vendor?.address : "-"}
          </div>
        </div>
      </Row>
      <Row className="w-full">
        <div className="sales-detail-border flex flex-col gap-2 w-full">
          <div className="sales-detail-placeholder">Remarks</div>
          <div className="sales-detail-sub-title common-work-break">
            {data?.vendor?.remarks ? data?.vendor?.remarks : "-"}
          </div>
        </div>
      </Row>
    </div>
  );
};

export default VendorTab;
