import { Button, DatePicker, Form, Input, message, Radio, Row } from "antd";
import moment from "moment";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  disableFutureDates,
  validatePositiveNumber,
} from "../../../helpers/helperFunction";
import { useUpdateJobInHandMutation } from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import { Text } from "../../CommonFont/text";
interface ICreateSales {
  setOpen: (data: boolean) => void;

  setDrawer: (data: boolean) => void;
  commitedDate: any;
  jobType: string;
  quotedPrice: number;
  // Pass the record ID as a prop
}
export const CompletedJob: React.FC<ICreateSales> = ({
  setOpen,
  commitedDate,
  setDrawer,
  jobType,
  quotedPrice,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>("completed");
  const [completeQuotation, { isLoading }] = useUpdateJobInHandMutation();
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const [form] = Form.useForm();
  const handleChange = (e: any) => {
    setSelectedValue(e.target.value);
  };
  const options = [
    { label: "completed", value: "completed" },
    { label: "Partially Completed", value: "partially_completed" },
  ];
  const handleComplete = async (value: any) => {
    // const formData = new FormData();

    // // Ensure values.document.fileList exists and has at least one file
    // if (value?.document?.fileList?.length > 0) {
    //   formData.append("document", value.document.fileList[0].originFileObj); // Append actual file object
    // }

    // value?.vendor_committed_on &&
    //   formData.append(
    //     "received_on",
    //     dayjs(value.received_on).format()
    //   );
    // value?.quote_price && formData.append("quote_price", value.quote_price);
    // selectedValue === "partial_completed"
    //   ? formData.append("job_status", "partially_completed")
    //   : formData.append("job_status", "completed");

    try {
      const response = await completeQuotation({
        id: Number(id),
        body: {
          ...value,
          job_status:
            selectedValue === "partially_completed"
              ? "partially_completed"
              : "completed",
        },
      }).unwrap(); // Send transformed data
      form.resetFields();
      //   navigate("/for-lining");
      setOpen(false);
      setDrawer(false);

      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message);
    }
  };
  return (
    <div>
      <Form layout="vertical" form={form} onFinish={handleComplete}>
        <div className="flex gap-2 mb-10">
          <img src="/assets/iButton.svg" />
          <Text variant="wearlay-subtitle" value="Update the Job Card Status" />
        </div>
        {jobType !== "internal" && (
          <Row>
            <Text variant="wearlay-label" value="Quoted Amount :" />
            <Text
              variant="wearlay-label-bold"
              value={quotedPrice?.toString()}
            />
          </Row>
        )}

        <Row>
          <Text
            variant="wearlay-label"
            value="Proposed Time for Completion :"
          />
          <Text
            variant="wearlay-label-bold"
            value={moment(commitedDate)?.format("DD/MM/YYYY")}
          />
        </Row>
        <Row className="mt-24">
          <Radio.Group onChange={handleChange} value={selectedValue}>
            {options.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </Row>
        {jobType !== "internal" && (
          <Form.Item
            name="amount"
            className="w-full mt-24"
            label="Amount Paid"
            rules={[
              {
                validator: validatePositiveNumber,
              },
            ]}
          >
            <Input placeholder="Enter" className="customInput w-full" />
          </Form.Item>
        )}
        <Form.Item
          name="received_on"
          required
          className="w-full mt-24"
          label="Actual Completed Date"
          rules={[
            {
              required: true,
              message: "Please select a date",
            },
          ]}
        >
          <DatePicker
            placeholder="Select"
            className="customPicker w-full"
            format={"DD/MM/YYYY"}
            disabledDate={disableFutureDates}
          />
        </Form.Item>
        <div className="flex gap-2 justify-end mt-24p">
          <Button
            className="wealay-secondary-btn"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>

          <CommonButton
            variant="wealay-primary-btn"
            value="Close Job Card"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
      </Form>
    </div>
  );
};

export default CompletedJob;
