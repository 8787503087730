import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { message } from "antd";
import {
  useActivateCategoryMutation,
  useDeleteCategoryMutation,
} from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import "./index.css";
interface ICreateSales {
  setOpen: (data: any) => void;
  drawerContent: string;
  id: number;
}

export const ActivateDeactivateModal: React.FC<ICreateSales> = ({
  setOpen,
  drawerContent,
  id,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const category_id = searchParams.get("category_id");
  const parent_id = searchParams.get("inventory_id");
  const sub_category_name = searchParams.get("sub_category_name");
  const sub_category_id = searchParams.get("sub_category_id");
  const confirmation = searchParams.get("confirmation");
  const method = searchParams.get("method");
  const [deactivateCategory, { isLoading: deactivateLoading }] =
    useDeleteCategoryMutation();
  const [activateCategory, { isLoading: activateLoading }] =
    useActivateCategoryMutation();
  const handleCancelModal = () => {
    // Initialize navigate function
    setOpen("");

    // Close the modal (assuming you have setIsModalOpen function)
  };
  const handleDeleteWarehouse = async (id: number | null) => {
    if (!id) {
      console.error("Warehouse ID is null");
      return; // Or show an error message
    }

    const numericId = Number(id);
    if (isNaN(numericId)) {
      console.error("Invalid Warehouse ID");
      return; // Handle invalid ID case
    }

    await deactivateCategory(numericId)
      .unwrap()
      .then((response) => {
        message.success(response.message);
        navigate(location.pathname + location.search, { replace: true });
        setOpen("");
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const handleActivateWarehouse = async (id: number | null) => {
    if (!id) {
      console.error("Warehouse ID is null");
      return; // Or show an error message
    }

    const numericId = Number(id);
    if (isNaN(numericId)) {
      console.error("Invalid Warehouse ID");
      return; // Handle invalid ID case
    }

    await activateCategory({
      id: numericId,
      body: { is_active: true, is_deleted: false },
    })
      .unwrap()
      .then((response) => {
        message.success(response.message);
        navigate(location.pathname + location.search, { replace: true });
        setOpen("");
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const handleSubmit = () => {
    if (drawerContent === "activate") {
      handleActivateWarehouse(id);
    } else {
      handleDeleteWarehouse(id);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 align-start">
        <img src="/assets/notify.svg"></img>
        <div className="confirmationtext">
          Are you sure you want to{" "}
          {drawerContent !== "activate" ? "Deactivate" : "Activate"}?
        </div>
      </div>
      <div className="confirmationtext2">
        By {drawerContent !== "activate" ? "deactivating" : "activating"}, this
        will also {drawerContent !== "activate" ? "deactivate" : "activate"} the
        subcategories.
      </div>
      <div className="flex justify-end gap-2">
        <CommonButton
          variant="wealay-secondary-btn"
          value="Cancel"
          onClick={() => {
            handleCancelModal();
            setOpen("");
          }}
        />
        <CommonButton
          variant="wealay-primary-btn"
          value={drawerContent !== "activate" ? "Deactivate" : "Activate"}
          onClick={() => {
            handleSubmit();
          }}
          isLoading={activateLoading || deactivateLoading}
          disabled={activateLoading || deactivateLoading}
        />
      </div>
    </div>
  );
};
