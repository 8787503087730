import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  Dropdown,
  message,
  Pagination,
  PaginationProps,
  Tag,
} from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { saveAs } from "file-saver";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CommonButton } from "../../../components/CommonButton/button";
import WarehouseFilterComponent from "../../../components/CommonFilter/warehouse";
import { Text } from "../../../components/CommonFont/text";
import { SearchBox } from "../../../components/CommonSearchBox";
import { CommonTable } from "../../../components/commonTable";

import moment from "moment";
import SpecimenTabPreview from "../../../components/Drawer/SpecimenPreview";
import { getContactsParams } from "../../../helpers/quarery";
import { WarehouseFormValues } from "../../../interface/warehouse";
import {
  useActivateSpecimensMutation,
  useDeleteSpecimensMutation,
  useGetAllSpecimenQuery,
  useGetExcelSpecimenByIdQuery,
} from "../../../services/api";

// import "./index.css";

function SampleSpecimens() {
  const [open, setOpen] = useState(false);
  const [warehouse_id, setWarehouseId] = useState("");
  const [isActive, setActive] = useState(false);
  // const [selectedValue, setSelectedValue] = useState("new_goods");

  const [drawerContent, setDrawerContent] = useState<
    "add" | "preview" | "update"
  >("add"); // To toggle between AddWarehouse and WarehousePreview
  const [selectedWarehouse, setSelectedWarehouse] = useState(null); // Store selected warehouse data
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState(false);
  const [downloadIds, setDownloadIds] = useState<any>();

  const id = searchParams.get("id");

  const search = searchParams.get("search");
  const status = searchParams.get("status");

  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 10,
  });
  const params = getContactsParams(pageData.page, pageData.pageSize);

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPageData({
      page: current,
      pageSize: pageSize,
    });
  };

  const getAllExecutiveParams = {
    page_size: params?.page_size,
    ...(search || status ? { page: params?.page } : { page: params?.page }),
    query: search || "", // Simplified ternary for `query`
    ...(status ? { active_status: status === "true" ? "True" : "False" } : {}),
  };

  // Pass the params explicitly inside an object
  const { data, isLoading, isFetching } = useGetAllSpecimenQuery(
    getAllExecutiveParams
  );
  useEffect(() => {
    if (search || status) {
      setPageData({
        page: 0,
        pageSize: 10,
      });
    }
  }, [status, search]);

  const [deactivateWarehouse, { isLoading: deactivatingLoading }] =
    useDeleteSpecimensMutation();
  const [activateWarehouse, { isLoading: activateLoading }] =
    useActivateSpecimensMutation();

  const showDrawer = () => {
    setOpen(true);
  };
  const [selectedUnit, setSelectedUnit] = useState<string | null>(null);

  // Handle unit selection change

  // Handle unit selection change
  const [selectedDimensions, setSelectedDimensions] = useState<
    Record<number, string>
  >({});
  const [selectedUnits, setSelectedUnits] = useState<Record<number, string>>(
    {}
  );
  // Handle unit selection change
  const handleUnitChange = (
    value: string,
    specKey: string,
    specName: string,
    specifications: any[],
    isInitial = false
  ) => {
    const selectedSpec = specifications.find(
      (spec: any) => spec.specification_name === specName
    );

    if (selectedSpec) {
      const correspondingValueField = selectedSpec.valueFields.find(
        (field: any) => field.unit === value
      );

      if (
        !correspondingValueField ||
        !correspondingValueField.dimension_value
      ) {
        setSelectedDimensions((prev) => ({
          ...prev,
          [specKey]: "No dimension available.",
        }));
      } else {
        setSelectedDimensions((prev) => ({
          ...prev,
          [specKey]: Array.isArray(correspondingValueField.dimension_value)
            ? correspondingValueField.dimension_value.join(", ") // Join array values with a separator
            : correspondingValueField.dimension_value,
        }));
      }

      if (!isInitial) {
        setSelectedUnits((prev) => ({
          ...prev,
          [specKey]: value,
        }));
      }
    }
  };

  // useEffect(() => {
  //   data?.results?.data.forEach((record: any, recordIndex: number) => {
  //     if (record.specification && record.specification.length > 0) {
  //       record.specification.forEach((spec: any, specIndex: number) => {
  //         if (spec.unitOptions && spec.unitOptions.length > 0) {
  //           const firstUnit = spec.unitOptions[0].value;
  //           handleUnitChange(
  //             firstUnit,
  //             `${recordIndex}-${specIndex}`,
  //             spec.specification_name,
  //             record.specification,
  //             true
  //           );
  //         }
  //       });
  //     }
  //   });
  // }, [data]);

  const onClose = () => {
    setOpen(false);
    setSelectedWarehouse(null); // Reset selected warehouse when closing
    navigate(`/samples-specimens`);
  };
  // const [selectedDimension, setSelectedDimension] = useState<string>("");
  const [showAll, setShowAll] = useState(false); // State to track whether to show all items

  const columns = [
    {
      title: "Specimen Id",
      dataIndex: "specimen_id",
      // sorter: (a: any, b: any) => a.company_name.localeCompare(b.company_name),
      ellipsis: true,
      render: (text: string, record: any) => (
        <span
          className="cursor-pointer text-blue-500"
          onClick={() => {
            handleRowClick(record);
            navigate(`/samples-specimens?id=${record.id}`); // Open the drawer in preview mode
          }}
        >
          {text}
        </span>
      ),
      fixed: "left",
      width: 200,
    },
    {
      title: "Product Name",
      dataIndex: "name",
      width: 200,
      fixed: "left",

      ellipsis: true,
      render: (text: string, record: any) => (
        <span
          className="cursor-pointer text-blue-500"
          onClick={() => {
            handleRowClick(record);

            navigate(`/samples-specimens?id=${record.id}`); // Open the drawer in preview mode
          }}
        >
          {record?.name?.name}
        </span>
      ),
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      width: 200,

      ellipsis: true,
      render: (text: any, record: any) => (
        <div>
          {record?.manufacturer?.name ? record?.manufacturer?.name : "-"}
        </div>
      ),
    },
    {
      title: "Category",
      dataIndex: "phone",
      render: (text: any, record: any) => (
        <div>
          {record?.category?.name?.name ? record?.category?.name?.name : "-"}
        </div>
      ),
      ellipsis: true,
      width: 200,
    },

    {
      title: "Specification",
      dataIndex: "email", // You can change this to another dataIndex if needed
      ellipsis: true,
      width: 150,
      render: (text: any, record: any, recordIndex: number) => {
        // State to track whether to show all items

        const handleViewAllClick = () => {
          setShowAll(true); // Show all items when the button is clicked
        };

        // Ensure record.specification is an array before calling .slice
        const specifications = Array.isArray(record.specification)
          ? record.specification
          : [];

        // Limit the displayed specifications based on `showAll`
        const displayedSpecifications = showAll
          ? specifications
          : specifications.slice(0, 2);

        return (
          <div className="flex flex-col">
            {specifications.length > 0 ? (
              <>
                {displayedSpecifications.map((spec: any, specIndex: number) => {
                  const specKey: any = `${recordIndex}-${specIndex}`;
                  return (
                    <div key={specKey} className="flex flex-col mb-2">
                      {/* Display selected dimension */}
                      <div className="flex gap-2">
                        {selectedDimensions[specKey] ? (
                          <div style={{ marginBottom: "5px" }}>
                            <span>Dimension:</span>{" "}
                            <strong>{selectedDimensions[specKey]}</strong>
                          </div>
                        ) : (
                          <div style={{ marginBottom: "5px" }}>
                            <span>Dimension:</span>{" "}
                            {spec?.valueFields
                              ?.flatMap((item: any) => item?.dimension_value) // Flatten all dimension_value arrays
                              ?.map(
                                (
                                  dimension: any,
                                  index: number,
                                  array: string[]
                                ) => (
                                  <strong key={index}>
                                    {dimension}
                                    {index !== array.length - 1 && ", "}
                                  </strong>
                                )
                              )}
                          </div>
                        )}

                        {/* Dimensions */}
                        {/* {spec.unitOptions?.length > 0 &&
                          spec.valueFields
                            ?.slice(0, 1)
                            .map((field: any, fieldIndex: number) => (
                              <div key={fieldIndex} className="flex mb-2">
                                <Select
                                  style={{ width: 70 }}
                                  placeholder={`Select ${spec.specification_name} unit`}
                                  value={
                                    selectedUnits[specKey] ||
                                    spec.unitOptions[0]?.value
                                  }
                                  onChange={(value) =>
                                    handleUnitChange(
                                      value,
                                      specKey,
                                      spec.specification_name,
                                      record.specification
                                    )
                                  }
                                  options={spec.unitOptions.map(
                                    (unit: any) => ({
                                      label: unit.label,
                                      value: unit.value,
                                    })
                                  )}
                                />
                              </div>
                            ))} */}
                        {spec.unitOptions?.length > 0 && (
                          <strong>
                            {}
                            {
                              spec.unitOptions.find(
                                (option: any) =>
                                  option.value === spec.valueFields[0]?.unit
                              )?.label
                            }
                          </strong>
                        )}
                      </div>
                      {/* Specification Name */}
                      <div className="flex">
                        <div>Specification Name: </div>
                        <strong className="pl-5">
                          {spec.specification_name}
                        </strong>
                      </div>
                    </div>
                  );
                })}

                {/* Show View All button if there are more than 2 items */}
                {!showAll && specifications.length > 2 && (
                  <div
                    onClick={() => {
                      navigate(`/samples-specimens?id=${record?.id}`);
                      handleRowClick(record);
                    }}
                    className="mt-2 text-blue-500 hover:underline view-all cursor-pointer"
                  >
                    View All
                  </div>
                )}
              </>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Grade",
      dataIndex: "quantity",
      ellipsis: true,
      render: (text: any, record: any) => (
        <div>{record?.grade?.name ? record?.grade?.name : "-"}</div>
      ),
      width: 200,
    },
    {
      title: "Sample Size/Qty",
      dataIndex: "quantity",

      ellipsis: true,
      width: 200,
    },
    {
      title: "Purpose",
      dataIndex: "quantity",
      ellipsis: true,
      render: (text: any, record: any) => (
        <div>{record?.purpose?.name ? record?.purpose?.name : "-"}</div>
      ),
    },
    {
      title: "Requested On",
      dataIndex: "requested_on",
      ellipsis: true,
      render: (text: any) => <div>{moment(text).format("DD/MM/YYYY")}</div>,
      width: 200,
    },
    {
      title: "Received On",
      dataIndex: "received_on",
      render: (text: any) => <div>{moment(text).format("DD/MM/YYYY")}</div>,
      ellipsis: true,
      width: 200,
    },
    {
      title: "Expiry Period",
      dataIndex: "expiry_date",
      ellipsis: true,
      render: (text: any) => (
        <div>{text ? moment(text).format("DD/MM/YYYY") : "-"}</div>
      ),
      width: 200,
    },

    {
      title: "Active Status",
      dataIndex: "is_active",
      ellipsis: true,
      render: (text: boolean) => (
        <div>
          {text === false ? (
            <Tag color="red" icon={<CloseCircleOutlined />}>
              Inactive
            </Tag>
          ) : (
            <Tag color="green" icon={<CheckOutlined />}>
              Active
            </Tag>
          )}
        </div>
      ),
    },
    {
      fixed: "right",
      title: "Actions",
      dataIndex: "industry_name",
      ellipsis: true,
      render: (_: unknown, record: WarehouseFormValues) => {
        // Dynamically define dropdown items based on status
        const items: ItemType[] = [
          record.is_active
            ? {
                key: "1",
                label: (
                  <Button
                    type="ghost"
                    onClick={() => {
                      setOpen(true);
                      setDrawerContent("update");
                    }}
                  >
                    Update
                  </Button>
                ),
              }
            : null,
          {
            key: "2",
            label: (
              <span>
                {" "}
                {record.is_active ? (
                  <Button
                    type="ghost"
                    disabled={deactivatingLoading}
                    loading={deactivatingLoading}
                  >
                    Deactivate
                  </Button>
                ) : (
                  <Button
                    type="ghost"
                    disabled={activateLoading}
                    loading={activateLoading}
                  >
                    Activate
                  </Button>
                )}
              </span>
            ),
          },
          (localStorage.getItem("user_role") === "3" ||
            localStorage.getItem("user_role") === "4") &&
          record?.is_active
            ? {
                key: "3",
                label: (
                  <div>
                    {" "}
                    <Button
                      type="ghost"
                      onClick={() => {
                        // Open the drawer in preview mode
                        handleRowClick(record);
                      }}
                    >
                      Add Log Entry
                    </Button>
                  </div>
                ),
              }
            : null,
        ].filter(Boolean); // Removes null or undefined values safely

        const handleDeleteWarehouse = async (id: number | null) => {
          if (!id) {
            console.error("Warehouse ID is null");
            return; // Or show an error message
          }

          const numericId = Number(id);
          if (isNaN(numericId)) {
            console.error("Invalid Warehouse ID");
            return; // Handle invalid ID case
          }

          await deactivateWarehouse(numericId)
            .unwrap()
            .then((response) => {
              message.success(response.message);
              navigate("/samples-specimens");
            })
            .catch((error) => {
              message.error(error.message);
            });
        };

        const handleActivateWarehouse = async (id: number | null) => {
          if (!id) {
            console.error("Warehouse ID is null");
            return; // Or show an error message
          }

          const numericId = Number(id);
          if (isNaN(numericId)) {
            console.error("Invalid Warehouse ID");
            return; // Handle invalid ID case
          }

          await activateWarehouse({
            id: numericId,
            body: { is_active: true, is_deleted: false },
          })
            .unwrap()
            .then((response) => {
              message.success(response.message);
              navigate("/samples-specimens");
            })
            .catch((error) => {
              message.error(error.message);
            });
        };

        return (
          <Dropdown
            menu={{
              items,
              onClick: (info) => {
                if (info.key === "1") {
                  setOpen(true);
                  // setDrawerContent("update");
                  navigate(`/add-sample-specimen?id=${record.id}`); // Open the drawer in preview mode
                } else if (info.key === "2") {
                  // Handle Activate/Deactivate logic
                  if (!record.is_active) {
                    handleActivateWarehouse(Number(record.id));
                    navigate(`/samples-specimens?id=${record.id}`); // Open the drawer in preview mode
                  } else {
                    handleDeleteWarehouse(Number(record.id));
                    navigate(`/samples-specimens?id=${record.id}`); // Open the drawer in preview mode
                  }
                } else if (info.key === "3") {
                  navigate(`/samples-specimens?id=${record.id}&log=true`);
                }
              },
            }}
            placement="bottomLeft"
            trigger={["click"]}
          >
            <img
              className="cursor-pointer"
              src="/assets/dots.svg"
              onClick={(e) => e.stopPropagation()} // Prevent row click on dots
            />
          </Dropdown>
        );
      },
    },
  ];

  const handleRowClick = (record: any) => {
    setSelectedWarehouse(record); // Set selected warehouse details
    setDrawerContent("preview"); // Open the drawer in preview mode
    setOpen(true);
    setWarehouseId(record?.vendor_id);
  };
  const findIndexForObject = (array: [], object: any) => {
    return _.findIndex(array, object);
  };

  const targetObject = { id: Number(id) };
  const index = findIndexForObject(data?.results?.data as [], targetObject);

  const [currentIndex, setCurrentIndex] = useState(index);

  const getObjectAtIndex = (array: [], index: number) => {
    const object = _.get(array, index);
    return object;
  };

  // useEffect(() => {
  //   setCurrentIndex(index);
  // }, [index]);
  // useEffect(() => {
  //   if (currentIndex > -1) {
  //     const updatedObject: any = getObjectAtIndex(
  //       data?.results?.data as [],
  //       currentIndex
  //     );

  //     navigate(`/assets?id=${updatedObject?.id}`);
  //   }
  // }, [currentIndex]);

  const handleNext = () => {
    if (currentIndex < (data?.results?.data?.length as number) - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];
  const idArray = _.map(data?.results?.data, "id");

  const { data: downloadedData, isLoading: downloadLoading } =
    useGetExcelSpecimenByIdQuery(
      downloadIds?.length > 0 ? downloadIds : idArray
    );
  type DownloadedData = Blob | string; // Update this type to match your data structure

  const handleDownload = () => {
    if (downloadedData) {
      if (
        typeof downloadedData === "string" ||
        downloadedData instanceof Blob
      ) {
        saveAs(downloadedData, "specimen-export.xlsx");
      } else {
        message.error("Error: Invalid data format for download.");
      }
    } else {
      message.error("Error: Downloaded data is undefined.");
    }
  };

  const handleChange = (e: any) => {
    setPageData({
      page: 0,
      pageSize: 10,
    });
  };

  return (
    <div className="mt-24">
      <div className="flex justify-between items-center">
        <div className="sales-title">
          <Text value="Specimens/Samples" variant="wearlay-display" />
        </div>
        <CommonButton
          variant="wealay-primary-btn"
          value="+ Add New Sample"
          className="primary-btn "
          onClick={() => {
            navigate(`/add-sample-specimen`);
          }}
        />
      </div>
      <div className="p-10 mt-24p">
        <div className="flex justify-between">
          <div className="flex gap-2 items-center">
            <Text
              value="Specimens/Samples List"
              variant="wearlay-headline-2"
              className="p-1"
            />
          </div>
          <div className="flex items-center h-full gap-2">
            <div className="search-resp">
              <SearchBox placeholder="Search" />
            </div>

            <div className="relative">
              <CommonButton
                variant="wearlay-with-icon"
                icon={filter ? "/assets/close.svg" : "/assets/filter.svg"}
                onClick={() => {
                  setFilter(!filter);
                }}
                className="download-btn-color"
              />
              {filter && (
                <WarehouseFilterComponent
                  title="Status"
                  options={statusOptions}
                  queryParam="status" // This will append "status=true" or "status=false" to the URL
                />
              )}
            </div>
            <CommonButton
              variant="wearlay-with-icon"
              icon={
                data?.results?.data.length === 0
                  ? "/assets/disabledDownload.svg"
                  : "/assets/download.svg"
              }
              onClick={() => {
                handleDownload();
              }}
              disabled={data?.results?.data.length === 0}
            />
          </div>
        </div>
        <div className="resp-search">
          <SearchBox placeholder="Search" />
        </div>
        <Divider className="commonDidider" />

        <div className="resp-table">
          <CommonTable
            datasource={data?.results?.data}
            coloumn={columns}
            isLoading={isLoading || isFetching}
            setDownloadIds={setDownloadIds}
          />
        </div>
        <div className="flex h-full items-end justify-end">
          {(data?.count as number) > 0 && (
            <Pagination
              current={pageData.page === 0 ? 1 : pageData.page}
              onChange={onPaginationChange}
              total={data?.count}
              pageSize={pageData?.pageSize}
              defaultCurrent={1}
              responsive={true}
              simple
              // onShowSizeChange={onShowSizeChange}
              // showSizeChanger={(data?.count as number) > 10 ? true : false}
            />
          )}
        </div>
      </div>

      <Drawer
        title={
          <div className="flex justify-between items-center">
            <div className="flex gap-2">
              <Text value=" Preview" variant="wearlay-subtitle" />

              {drawerContent === "preview" && (
                <Tag color="var(--primary)" className="warehouse-tag">
                  {warehouse_id}
                </Tag>
              )}
            </div>
            {drawerContent === "preview" && (
              <div className="flex gap-2">
                {isActive && (
                  <img
                    src="/assets/warehouseEdit.svg"
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(`/add-sample-specimen?id=${id}`);
                      setDrawerContent("update");
                    }}
                  />
                )}
              </div>
            )}
          </div>
        }
        placement="right"
        onClose={onClose}
        open={open}
        width="500"
        destroyOnClose
      >
        <SpecimenTabPreview setId={setWarehouseId} setActive={setActive} />
      </Drawer>
    </div>
  );
}

export default SampleSpecimens;
