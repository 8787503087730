import { Col, message, Modal, Row, Skeleton, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { concatTabTextFunction } from "../../../helpers/helperFunction";
import { ForLiningInventoryItem2 } from "../../../interface/forLining";
import { useApproveForLiningMutation } from "../../../services/api";
import { CommonButton } from "../../CommonButton/button";
import { AssignQuoteModal } from "../../Modals/assignQuote";
import { LiningCompletionModal } from "../../Modals/ForLiningCompletionDate";
import { QuatedPriceModal } from "../../Modals/QuotedPrice";
// import "./index.css";
interface ICreateSales {
  setId: (data: string) => void;
  setActive: (data: boolean) => void;
  data: ForLiningInventoryItem2;
  isLoading: boolean;
  setOpenDrawer: (data: boolean) => void;
}
export const ForLiningDetailPreview: React.FC<ICreateSales> = ({
  setId,
  setActive,
  data,
  isLoading,
  setOpenDrawer,
}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const [open, isOpen] = useState(false);
  const [assignOpen, isAssignOpen] = useState(false);
  const [liningStatus, isLiningStatus] = useState(false);

  const ratingData = [
    "Cost",
    "Quality",
    "Credit terms",
    "Reliability",
    "Logistic chain",
    "Communication",
  ];

  useEffect(() => {
    if (id) {
      setId(data?.job_id as string);
      setActive(data?.is_active as boolean);
    }
  }, [id, data?.job_id]);
  const [approveQutation, { isLoading: approveLoading }] =
    useApproveForLiningMutation();
  const handleRejected = async () => {
    try {
      const response = await approveQutation({
        id: Number(id),
        body: { quote_status: "rejected" },
      }).unwrap(); // Send transformed data
      //   form.resetFields();
      //   navigate("/for-lining");
      setOpenDrawer(false);

      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message);
    }
  };
  const handleClosed = async () => {
    try {
      const response = await approveQutation({
        id: Number(id),
        body: { quote_status: "closed" },
      }).unwrap(); // Send transformed data
      //   form.resetFields();
      //   navigate("/for-lining");
      setOpenDrawer(false);

      message.success(response?.message);
    } catch (err: any) {
      message.error(err?.data?.message);
    }
  };
  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <div className="sales-detail-border-main ">
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Job Name</div>
                <div className="sales-detail-sub-title common-work-break">
                  {data?.name?.name}
                </div>
              </div>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Category</div>
                <div className="sales-detail-sub-title common-work-break">
                  <div className="flex gap-2">
                    {data?.category?.breadcrumb &&
                      data?.category?.breadcrumb?.map((item: any, index) => (
                        <div className="flex gap-2">
                          <span>{item}</span>
                          {data?.category?.breadcrumb &&
                            index !== data.category.breadcrumb.length - 1 && (
                              <img
                                src="/assets/rightArrow.svg"
                                alt="Right Arrow"
                              />
                            )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Row>

            <div>
              <Row>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">Job Type</div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.type
                        ? data?.type === "internal"
                          ? "Internal"
                          : "Outsource Vendor"
                        : "-"}
                    </div>
                  </div>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">Recieved On</div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.received_on
                        ? moment(data.received_on).format("DD-MM-YYYY")
                        : "-"}
                    </div>
                  </div>
                </Col>
                <Col span={24} md={{ span: 12 }}>
                  <div className="sales-detail-border flex flex-col gap-2">
                    <div className="sales-detail-placeholder">Client Name</div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.client?.name ? data?.client?.name : "-"}
                    </div>
                  </div>
                  <div className=" flex flex-col gap-2 overallrating sales-detail-border">
                    <div className="sales-detail-placeholder">
                      Committed Date
                    </div>
                    <div className="sales-detail-sub-title common-work-break">
                      {data?.committed_on
                        ? moment(data.committed_on).format("DD-MM-YYYY")
                        : "-"}
                    </div>
                  </div>
                </Col>
              </Row>

              {data?.type !== "internal" && (
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Vendor Name
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.vendor?.name?.name
                          ? data?.vendor?.name?.name
                          : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <div className="sales-detail-border flex flex-col gap-2">
                      <div className="sales-detail-placeholder">
                        Quote Issue Date
                      </div>
                      <div className="sales-detail-sub-title common-work-break">
                        {data?.quote_issue_on
                          ? moment(data.quote_issue_on).format("DD-MM-YYYY")
                          : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <Row className="w-full mt-24p">
            <div className="sales-detail-border flex flex-col gap-2 w-full">
              <div className="sales-detail-placeholder">Remarks</div>
              <div className="sales-detail-sub-title  common-work-break">
                {data?.remarks ? data?.remarks : "-"}
              </div>
            </div>
          </Row>
          {data?.document && (
            <div>
              <div className="flex justify-between cancel-pdf items-center mt-24">
                <div className="flex gap-2">
                  <img src="/assets/file.svg"></img>
                  <div className="flex flex-col">
                    <div>
                      <Tooltip title={data?.document_name}>
                        {concatTabTextFunction(data?.document_name, 10)}
                      </Tooltip>
                    </div>
                    <div className="textHash">
                      {data?.document_size} .{" "}
                      {moment(data?.modified_date).format("DD-MMM-YYYY")}{" "}
                    </div>
                  </div>
                </div>

                <a href={data?.document}>
                  <img
                    src="/assets/download.svg"
                    className="file-delete "
                  ></img>
                </a>
              </div>
            </div>
          )}
          {data?.specification?.length > 0 && (
            <div>
              <Row className="mt-24p">
                <Col span={8} className="sales-detail-border">
                  <b>Specification</b>
                </Col>
                <Col span={8} className="sales-detail-border">
                  <b>Dimension</b>
                </Col>
                <Col span={8} className="sales-detail-border">
                  <b>Unit</b>
                </Col>
              </Row>

              {data?.specification?.map(
                (specification: any, specIndex: number) => (
                  <Row key={specIndex}>
                    {/* Material (Specification Name) */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.specification_name}
                    </Col>

                    {/* Dimension */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.valueFields?.map(
                        (field: any, fieldIndex: number) => (
                          <div key={fieldIndex}>
                            {field?.dimension_value?.join(", ") || "N/A"}
                          </div>
                        )
                      )}
                    </Col>

                    {/* Unit */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.valueFields?.map(
                        (field: any, fieldIndex: number) => {
                          const unitName = specification?.unitOptions?.find(
                            (option: any) => option.value === field.unit
                          )?.label;

                          return <div key={fieldIndex}>{unitName || "-"}</div>;
                        }
                      )}
                    </Col>
                  </Row>
                )
              )}
            </div>
          )}
          <Row className="w-full mt-24p">
            <div className="sales-detail-border flex flex-col gap-2 w-full">
              <div className="sales-detail-placeholder">
                Additional Specification
              </div>
              <div className="sales-detail-sub-title  common-work-break">
                {data?.remarks ? data?.remarks : "-"}
              </div>
            </div>
          </Row>
          {data?.quote_status !== "approved" &&
          data?.quote_status !== "rejected" &&
          data?.quote_status !== "closed" &&
          data?.type !== "internal" ? (
            <div className="flex gap-2 justify-end mt-24p">
              {!["rejected", "closed"].includes(data?.quote_status) && (
                <CommonButton
                  variant="wealay-secondary-btn"
                  value="Reject Quote"
                  onClick={handleRejected}
                />
              )}

              <CommonButton
                variant="wealay-primary-btn"
                value="Approve Quote"
                onClick={() => {
                  isOpen(true);
                }}
              />
            </div>
          ) : (
            data?.job_card?.job_status !== "closed" &&
            data?.job_card?.job_status !== "opened" &&
            data?.job_card?.job_status !== "completed" &&
            data?.job_card?.job_status !== "partially_completed" &&
            (data?.quote_status === "approved" ||
              data?.quote_status === "closed" ||
              data?.quote_status === "rejected") &&
            data?.job_card?.job_status !== "opened" &&
            data?.type !== "internal" && (
              <div className="flex gap-2 justify-end mt-24p">
                {!["rejected", "closed"].includes(data?.quote_status) && (
                  <CommonButton
                    variant="wealay-secondary-btn"
                    value="Close Quote"
                    onClick={handleClosed}
                    isLoading={approveLoading}
                    disabled={approveLoading}
                  />
                )}

                <CommonButton
                  variant="wealay-primary-btn"
                  value={
                    ["rejected", "closed"].includes(data?.quote_status)
                      ? "Re-assign Work"
                      : "Assign"
                  }
                  onClick={() => {
                    isAssignOpen(true);
                  }}
                  isLoading={approveLoading}
                  disabled={approveLoading}
                />
              </div>
            )
          )}
        </div>
      )}
      {(data?.job_card?.job_status === "completed" ||
        data?.job_card?.job_status === "partially_completed") &&
        !data?.lining_completed_on && (
          <Row justify={"end"} className="mt-24">
            <CommonButton
              variant="wealay-primary-btn"
              value={"Update Lining Status"}
              onClick={() => {
                isLiningStatus(true);
              }}
            />
          </Row>
        )}

      <Modal open={open} footer={false} closable={false} destroyOnClose>
        <QuatedPriceModal setOpen={isOpen} />
      </Modal>
      <Modal open={liningStatus} footer={false} closable={false} destroyOnClose>
        <LiningCompletionModal
          setOpen={isLiningStatus}
          setDrawer={setOpenDrawer}
        />
      </Modal>
      <Modal open={assignOpen} footer={false} closable={false} destroyOnClose>
        <AssignQuoteModal
          setOpen={isAssignOpen}
          isReassign={
            ["rejected", "closed"].includes(data?.quote_status) ? true : false
          }
          setDrawer={setOpenDrawer}
        />
      </Modal>
    </>
  );
};

export default ForLiningDetailPreview;
