import { Col, Row, Skeleton } from "antd";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetWarehouseByIdQuery } from "../../../services/api";

interface ICreateSales {
  setId: (data: string) => void;
  setActive: (data: boolean) => void;
}
export const WarehousePreview: React.FC<ICreateSales> = ({
  setId,
  setActive,
}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { data, isLoading } = useGetWarehouseByIdQuery(Number(id), {
    skip: Number(id) === 0, // Skip the query when id is 0
  });
  useEffect(() => {
    if (id) {
      setId(data?.data?.warehouse_id as string);
      setActive(data?.data?.is_active as boolean);
    }
  }, [id, data?.data?.warehouse_id]);
  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div className="sales-detail-border-main ">
          <Row className="w-full">
            <div className="sales-detail-border flex flex-col gap-2 w-full">
              <div className="sales-detail-placeholder">Warehouse Name</div>
              <div className="sales-detail-sub-title common-work-break ">
                {data?.data?.name?.name}
              </div>
            </div>
          </Row>
          <Row className="w-full">
            <div className="sales-detail-border flex flex-col gap-2 w-full">
              <div className="sales-detail-placeholder">Address</div>
              <div className="sales-detail-sub-title common-work-break ">
                {data?.data?.address}
              </div>
            </div>
          </Row>
          <Row>
            <Col span={24} md={{ span: 12 }}>
              <div className="sales-detail-border flex flex-col gap-2">
                <div className="sales-detail-placeholder">Location</div>
                <div className="sales-detail-sub-title common-work-break ">
                  {data?.data?.location}
                </div>
              </div>
              <div className="sales-detail-border flex flex-col gap-2">
                <div className="sales-detail-placeholder">Contact Number</div>
                <div className="sales-detail-sub-title common-work-break ">
                  {data?.data?.phone}
                </div>
              </div>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <div className="sales-detail-border flex flex-col gap-2">
                <div className="sales-detail-placeholder">Contact Person</div>
                <div className="sales-detail-sub-title common-work-break ">
                  {data?.data?.contact_person}
                </div>
              </div>
              <div className="sales-detail-border flex flex-col gap-2">
                <div className="sales-detail-placeholder">Email Id</div>
                <div className="sales-detail-sub-title common-work-break ">
                  {data?.data?.email}
                </div>
              </div>
            </Col>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Remarks</div>
                <div className="sales-detail-sub-title common-work-break ">
                  {data?.data?.remarks ? data?.data?.remarks : "-"}
                </div>
              </div>
            </Row>
          </Row>
        </div>
      )}
    </>
  );
};

export default WarehousePreview;
