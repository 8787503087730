import { Col, Row, Skeleton } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RejectedProductsDataType2 } from "../../../interface/rejectedProducts";

interface ICreateSales {
  setId: (data: string) => void;
  setActive: (data: boolean) => void;
  data: RejectedProductsDataType2;
  isLoading: boolean;
}
export const RejectedProductPreview: React.FC<ICreateSales> = ({
  setId,
  setActive,
  data,
  isLoading,
}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const ratingData = [
    "Cost",
    "Quality",
    "Credit terms",
    "Reliability",
    "Logistic chain",
    "Communication",
  ];

  useEffect(() => {
    if (id) {
      setId(data?.reject_product_id as string);
      setActive(data?.is_active as boolean);
    }
  }, [id, data?.reject_product_id]);

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <div className="sales-detail-border-main ">
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Item Name</div>
                <div className="sales-detail-sub-title common-work-break">
                  {data?.name?.name}
                </div>
              </div>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Category</div>
                <div className="sales-detail-sub-title common-work-break">
                  <div className="flex gap-2">
                    {data?.category?.breadcrumb &&
                      data?.category?.breadcrumb?.map((item: any, index) => (
                        <div className="flex gap-2">
                          <span>{item}</span>
                          {data?.category?.breadcrumb &&
                            index !== data.category.breadcrumb.length - 1 && (
                              <img
                                src="/assets/rightArrow.svg"
                                alt="Right Arrow"
                              />
                            )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Color</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.color?.name ? data?.color?.name : "-"}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Days Performed</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.days_performed ? data?.days_performed : "-"}
                  </div>
                </div>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <div className=" flex flex-col gap-2 sales-detail-border  ">
                  <div className="sales-detail-placeholder">Quantity</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.quantity ? data?.quantity : "-"}
                  </div>
                </div>
                <div className=" flex flex-col gap-2 sales-detail-border  ">
                  <div className="sales-detail-placeholder">Sold On</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {moment(data?.sold_on).format("DD-MM-YYYY")}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Sales Person</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.sales_person?.name ? data?.sales_person?.name : "-"}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">
                    Item Received On
                  </div>
                  <div className="sales-detail-sub-title common-work-break">
                    {moment(data?.item_received_on).format("DD-MM-YYYY")}
                  </div>
                </div>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <div className=" flex flex-col gap-2 sales-detail-border  ">
                  <div className="sales-detail-placeholder">
                    Issue Reported On
                  </div>
                  <div className="sales-detail-sub-title common-work-break">
                    {moment(data?.issue_reported_on).format("DD-MM-YYYY")}
                  </div>
                </div>
                <div className=" flex flex-col gap-2 sales-detail-border  ">
                  <div className="sales-detail-placeholder">
                    Production Date
                  </div>
                  <div className="sales-detail-sub-title common-work-break">
                    {moment(data?.production_date).format("DD-MM-YYYY")}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Shift</div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.shift?.name ? data?.shift?.name : "-"}
                  </div>
                </div>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <div className=" flex flex-col gap-2 sales-detail-border  ">
                  <div className="sales-detail-placeholder">
                    Shift In Charge
                  </div>
                  <div className="sales-detail-sub-title common-work-break">
                    {data?.shift_incharge?.name
                      ? data?.shift_incharge?.name
                      : "-"}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">
                  Row Material Code
                </div>
                <div className="sales-detail-sub-title  common-work-break">
                  {data?.material_code?.name ? data?.material_code?.name : "-"}
                </div>
              </div>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">
                  Reason for Rejection
                </div>
                <div className="sales-detail-sub-title  common-work-break">
                  {data?.reason ? data?.reason : "-"}
                </div>
              </div>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Conclusion</div>
                <div className="sales-detail-sub-title  common-work-break">
                  {data?.conclusion ? data?.conclusion : "-"}
                </div>
              </div>
            </Row>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">
                  Suggestion to Reduce Rejection
                </div>
                <div className="sales-detail-sub-title  common-work-break">
                  {data?.suggestion ? data?.suggestion : "-"}
                </div>
              </div>
            </Row>
          </div>
          {data?.specification?.length > 0 && (
            <div>
              <Row className="mt-24p">
                <Col span={8} className="sales-detail-border">
                  <b>Specification</b>
                </Col>
                <Col span={8} className="sales-detail-border">
                  <b>Dimension</b>
                </Col>
                <Col span={8} className="sales-detail-border">
                  <b>Unit</b>
                </Col>
              </Row>

              {data?.specification?.map(
                (specification: any, specIndex: number) => (
                  <Row key={specIndex}>
                    {/* Material (Specification Name) */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.specification_name}
                    </Col>

                    {/* Dimension */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.valueFields?.map(
                        (field: any, fieldIndex: number) => (
                          <div key={fieldIndex}>
                            {field?.dimension_value?.join(", ") || "N/A"}
                          </div>
                        )
                      )}
                    </Col>

                    {/* Unit */}
                    <Col span={8} className="sales-detail-border">
                      {specification?.valueFields?.map(
                        (field: any, fieldIndex: number) => {
                          const unitName = specification?.unitOptions?.find(
                            (option: any) => option.value === field.unit
                          )?.label;

                          return <div key={fieldIndex}>{unitName || "-"}</div>;
                        }
                      )}
                    </Col>
                  </Row>
                )
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RejectedProductPreview;
