import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../src/antd.override.css";
import "./App.css";
import { ErrorHandler } from "./components/Errorhandler";
import { PrivateRoute } from "./helpers/privateRoute";
import { PublicRoute } from "./helpers/publicRoute";
import "./index.css";
import AddAssets from "./Pages/AddAssets";
import AddConsumable from "./Pages/AddConsumable";
import AddJobCard from "./Pages/AddJob";
import AddMould from "./Pages/AddMould";
import AddCategory from "./Pages/AddNewCategory";
import AddNewGoods from "./Pages/AddNewGoods";
import { CreateNewLead } from "./Pages/AddNewLead";
import AddNewLining from "./Pages/AddNewLining";
import AddRawMaterials from "./Pages/AddRawMaterials";
import AddRejectedProducts from "./Pages/AddRejectProducts";
import AddSpecimens from "./Pages/AddSpecimens";
import AddUnclassified from "./Pages/AddUnclassified";
import { Allocated } from "./Pages/Allocated";
import { Contacts } from "./Pages/Contacts";
import { CreateQuotion } from "./Pages/CreateQuotation";
import InventryDashboard from "./Pages/Dashboard";
import { Employee } from "./Pages/Employee";
import { EmployeeDetailPage } from "./Pages/Employee/detailPage";
import { ForgotPasswordPage } from "./Pages/forgotPassword";
import { Inquiries } from "./Pages/Inquiries";
import Assets from "./Pages/Inventory/Assets";
import Consumables from "./Pages/Inventory/consumables";
import FinishedGoods from "./Pages/Inventory/FinishedGoods";
import ForLining from "./Pages/Inventory/ForLining";
import JobCard from "./Pages/Inventory/JobCard";
import Molds from "./Pages/Inventory/molds";
import RawMaterials from "./Pages/Inventory/RawMaterials";
import RejectedMaterials from "./Pages/Inventory/RejectedProducts";
import SampleSpecimens from "./Pages/Inventory/SampleSpecimens";
import Unclassified from "./Pages/Inventory/Unclassified";
import CategoryManagement from "./Pages/InventryCnrl/Category";
import VendorManagement from "./Pages/InventryCnrl/vendor";
import WarehouseManagement from "./Pages/InventryCnrl/Warehouse";
import { InventoryEmployee } from "./Pages/InventryEmployee";
import { InventoryEmployeeDetailPage } from "./Pages/InventryEmployee/detailPage";
import { LoginPage } from "./Pages/Login";
import { Notification } from "./Pages/Notificarion";
import { Orders } from "./Pages/Orders";
import { Overview } from "./Pages/overview";
import { PageLayout } from "./Pages/PageLayout";
import { Profile } from "./Pages/Profile";
import { Quotation } from "./Pages/Quatations";
import { ResetPassword } from "./Pages/ResetPassword";
import { Sales } from "./Pages/Sales";
import { PathConstants } from "./pathConstants/pathConstants";
import "./variables.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            index
            path={PathConstants.LOGIN}
            element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            }
          />
          <Route
            path={PathConstants.FORGOT_PASSWORD}
            element={
              <PublicRoute>
                <ForgotPasswordPage />
              </PublicRoute>
            }
          />
          <Route
            path={PathConstants.RESET_PASSWORD}
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path={PathConstants.HOME}
            element={
              <PrivateRoute>
                <PageLayout />
              </PrivateRoute>
            }
          >
            <Route element={<Overview />} />
            <Route path={PathConstants.OVERVIEW} element={<Overview />} />
            <Route
              path={PathConstants.OVERVIEW_INVENTORY}
              element={<InventryDashboard />}
            />

            <Route path={PathConstants.SALES} element={<Sales />} />
            <Route path={PathConstants.SALES_DETAILS} element={<Sales />} />
            <Route path={PathConstants.QUOTATION} element={<Quotation />} />
            <Route
              path={PathConstants.DETAIL_QUOTATION}
              element={<Quotation />}
            />
            <Route path={PathConstants.DETAIL_ORDER} element={<Orders />} />
            <Route path={PathConstants.ORDER} element={<Orders />} />
            <Route path={PathConstants.EMPLOYEE} element={<Employee />} />
            <Route
              path={PathConstants.INVENTORY_EMPLOYEE}
              element={<InventoryEmployee />}
            />

            <Route
              path={PathConstants.DETAIL_PAGE}
              element={<EmployeeDetailPage />}
            />
            <Route
              path={PathConstants.INVENTORY_DETAIL_PAGE}
              element={<InventoryEmployeeDetailPage />}
            />
            <Route path={PathConstants.ADD_LEAD} element={<CreateNewLead />} />
            <Route path={PathConstants.ERROR_403} element={<ErrorHandler />} />
            <Route
              path={PathConstants.NOTIFICATION}
              element={<Notification />}
            />

            <Route path={PathConstants.CONTACTS} element={<Contacts />} />
            <Route
              path={PathConstants.INQUIRIES}
              element={<Inquiries />}
            ></Route>
            <Route
              path={PathConstants.ALLOCATED}
              element={<Allocated />}
            ></Route>
            <Route
              path={PathConstants.CREATE_QUOTATION}
              element={<CreateQuotion />}
            />
            <Route
              path={PathConstants.WARE_HOUSE_MANAGEMENT}
              element={<WarehouseManagement />}
            />
            <Route
              path={PathConstants.VENDOR_MANAGEMENT}
              element={<VendorManagement />}
            />
            <Route
              path={PathConstants.CATEGORY_MANAGEMENT}
              element={<CategoryManagement />}
            />
            <Route
              path={PathConstants.FINISHED_GOODS}
              element={<FinishedGoods />}
            />
            <Route
              path={PathConstants.UNCLASSIFIED}
              element={<Unclassified />}
            />
            <Route path={PathConstants.SAMPLE} element={<SampleSpecimens />} />
            <Route
              path={PathConstants.ADD_CONSUMABLE}
              element={<AddConsumable />}
            />
            <Route path={PathConstants.ADD_SAMPLE} element={<AddSpecimens />} />
            <Route path={PathConstants.CONSUMABLE} element={<Consumables />} />
            <Route path={PathConstants.ASSETS} element={<Assets />} />
            <Route path={PathConstants.ADD_ASSETS} element={<AddAssets />} />

            <Route
              path={PathConstants.REJECTED_PRODUCTS}
              element={<RejectedMaterials />}
            />
            <Route
              path={PathConstants.ADD_REJECTED_PRODUCT}
              element={<AddRejectedProducts />}
            />
            <Route
              path={PathConstants.ADD_RAW_MATERIAL}
              element={<AddRawMaterials />}
            />
            <Route
              path={PathConstants.RAW_MATERIALs}
              element={<RawMaterials />}
            />
            <Route path={PathConstants.ADD_GOODS} element={<AddNewGoods />} />

            <Route
              path={PathConstants.ADD_UNCLASSIFIED}
              element={<AddUnclassified />}
            />

            <Route
              path={PathConstants.ADD_CATEGORY}
              element={<AddCategory />}
            />
            <Route path={PathConstants.ADD_LINING} element={<AddNewLining />} />
            <Route path={PathConstants.ADD_JOB_CARD} element={<AddJobCard />} />

            <Route path={PathConstants.FOR_LINING} element={<ForLining />} />
            <Route path={PathConstants.MOULDS} element={<Molds />} />

            <Route path={PathConstants.JOB_CARD} element={<JobCard />} />
            <Route path={PathConstants.ADD_MOULDS} element={<AddMould />} />

            <Route path={PathConstants.PROFILE} element={<Profile />}></Route>
          </Route>
          {/* <Route
            path="/pdf"
            element={
              <PDFViewer className="h-full-vh w-full">
                <QuotationExport />
              </PDFViewer>
            }
          ></Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
