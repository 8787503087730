import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { env } from "../config/env";
import { getAccessToken } from "../helpers/localStorage";
import { Assets } from "../interface/assets";
import {
  IApiResponse,
  IForgotPasswordResponse,
  ILoginRequest,
  ILoginResponse,
  IResetPasswordRequest,
  IResetPasswordResponse,
} from "../interface/authInterface";
import { InventoryItem } from "../interface/category";
import { ConsumableItem, ConsumableItem2 } from "../interface/consumable";
import {
  FastMoving,
  FinishedGoodDashboard,
  JobDetails,
  Reservation,
} from "../interface/dashboard";
import {
  Item,
  MainObject,
  NewLogData,
  ReservationDataType,
  ReservedData,
} from "../interface/finishedGoods";
import {
  ForLiningInventoryItem,
  ForLiningInventoryItem2,
} from "../interface/forLining";
import {
  RawMaterialsProduct,
  RawMaterialsProduct2,
} from "../interface/rawMaterials";
import {
  RejectedProductsDataType,
  RejectedProductsDataType2,
} from "../interface/rejectedProducts";
import { Specimen } from "../interface/specimen";
import {
  UnclassifiedDataType,
  UnclassifiedDataType2,
} from "../interface/unclassified";
import { User } from "../interface/user";
import { Vendor } from "../interface/vendor";
import {
  activateWarehousePayload,
  CategoryApiResponse,
  CategoryApiResponse2,
  CategoryData,
  CreateSelectValuesPayload2,
  getSelectValues,
  getSelectValuesByid,
  getSelectValuesParams,
  UpdateWarehousePayload,
  WarehouseApiResponse,
  WarehouseApiResponseById,
  WarehouseFormValues,
} from "../interface/warehouse";

interface CustomError {
  data: {
    message: string;
  };
  status: string;
}
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: env.development.API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getAccessToken();
      if (token) {
        headers.set("Authorization", `Token ${token}`);
      }
      return headers;
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
  tagTypes: [
    "GetAllContacts",
    "GetContactsById",
    "GetUserById",
    "GetSalesById",
    "GetAllSalesLead",
    "GetLeadById",
    "GetDiscount",
    "GetQuataionList",
    "GetSalesAfterQuotation",
    "GetQuotationById",
    "GetGroup",
    "GetAllNap",
    "GetNpdById",
    "GetStatus",
    "GetShareQuotation",
    "GetSalesUnderExecutive",
    "GetAllOrders",
    "GetDeliveryStatus",
    "GetDispatchMode",
    "GetOrdersById",
    "GetAllAllocatedData",
    "GetShareOrder",
    "GetAllDashboardCount",
    "GetAllDashboardManagerCount",
    "GetdropDownList",
    "GetAllDashboardPieChartCount",
    "GetAllDashboardBarChartCount",
    "GetAllDashboardBar2ChartCount",
    "GetAllRegion",
    "GetAllDesignation",
    "GetAllnature",
    "GetAllSource",
    "GetAllCategory",
    "GetAllTypes",
    "GetAllIndustry",
    "GetNotification",
    "GetAllDashboardFunnelChartCount",
    "GetWarehouseList",
    "GetWarehouseById",
    "GetAllSelectList",
    "GetSelectById",
    "GetCategoryList",
    "GetInventoryList",
    "GetInventoryListById",
    "vendorList",
    "GetVendorById",
    "GetSubCategories",
    "GetWarehouseExcel",
    "categoryExcel",
    "vendorExcel",
    "GetTreeCategories",
    "FinishedGoods",
    "GetFinishedGoodsExcel",
    "GetFinishedGoodsListById",
    "GetLoginEntries",
    "GetAllUnclassified",
    "GetUnclassifiedListById",
    "GetUnclassifiedExcel",
    "GetReservationListById",
    "GetReservationList",
    "RejectedGoods",
    "GetRejectedGoodsExcel",
    "GetRejectedProductsById",
    "GetRawMaterialsById",
    "RawMaterials",
    "GetRawMaterialsExcel",
    "GetForLiningList",
    "GetJobLiningExcel",
    "GetJobLiningListById",
    "GetAllJobInHand",
    "GetJobInHandExcel",
    "GetJobInHandById",
    "GetAllMoulds",
    "GetMouldsExcel",
    "GetMouldsById",
    "GetInventoryExecutive",
    "GetAllDashboardWorkDelay",
    "GetAllDashboardFastMoving",
    "GetAllDashboardFinishedGoods",
    "GetAllDashboardRawMaterials",
    "GetAssetsExcel",
    "GetAssetsListById",
    "GetAssetsList",
    "GetAllConsumable",
    "GetConsumableListById",
    "GetConsumableExcel",
    "GetAllSpecimen",
    "GetSpecimenListById",
    "GetSpecimenExcel",
  ],
  endpoints: (builder) => ({
    // login api
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: credentials,
      }),
    }),
    // reset-password
    resetPassword: builder.mutation<
      IResetPasswordResponse,
      IResetPasswordRequest
    >({
      query: ({ credentials, uid, token }) => ({
        url: `/reset-password?uidb64=${uid}&token=${token}`,
        method: "POST",
        body: credentials,
      }),
    }),
    // forgot-password
    forgotPassword: builder.mutation<
      IForgotPasswordResponse,
      IForgotPasswordResponse
    >({
      query: (email) => ({
        url: `/forgot-password-link`,
        method: "POST",
        body: email,
      }),
    }),
    //userbyId
    getUserDetailById: builder.query<any, any>({
      query: (id) => ({
        url: `/me/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetUserById"],
    }),
    //edit profile
    editUser: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/me/${id}/`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["GetUserById"],
    }),

    //create-contacts
    createContacts: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/${id}/contacts/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["GetAllContacts"],
    }),
    //create-contacts
    editContacts: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/contacts/${id}/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["GetContactsById", "GetAllContacts"],
    }),
    //update-backlog
    editBacklog: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `inventory/${id}/backdate-entry/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["GetInventoryExecutive"],
    }),
    //get-all-contacts
    getAllContacts: builder.query<any, any>({
      query: ({ id, params, search, body }) => ({
        url: `/${id}/contacts/?q=${search}&filter_data=${encodeURIComponent(
          body
        )}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetAllContacts"],
    }),

    //get-contacts-by-id
    getContactsById: builder.query<any, any>({
      query: (id) => ({
        url: `/contacts/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetContactsById"],
    }),
    //delete-contacts-by-id
    deleteContactsById: builder.mutation<any, any>({
      query: (id) => ({
        url: `/contacts/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetAllContacts"],
    }),
    //get-sales-by-id
    getSalesById: builder.query<any, any>({
      query: (id) => ({
        url: `/${id}/sales_lead/`,
        method: "GET",
      }),
      providesTags: ["GetSalesById"],
    }),
    //create-lead
    createLead: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/${id}/sales_lead/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["GetAllSalesLead", "GetAllContacts"],
    }),

    //get-all-source
    getSources: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=source`,
        method: "GET",
      }),
      providesTags: ["GetAllSource"],
    }),
    //get-all-types
    getTypes: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=type`,
        method: "GET",
      }),
      providesTags: ["GetAllTypes"],
    }),
    //get-all-nature
    getNature: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=nature`,
        method: "GET",
      }),
      providesTags: ["GetAllnature"],
    }),
    //get-all-designation
    getDesignation: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=designation`,
        method: "GET",
      }),
      providesTags: ["GetAllDesignation"],
    }),
    //get-all-source
    getSource: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=source`,
        method: "GET",
      }),
      providesTags: ["GetAllSource"],
    }),
    //get-industry
    getIndustry: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=industry`,
        method: "GET",
      }),
      providesTags: ["GetAllIndustry"],
    }),
    //get-industry
    getRegion: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=region`,
        method: "GET",
      }),
      providesTags: ["GetAllRegion"],
    }),
    //get-group
    getGroup: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=group`,
        method: "GET",
      }),
      providesTags: ["GetGroup"],
    }),
    //get-all-sales-lead
    getAllSalesLead: builder.query<any, any>({
      query: ({ id, params, search, body }) => ({
        url: `/${id}/sales_lead/?q=${search}&filter_data=${encodeURIComponent(
          body
        )}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetAllSalesLead"],
    }),
    //get-sales-details
    getSalesDetails: builder.query<any, any>({
      query: (id) => ({
        url: `/sales_lead/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetLeadById"],
    }),
    //get-all-sales-lead
    getLeadById: builder.query<any, any>({
      query: (id) => ({
        url: `/sales_lead/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetLeadById"],
    }),
    //get-downloaded-data
    getDownloadData: builder.query<any, any>({
      query: (id) => ({
        url: `contacts-download/?id=[${id}]`,
        method: "GET",
        responseHandler: async (response) => {
          // Read the response as a Blob
          const blob = await response.blob();

          // Process the Blob or store its URL or metadata in the state
          // For example, you can store the URL of the Blob in the state
          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
    }),
    getSalesDownloadData: builder.query<any, any>({
      query: (id) => ({
        url: `sales-lead-download/?id=[${id}]`,
        method: "GET",
        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
    }),
    // get-company-names
    getCompanyNames: builder.query<any, string>({
      query: (searchTerm) => ({
        url: `/sales-lead-auto-complete/`,
        method: "GET",
      }),
      providesTags: ["GetSalesById"],
    }),
    //get-enquiry-category
    getEnquiryCategory: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=enquirycategory`,
        method: "GET",
      }),
      providesTags: ["GetAllCategory"],
    }),
    //get-enquiry-status
    getEnquiryStatus: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=followupstatusstage`,
        method: "GET",
      }),
      providesTags: ["GetStatus"],
    }),
    //create-quatation
    createCreateQuotation: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/${id}/quotation/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["GetSalesById", "GetQuataionList", "GetAllSalesLead"],
    }),
    //get-discount
    getDiscount: builder.query<any, any>({
      query: () => ({
        url: `/discounts/`,
        method: "GET",
      }),
      providesTags: ["GetDiscount"],
    }),
    //get-all-quotation
    getQuotation: builder.query<any, any>({
      query: ({ id, params, search, status, body }) => ({
        url: `/${id}/list-quotations-created-by-leads/?q=${search}&is_closed=${status}&filter_data=${encodeURIComponent(
          body
        )}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetQuataionList"],
    }),
    editLead: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/sales_lead/${id}/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [
        "GetLeadById",
        "GetAllSalesLead",
        "GetAllAllocatedData",
      ],
    }),
    closeQuotation: builder.mutation<any, any>({
      query: (id) => ({
        url: `quotation-close/${id}/`,
        method: "PUT",
      }),
      invalidatesTags: ["GetQuataionList", "GetShareQuotation"],
    }),
    deleteLead: builder.mutation<any, any>({
      query: (id) => ({
        url: `/sales_lead/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetAllSalesLead"],
    }),

    //get-quotation-by-id
    getQuotationById: builder.query<any, any>({
      query: (id) => ({
        url: `/quotations/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetQuotationById"],
    }),
    //get-all-sakes-after-quotation-creation
    getSalesAfterQuotation: builder.query<any, any>({
      query: (id) => ({
        url: `/sales-lead/detail-page/${id}/after-quotation-create`,
        method: "GET",
      }),
      providesTags: ["GetSalesAfterQuotation"],
    }),
    //get-all-npd-after-quotation-creation
    getSalesAfterNpdQuotation: builder.query<any, any>({
      query: (id) => ({
        url: `/npd/detail-page/${id}/after-quotation-create`,
        method: "GET",
      }),
      providesTags: ["GetSalesAfterQuotation"],
    }),
    //create-quotation
    editQuotation: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/quotations/${id}/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [
        "GetQuotationById",
        "GetQuataionList",
        "GetSalesAfterQuotation",
        "GetShareQuotation",
      ],
    }),
    //get-downloaded-data
    getQuotationDownloadData: builder.query<Blob, number>({
      query: (id) => ({
        url: `quotation-download/?id=[${id}]`,
        method: "GET",
        responseHandler: async (response) => {
          // Read the response as a Blob
          const blob = await response.blob();

          // Process the Blob or store its URL or metadata in the state
          // For example, you can store the URL of the Blob in the state
          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
    }),
    //create-order
    createCreateOrder: builder.mutation<any, any>({
      query: ({ body, id, qid }) => ({
        url: `/${id}/orders/${qid}/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["GetQuataionList", "GetAllOrders"],
    }),
    // upload file
    uploadFile: builder.mutation({
      query: (body) => ({
        url: "/upload_file/",
        method: "POST",
        body: body,
      }),
    }),

    //create-npd
    createCreateNpd: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/${id}/npd/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["GetAllNap"],
    }),
    //get-Npd
    getNpd: builder.query<any, any>({
      query: ({ id, params, search, body }) => ({
        url: `/${id}/npd/?q=${search}&filter_data=${encodeURIComponent(body)}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetAllNap"],
    }),
    //get-Npd-by-id
    getNpdById: builder.query<any, any>({
      query: (id) => ({
        url: `/npd/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetNpdById", "GetAllNap"],
    }),
    //edt-npd
    editNpd: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/npd/${id}/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["GetNpdById", "GetAllNap"],
    }),
    //delete-npd

    deleteNpd: builder.mutation<any, any>({
      query: (id) => ({
        url: `/npd/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetAllNap"],
    }),
    //npd-download
    getNpdDownload: builder.query<any, any>({
      query: (id) => ({
        url: `npd-download/?id=[${id}]`,
        method: "GET",
        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
    }),
    //allocated-download
    getAllocatedDownload: builder.query<any, any>({
      query: (id) => ({
        url: `/allocated-download/?id=[${id}]`,
        method: "GET",
        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
    }),
    getExecutives: builder.query<any, any>({
      query: ({ id, search, params }) => ({
        url: `/${id}/list-of-executives-under-managers/?q=${search}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetContactsById"],
    }),

    //getall inventory employees
    getInventoryExecutives: builder.query<
      {
        results: {
          data: User[];
        };
        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/manager/employees/`, // The endpoint for the GET request
        method: "GET", // GET request method
        params, // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["GetInventoryExecutive"],
    }),

    getSalesUnderExecutive: builder.query<any, any>({
      query: ({ id, params, search, body }) => ({
        url: `/${id}/list-of-sales-lead-customers/?q=${search}&filter_data=${encodeURIComponent(
          body
        )}`,
        method: "GET",
        params,
      }),
      providesTags: ["GetSalesUnderExecutive"],
    }),
    //get-all-sales-lead
    getCustomerById: builder.query<any, any>({
      query: (id) => ({
        url: `/sales_lead/${id}/`,
        method: "GET",
      }),
      // providesTags: ["GetSalesUnderExecutive"],
    }),
    getManagersList: builder.query<any, any>({
      query: ({ user_id, id }) => ({
        url: `/${user_id}/allocation-executive-dropdown-list/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetdropDownList"],
    }),
    allocateManager: builder.mutation<any, any>({
      query: ({ body, managerId, salesId }) => ({
        url: `/allocate-lead-by-manager/${salesId}/?allocate_to=${managerId}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [
        "GetNpdById",
        "GetAllNap",
        "GetSalesUnderExecutive",
        "GetdropDownList",
      ],
    }),
    //create-quatation
    createNpdQuotation: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/${id}/npd-quotation/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["GetSalesById"],
    }),
    //quotation-share

    getQuotationShare: builder.query<any, any>({
      query: ({ id, user_id }) => ({
        url: `/${id}/share-quotation-details/${user_id}/`,
        method: "GET",
      }),
      providesTags: ["GetShareQuotation"],
    }),
    //get-all-orders

    getAllOrders: builder.query<any, any>({
      query: ({ id, order_status, search, params, body }) => ({
        url: `/${id}/list-orders-created-by-leads/?order_status=${order_status}&q=${search}&filter_data=${encodeURIComponent(
          body
        )}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetAllOrders"],
    }),
    //pass-quotation-id-to-get-npd-or-saleslead

    getQuotationOrNpd: builder.query<any, any>({
      query: (id) => ({
        url: `/pass-quotation-id-to-get-npd-or-saleslead/${id}/`,
        method: "GET",
      }),
    }),
    //order-by-id

    getOrderById: builder.query<any, any>({
      query: (id) => ({
        url: `/orders/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetOrdersById"],
    }),

    //update-order

    updateOrder: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/orders/${id}/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [
        "GetOrdersById",
        "GetAllOrders",
        "GetShareOrder",
        "GetAllDashboardCount",
        "GetAllDashboardManagerCount",
      ],
    }),
    // upload order file
    uploadOrderFile: builder.mutation({
      query: (body) => ({
        url: "/order-upload-file/",
        method: "POST",
        body: body,
      }),
    }),
    // Delete order file
    deleteOrderFile: builder.mutation({
      query: (body) => ({
        url: "/order-upload-file/",
        method: "DELETE",
        body: body,
      }),
    }),
    //order-share

    getOrderShare: builder.query<any, any>({
      query: ({ id, user_id }) => ({
        url: `/${id}/share-order-details/${user_id}/`,
        method: "GET",
      }),
      providesTags: ["GetShareOrder"],
    }),
    //get-delivery-status
    getDeliveryStatus: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=orderstatus`,
        method: "GET",
      }),
      providesTags: ["GetDeliveryStatus"],
    }),
    //get-dispatch-mode
    getDispatchMode: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=dispatchmode`,
        method: "GET",
      }),
      providesTags: ["GetDispatchMode"],
    }),
    //order-download
    getOrderDownloadData: builder.query<any, any>({
      query: (id) => ({
        url: `order-download/?id=[${id}]`,
        method: "GET",
        responseHandler: async (response) => {
          // Read the response as a Blob
          const blob = await response.blob();

          // Process the Blob or store its URL or metadata in the state
          // For example, you can store the URL of the Blob in the state
          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
    }),
    //get-all-allocated
    getAllAllocated: builder.query<any, any>({
      query: ({ id, search, params, body }) => ({
        url: `/allocated-list-for-manager-or-executives/?${id}&q=${search}&filter_data=${encodeURIComponent(
          body
        )}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetAllAllocatedData"],
    }),
    //get-all-dashboard-count
    getDashboardCount: builder.query<any, any>({
      query: (id) => ({
        url: `/${id}/executive-dashboard-count/`,
        method: "GET",
      }),
      providesTags: ["GetAllDashboardCount"],
    }),
    //get-all-dashboard-count-manager
    getDashboardManagerCount: builder.query<any, any>({
      query: (id) => ({
        url: `/${id}/manager-dashboard-count/`,
        method: "GET",
      }),
      providesTags: ["GetAllDashboardManagerCount"],
    }),
    //get-all-dashboard-count-pie-chart
    getDashboardPieChartCount: builder.query<any, any>({
      query: ({ id, date_id }) => ({
        url: `/${id}/pie-chart?day_no=${date_id}`,
        method: "GET",
      }),
      providesTags: ["GetAllDashboardPieChartCount"],
    }),
    //get-all-dashboard-count-funnel-chart
    getDashboardFunnelChartCount: builder.query<any, any>({
      query: ({ id, source }) => ({
        url: `/${id}/source-dashboard-count/?option=${source}`,
        method: "GET",
      }),
      providesTags: ["GetAllDashboardFunnelChartCount"],
    }),
    //get-all-dashboard-count-bar-chart
    getDashboardBarChartCount: builder.query<any, any>({
      query: ({ id, date_id }) => ({
        url: `/${id}/bar-chart-for-customer-list?year=${date_id}`,
        method: "GET",
      }),
      providesTags: ["GetAllDashboardBarChartCount"],
    }),
    //get-all-dashboard-count-bar-2-chart
    getDashboardBar2ChartCount: builder.query<any, any>({
      query: ({ id, date_id }) => ({
        url: `/${id}/sales-closing-list?year=${date_id}`,
        method: "GET",
      }),
      providesTags: ["GetAllDashboardBar2ChartCount"],
    }),
    //get-notification
    getNotifications: builder.query<any, any>({
      query: (id) => ({
        url: `/${id}/list-of-notifications`,
        method: "GET",
      }),
      providesTags: ["GetNotification"],
    }),
    //get-warehouse
    getWarehouse: builder.query<WarehouseApiResponse, Record<string, any>>({
      query: (params = {}) => ({
        url: `/inventory/warehouse/`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetWarehouseList"],
    }),
    //get-for-lining
    getForLining: builder.query<
      {
        results: {
          data: ForLiningInventoryItem[];
        };
        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/job-lining/`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetForLiningList"],
    }),
    //get-assets
    getAssets: builder.query<
      {
        results: {
          data: ForLiningInventoryItem[];
        };
        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/assets/`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetAssetsList"],
    }),
    //get-categories
    getCategories: builder.query<
      CategoryApiResponse,
      { id: string | number; params?: Record<string, any> }
    >({
      query: ({ id, params = {} }) => {
        const { warehouse, ...restParams } = params;
        const warehouseSegment = warehouse ? `[${warehouse}]` : "";
        return {
          url: `inventory/categories/${id}/sub-categories/?warehouse=${warehouseSegment}`,
          method: "GET",
          params: restParams, // Pass additional query params here
        };
      },
      providesTags: ["GetCategoryList"],
    }),

    //get-categories
    getAllInventries: builder.query<
      CategoryApiResponse2,
      { params?: Record<string, any> }
    >({
      query: (params = {}) => ({
        url: `/inventory/categories/`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetInventoryList"],
    }),
    //get-reservation
    getAllReservation: builder.query<
      { data: ReservationDataType[] },
      number // Accept a number as the parameter
    >({
      query: (id) => ({
        url: `/inventory/reservations/?id=${id}`, // Use the ID directly in the URL
        method: "GET",
      }),
      providesTags: ["GetReservationList"],
    }),
    //get-reservation
    getAllReservationById: builder.query<
      { data: ReservedData },
      number // Accept a number as the parameter
    >({
      query: (id) => ({
        url: `/inventory/reservations/${id}/`, // Use the ID directly in the URL
        method: "GET",
      }),
      providesTags: ["GetReservationListById"],
    }),
    //get-finished-goods-id
    getAllFinishedGoodsById: builder.query<{ data: Item }, number>({
      query: (id) => ({
        url: `inventory/finished-goods/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetFinishedGoodsListById"],
    }),
    //get-raw-materials-id
    getAllRawMaterialsById: builder.query<
      { data: RawMaterialsProduct2 },
      number
    >({
      query: (id) => ({
        url: `inventory/raw-materials/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetRawMaterialsById"],
    }),
    //get-job-in-hand-id
    getAllJobInHandById: builder.query<{ data: any }, number>({
      query: (id) => ({
        url: `inventory/job-in-hand/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetJobInHandById"],
    }),
    //get-moulds-id
    getAllMouldsById: builder.query<{ data: any }, number>({
      query: (id) => ({
        url: `inventory/moulds/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetMouldsById"],
    }),
    //get-rejected-products-id
    getAllRejectedProductsById: builder.query<
      { data: RejectedProductsDataType2 },
      number
    >({
      query: (id) => ({
        url: `inventory/rejected-products/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetRejectedProductsById"],
    }),
    //get-unclassified-id
    getAllUnclassifiedById: builder.query<
      { data: UnclassifiedDataType2 },
      number
    >({
      query: (id) => ({
        url: `inventory/unclassified/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetUnclassifiedListById"],
    }),
    //get-consumable-id
    getAllConsumableById: builder.query<{ data: ConsumableItem2 }, number>({
      query: (id) => ({
        url: `inventory/consumables/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetConsumableListById"],
    }),
    //get-specimen-id
    getAllSpecimenById: builder.query<{ data: Specimen }, number>({
      query: (id) => ({
        url: `inventory/specimens/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetSpecimenListById"],
    }),
    //get-job-lining-id
    getAllJobLiningById: builder.query<
      { data: ForLiningInventoryItem2 },
      number
    >({
      query: (id) => ({
        url: `inventory/job-lining/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetJobLiningListById"],
    }),
    //get-job-lining-id
    getAllAssetsById: builder.query<{ data: Assets }, number>({
      query: (id) => ({
        url: `inventory/assets/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetAssetsListById"],
    }),
    //get-categories-id
    getAllInventriesById: builder.query<
      { data: InventoryItem },
      { id: number }
    >({
      query: ({ id }) => ({
        url: `inventory/categories/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetInventoryListById"],
    }),
    //get-warehouse-by-id
    getWarehouseById: builder.query<WarehouseApiResponseById, number>({
      query: (id) => ({
        url: `/inventory/warehouse/${id}/`, // Include the ID in the URL path
        method: "GET",
      }),
      providesTags: ["GetWarehouseById"],
    }),
    //get-vendor-by-id
    getVendorById: builder.query<{ data: Vendor }, number>({
      query: (id) => ({
        url: `/inventory/vendors/${id}/`, // Include the ID in the URL path
        method: "GET",
      }),
      providesTags: ["GetVendorById"],
    }),
    //create-region
    createRegion: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/create/${id}/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [
        "GetAllRegion",
        "GetAllDesignation",
        "GetAllTypes",
        "GetAllCategory",
        "GetAllSource",
        "GetAllIndustry",
        "GetAllnature",
        "GetDispatchMode",
        "GetGroup",
      ],
    }),
    //create-warehouse
    createWarehouse: builder.mutation<IApiResponse, WarehouseFormValues>({
      query: (body) => ({
        url: `/inventory/warehouse/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["GetWarehouseList"],
    }),
    //create-categories
    createCategory: builder.mutation<IApiResponse, CategoryData>({
      query: (body) => ({
        url: `/inventory/categories/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["GetCategoryList", "GetTreeCategories"],
    }),
    //create-reservation
    createReservation: builder.mutation<IApiResponse, ReservationDataType>({
      query: (body) => ({
        url: `/inventory/reservations/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [
        "GetReservationList",
        "FinishedGoods",
        "GetFinishedGoodsListById",
      ],
    }),
    //create-reservation
    updateReservation: builder.mutation<
      IApiResponse,
      { id: number; body: ReservationDataType }
    >({
      query: ({ id, body }) => ({
        url: `/inventory/reservations/${id}/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [
        "GetReservationList",
        "FinishedGoods",
        "GetFinishedGoodsListById",
      ],
    }),
    //common-createSelectApi-post
    createSelectApi: builder.mutation<
      {
        data: {
          field: string;
          id: number;
          model: number;
          name: string;
        };
        message: string;
      },
      CreateSelectValuesPayload2
    >({
      query: ({ body }: CreateSelectValuesPayload2) => ({
        url: `inventory/dropdown/`, // Use the passed `name` in the query string
        method: "POST",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: ["GetAllSelectList"], // Invalidate the cache for the warehouse list
    }),
    //create-vendor
    createVendor: builder.mutation<IApiResponse, Vendor>({
      query: (body: Vendor) => ({
        url: `/inventory/vendors/`, // Use the passed `name` in the query string
        method: "POST",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: ["vendorList"], // Invalidate the cache for the warehouse list
    }),
    //create-login-entries
    createLoginEntries: builder.mutation<IApiResponse, NewLogData>({
      query: (body: NewLogData) => ({
        url: `/inventory/log-entries/`, // Use the passed `name` in the query string
        method: "POST",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: ["GetLoginEntries"], // Invalidate the cache for the warehouse list
    }),
    //create-finished-goods
    createFinishedGoods: builder.mutation<IApiResponse, MainObject>({
      query: (body: MainObject) => ({
        url: `/inventory/finished-goods/`, // Use the passed `name` in the query string
        method: "POST",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: ["FinishedGoods", "GetFinishedGoodsExcel"], // Invalidate the cache for the warehouse list

      // Invalidate the cache for the warehouse list
    }),
    //create-raw-materials
    createRawMaterials: builder.mutation<IApiResponse, RawMaterialsProduct>({
      query: (body: RawMaterialsProduct) => ({
        url: `/inventory/raw-materials/`, // Use the passed `name` in the query string
        method: "POST",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: ["RawMaterials", "GetRawMaterialsExcel"], // Invalidate the cache for the warehouse list

      // Invalidate the cache for the warehouse list
    }),
    //create-rejected-products
    createRejectedProducts: builder.mutation<
      IApiResponse,
      RejectedProductsDataType
    >({
      query: (body: RejectedProductsDataType) => ({
        url: `/inventory/rejected-products/`, // Use the passed `name` in the query string
        method: "POST",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: ["RejectedGoods", "GetRejectedGoodsExcel"], // Invalidate the cache for the warehouse list

      // Invalidate the cache for the warehouse list
    }),
    //create-unclassified
    createUnclassified: builder.mutation<IApiResponse, UnclassifiedDataType>({
      query: (body: UnclassifiedDataType) => ({
        url: `/inventory/unclassified/`, // Use the passed `name` in the query string
        method: "POST",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: ["GetAllUnclassified", "GetUnclassifiedExcel"], // Invalidate the cache for the warehouse list

      // Invalidate the cache for the warehouse list
    }),
    //create-unclassified
    createConsumable: builder.mutation<IApiResponse, ConsumableItem>({
      query: (body: ConsumableItem) => ({
        url: `/inventory/consumables/`, // Use the passed `name` in the query string
        method: "POST",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: ["GetAllConsumable", "GetConsumableExcel"], // Invalidate the cache for the warehouse list

      // Invalidate the cache for the warehouse list
    }),
    //create-specimen
    createSpecimen: builder.mutation<IApiResponse, ConsumableItem>({
      query: (body: ConsumableItem) => ({
        url: `/inventory/specimens/`, // Use the passed `name` in the query string
        method: "POST",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: ["GetAllSpecimen", "GetSpecimenExcel"], // Invalidate the cache for the warehouse list

      // Invalidate the cache for the warehouse list
    }),
    //create-for-lining
    createForLining: builder.mutation<IApiResponse, any>({
      query: (body: any) => ({
        url: `/inventory/job-lining/`, // Use the passed `name` in the query string
        method: "POST",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: ["GetForLiningList", "GetJobLiningExcel"], // Invalidate the cache for the warehouse list

      // Invalidate the cache for the warehouse list
    }),
    //create-assets
    createAssets: builder.mutation<IApiResponse, any>({
      query: (body: any) => ({
        url: `/inventory/assets/`, // Use the passed `name` in the query string
        method: "POST",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: ["GetAssetsList", "GetAssetsExcel"], // Invalidate the cache for the warehouse list

      // Invalidate the cache for the warehouse list
    }),
    //create-job-hand
    createJobHand: builder.mutation<IApiResponse, FormData>({
      query: (body) => ({
        url: `/inventory/job-in-hand/`, // Use the passed `name` in the query string
        method: "POST",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: ["GetAllJobInHand", "GetJobInHandExcel"], // Invalidate the cache for the warehouse list

      // Invalidate the cache for the warehouse list
    }),
    //create-job-hand
    createMould: builder.mutation<IApiResponse, FormData>({
      query: (body) => ({
        url: `/inventory/moulds/`, // Use the passed `name` in the query string
        method: "POST",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: ["GetAllMoulds", "GetMouldsExcel"], // Invalidate the cache for the warehouse list

      // Invalidate the cache for the warehouse list
    }),
    // get-all-vendor
    // getWarehouse: builder.query<WarehouseApiResponse, Record<string, any>>({
    //approve-for-lining
    approveForLining: builder.mutation<IApiResponse, any>({
      query: ({ id, body }) => ({
        url: `/inventory/job-lining/${id}/`, // Use the passed `name` in the query string
        method: "PUT",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: [
        "GetForLiningList",
        "GetJobLiningExcel",
        "GetJobLiningListById",
      ], // Invalidate the cache for the warehouse list

      // Invalidate the cache for the warehouse list
    }),
    //update-assets
    updateAssets: builder.mutation<IApiResponse, any>({
      query: ({ id, body }) => ({
        url: `/inventory/assets/${id}/`, // Use the passed `name` in the query string
        method: "PUT",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: ["GetAssetsList", "GetAssetsExcel", "GetAssetsListById"], // Invalidate the cache for the warehouse list

      // Invalidate the cache for the warehouse list
    }),
    //assign-for-lining

    assignForLining: builder.mutation<IApiResponse, any>({
      query: ({ id, body }) => ({
        url: `/inventory/job-lining/${id}/assign/`, // Use the passed `name` in the query string
        method: "PUT",
        body: body, // Include the `body` in the POST request
      }),
      invalidatesTags: [
        "GetForLiningList",
        "GetJobLiningExcel",
        "GetJobLiningListById",
      ], // Invalidate the cache for the warehouse list

      // Invalidate the cache for the warehouse list
    }),
    getAllVendor: builder.query<
      {
        results: {
          data: Vendor[];
        };
        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/vendors/`, // The endpoint for the GET request
        method: "GET", // GET request method
        params, // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["vendorList"],
    }),

    //getall finished_goods
    getAllFinishedGoods: builder.query<
      {
        results: {
          data: MainObject[];
        };
        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/finished-goods/`, // The endpoint for the GET request
        method: "GET", // GET request method
        params, // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["FinishedGoods"],
    }),
    //getall raw_materials
    getAllRawMaterials: builder.query<
      {
        results: {
          data: RawMaterialsProduct[];
        };
        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/raw-materials/`, // The endpoint for the GET request
        method: "GET", // GET request method
        params, // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["RawMaterials"],
    }),
    //getall job-in-hand
    getAllJobInHand: builder.query<
      {
        results: {
          data: any;
        };
        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/job-in-hand/`, // The endpoint for the GET request
        method: "GET", // GET request method
        params, // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["GetAllJobInHand"],
    }),
    //getall moulds
    getAllMoulds: builder.query<
      {
        results: {
          data: any;
        };
        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/moulds/`, // The endpoint for the GET request
        method: "GET", // GET request method
        params, // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["GetAllMoulds"],
    }),
    //getall finished_goods
    getAllRejectedProducts: builder.query<
      {
        results: {
          data: RejectedProductsDataType[];
        };
        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/rejected-products/`, // The endpoint for the GET request
        method: "GET", // GET request method
        params, // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["RejectedGoods"],
    }),
    //getall unclassified
    getAllUnClassified: builder.query<
      {
        results: {
          data: UnclassifiedDataType[];
        };
        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/unclassified/`, // The endpoint for the GET request
        method: "GET", // GET request method
        params, // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["GetAllUnclassified"],
    }),
    //getall unclassified
    getAllConsumable: builder.query<
      {
        results: {
          data: UnclassifiedDataType[];
        };
        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/consumables/`, // The endpoint for the GET request
        method: "GET", // GET request method
        params, // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["GetAllConsumable"],
    }),
    //getall specimen
    getAllSpecimen: builder.query<
      {
        results: {
          data: UnclassifiedDataType[];
        };
        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/specimens/`, // The endpoint for the GET request
        method: "GET", // GET request method
        params, // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["GetAllSpecimen"],
    }),
    //getall dashboard  work delay
    getAllDashboardWorkDelay: builder.query<
      {
        data: {
          data: JobDetails[];
        };
        count: number;
      },
      Record<string, any>
    >({
      query: () => ({
        url: `/inventory/dashboard/work-delay/`, // The endpoint for the GET request
        method: "GET", // GET request method
        // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["GetAllDashboardWorkDelay"],
    }),
    //getall dashboard  fast moving
    getAllDashboardFastMovingGoods: builder.query<
      {
        data: FastMoving[];

        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/dashboard/fast-moving-goods/`, // The endpoint for the GET request
        method: "GET", // GET request method
        params, // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["GetAllDashboardFastMoving"],
    }),
    //getall dashboard  finished goods
    getAllDashboardFinishedGoods: builder.query<
      {
        results: {
          data: FinishedGoodDashboard[];
        };
        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/dashboard/finished-goods/`, // The endpoint for the GET request
        method: "GET", // GET request method
        params, // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["GetAllDashboardFinishedGoods"],
    }),
    //getall dashboard  finished goods
    getAllDashboardRawMaterials: builder.query<
      {
        results: {
          data: Reservation[];
        };
        count: number;
      },
      Record<string, any>
    >({
      query: (params = {}) => ({
        url: `/inventory/dashboard/raw-materials/`, // The endpoint for the GET request
        method: "GET", // GET request method
        params, // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["GetAllDashboardRawMaterials"],
    }),
    //getall login entries
    getAllLoginEntries: builder.query<
      {
        data: NewLogData[];

        count: number;
      },
      Record<string, any>
    >({
      query: (params: {
        model_id: string;
        object_id: string;
        date?: string;
      }) => ({
        url: `inventory/log-entries/`, // The endpoint for the GET request
        method: "GET", // GET request method
        params,
        // Passing query parameters as params
      }),
      // Optionally, add cache invalidation or other features
      providesTags: ["GetLoginEntries"],
    }),
    //common-createSelectApi-get
    getSelectApi: builder.query<getSelectValues, getSelectValuesParams>({
      query: (body) => ({
        url: `inventory/dropdown/?field=${body?.name}&model=${body?.model}`,
        method: "GET",
      }),
      providesTags: ["GetAllSelectList"],
    }),
    //common-createCategoriesSubCategories
    getCategoriesAndSubCategories: builder.query<any, any>({
      query: (body) => ({
        url: `/inventory/categories/${body}/tree/?status=True`,
        method: "GET",
      }),
      providesTags: ["GetTreeCategories"],
    }),
    //common-createSelectApi-get-By-id
    getSelectByIdApi: builder.query<getSelectValuesByid, string>({
      query: (body) => ({
        url: `inventory/model/${body}/`,
        method: "GET",
      }),
      providesTags: ["GetSelectById"],
    }),
    //download-warehouse-excel
    getExcelWarehouseById: builder.query<
      getSelectValuesByid,
      { id: Array<number> }
    >({
      query: (id) => ({
        url: `/inventory/warehouse/excel/?id=[${id}]`,
        method: "GET",

        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
      providesTags: ["GetWarehouseExcel"],
    }),
    //download-vendor-excel
    getExcelVendorById: builder.query<
      getSelectValuesByid,
      { id: Array<number>; params?: Record<string, any> }
    >({
      query: ({ id, params = {} }) => ({
        url: `/inventory/vendors/excel/?id=[${id}]`,
        method: "GET",
        params: params,
        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
      providesTags: ["vendorExcel"],
    }),
    //export category
    //download-warehouse-excel
    getExcelCategoryById: builder.query<
      getSelectValuesByid,
      { id: Array<number> }
    >({
      query: (id) => ({
        url: `/inventory/categories/excel/?id=[${id}]`,
        method: "GET",

        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
      providesTags: ["categoryExcel"],
    }),
    //finished-goods-excel
    getExcelFinishedGoodsById: builder.query<
      getSelectValuesByid,
      { id: Array<number> }
    >({
      query: (id) => ({
        url: `/inventory/finished-goods/excel/?id=[${id}]`,
        method: "GET",

        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
      providesTags: ["GetFinishedGoodsExcel"],
    }),
    //raw-materials-excel
    getExcelMaterialsById: builder.query<
      getSelectValuesByid,
      { id: Array<number> }
    >({
      query: (id) => ({
        url: `/inventory/raw-materials/excel/?id=[${id}]`,
        method: "GET",

        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
      providesTags: ["GetRawMaterialsExcel"],
    }),
    //rejected-goods-excel
    getExcelRejectedGoodsById: builder.query<
      getSelectValuesByid,
      { id: Array<number> }
    >({
      query: (id) => ({
        url: `/inventory/rejected-products/excel/?id=[${id}]`,
        method: "GET",

        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
      providesTags: ["GetRejectedGoodsExcel"],
    }),
    //rejected-goods-excel
    getExcelJobInHandById: builder.query<
      getSelectValuesByid,
      { id: Array<number> }
    >({
      query: (id) => ({
        url: `/inventory/job-in-hand/excel/?id=[${id}]`,
        method: "GET",

        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
      providesTags: ["GetJobInHandExcel"],
    }),
    //rejected-moulds-excel
    getExcelMouldsById: builder.query<
      getSelectValuesByid,
      { id: Array<number> }
    >({
      query: (id) => ({
        url: `/inventory/moulds/excel/?id=[${id}]`,
        method: "GET",

        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
      providesTags: ["GetMouldsExcel"],
    }),
    //unclassified-goods-excel
    getExcelUnclassifiedById: builder.query<
      getSelectValuesByid,
      { id: Array<number> }
    >({
      query: (id) => ({
        url: `/inventory/unclassified/excel/?id=[${id}]`,
        method: "GET",

        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
      providesTags: ["GetUnclassifiedExcel"],
    }),
    //consumable-goods-excel
    getExcelConsumableById: builder.query<
      getSelectValuesByid,
      { id: Array<number> }
    >({
      query: (id) => ({
        url: `/inventory/consumables/excel/?id=[${id}]`,
        method: "GET",

        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
      providesTags: ["GetConsumableExcel"],
    }),
    //specimen-goods-excel
    getExcelSpecimenById: builder.query<
      getSelectValuesByid,
      { id: Array<number> }
    >({
      query: (id) => ({
        url: `/inventory/specimens/excel/?id=[${id}]`,
        method: "GET",

        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
      providesTags: ["GetSpecimenExcel"],
    }),
    //job-lining-excel
    getExcelJobLiningById: builder.query<
      getSelectValuesByid,
      { id: Array<number> }
    >({
      query: (id) => ({
        url: `/inventory/job-lining/excel/?id=[${id}]`,
        method: "GET",

        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
      providesTags: ["GetJobLiningExcel"],
    }),
    //excel-assets
    getExcelAssetsById: builder.query<
      getSelectValuesByid,
      { id: Array<number> }
    >({
      query: (id) => ({
        url: `/inventory/assets/excel/?id=[${id}]`,
        method: "GET",

        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
      providesTags: ["GetAssetsExcel"],
    }),
    //update-warehouse
    updateWarehouse: builder.mutation<IApiResponse, UpdateWarehousePayload>({
      query: ({ id, body }) => ({
        url: `/inventory/warehouse/${id}/`,
        method: "PUT",
        body: body, // Spread the body and include the id
      }),
      invalidatesTags: ["GetWarehouseById", "GetWarehouseList"],
    }),
    //update-vendor
    updateVendor: builder.mutation<IApiResponse, { id: number; body: Vendor }>({
      query: ({ id, body }) => ({
        url: `/inventory/vendors/${id}/`,
        method: "PUT",
        body: body, // Spread the body and include the id
      }),
      invalidatesTags: ["GetVendorById", "vendorList"],
    }),
    //update-loginHistory
    updateLoginHistory: builder.mutation<
      IApiResponse,
      { id: number; body: NewLogData }
    >({
      query: ({ id, body }) => ({
        url: `/inventory/log-entries/${id}/`,
        method: "PUT",
        body: body, // Spread the body and include the id
      }),
      invalidatesTags: ["GetLoginEntries"],
    }),
    //update-loginHistory
    updateReserved: builder.mutation<
      IApiResponse,
      {
        id: number;
        body: {
          reserved_on: any;
        };
      }
    >({
      query: ({ id, body }) => ({
        url: `/inventory/reservations/${id}/`,
        method: "PUT",
        body: body, // Spread the body and include the id
      }),
      invalidatesTags: [
        "GetReservationList",
        "FinishedGoods",
        "GetFinishedGoodsListById",
      ],
    }),
    //finished-goods
    updateFinishedGoods: builder.mutation<
      IApiResponse,
      { id: number; body: MainObject }
    >({
      query: ({ id, body }) => ({
        url: `/inventory/finished-goods/${id}/`,
        method: "PUT",
        body: body, // Spread the body and include the id
      }),
      invalidatesTags: [
        "GetFinishedGoodsListById",
        "FinishedGoods",
        "GetFinishedGoodsExcel",
      ],
    }),
    //raw-materials
    updateRawMaterials: builder.mutation<
      IApiResponse,
      { id: number; body: RawMaterialsProduct }
    >({
      query: ({ id, body }) => ({
        url: `/inventory/raw-materials/${id}/`,
        method: "PUT",
        body: body, // Spread the body and include the id
      }),
      invalidatesTags: [
        "GetRawMaterialsById",
        "RawMaterials",
        "GetRawMaterialsExcel",
      ],
    }),
    //update -unclassified
    updateUnclassified: builder.mutation<
      IApiResponse,
      { id: number; body: UnclassifiedDataType }
    >({
      query: ({ id, body }) => ({
        url: `/inventory/unclassified/${id}/`,
        method: "PUT",
        body: body, // Spread the body and include the id
      }),
      invalidatesTags: [
        "GetAllUnclassified",
        "GetUnclassifiedListById",
        "GetUnclassifiedExcel",
      ],
    }),
    //update -consumable
    updateConsumable: builder.mutation<
      IApiResponse,
      { id: number; body: UnclassifiedDataType }
    >({
      query: ({ id, body }) => ({
        url: `/inventory/consumables/${id}/`,
        method: "PUT",
        body: body, // Spread the body and include the id
      }),
      invalidatesTags: [
        "GetAllConsumable",
        "GetConsumableListById",
        "GetConsumableExcel",
      ],
    }),
    //update -consumable
    updateSpecimen: builder.mutation<
      IApiResponse,
      { id: number; body: UnclassifiedDataType }
    >({
      query: ({ id, body }) => ({
        url: `/inventory/specimens/${id}/`,
        method: "PUT",
        body: body, // Spread the body and include the id
      }),
      invalidatesTags: [
        "GetAllSpecimen",
        "GetSpecimenListById",
        "GetSpecimenExcel",
      ],
    }),
    //update - for-lining
    updateForLining: builder.mutation<
      IApiResponse,
      { id: number; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/inventory/job-lining/${id}/`,
        method: "PUT",
        body: body, // Spread the body and include the id
      }),
      invalidatesTags: [
        "GetForLiningList",
        "GetJobLiningListById",
        "GetJobLiningExcel",
      ],
    }),
    //update - for-lining
    updateJobInHand: builder.mutation<
      IApiResponse,
      { id: number; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/inventory/job-in-hand/${id}/`,
        method: "PUT",
        body: body, // Spread the body and include the id
      }),
      invalidatesTags: [
        "GetAllJobInHand",
        "GetJobInHandById",
        "GetJobLiningExcel",
      ],
    }),
    //update -moulds
    updateMoulds: builder.mutation<
      IApiResponse,
      { id: number; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/inventory/moulds/${id}/`,
        method: "PUT",
        body: body, // Spread the body and include the id
      }),
      invalidatesTags: ["GetAllMoulds", "GetMouldsById", "GetMouldsExcel"],
    }),
    //update rejected product
    updateRejectedProduct: builder.mutation<
      IApiResponse,
      { id: number; body: RejectedProductsDataType }
    >({
      query: ({ id, body }) => ({
        url: `/inventory/rejected-products/${id}/`,
        method: "PUT",
        body: body, // Spread the body and include the id
      }),
      invalidatesTags: [
        "GetForLiningList",
        "GetRejectedProductsById",
        "GetRejectedGoodsExcel",
      ],
    }),
    //update-category
    updateCategory: builder.mutation<
      IApiResponse,
      { id: number; body: CategoryData }
    >({
      query: ({ id, body }) => ({
        url: `/inventory/categories/${id}/`,
        method: "PUT",
        body: body, // Spread the body and include the id
      }),
      invalidatesTags: [
        "GetCategoryList",
        "GetInventoryListById",
        "GetTreeCategories",
      ],
    }),
    //deactivate-warehouse
    deleteWarehouse: builder.mutation<IApiResponse, number>({
      query: (id) => ({
        url: `/inventory/warehouse/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "GetWarehouseList",
        "GetWarehouseById",
        "GetWarehouseExcel",
      ],
    }),
    //deactivate-job-in-hand
    deleteJobInHand: builder.mutation<IApiResponse, number>({
      query: (id) => ({
        url: `/inventory/job-in-hand/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "GetJobInHandExcel",
        "GetJobInHandById",
        "GetAllJobInHand",
      ],
    }),
    //deactivate-moulds
    deleteMoulds: builder.mutation<IApiResponse, number>({
      query: (id) => ({
        url: `/inventory/moulds/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetMouldsExcel", "GetMouldsById", "GetAllMoulds"],
    }),
    //deactivate-vendor
    deleteVendor: builder.mutation<IApiResponse, number>({
      query: (id) => ({
        url: `/inventory/vendors/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["vendorList", "GetVendorById", "vendorExcel"],
    }),
    //deactivate-reservation
    deleteReservation: builder.mutation<IApiResponse, number>({
      query: (id) => ({
        url: `/inventory/reservations/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "GetReservationList",
        "FinishedGoods",
        "GetFinishedGoodsListById",
      ],
    }),
    //activate-warehouse
    activateWarehouse: builder.mutation<IApiResponse, activateWarehousePayload>(
      {
        query: ({ id, body }) => ({
          url: `/inventory/warehouse/${id}/activate/`,
          method: "PUT",
          body: body,
        }),
        invalidatesTags: [
          "GetWarehouseList",
          "GetWarehouseById",
          "GetWarehouseExcel",
        ],
      }
    ),
    //deactivate-vendor
    deleteFinishedGoods: builder.mutation<IApiResponse, number>({
      query: (id) => ({
        url: `/inventory/finished-goods/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "FinishedGoods",
        "GetFinishedGoodsListById",
        "GetFinishedGoodsExcel",
      ],
    }),
    //deactivate-raw materials
    deleteRawMaterials: builder.mutation<
      IApiResponse,
      { id: number; reason: string }
    >({
      query: ({ id, reason }) => ({
        url: `/inventory/raw-materials/${id}/`,
        method: "DELETE",
        body: { reason },
      }),
      invalidatesTags: [
        "RawMaterials",
        "GetRawMaterialsById",
        "GetRawMaterialsExcel",
      ],
    }),
    //deactivate-rejected
    deleteRejectedProducts: builder.mutation<IApiResponse, number>({
      query: (id) => ({
        url: `/inventory/rejected-products/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "RejectedGoods",
        "GetRejectedProductsById",
        "GetRejectedGoodsExcel",
      ],
    }),
    //deactivate-unclassified
    deleteUnclassified: builder.mutation<IApiResponse, number>({
      query: (id) => ({
        url: `/inventory/unclassified/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "GetAllUnclassified",
        "GetUnclassifiedListById",
        "GetUnclassifiedExcel",
      ],
    }),
    //deactivate-consumable
    deleteConsumable: builder.mutation<IApiResponse, number>({
      query: (id) => ({
        url: `/inventory/consumables/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "GetAllConsumable",
        "GetConsumableListById",
        "GetConsumableExcel",
      ],
    }),
    //deactivate-job-ling
    deleteJobLining: builder.mutation<IApiResponse, number>({
      query: (id) => ({
        url: `/inventory/job-lining/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "GetForLiningList",
        "GetJobLiningListById",
        "GetJobLiningExcel",
      ],
    }),
    //deactivate-assets
    deleteAssets: builder.mutation<IApiResponse, number>({
      query: (id) => ({
        url: `/inventory/assets/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "GetForLiningList",
        "GetAssetsListById",
        "GetAssetsExcel",
      ],
    }),
    //deactivate-specimens
    deleteSpecimens: builder.mutation<IApiResponse, number>({
      query: (id) => ({
        url: `/inventory/specimens/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "GetAllSpecimen",
        "GetSpecimenListById",
        "GetSpecimenExcel",
      ],
    }),
    //activate-warehouse
    activateFinishedGoods: builder.mutation<
      IApiResponse,
      activateWarehousePayload
    >({
      query: ({ id, body }) => ({
        url: `/inventory/finished-goods/${id}/activate/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [
        "FinishedGoods",
        "GetFinishedGoodsListById",
        "GetFinishedGoodsExcel",
      ],
    }),
    //activate-raw materials
    activateRawMaterials: builder.mutation<
      IApiResponse,
      activateWarehousePayload
    >({
      query: ({ id, body }) => ({
        url: `/inventory/raw-materials/${id}/activate/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [
        "RawMaterials",
        "GetRawMaterialsById",
        "GetRawMaterialsExcel",
      ],
    }),
    //activate-rejected-goods
    activateRejectedGoods: builder.mutation<
      IApiResponse,
      activateWarehousePayload
    >({
      query: ({ id, body }) => ({
        url: `/inventory/rejected-products/${id}/activate/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [
        "RejectedGoods",
        "GetRejectedProductsById",
        "GetRejectedGoodsExcel",
      ],
    }),
    //activate-warehouse
    activateUnclassified: builder.mutation<
      IApiResponse,
      activateWarehousePayload
    >({
      query: ({ id, body }) => ({
        url: `/inventory/unclassified/${id}/activate/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [
        "GetAllUnclassified",
        "GetUnclassifiedListById",
        "GetUnclassifiedExcel",
      ],
    }),
    //activate-consumable
    activateConsumable: builder.mutation<
      IApiResponse,
      activateWarehousePayload
    >({
      query: ({ id, body }) => ({
        url: `/inventory/consumables/${id}/activate/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [
        "GetAllConsumable",
        "GetConsumableListById",
        "GetConsumableExcel",
      ],
    }),
    //activate-specimens
    activateSpecimens: builder.mutation<IApiResponse, activateWarehousePayload>(
      {
        query: ({ id, body }) => ({
          url: `/inventory/specimens/${id}/activate/`,
          method: "PUT",
          body: body,
        }),
        invalidatesTags: [
          "GetAllSpecimen",
          "GetSpecimenListById",
          "GetSpecimenExcel",
        ],
      }
    ),
    //activate-for-lining
    activateForLining: builder.mutation<IApiResponse, activateWarehousePayload>(
      {
        query: ({ id, body }) => ({
          url: `/inventory/job-lining/${id}/activate/`,
          method: "PUT",
          body: body,
        }),
        invalidatesTags: [
          "GetForLiningList",
          "GetJobLiningListById",

          "GetJobLiningExcel",
        ],
      }
    ),
    //activate-for-lining
    activateAssets: builder.mutation<IApiResponse, activateWarehousePayload>({
      query: ({ id, body }) => ({
        url: `/inventory/assets/${id}/activate/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["GetAssetsList", "GetAssetsListById", "GetAssetsExcel"],
    }),
    //activate-warehouse
    activateVendor: builder.mutation<IApiResponse, activateWarehousePayload>({
      query: ({ id, body }) => ({
        url: `/inventory/vendors/${id}/activate/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["vendorList", "GetVendorById", "vendorExcel"],
    }),
    //activate-job-in-hand
    activateJobInHand: builder.mutation<IApiResponse, activateWarehousePayload>(
      {
        query: ({ id, body }) => ({
          url: `/inventory/job-in-hand/${id}/activate/`,
          method: "PUT",
          body: body,
        }),
        invalidatesTags: [
          "GetAllJobInHand",
          "GetJobInHandById",

          "GetJobInHandExcel",
        ],
      }
    ),
    //activate-moulds
    activateMoulds: builder.mutation<IApiResponse, activateWarehousePayload>({
      query: ({ id, body }) => ({
        url: `/inventory/moulds/${id}/activate/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["GetAllMoulds", "GetMouldsById", "GetMouldsExcel"],
    }),
    //deactivate-category
    deleteCategory: builder.mutation<IApiResponse, number>({
      query: (id) => ({
        url: `/inventory/categories/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "GetCategoryList",
        "GetInventoryListById",
        "categoryExcel",
        "GetTreeCategories",
      ],
    }),
    //activate-category
    activateCategory: builder.mutation<IApiResponse, activateWarehousePayload>({
      query: ({ id }) => ({
        url: `/inventory/categories/${id}/activate/`,
        method: "PUT",
      }),
      invalidatesTags: [
        "GetCategoryList",
        "GetInventoryListById",
        "categoryExcel",
      ],
    }),
    //seen
    readedNotification: builder.mutation<any, any>({
      query: (notificationId) => ({
        url: `/notification-read/${notificationId}`,
        method: "PUT",
      }),
      invalidatesTags: ["GetNotification"],
    }),
  }),
});
export const {
  useLoginMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useGetUserDetailByIdQuery,
  useCreateContactsMutation,
  useGetAllContactsQuery,
  useGetContactsByIdQuery,
  useLazyGetContactsByIdQuery,
  useEditContactsMutation,
  useDeleteContactsByIdMutation,
  useEditUserMutation,
  useGetSalesByIdQuery,
  useCreateLeadMutation,
  useGetSourcesQuery,
  useGetTypesQuery,
  useGetNatureQuery,
  useGetDesignationQuery,
  useGetSourceQuery,
  useGetIndustryQuery,
  useGetRegionQuery,
  useGetDownloadDataQuery,
  useGetCompanyNamesQuery,
  useGetEnquiryCategoryQuery,
  useGetAllSalesLeadQuery,
  useGetLeadByIdQuery,
  useLazyGetLeadByIdQuery,
  useCreateCreateQuotationMutation,
  useGetDiscountQuery,
  useGetQuotationQuery,
  useGetSalesDownloadDataQuery,
  useEditLeadMutation,
  useDeleteLeadMutation,
  useGetSalesAfterQuotationQuery,
  useGetQuotationByIdQuery,
  useEditQuotationMutation,
  useGetQuotationDownloadDataQuery,
  useLazyGetQuotationDownloadDataQuery,
  useCreateCreateOrderMutation,
  useUploadFileMutation,
  useGetGroupQuery,
  useCreateCreateNpdMutation,
  useGetNpdQuery,
  useGetNpdByIdQuery,
  useEditNpdMutation,
  useDeleteNpdMutation,
  useGetNpdDownloadQuery,
  useGetExecutivesQuery,
  useCreateNpdQuotationMutation,
  useGetEnquiryStatusQuery,
  useGetQuotationShareQuery,
  useGetSalesUnderExecutiveQuery,
  useGetCustomerByIdQuery,
  useGetManagersListQuery,
  useAllocateManagerMutation,
  useLazyGetSalesAfterQuotationQuery,
  useGetSalesAfterNpdQuotationQuery,
  useLazyGetSalesAfterNpdQuotationQuery,
  useGetAllOrdersQuery,
  useGetQuotationOrNpdQuery,
  useGetOrderByIdQuery,
  useUpdateOrderMutation,
  useUploadOrderFileMutation,
  useDeleteOrderFileMutation,
  useGetDeliveryStatusQuery,
  useGetDispatchModeQuery,
  useGetOrderDownloadDataQuery,
  useGetAllAllocatedQuery,
  useGetOrderShareQuery,
  useGetDashboardCountQuery,
  useGetDashboardManagerCountQuery,
  useGetAllocatedDownloadQuery,
  useGetDashboardPieChartCountQuery,
  useGetDashboardBarChartCountQuery,
  useGetDashboardBar2ChartCountQuery,
  useCreateRegionMutation,
  useGetNotificationsQuery,
  useGetDashboardFunnelChartCountQuery,
  useReadedNotificationMutation,
  useCloseQuotationMutation,
  useCreateWarehouseMutation,
  useGetWarehouseQuery,
  useUpdateWarehouseMutation,
  useGetWarehouseByIdQuery,
  useDeleteWarehouseMutation,
  useActivateWarehouseMutation,
  useCreateSelectApiMutation,
  useGetSelectApiQuery,
  useCreateCategoryMutation,
  useGetSelectByIdApiQuery,
  useGetCategoriesQuery,
  useGetAllInventriesQuery,
  useGetAllInventriesByIdQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useActivateCategoryMutation,
  useGetExcelWarehouseByIdQuery,
  useGetExcelCategoryByIdQuery,
  useCreateVendorMutation,
  useGetAllVendorQuery,
  useGetVendorByIdQuery,
  useUpdateVendorMutation,
  useActivateVendorMutation,
  useDeleteVendorMutation,
  useGetExcelVendorByIdQuery,
  useGetCategoriesAndSubCategoriesQuery,
  useCreateFinishedGoodsMutation,
  useGetAllFinishedGoodsQuery,
  useGetExcelFinishedGoodsByIdQuery,
  useActivateFinishedGoodsMutation,
  useDeleteFinishedGoodsMutation,
  useGetAllFinishedGoodsByIdQuery,
  useUpdateFinishedGoodsMutation,
  useCreateLoginEntriesMutation,
  useGetAllLoginEntriesQuery,
  useUpdateLoginHistoryMutation,
  useCreateUnclassifiedMutation,
  useGetAllUnClassifiedQuery,
  useGetAllUnclassifiedByIdQuery,
  useGetExcelUnclassifiedByIdQuery,
  useActivateUnclassifiedMutation,
  useDeleteUnclassifiedMutation,
  useUpdateUnclassifiedMutation,
  useCreateReservationMutation,
  useGetAllReservationQuery,
  useDeleteReservationMutation,
  useUpdateReservedMutation,
  useCreateRejectedProductsMutation,
  useGetAllRejectedProductsQuery,
  useGetExcelRejectedGoodsByIdQuery,
  useDeleteRejectedProductsMutation,
  useActivateRejectedGoodsMutation,
  useGetAllRejectedProductsByIdQuery,
  useUpdateRejectedProductMutation,
  useCreateRawMaterialsMutation,
  useActivateRawMaterialsMutation,
  useGetAllRawMaterialsQuery,
  useDeleteRawMaterialsMutation,
  useGetAllRawMaterialsByIdQuery,
  useUpdateRawMaterialsMutation,
  useGetExcelMaterialsByIdQuery,
  useUpdateReservationMutation,
  useGetAllReservationByIdQuery,
  useCreateForLiningMutation,
  useGetForLiningQuery,
  useGetExcelJobLiningByIdQuery,
  useActivateForLiningMutation,
  useDeleteJobLiningMutation,
  useGetAllJobLiningByIdQuery,
  useUpdateForLiningMutation,
  useApproveForLiningMutation,
  useAssignForLiningMutation,
  useCreateJobHandMutation,
  useGetAllJobInHandQuery,
  useGetExcelJobInHandByIdQuery,
  useActivateJobInHandMutation,
  useDeleteJobInHandMutation,
  useUpdateJobInHandMutation,
  useGetAllJobInHandByIdQuery,
  useActivateMouldsMutation,
  useCreateMouldMutation,
  useDeleteMouldsMutation,
  useGetAllMouldsByIdQuery,
  useGetAllMouldsQuery,
  useGetExcelMouldsByIdQuery,
  useUpdateMouldsMutation,
  useGetInventoryExecutivesQuery,
  useEditBacklogMutation,
  useGetAllDashboardFastMovingGoodsQuery,
  useGetAllDashboardFinishedGoodsQuery,
  useGetAllDashboardRawMaterialsQuery,
  useGetAllDashboardWorkDelayQuery,
  useActivateAssetsMutation,
  useCreateAssetsMutation,
  useDeleteAssetsMutation,
  useGetAllAssetsByIdQuery,
  useGetAssetsQuery,
  useUpdateAssetsMutation,
  useGetExcelAssetsByIdQuery,
  useActivateConsumableMutation,
  useCreateConsumableMutation,
  useDeleteConsumableMutation,
  useGetAllConsumableByIdQuery,
  useGetAllConsumableQuery,
  useGetExcelConsumableByIdQuery,
  useUpdateConsumableMutation,
  useCreateSpecimenMutation,
  useGetAllSpecimenQuery,
  useGetExcelSpecimenByIdQuery,
  useDeleteSpecimensMutation,
  useUpdateSpecimenMutation,
  useGetAllSpecimenByIdQuery,
  useActivateSpecimensMutation,
} = api;
